import { Button, Grid, useTheme } from '@mui/material';
import { IAnalyticsTabs3DData } from 'common/defines/clients';
import DataViewModal from 'components/OverviewClient/DataViewModal';
import { CROP_HIERARCHY, PIE_CHART_NO_DATA_LABEL, QUERY_KEY, SEVERITY } from 'constants/constants';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import GlobalModel from 'echarts/types/src/model/Global';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getDashboardProductionCostByBlock } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setProductionExpenditure } from 'store/slices/dashboardWidgetSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { getColorPaletteForAnalyticsTab, getTopThreeSortedPieChartData, IPieChatData } from '../analyticsTabConstants';

export const ExpenditurePerDivision = (props: { dateRange: any }) => {
  const theme = useTheme();
  const { dateRange } = props;
  const dispatch = useAppDispatch();
  const { selectedLevelHierarchyList } = useAppSelector(mapViewSelector);
  const [dataViewOptions, setDataViewOptions] = useState<EChartsOption>({});
  const [dataViewModal, setDataViewModal] = useState<boolean>(false);
  const [productionExpenditureData, setProductionExpenditureData] = useState<EChartsOption>({});
  const [levelHierarchyObject, setLevelHierarchyObject] = useState<any>(null);
  const [showAllData, setShowAllData] = useState(false);
  const [productionPieChartdata, setProductionPieChartdata] = useState<IPieChatData[]>([{ name: '', value: 0 }]);
  const iconPath =
    'path://M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,5v3H5V5H19z M19,10v4H5v-4H19z M5,19v-3h14v3H5z';

  const findSeverity = (totalValue: number = 0, value: number = 0): string => {
    const partSize = totalValue / 3;
    if (totalValue && value) {
      if (value <= partSize) {
        return SEVERITY.LOW;
      } else if (value <= partSize * 2) {
        return SEVERITY.MEDIUM;
      } else {
        return SEVERITY.HIGH;
      }
    } else {
      return SEVERITY.LOW;
    }
  };

  const colorPalette = getColorPaletteForAnalyticsTab(theme.palette.background.paper);

  useEffect(() => {
    let levelData: any[] =
      selectedLevelHierarchyList && selectedLevelHierarchyList.length ? [...selectedLevelHierarchyList] : [];

    if (levelData.length) {
      let estateObj = levelData.filter((ele) => ele?.level === 1)[0] || {};
      let divisionObj = levelData.filter((ele) => ele?.level === 2)[0] || {};
      let yopObj = levelData.filter((ele) => ele?.level === 3)[0] || {};
      let blockObj = levelData.filter((ele) => ele?.level === 4)[0] || {};
      setLevelHierarchyObject({
        estateId: estateObj?.levelId || '',
        divisionName: divisionObj?.levelName || '',
        yop: yopObj?.levelName || '',
        blockName: blockObj?.levelName || '',
      });
    } else {
      setLevelHierarchyObject(null);
    }
  }, [selectedLevelHierarchyList]);

  const pieDataRevamp = (pieList: IPieChatData[]) => {
    setProductionExpenditureData((prevState: any) => ({
      ...prevState,
      series: [{ ...prevState?.series[0], data: pieList }],
    }));
  };

  useEffect(() => {
    if (productionPieChartdata?.length) {
      if (showAllData) {
        pieDataRevamp(productionPieChartdata);
      } else if (productionPieChartdata?.length > 3) {
        pieDataRevamp(getTopThreeSortedPieChartData(productionPieChartdata));
      }
    }
  }, [showAllData, productionPieChartdata]);

  useQuery(
    [QUERY_KEY.GET_PRODUCTION_COST, dateRange, levelHierarchyObject],
    () =>
      getDashboardProductionCostByBlock({
        year: moment(dateRange.startDate).format('YYYY').toString(),
        month: moment(dateRange.startDate).format('M').toString(),
        estateId: levelHierarchyObject?.estateId || '',
        divName: levelHierarchyObject?.divisionName || '',
        yop: levelHierarchyObject?.yop || '',
        blockName: levelHierarchyObject?.blockName || '',
      }),
    {
      onSuccess: (data: any) => {
        let productionExpenditure: any[] = [];
        let expenditureData = data?.data && data?.data.length ? [...data?.data] : [];
        let groupKey: string = CROP_HIERARCHY.LOCATION;
        if (
          levelHierarchyObject?.estateId &&
          !levelHierarchyObject?.divisionName &&
          !levelHierarchyObject?.yop &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.DIV_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          !levelHierarchyObject?.yop &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.YOP;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          levelHierarchyObject?.yop &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          levelHierarchyObject?.yop &&
          levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else {
          groupKey = CROP_HIERARCHY.LOCATION;
        }

        const groupedChartExpenditureData: any = {};
        const groupedChartBarData: any = {};
        for (let item of expenditureData) {
          const name = item[groupKey];
          if (!groupedChartBarData[name]) {
            groupedChartBarData[name] = 0;
          }
          groupedChartBarData[name] += (item?.totalYOPMillwieght || 0) / 1000;

          if (!groupedChartExpenditureData[name]) {
            groupedChartExpenditureData[name] = 0;
          }

          groupedChartExpenditureData[name] += item?.productionExpenditure || 0;
        }

        let mappedChartBarData = Object.keys(groupedChartBarData).map((item) => ({
          name: item,
          value: groupedChartBarData[item],
        }));

        productionExpenditure = Object.keys(groupedChartExpenditureData).map((item) => ({
          name: item,
          value: groupedChartExpenditureData[item] ? groupedChartExpenditureData[item].toFixed(2) : 0,
        }));

        let finalChartBarData: IAnalyticsTabs3DData[] = [];
        if (mappedChartBarData.length) {
          const highestValueObject = mappedChartBarData.reduce((prev: any, current: any) => {
            return prev.value > current.value ? prev : current;
          }, {});

          finalChartBarData = mappedChartBarData.map((ele: any) => {
            return {
              ...ele,
              severity: findSeverity(highestValueObject?.value || 0, ele?.value || 0),
            };
          });
        }

        const productionExpenditureDonut = {
          color: colorPalette.donutProductionChart,
          colorBy: 'series',
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>RM {c}</b>`,
          },
          title: {
            text: 'Production Expenditure',
            show: true,
            subtext: 'Per Block',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'productionExpenditure');
                },
                icon: iconPath,
              },
            },
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              top: '10%',
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                show: true,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                  formatter: 'RM {c}',
                },
              },
              labelLine: {
                show: true,
              },
              color: productionExpenditure?.length ? null : ['#ddd'],
              data: productionExpenditure?.length ? productionExpenditure : PIE_CHART_NO_DATA_LABEL,
            },
          ],
        };

        setProductionPieChartdata(productionExpenditure);
        setProductionExpenditureData(productionExpenditureDonut);
        dispatch(setProductionExpenditure(finalChartBarData));
      },
    }
  );

  const handleDataViewModal = (isShow: boolean, EChartOption: EChartsOption, chartName: string) => {
    setDataViewOptions({ ...EChartOption, chartName: chartName });
    setDataViewModal(isShow);
  };

  return (
    <>
      <DataViewModal
        EChartsOption={dataViewOptions}
        dataViewModal={dataViewModal}
        setDataViewModal={setDataViewModal}
      />
      <Grid sx={{ position: 'relative' }}>
        {productionPieChartdata?.length > 3 && (
          <Button
            sx={{ position: 'absolute', right: '60px', zIndex: '1' }}
            onClick={() => setShowAllData(!showAllData)}>
            {showAllData ? 'Hide Others' : 'Show All'}
          </Button>
        )}
        <ReactEcharts option={productionExpenditureData} />
      </Grid>
    </>
  );
};
