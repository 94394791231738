import { Box, Card, Grid, Typography, useTheme } from "@mui/material"
import { useRightBarStyle } from "../../RightBarStyle";
// import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { TripStatusChart } from "./TripStatus";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { dashboardWidgetSelector } from 'store/slices/dashboardWidgetSlice';

export const LogisticTab = () => {
    const theme = useTheme();
    const classes = useRightBarStyle();
    const { logisticTripStatus } = useAppSelector(dashboardWidgetSelector);
    const [totalProduction, setTotalProduction] = useState<number>(0);
    const [averageProduction, setAverageProduction] = useState<number>(0);

    useEffect(() => {
        let totatlProduction: number = logisticTripStatus.reduce((sum, entry) => sum + entry.value, 0);
        let averageProduction: number = Number((totatlProduction / logisticTripStatus.length).toFixed(3)) || 0;
        setTotalProduction(Number(totatlProduction.toFixed(3)) || 0);
        setAverageProduction(averageProduction);
    }, [logisticTripStatus]);

    return (
        <Grid>
            <Card className={classes.totalProductionCardStyle}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box className={classes.borderRight}>
                            <Typography variant="h2" component="h2">Total Trips</Typography>
                            <Typography variant="h1" component="h1" mt={1}>{totalProduction}</Typography>
                            <Typography variant="subtitle2" component="p" mt={1} sx={{ color: theme.palette.color.grey7 }}>Compared to last month</Typography>
                            {/* <span className={classes.Percentage}> <ArrowDownward fontSize="small" /> 12%</span> */}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant="h2" component="h2">Avg Trips</Typography>
                            <Typography variant="h1" component="h1" mt={1}>{averageProduction} <small style={{ color: theme.palette.color.grey7 }}>/day</small> </Typography>
                            <Typography variant="subtitle2" component="p" mt={1} sx={{ color: theme.palette.color.grey7 }}>Compared to last month</Typography>
                            {/* <span className={classes.Percentage}><ArrowUpward fontSize="small" /> 12%</span> */}
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <Card className={classes.shadowUnsetBorderRadius}>
                <TripStatusChart />
            </Card>
        </Grid>)
}