import { IStreamlineOrderData, SettingUIName } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getStreamlineOrderList } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { SortDateEnum } from '../RightBarOld/IssuesTab/interfaces';

const StreamlineOrderLayer = () => {
  const { streamlineOrderDisplay, streamlineOrderSensorId: sensorId } = useAppSelector(rightBarSelector);

  const { clientSetting } = useQueryClientSetting();
  const uiSetting = clientSetting?.uiSetting;
  const strokeWidthValue = uiSetting && uiSetting.find((item) => item.name === SettingUIName.STROKE_WIDTH)?.value;

  const { data: streamlineInfo } = useQuery(
    [QUERY_KEY.GET_STREAMLINE_ORDER_LIST, sensorId],
    () => getStreamlineOrderList({ page: 1, perPage: 20, sortDate: SortDateEnum.DESC, sensorId }),
    {
      enabled: !!sensorId,
    }
  );

  const streamlineOrderList: IStreamlineOrderData[] = useMemo(() => {
    return streamlineInfo?.data?.docs;
  }, [streamlineInfo?.data?.docs]);

  const convertDataVector = (): GeoJSON.FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: streamlineOrderList
        ?.filter((item) => {
          const listVisibleOrder = streamlineOrderDisplay.filter((ele) => ele.visible).map((ele) => ele.order);
          return listVisibleOrder.includes(item.order);
        })
        .map(
          (item) =>
          ({
            type: 'Feature',
            geometry: item.geometry,
            properties: {
              color: item.colorCode,
            },
          } as GeoJSON.Feature)
        ),
    };
  };

  return (
    <>
      {streamlineOrderList?.length && (
        <Source id="streamline_order_source" type="geojson" data={convertDataVector()} lineMetrics={true}>
          <Layer
            id={`streamline_order`}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
              visibility: 'visible',
            }}
            paint={{
              'line-opacity': 1,
              'line-width': strokeWidthValue || 1.4,
              'line-color': ['get', 'color'],
            }}
          />
        </Source>
      )}
    </>
  );
};

export default StreamlineOrderLayer;
