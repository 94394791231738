import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Grid, useTheme } from '@mui/material';
import AppLayout from 'components/AppLayout';
import { ToolMapView } from 'components/MapView/ToolMapView';
import Minimap from 'components/Minimap';
import { useMapViewPageStyle } from 'pages/MapViewPage/MapViewPageStyle';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearAllTilingMapData, tilingMapSelector } from 'store/slices/tilingMapSlice';
import React3dMap from './3d-map';
import NavbarTilingMap from './NavbarTilingMap';
import ReactMapGLTiling from './react-mapgl-tiling';
import RightBarTilingMap from './right-bar-tiling-map';

const TilingMap = () => {
  const theme = useTheme();
  const classes = useMapViewPageStyle();
  const dispatch = useAppDispatch();

  const [isShowNavbar, setIsShowNavbar] = useState<boolean>(true);
  const [isShowRightBar, setIsShowRightBar] = useState<boolean>(false);
  const [mapStyle, setMapStyle] = useState<string>(
    theme.palette.mode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10'
  );

  const {
    rightBar: { selectedTabHistory },
  } = useAppSelector(tilingMapSelector);

  useEffect(() => {
    return () => {
      dispatch(clearAllTilingMapData());
    };
  }, [dispatch]);

  // set initial mapStyle
  useEffect(() => {
    setMapStyle(theme.palette.mode === 'dark' ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10');
  }, [theme.palette.mode]);

  const isShow3dMap = () => {
    const lastIndex = selectedTabHistory.slice(-1)[0];
    const beforeTheLastIndex = selectedTabHistory.slice(-2, -1)[0];

    // 1 is index of map3d tab in right bar
    if (selectedTabHistory.length > 1 && (lastIndex === 1 || (lastIndex !== 0 && beforeTheLastIndex === 1))) {
      return true;
    }
    return false;
  };

  return (
    <AppLayout>
      <ToolMapView showAnalytics={isShowRightBar} openAnalytic={setIsShowRightBar} />

      {/* Left Bar */}
      <Grid
        className={classes.columnNavbar}
        sx={{
          width: '400px',
          height: '100%',
          display: isShowNavbar ? '' : 'none',
          position: 'fixed',
          zIndex: 8,
          backgroundColor: theme.palette.background.default,
        }}>
        <NavbarTilingMap />
      </Grid>
      <Button
        sx={{
          left: isShowNavbar ? '388px' : ' 12px',
          position: 'absolute',
          top: '140px',
        }}
        className={classes.navBarButton}
        onClick={() => setIsShowNavbar((value) => !value)}>
        {isShowNavbar ? (
          <ArrowBackIosNewIcon className={classes.arrowIcon} fontSize="small" />
        ) : (
          <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />
        )}
      </Button>
      {/* Left Bar */}

      {/* Map View  */}
      <Box sx={{ width: '100%', height: 'calc(100% - 6px)' }}>
        {isShow3dMap() ? (
          <React3dMap isShowRightBar={isShowRightBar} />
        ) : (
          <ReactMapGLTiling mapStyle={mapStyle} isShowNavbar={isShowNavbar} />
        )}
      </Box>
      {/* Map View  */}

      {/* Right Bar */}
      <Box
        sx={{
          width: '355px',
          height: '100%',
          borderLeft: `1px solid ${theme.palette.divider}`,
          display: isShowRightBar ? '' : 'none',
          position: 'fixed',
          right: 0,
          top: '64px',
          backgroundColor: theme.palette.background.default,
        }}>
        <Button
          sx={{
            position: 'absolute',
            visibility: isShowRightBar ? 'visible' : 'hidden',
            right: '338px',
          }}
          classes={{ root: isShowRightBar ? classes.showPanelBtn : undefined }}
          onClick={() => setIsShowRightBar((value) => !value)}>
          {isShowRightBar && <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />}
        </Button>
        <RightBarTilingMap />
      </Box>
      {/* Right Bar */}
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: isShowNavbar ? '400px' : '20px',
          display: isShow3dMap() ? 'none' : 'block',
        }}>
        <Minimap onChangeStyleMap={(style) => setMapStyle(style)} />
      </Box>
    </AppLayout>
  );
};

export default TilingMap;
