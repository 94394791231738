import { Box, Button, Typography, useTheme } from '@mui/material';
import { Feature } from '@turf/turf';
import DrawControl from 'components/Common/DrawControl';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateFieldBoundary } from 'services/workspaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDrawingBoundary, tilingMapSelector } from 'store/slices/tilingMapSlice';

let initialControls = {
  point: false,
  line_string: false,
  polygon: true,
  trash: true,
};

const DrawBoundary = () => {
  const [features, setFeatures] = useState({});

  const {
    drawingBoundary: { isShow, _id },
  } = useAppSelector(tilingMapSelector);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const uploadBoundaryMutation = useMutation(updateFieldBoundary, {
    onMutate() {
      dispatch(changeDrawingBoundary({ isLoading: true }));
    },
    onSuccess() {
      dispatch(changeDrawingBoundary({ isLoading: false }));
      toast.success('Create boundary successful');
      queryClient.invalidateQueries([QUERY_KEY.GET_FIELD_DETAIL]);
    },
    onError() {
      dispatch(changeDrawingBoundary({ isLoading: false }));
      toast.error('Create boundary failed');
    },
  });

  const onUpdate = useCallback((e: { features: any }) => {
    setFeatures((currFeatures) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e: { features: any }) => {
    setFeatures((currFeatures) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const handleDrawDone = () => {
    const featureList = Object.values(features);
    const drawObjectInfo: Feature<any> = featureList[featureList.length - 1] as Feature<any>;
    const geometry = drawObjectInfo.geometry;
    dispatch(changeDrawingBoundary({ isShow: false, geometry }));

    const geojson: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: geometry,
          properties: {
            color: theme.palette.primary.main,
          },
        },
      ],
    };
    uploadBoundaryMutation.mutate({ _id, geojson });
  };

  // const handleCancelDraw = () => {
  //   dispatch(changeDrawingBoundary({ isShow: false, geometry: { coordinates: [] } }))
  // };

  return (
    <Box>
      {isShow && (
        <Box>
          <Box
            className="finish-drawing"
            sx={{
              position: 'absolute',
              backgroundColor: theme.palette.background.paper,
            }}>
            <Typography sx={{ display: 'inline-block', px: '8px' }}>Click here after finish drawing</Typography>
            <Button variant="outlined" size="small" onClick={handleDrawDone}>
              Done
            </Button>
            {/* {drawIssueShape.initialData?.id && (
              <Button variant="outlined" size="small" color="error" onClick={handleCancelDraw} sx={{ ml: '8px' }}>
                Cancel
              </Button>
            )} */}
          </Box>
          <DrawControl
            position="top-left"
            displayControlsDefault={false}
            controls={initialControls}
            defaultMode={'simple_select'}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Box>
      )}
    </Box>
  );
};

export default DrawBoundary;
