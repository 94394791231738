import { Box, Grid, Slider, styled, SxProps, Theme } from '@mui/material';
import { FORMAT_DATE, QUERY_KEY } from 'constants/constants';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useQuery } from 'react-query';
import { getAllDateLandUseAnalytic } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDateAnalyticSelected, changeRastersSelected, mapViewSelector } from 'store/slices/mapViewSlice';
import { changeAnalyticName } from 'store/slices/rightBarSlice';

const SliderCustom = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-track': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  '& .MuiSlider-mark': {
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    width: '8px',
    height: '8px',
    marginLeft: '-6px',
    borderColor: theme.palette.color.grey9,
    backgroundColor: theme.palette.color.white,
    opacity: '1 !important',
  },
  '& .MuiSlider-rail': {
    height: '6px',
    borderRadius: '3px',
    backgroundColor: theme.palette.color.black1,
    padding: '0 20px',
    marginLeft: '-20px',
  },
  '& .MuiSlider-thumb': {
    border: '0px',
    borderRadius: '8px',

    width: '40px',
    height: '6px',
    borderColor: theme.palette.color.green,
    backgroundColor: theme.palette.color.green,
    boxShadow: 'none !important',
    '&:before': { display: 'none' },
  },
  '& .MuiSlider-valueLabel': {
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: '16px',
    margin: '2px 0 0 -2px',
    width: '12px',
    height: '12px',
    borderColor: theme.palette.color.green,
    backgroundColor: theme.palette.color.white,
    transform: 'translateY(5px) !important',
    padding: '0px',
    '&:hover': {
      '& .MuiSlider-valueLabelLabel': {
        visibility: 'visible !important',
      },
    },
    '&:before': { display: 'none' },
    '& .MuiSlider-valueLabelCircle': {
      // display: 'none',
      position: 'relative',
    },
    '& .MuiSlider-valueLabelLabel': {
      position: 'absolute',
      top: '-35px',
      backgroundColor: 'grey',
      visibility: 'hidden',
      zIndex: 1000,
      padding: '4px 10px',
      borderRadius: '8px',
      width: '170px',
      maxHeight: '70px',
      // texWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      textOverflow: 'ellipsis',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  '& .MuiSlider-markLabel': {
    top: '24px',
    color: theme.palette.color.grey2,
    fontSize: '14px',
  },
}));

const GridSlider = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.color.white,
  overflow: 'hidden',
}));

interface ISliderSelectDate {
  sx?: SxProps<Theme>;
  levelId: string;
}

export const SliderSelectDate = (props: ISliderSelectDate) => {
  const dispatch = useAppDispatch();

  const { dateAnalyticSelected } = useAppSelector(mapViewSelector);

  const { data, isLoading } = useQuery([QUERY_KEY.DATE_HISTORY_LEVEL_M, props.levelId], () =>
    getAllDateLandUseAnalytic(props.levelId)
  );

  const datePickers = useMemo(() => {
    if (!data || isEmpty(data.data)) {
      return [];
    }
    const dateList = [...data.data]
      .reverse()
      .map((_item: { date: string; rasters: Array<{ _id: string; name: string }> }, index: number) => ({
        value: index,
        label: moment(_item.date).format(FORMAT_DATE),
        rasters: _item.rasters,
      }));
    return dateList;
  }, [data]);

  const toolTipByHover: any = useMemo(() => {
    let hoverData = null;
    if (isArray(datePickers)) {
      hoverData = datePickers.find((item) => item.label === dateAnalyticSelected);
    }
    return hoverData?.rasters[0];
  }, [dateAnalyticSelected, datePickers]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isEmpty(datePickers)) {
      dispatch(changeDateAnalyticSelected(null));
      dispatch(changeRastersSelected([]));
    } else if (!dateAnalyticSelected) {
      dispatch(changeDateAnalyticSelected(datePickers[0].label));
      dispatch(changeRastersSelected(datePickers[0].rasters));
    }
  }, [datePickers, dispatch, isLoading, dateAnalyticSelected]);

  const indexValue = useMemo(() => {
    return Math.max(
      0,
      datePickers.findIndex((item: any) => item.label === dateAnalyticSelected)
    );
  }, [datePickers, dateAnalyticSelected]);

  return (
    <GridSlider sx={props.sx}>
      {datePickers.length > 0 && (
        <Scrollbars style={{ width: '100%', height: '90px' }} autoHide>
          <Box
            sx={{
              width: 'fit-content',
              height: '90px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              paddingTop: '20px',
            }}>
            <SliderCustom
              sx={{ width: `${80 * (datePickers.length - 1)}px`, margin: '0px 48px' }}
              // aria-label="Small steps"
              step={1}
              min={0}
              max={Math.max(datePickers.length - 1, 1)}
              marks={datePickers}
              value={indexValue}
              onChange={(_, value) => {
                if (typeof value === 'number') {
                  dispatch(changeDateAnalyticSelected(datePickers[value].label));
                  dispatch(changeRastersSelected(datePickers[value].rasters));
                  dispatch(changeAnalyticName(null));
                }
              }}
              valueLabelDisplay="on"
              aria-label="custom thumb label"
              defaultValue={20}
              valueLabelFormat={(value) => toolTipByHover?.description || 'No description'}
            />
          </Box>
        </Scrollbars>
      )}
    </GridSlider>
  );
};
