import { SvgIconComponent } from '@mui/icons-material';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Box, Button, Grid, MenuItem, Select, styled, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ANALYTICS_TABS, MAP_ANGLES } from 'constants/constants';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeMapViewAngle, dashboardWidgetSelector, setAnalyticActiveTab } from 'store/slices/dashboardWidgetSlice';
import { onMapViewReset } from 'store/slices/mapViewSlice';

const ButtonCustom = styled(Button)(({ theme }) => ({
  ...theme.mixins.toolbar,
  minWidth: '30px !important',
  height: '30px !important',
  minHeight: '30px !important',
  color: '#3C4043 !important',
  backgroundColor: '#FFF !important',
  border: 0,
  borderRadius: '5px',
  fontSize: '16px',
  boxShadow: `0px 2px 10px ${theme.palette.color.grey5}`,
}));

const toolMapViewStyle = makeStyles((theme: any) => ({
  iconInfo: {
    color: theme.palette.mode === 'dark' ? '#3C4043' : theme.palette.color.black2,
  },
  toolButtonGroup: {
    // position: 'absolute',
    // top: '10px',
    // right: '10px',
    zIndex: 9,
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'column',
    alignItems: 'end',
    marginRight: '10px',
  },
  toolDropdown: {
    margin: '10px 0px 0px 10px',
    zIndex: 9,
  },
}));

export const DToolMapView = ({
  showAnalytics,
  openAnalytic,
  analyticInformationPermission,
}: {
  showAnalytics: boolean;
  openAnalytic: Dispatch<SetStateAction<boolean>>;
  analyticInformationPermission?: boolean | undefined;
}) => {
  const dispatch = useAppDispatch();
  const classes = toolMapViewStyle();
  const [showProductionScale, setShowProductionScale] = useState(false);
  const [activeChart, setActiveChart] = useState<string>(ANALYTICS_TABS.PRODUCTION);
  const { mapViewAngle, analyticActiveTab } = useAppSelector(dashboardWidgetSelector);

  const toggleButton = (title: string, onClickToggle: Function, ToggleIcon: SvgIconComponent) => {
    return (
      <Grid item xs={12}>
        <Tooltip title={title}>
          <ButtonCustom
            sx={{
              width: '30px !important',
            }}
            onClick={() => onClickToggle(true)}>
            <ToggleIcon className={classes.iconInfo} />
          </ButtonCustom>
        </Tooltip>
      </Grid>
    );
  };

  const onClickProductionScaleButton = () => {
    setShowProductionScale((prevState) => !prevState);
  };

  const onClickAngleToggleButton = () => {
    dispatch(changeMapViewAngle(mapViewAngle === MAP_ANGLES.DEGREE_90 ? MAP_ANGLES.DEGREE_0 : MAP_ANGLES.DEGREE_90));
    // dispatch(changeIs3D(mapViewAngle === MAP_ANGLES.DEGREE_90 ? false : true))
  };

  // trigerring to reset the map view to default
  const onClickMapViewReset = () => {
    dispatch(onMapViewReset());
  };

  useEffect(() => {
    setActiveChart(analyticActiveTab);
  }, [analyticActiveTab]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '100%' }}>
      <div className={classes.toolDropdown}>
        <Box>
          <Select
            sx={{ height: '34px', padding: '0px 5px', width: '140px', backgroundColor: 'white' }}
            defaultValue={activeChart}
            onChange={(e) => {
              dispatch(setAnalyticActiveTab(e.target.value));
            }}>
            {Object.keys(ANALYTICS_TABS).map((key) => (
              <MenuItem key={key} sx={{ height: '35.98px' }} value={ANALYTICS_TABS[key]}>
                {ANALYTICS_TABS[key]}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </div>
      <div className={classes.toolButtonGroup}>
        <div style={{ margin: '5px 0' }} />
        {/* {analyticInformationPermission && !showAnalytics && toggleButton('Analytical information', openAnalytic, AutoAwesomeMosaicIcon)} */}
        {/* {analyticInformationPermission && toggleButton('Production Scale', onClickProductionScaleButton, LanIcon)} */}
        {/* {showProductionScale && <ProductionScale showAnalytics={showAnalytics} />} */}
        {toggleButton(
          mapViewAngle === MAP_ANGLES.DEGREE_90 ? '2D' : '3D',
          onClickAngleToggleButton,
          Rotate90DegreesCcwIcon
        )}
        <div style={{ margin: '2px 0' }} />
        {toggleButton('Map View Reset', onClickMapViewReset, SettingsBackupRestoreIcon)}
      </div>
    </div>
  );
};
