import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Popover,
  Select,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY, STATUS_API } from 'constants/constants';
import {
  changeIsDisplayBoundary,
  changeLayer3D,
  changeSizePlantHealthSelected,
  changeVisiblePlantHealthAllLayers,
  changeVisiblePlantHealthLayer,
  mapViewSelector,
  setDataPlantHealth,
} from 'store/slices/mapViewSlice';
import { getDataPlantHealthAnalytic, updateColorForAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AbilityDownload from '../AbilityDownload';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PolygonLayerPaddy } from '../PolygonLayerPaddy';
import SideSurface from '../SideSurface';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { SwitchRaster } from '../SwitchRaster';
import TopSurface from '../TopSurface';
import { get } from 'lodash';
import randomColor from 'randomcolor';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';

const PlantHealthCardLayer = ({ labelCard }: any) => {
  const classes = useLayerSelectCardStyle();

  const {
    analyticId,
    isLayer3D,
    isShowAllLayer,
    isDisplayBoundaryLine,
    dataPlantHealth: data,
    sizePlantHealthSelected,
  } = useAppSelector(mapViewSelector);

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const ButtonSubmit = styled(Button)((a) => {
    return {
      width: '100%',
      height: '40px',
      borderRadius: '5px',
      padding: '12px 10px',
      marginBottom: '30px',
      textTransform: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',
      backgroundColor: `${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#1C9D57',
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
        border: `1px solid ${theme.palette.primary.main}`,
      },
    };
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isShow, setIsShow] = useState(true);
  const [isChangeColor, setIsChangeColor] = useState(false);
  const [isRaster2, setRaster2] = useState(true);

  useEffect(() => {
    if (!isShowAllLayer) {
      dispatch(changeVisiblePlantHealthAllLayers(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { analyticName } = useAppSelector(rightBarSelector);

  const { data: dataCropType, status: statusDataCropType } = useQuery(
    [QUERY_KEY.SIZE_PLANT_HEALTH_ANALYTIC, analyticId, sizePlantHealthSelected],
    () => getDataPlantHealthAnalytic(analyticId || '', sizePlantHealthSelected + ''),
    { enabled: !!analyticId && !!sizePlantHealthSelected }
  );

  const mutateUpdateColor = useMutation(
    (data: Array<{ id: string; color: string }>) => updateColorForAnalytic('plant-health-analysis', data),
    {
      onSuccess: (_data: any) => {
        queryClient.invalidateQueries([QUERY_KEY.SIZE_PLANT_HEALTH_ANALYTIC, analyticId, sizePlantHealthSelected]);
      },
    }
  );

  useEffect(() => {
    if (dataCropType?.data && statusDataCropType === STATUS_API.SUCCESS) {
      dispatch(
        setDataPlantHealth(
          dataCropType.data.map((_item: any, index: number) => ({
            ..._item,
            visible: true,
            color: _item.color || get(DIFFERENT_COLOR, `[${index}]`, randomColor({ luminosity: 'dark' })),
          }))
        )
      );
    }
  }, [dataCropType, dispatch, statusDataCropType]);

  const gridSizeList = useMemo((): any => {
    if (!queryClient.getQueryData([QUERY_KEY.PLANT_HEALTH_ANALYTIC, analyticId])) {
      return [];
    }
    return (queryClient.getQueryData([QUERY_KEY.PLANT_HEALTH_ANALYTIC, analyticId]) as any).data;
  }, [queryClient, analyticId]);

  const filterDownloadData = useMemo(() => {
    const downloadData: any[] = [];
    data.forEach((newData) => {
      if (newData.visible) {
        downloadData.push(newData);
      }
    });
    return downloadData;
  }, [data]);

  const handleChangeColors = (id: string, color: string, typeOfAnalytic: string) => {
    setIsChangeColor(true);
    if (id && color) {
      dispatch(
        setDataPlantHealth(
          data.map((_item) => {
            if (_item._id === id) {
              return {
                ..._item,
                color,
              };
            }
            return {
              ..._item,
            };
          })
        )
      );
    }
  };

  return (
    <>
      {data.length > 0 && (
        <>
          <Grid item xs={12} md={12}>
            <Button
              startIcon={isRaster2 ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              onClick={() => setRaster2(!isRaster2)}
              classes={{ root: classes.buttonTextStyle }}>
              {'Raster'}
            </Button>
            <Collapse in={isRaster2} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              <SwitchRaster analyticType={analyticName} isShowInlineBlock={true} />
            </Collapse>
          </Grid>

          <Button
            startIcon={isShow ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsShow(!isShow)}
            classes={{ root: classes.buttonTextStyle }}>
            {labelCard}
            <Typography variant="caption" color="inherit" classes={{ root: classes.numberDataStyle }}>
              {data.length}
            </Typography>
          </Button>
          <Collapse in={isShow} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography className={classes.selectText} sx={{ ml: '-11px' }}>
                  Grid size
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Select
                  sx={{
                    border: 'none',
                    height: '37px',
                    margin: '8px 0 8px -11px',
                  }}
                  id="grid-size-select"
                  value={sizePlantHealthSelected}
                  onChange={(event: any) => dispatch(changeSizePlantHealthSelected(event.target.value))}>
                  {gridSizeList.map((item: number) => (
                    <MenuItem key={item} value={item}>
                      {`${item}cm x ${item}cm`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6} md={6}>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isDisplayBoundaryLine}
                      onClick={() => dispatch(changeIsDisplayBoundary(!isDisplayBoundaryLine))}
                    />
                  }
                  label={<Typography className={classes.selectText}>2D Contour</Typography>}
                />
              </Grid>
              {!isDisplayBoundaryLine && (
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                    label={<Typography className={classes.selectText}>{isLayer3D ? '3D Fill' : '2D Fill'}</Typography>}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.color.grey3}`,
                  margin: '10px 0',
                }}></Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                  }}>
                  {filterDownloadData.length > 0 && (
                    <AbilityDownload
                      dataPoints={filterDownloadData}
                      type={TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD}
                    />
                  )}
                </Box>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isShowAllLayer}
                      onClick={() => dispatch(changeVisiblePlantHealthAllLayers(!isShowAllLayer))}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Typography className={classes.selectText}>
                        {isShowAllLayer ? 'Unselect all' : 'Select all'}
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
              <>
                {data.map((value, index: number) => {
                  return (
                    <PolygonLayerPaddy
                      key={index}
                      index={index}
                      label={value.type}
                      color={value.color}
                      id={value._id}
                      visible={!!value.visible}
                      dataPoints={[value]}
                      changeColorAnalyticsLayer={handleChangeColors}
                      changeVisibleAnalyticsLayer={changeVisiblePlantHealthLayer}
                      typeOfAnalytic={TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD}
                    />
                  );
                })}
              </>
              {isChangeColor && (
                <Grid container top={2} paddingTop={2} justifyContent={'center'} gap={2} xs={12}>
                  <Grid item xs={4}>
                    <Button
                      color="neutral"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderRadius: '5px',
                        p: '12px 10px',
                        mb: '30px',
                        textTransform: 'none',
                        border: `1px solid ${theme.palette.divider}`,
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                      }}
                      onClick={() => {
                        setIsChangeColor(false);
                        queryClient.invalidateQueries([
                          QUERY_KEY.SIZE_PLANT_HEALTH_ANALYTIC,
                          analyticId,
                          sizePlantHealthSelected,
                        ]);
                      }}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <ButtonSubmit
                      onClick={() => {
                        setIsChangeColor(false);
                        const dataNew = data.map((item) => ({ id: item._id, color: item.color }));
                        mutateUpdateColor.mutate(dataNew);
                      }}>
                      Save
                    </ButtonSubmit>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </>
      )}
      {isLayer3D && <SideSurface />}
      <TopSurface />
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 130,
          horizontal: 60,
        }}>
        {/* <Box>
          <HexColorPicker
            style={{
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}
            color={colorBorderPlantation}
            onChange={(_color) => dispatch(changeColorBorderPlantation(_color))}
          />
        </Box> */}
      </Popover>
    </>
  );
};

export default PlantHealthCardLayer;
