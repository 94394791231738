import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { checkIsExistPoint } from 'services/analytics/apiAnalyticsConfig.services';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeColorAnalyticLayers,
  changeIsDisplayBoundary,
  changeLayer3D,
  changeVisibleAllLayers,
  changeVisibleLayer,
  changeVisiblePointDataLandUse,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { PolygonLayer } from '../PolygonLayer';
import SideSurface from '../SideSurface';
import TopSurface from '../TopSurface';

export const LandUseCardLayer = ({ labelCard }: any) => {
  const {
    isLayer3D,
    isShowAllLayer,
    isDisplayBoundaryLine,
    isShowPointDataLandUse,
    polygonLandUseAnalytics: data,
  } = useAppSelector(mapViewSelector);
  const { analyticId } = useAppSelector(mapViewSelector);
  const { clientId } = useParams();
  const queryClient = useQueryClient();
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();

  const [isPointExist, setIsPointExist] = useState(false);
  const [raster, setRaster] = useState(true);

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  useQuery([QUERY_KEY.CHECK_IS_EXIST_POINT, analyticId], () => checkIsExistPoint(analyticId || ''), {
    enabled: !!analyticId,
    onSuccess: (response: any) => {
      setIsPointExist(response?.data?.isExist);
    },
    onError: (error: any) => {
      console.log('error', error);
    },
  });

  const callbackRefetchData = () => {

  }



  return (
    <>
      {data?.length > 0 && (
        <Grid sx={{ mt: 1 }}>
          <Button
            startIcon={raster ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setRaster(!raster)}
            classes={{ root: classes.buttonTextStyle }}>
            {labelCard}
            <Typography variant="caption" color="inherit" classes={{ root: classes.numberDataStyle }}>
              {data.length}
            </Typography>
          </Button>
          <Collapse in={raster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              {isPointExist && (
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <SwitchCustom
                        checked={isShowPointDataLandUse}
                        onClick={() => dispatch(changeVisiblePointDataLandUse(!isShowPointDataLandUse))}
                      />
                    }
                    label={<Typography className={classes.selectText}>Point</Typography>}
                  />
                </Grid>
              )}
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isDisplayBoundaryLine}
                      onClick={() => dispatch(changeIsDisplayBoundary(!isDisplayBoundaryLine))}
                    />
                  }
                  label={<Typography className={classes.selectText}>2D Contour</Typography>}
                />
              </Grid>
              {!isDisplayBoundaryLine && (
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                    label={<Typography className={classes.selectText}>{isLayer3D ? '3D Fill' : '2D Fill'}</Typography>}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.color.grey3}`,
                  margin: '10px 0',
                }}></Grid>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isShowAllLayer}
                      onClick={() => dispatch(changeVisibleAllLayers(!isShowAllLayer))}
                    />
                  }
                  label={
                    <Typography className={classes.selectText}>
                      {isShowAllLayer ? 'Unselect all' : 'Select all'}
                    </Typography>
                  }
                />
              </Grid>
              <>
                {data.map((value: any, index: number) => {
                  return (
                    <PolygonLayer
                      key={index}
                      label={value.category ? `${value.landUse}: ${value.category}` : value.landUse}
                      color={value.color}
                      id={value._id}
                      visible={value.visible}
                      changeColorAnalyticsLayer={changeColorAnalyticLayers}
                      callback={callbackRefetchData}
                      changeVisibleAnalyticsLayer={changeVisibleLayer}
                      typeOfAnalytic={TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS}
                    />
                  );
                })}
              </>
            </Grid>
          </Collapse>
        </Grid>
      )}
      {isLayer3D && <SideSurface />}
      <TopSurface />
    </>
  );
};
