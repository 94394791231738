import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { chlorophyllAnalyticsSelector } from 'store/slices/map-view/chlorophyllAnalytics';
import { circumferenceAnalyticsSelector } from 'store/slices/map-view/circumferenceAnalytics';
import { crownAreaAnalyticsSelector } from 'store/slices/map-view/crownAreaAnalytics';
import { plantHealthOilPailmAnalyticsSelector } from 'store/slices/map-view/plantHealthOilPailmAnalytics';
import { plantStressAnalyticsSelector } from 'store/slices/map-view/plantStressAnalytics';
import { standCountAnalyticSelector } from 'store/slices/map-view/standCountAnalytics';
import { vigorAnalyticsSelector } from 'store/slices/map-view/vigorAnalytics';
import { waterUptakeAnalyticsSelector } from 'store/slices/map-view/waterUptakeAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';

export const useLabelAnalytic = () => {
  const { analyticName } = useAppSelector(rightBarSelector);
  const { dataVigorInterval } = useAppSelector(vigorAnalyticsSelector);
  const { dataWaterUptakeInterval } = useAppSelector(waterUptakeAnalyticsSelector);
  const { dataCircumferenceInterval } = useAppSelector(circumferenceAnalyticsSelector);
  const { dataChlorophyllInterval } = useAppSelector(chlorophyllAnalyticsSelector);
  const { dataPlantStressInterval } = useAppSelector(plantStressAnalyticsSelector);
  const { dataCrownAreaInterval } = useAppSelector(crownAreaAnalyticsSelector);
  const { dataPlantHealthOilPailmInterval } = useSelector(plantHealthOilPailmAnalyticsSelector);
  const { dataStandCount } = useAppSelector(mapViewSelector);
  const { isShowPopupAdd } = useAppSelector(standCountAnalyticSelector);

  const [typeSelectedAnalytic, typeLabel, dataIntervalByAnalytic] = useMemo(() => {
    let typeAnalytic = 'fill-top-surface-point' as string;
    let typeLabel = '' as string;
    let dataInterval = null;

    switch (analyticName) {
      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
        typeLabel = 'vigor';
        dataInterval = dataVigorInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
        typeLabel = 'water-uptake';
        dataInterval = dataWaterUptakeInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        typeLabel = 'circumference';
        dataInterval = dataCircumferenceInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
        typeLabel = 'chlorophyll';
        dataInterval = dataChlorophyllInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
        typeLabel = 'stress';
        dataInterval = dataPlantStressInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        typeLabel = 'crown-area';
        dataInterval = dataCrownAreaInterval;
        break;
      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
        typeLabel = 'plant-health-oilpalm';
        dataInterval = dataPlantHealthOilPailmInterval;
        break;
      default:
        break;
    }
    return [typeAnalytic, typeLabel, dataInterval];
  }, [
    analyticName,
    dataChlorophyllInterval,
    dataCircumferenceInterval,
    dataCrownAreaInterval,
    dataPlantHealthOilPailmInterval,
    dataPlantStressInterval,
    dataVigorInterval,
    dataWaterUptakeInterval,
  ]);

  const interactiveLayerIds = useMemo(() => {
    if (isShowPopupAdd || !analyticName) {
      return undefined;
    }

    if (analyticName === TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT) {
      const fullLayerId = ['stand-count-layer-Tree', 'stand-count-layer-Blank spot'];
      if (Array.isArray(dataStandCount)) {
        if (dataStandCount.length === 1) return fullLayerId.slice(0, 1);
        else if (dataStandCount.length === 2) return fullLayerId;
      }
      return fullLayerId;
    }

    return [typeSelectedAnalytic];
  }, [analyticName, dataStandCount, isShowPopupAdd, typeSelectedAnalytic]);

  const categoryByColor = useMemo(() => {
    let options = [] as any;
    dataIntervalByAnalytic?.range?.forEach((item: any) => {
      options.push({
        label: item.lable,
        color: item.color,
        visible: item.visible,
      });
    });
    return options;
  }, [dataIntervalByAnalytic?.range]);

  return { interactiveLayerIds, typeLabel, categoryByColor };
};
