import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { QUERY_KEY } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { changeUserSelected } from 'store/slices/userSlice';

const CardClientStyle = makeStyles((theme: any) => ({
  card: {
    width: '350px',
    height: '80px',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.divider} !important`,
    padding: '10px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
    borderColor: 'rgb(211 211 211 / 80%)',
    '&.mobile': {
      width: '100%',
    },
  },
}));

export const CardClient = ({ client }: any) => {
  const classes = CardClientStyle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const check = useMutation([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    onSuccess: () => {
      dispatch(changeUserSelected(client._id));
      navigate(`/dashboard/${client._id}`);
    },
    onError: (err: any) => {
      console.log('error', err);
    },
  });

  const onClick = () => {
    return check.mutate();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid className={isMobile ? `${classes.card} mobile` : classes.card} display="flex" onClick={() => onClick()}>
      <Grid sx={{ width: '40px' }} display="flex" justifyContent="center" alignItems="center">
        <Avatar src={client.avatar} />
      </Grid>
      <Grid
        sx={{ width: 'calc(100% - 40px)', pl: 2, flexDirection: 'column' }}
        display="flex"
        justifyContent="space-around">
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {client.name}
        </Typography>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {client.email}
        </Typography>
      </Grid>
    </Grid>
  );
};
