import { SortDateEnum } from 'components/MapView/RightBarOld/IssuesTab/interfaces';
import { sendDeleteRequest, sendGetRequest, sendPostRequest } from '../index';

export const getChartsCircumference = (analyticId: string, isDefault: boolean = true) =>
  sendGetRequest(`/circumference-analysis/getChart/${analyticId}/${isDefault}`);

export const getChartsPlantHealthOilPailm = (analyticId: string, isDefault: boolean = true) =>
  sendGetRequest(`/plant-health-oilpalm-analysis/getChart/${analyticId}/${isDefault}`);

export const getChartsVigor = (analyticId: string, isDefault: boolean = true) =>
  sendGetRequest(`/vigor-analysis/getChart/${analyticId}/${isDefault}`);

export const getChartsWaterUptake = (analyticId: string, isDefault: boolean = true) =>
  sendGetRequest(`/water-uptake-analysis/getChart/${analyticId}/${isDefault}`);

export const getChartsStress = (analyticId: string, isDefault: boolean = true) =>
  sendGetRequest(`/stress-analysis/getChart/${analyticId}/${isDefault}`);

export const getChartsChlorophyll = (analyticId: string, isDefault: boolean = true) =>
  sendGetRequest(`/chlorophyll-analysis/getChart/${analyticId}/${isDefault}`);

export const getChartsCrownArea = (analyticId: string, isDefault: boolean = true) =>
  sendGetRequest(`/crown-area-analysis/getChart/${analyticId}/${isDefault}`);

export const addMoreTree = (params: any) => sendPostRequest('/stand-count-analysis/addMoreTree', params);

export const addTreeManual = (params: any) => sendPostRequest('/stand-count-analysis/createManual', params);

export const deleteTreeManual = (id: string) => sendDeleteRequest(`/stand-count-analysis/${id}`);

export const deleteTreePolygonMode = (arrayId: any) => sendDeleteRequest('/stand-count-analysis', { data: arrayId });

export const getAllDataIntervalLimit = (rasterId: string, currentAnalysisId: string, isDefault: boolean = true) =>
  sendGetRequest(`/data-analytics/getIntervaLimit/${rasterId}/${currentAnalysisId}/${isDefault}`);

export const getDataLabelMaker = (analyticId: string, type: string) =>
  sendGetRequest(`/${type}-analysis/getLabel/${analyticId}`);

export const getSurfaceExposedWaterPagination = (data: { analysisId: string; page: number }) => {
  const { page, analysisId } = data;
  return sendGetRequest(`/surface-exposed-water/pagination`, {
    perPage: 500,
    sortDate: SortDateEnum.ASC,
    paginationVersion: 2,
    page,
    analysisId,
  });
};

export const getTensionCrackPagination = (data: { analysisId: string; page: number }) => {
  const { page, analysisId } = data;
  return sendGetRequest(`/tension-crack/pagination`, {
    perPage: 500,
    sortDate: SortDateEnum.ASC,
    paginationVersion: 2,
    page,
    analysisId,
  });
};

export const getSurfaceExposedWaterStatisticsInfo = (analysisId: string) =>
  sendGetRequest(`/surface-exposed-water/getSurfaceExposedWaterInfo/${analysisId}`);

export const getTensionCrackStatisticsInfo = (analysisId: string) =>
  sendGetRequest(`/tension-crack/getTensionCrackTotalLength/${analysisId}`);
