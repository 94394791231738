import { SvgIconComponent } from '@mui/icons-material';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import LanIcon from '@mui/icons-material/Lan';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Button, Grid, styled, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MAP_ANGLES } from 'constants/constants';
import { NEW_VERSION_PATHS } from 'constants/enums/version-enums';
import usePathForVersion from 'hooks/common/usePathForVersion';
import { Dispatch, SetStateAction, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeMapViewAngle, dashboardWidgetSelector } from 'store/slices/dashboardWidgetSlice';
import { onMapViewReset } from 'store/slices/mapViewSlice';
import ProductionScale from './ProductionScale';

const ButtonCustom = styled(Button)(({ theme }) => ({
  ...theme.mixins.toolbar,
  minWidth: '30px !important',
  height: '30px !important',
  minHeight: '30px !important',
  color: '#3C4043 !important',
  backgroundColor: '#FFF !important',
  border: 0,
  borderRadius: '5px',
  fontSize: '16px',
  boxShadow: `0px 2px 10px ${theme.palette.color.grey5}`,
}));

const toolMapViewStyle = makeStyles((theme: any) => ({
  iconInfo: {
    color: theme.palette.mode === 'dark' ? '#3C4043' : theme.palette.color.black2,
  },
}));

export const ToolMapView = ({
  showAnalytics,
  openAnalytic,
  analyticInformationPermission,
}: {
  showAnalytics: boolean;
  openAnalytic: Dispatch<SetStateAction<boolean>>;
  analyticInformationPermission?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const classes = toolMapViewStyle();
  const [showProductionScale, setShowProductionScale] = useState(false);
  const { mapViewAngle } = useAppSelector(dashboardWidgetSelector);
  const isNewVersion = usePathForVersion(NEW_VERSION_PATHS.MAP_VIEW);

  const toggleButton = (title: string, onClickToggle: Function, ToggleIcon: SvgIconComponent) => {
    return (
      <Grid item xs={12}>
        <Tooltip title={title}>
          <ButtonCustom
            sx={{
              width: '30px !important',
            }}
            onClick={() => onClickToggle(true)}>
            <ToggleIcon className={classes.iconInfo} />
          </ButtonCustom>
        </Tooltip>
      </Grid>
    );
  };

  const onClickProductionScaleButton = () => {
    setShowProductionScale((prevState) => !prevState);
  };

  const onClickAngleToggleButton = () => {
    dispatch(changeMapViewAngle(mapViewAngle === MAP_ANGLES.DEGREE_90 ? MAP_ANGLES.DEGREE_0 : MAP_ANGLES.DEGREE_90));
  };

  // trigerring to reset the map view to default
  const onClickMapViewReset = () => {
    dispatch(onMapViewReset());
  };

  return (
    <Grid
      className="tool_map_icon"
      container
      spacing={1}
      zIndex={9}
      sx={{
        position: 'absolute',
        ml: showAnalytics ? 'calc(100% - 55px)' : 'calc(100% - 65px)',
        mt: '0',
        width: 'auto',
      }}
      justifyContent="flex-end"
      columnSpacing={showAnalytics ? 0 : 2}>
      {analyticInformationPermission &&
        !showAnalytics &&
        toggleButton('Analytical information', openAnalytic, AutoAwesomeMosaicIcon)}
      {analyticInformationPermission &&
        isNewVersion &&
        toggleButton('Production Scale', onClickProductionScaleButton, LanIcon)}
      {showProductionScale && isNewVersion && <ProductionScale showAnalytics={showAnalytics} />}
      {isNewVersion &&
        toggleButton(
          mapViewAngle === MAP_ANGLES.DEGREE_90 ? '2D' : '3D',
          onClickAngleToggleButton,
          Rotate90DegreesCcwIcon
        )}
      {isNewVersion && toggleButton('Map View Reset', onClickMapViewReset, SettingsBackupRestoreIcon)}
    </Grid>
  );
};
