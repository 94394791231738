import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { IField } from 'interfaces/workspace';
import moment from 'moment';
import { FC } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeSelectedFieldId } from 'store/slices/tilingMapSlice';
import FieldDelete from './FieldDelete';
import FieldDownload from './FieldDownload';
import FieldEditDetail from './FieldEditDetail';
import FieldSearchPosition from './FieldSearchPosition';

interface FieldItemProps {
  isSelected: boolean;
  setIsShowCheckStatusModal: (arg: boolean) => void;
  data: IField;
}

const FieldItem: FC<FieldItemProps> = ({ isSelected, data, setIsShowCheckStatusModal }) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const handleClick = () => {
    dispatch(changeSelectedFieldId(data._id));
    if (!data.status) {
      setIsShowCheckStatusModal(true);
    }
  };

  const CustomBox = styled(Box)({
    borderRadius: '8px',
    border: isSelected
      ? `1px solid ${theme.palette.primary.main}`
      : theme.palette.mode === 'dark'
      ? '1px solid #515151'
      : '1px solid #EFEFEF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  });

  return (
    <Box sx={{ mt: '16px', width: '100%' }} onClick={handleClick}>
      <CustomBox>
        <Stack sx={{ flexBasis: '40%', overflow: 'hidden', mx: '8px' }}>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              fontWeight: '600',
              fontSize: '16px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
            }}>
            {data?.name}
          </Typography>
          <Box style={{ lineHeight: '12px' }}>
            <Typography
              sx={{
                display: 'inline',
                fontWeight: '500',
                fontSize: '12px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              Date:{' '}
            </Typography>
            <Typography
              sx={{
                display: 'inline',
                fontSize: '12px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              {moment(data?.updatedAt).format('DD-MM-YYYY')}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ flexBasis: '40%', display: 'flex', justifyContent: 'flex-end', mr: '16px' }}>
          <FieldSearchPosition />
          <FieldDownload />
          <FieldEditDetail />
          <FieldDelete />
        </Box>
      </CustomBox>
    </Box>
  );
};

export default FieldItem;
