import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import { IOption, Map3dOptionEnum } from 'interfaces/workspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeRightBar3dOptions } from 'store/slices/tilingMapSlice';

interface I3dOption extends IOption {
  name: Map3dOptionEnum;
}

const initial3dOptionList: I3dOption[] = [
  { key: 1, name: Map3dOptionEnum.POINT_CLOUD, label: 'Point Cloud', disabled: false, value: false },
  { key: 2, name: Map3dOptionEnum.MODEL_3D, label: 'Mesh', disabled: false, value: true },
];

const Layer3dMap = () => {
  const [list3dOption, setList3dOption] = useState<I3dOption[]>(initial3dOptionList);
  const dispatch = useAppDispatch();
  const { fieldDetail } = useQueryFieldDetail();

  // update option 3d value to redux
  useEffect(() => {
    const newOption3dList = list3dOption.map((item) => ({ name: item.name, value: item.value }));
    dispatch(changeRightBar3dOptions(newOption3dList));
  }, [dispatch, list3dOption]);

  const onToggle3dButton = async (event: ChangeEvent<HTMLInputElement>, key: number) => {
    const { checked } = event.target;
    setList3dOption((prev) => prev.map((item) => ({ ...item, value: item.key === key ? checked : false })));
  };

  return (
    <>
      {fieldDetail ? (
        <Box sx={{ m: '24px' }}>
          <Typography sx={{ fontSize: '16px' }}>3D Layers</Typography>
          <Stack sx={{ ml: '12px' }}>
            {list3dOption.map((item, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Switch
                    disabled={item.disabled}
                    checked={!!item.value}
                    onChange={(e) => onToggle3dButton(e, item.key)}
                  />
                }
                label={item.label}
              />
            ))}
          </Stack>
        </Box>
      ) : null}
    </>
  );
};

export default Layer3dMap;
