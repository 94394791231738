import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeShowIssueModal, updateEditIssueData } from 'store/slices/rightBarSlice';
import { IIssue } from '../../interfaces';

interface EditIssueProps {
  initialData: IIssue;
  colorByTheme?: string;
}

const EditIssue: FC<EditIssueProps> = ({ initialData, colorByTheme }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Tooltip title="Edit Issue">
        <ModeEditOutlineOutlinedIcon
          sx={{ cursor: 'pointer', color: colorByTheme }}
          onClick={() => {
            dispatch(changeShowIssueModal(true));
            dispatch(updateEditIssueData(initialData));
          }}
        />
      </Tooltip>
    </>
  );
};

export default EditIssue;
