import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import AppLayout from 'components/AppLayout';
import { ClientsListing } from 'components/ClientsInfo/ClientsListing';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { AnalyticsConfig } from 'components/Dashboard/AnalyticsConfig';
import ClientAuditTrail from 'components/Dashboard/AuditTrail/ClientAuditTrail';
import { ClientApis } from 'components/Dashboard/ClientApis/ClientApis';
import { ClientSettings } from 'components/Dashboard/ClientSettings/ClientSettings';
import { TabsBar } from 'components/Dashboard/TabsBar';
import { UserManagement } from 'components/Dashboard/UserProfile/UserManagement';
import OverviewClient from 'components/OverviewClient';
import OverviewClientOld from 'components/OverviewClient/OverviewClientOld';
import { QUERY_KEY } from 'constants/constants';
import { NEW_VERSION_PATHS } from 'constants/enums/version-enums';
import useNavBarBasedPath from 'hooks/common/useNavBarBasedPath';
import usePathForVersion from 'hooks/common/usePathForVersion';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUIDisplay, queryMe } from 'services/clients/apiClient.services';

const TabPanelCustom = styled(TabPanel)(({ theme }) => ({
  ...theme.mixins.toolbar,
  paddingBottom: 0,
}));

export const DashboardPage = (): ReactElement => {
  const isNewVersion = usePathForVersion(NEW_VERSION_PATHS.DASHBOARD);
  const Navbars = useNavBarBasedPath();
  const { clientId } = useParams();
  const { data: userInfo, isLoading: loadingMe } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const { data: userInfoByClient, isLoading: loadingUI } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    }
  );

  const flagTabData = useMemo(() => {
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser) {
      return Navbars;
    }
    if (!userInfoByClient?.data) return [];
    const dataRoleValue = userInfoByClient?.data?.flagTab;
    if (dataRoleValue) {
      return dataRoleValue;
    } else {
      return [];
    }
  }, [userInfo, userInfoByClient, Navbars]);

  const newNavbars = useMemo(() => {
    const newNavbarsData: string[] = [];
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser || flagTabData.length === 0) {
      return flagTabData;
    } else {
      Navbars.forEach((item: any) => {
        switch (item) {
          case 'Overview':
            if (flagTabData.includes('DASHBOARD-OVERVIEW')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'User Management':
            if (flagTabData.includes('DASHBOARD-USER-MANAGEMENT')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Data Configuration':
            if (flagTabData.includes('DASHBOARD-DATA-CONFIGURATION')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Activity':
            if (flagTabData.includes('DASHBOARD-ACTIVITY')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Client Settings':
            if (flagTabData.includes('DASHBOARD-CLIENT-SETTINGS')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Audit Trail':
            if (flagTabData.includes('DASHBOARD-AUDIT-TRAIL')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          case 'Client Apis':
            if (flagTabData.includes('DASHBOARD-CLIENT-APIS')) {
              newNavbarsData.push(item);
              return item;
            }
            break;
          default:
            return '';
        }
      });
      return newNavbarsData;
    }
  }, [flagTabData, userInfo, Navbars]);

  const [value, setValue] = useState(newNavbars[0] || flagTabData[0] || Navbars[0]);

  useEffect(() => {
    setValue(newNavbars[0] || flagTabData[0] || Navbars[0]);
  }, [Navbars]);

  return (
    <>
      {loadingMe || loadingUI ? (
        <WaitingPageAPI height="100vh" width="100vw" />
      ) : (
        <AppLayout>
          <TabsBar tab={value} setTab={setValue} />
          {!isNewVersion ? <ClientsListing tab={value} /> : <></>}
          <TabContext value={value}>
            <TabPanelCustom value="Overview">
              {isNewVersion ? <OverviewClient /> : <OverviewClientOld />}
            </TabPanelCustom>
            {!isNewVersion ? (
              <TabPanelCustom value="Data Configuration">
                <AnalyticsConfig />
              </TabPanelCustom>
            ) : (
              <></>
            )}
            <TabPanelCustom value="User Management">
              <UserManagement />
            </TabPanelCustom>
            {!isNewVersion ? (
              <>
                <TabPanelCustom value="Activity">Activity</TabPanelCustom>
                <TabPanelCustom value="Client Settings">
                  <ClientSettings />
                </TabPanelCustom>
                <TabPanelCustom value="Audit Trail">
                  <ClientAuditTrail />
                </TabPanelCustom>
                <TabPanelCustom value="Client Apis">
                  <ClientApis />
                </TabPanelCustom>
              </>
            ) : (
              <></>
            )}
          </TabContext>
        </AppLayout>
      )}
    </>
  );
};
