import { Box, FormControlLabel, Grid, Typography } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import SideSurface from 'components/MapView/RightBarOld/AnalyticsTab/Layer/SideSurface';
import TopSurface from 'components/MapView/RightBarOld/AnalyticsTab/Layer/TopSurface';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { change3dPolygonValue, rightBarSelector } from 'store/slices/rightBarSlice';
import { DrawIssueShapeEnum, IIssue } from '../../../interfaces';

interface SettingDisplayPolygonProps {
  formik: any;
  initialData: IIssue;
}

const SettingDisplayPolygon: FC<SettingDisplayPolygonProps> = ({ formik, initialData }) => {
  const dispatch = useAppDispatch();
  const {
    issuesTab: { polygon3dList },
  } = useAppSelector(rightBarSelector);
  const classes = useLayerSelectCardStyle();

  const foundPolygon3d = polygon3dList.find((item) => item.id === initialData._id);
  const { isDisplayBoundaryLine, isLayer3D } = foundPolygon3d || {};

  const checkIsDisplayBoundaryLine = () => {
    if (typeof isDisplayBoundaryLine === 'undefined') {
      return true;
    } else return isDisplayBoundaryLine;
  };

  return formik.values.type === DrawIssueShapeEnum.POLYGON && initialData._id ? (
    <>
      <Grid item xs={3}>
        <Typography sx={{ fontWeight: '500' }}>Display: </Typography>
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={4}>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <SwitchCustom
                  checked={checkIsDisplayBoundaryLine()}
                  onClick={() =>
                    dispatch(
                      change3dPolygonValue({ id: initialData._id, isDisplayBoundaryLine: !isDisplayBoundaryLine })
                    )
                  }
                />
              }
              label={<Typography className={classes.selectText}>2D Contour</Typography>}
            />
          </Grid>
          {!checkIsDisplayBoundaryLine() && (
            <Grid item xs={8}>
              <FormControlLabel
                sx={{ ml: 0 }}
                control={
                  <SwitchCustom
                    checked={!!isLayer3D}
                    onClick={() => {
                      dispatch(change3dPolygonValue({ id: initialData._id, isLayer3D: !isLayer3D }));
                    }}
                  />
                }
                label={<Typography className={classes.selectText}>{isLayer3D ? '3D Fill' : '2D Fill'}</Typography>}
              />
            </Grid>
          )}
        </Grid>
        {!checkIsDisplayBoundaryLine() && (
          <Box
            sx={{
              '& .MuiButton-root': {
                fontSize: '12px',
              },
            }}>
            {isLayer3D && <SideSurface id={initialData._id} />}
            <TopSurface id={initialData._id} />
          </Box>
        )}
      </Grid>
    </>
  ) : null;
};

export default SettingDisplayPolygon;
