import { Box, Button, ButtonGroup, Chip, Paper, Stack, Typography, useTheme } from '@mui/material';
import { EChartsOption } from 'echarts-for-react/src/types';
import moment from 'moment';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEY } from '../../constants/constants';
import { getCropCollectionCalendarDayData } from '../../services/clients/apiClient.services';

interface DashboardCalendarProps {
  date: string;
  calendarShowToggle: Dispatch<SetStateAction<boolean>>;
  calendarMonth: Dispatch<SetStateAction<string>>;
  setHeatmapOptions: Dispatch<SetStateAction<EChartsOption>>;
  options: EChartsOption;
  estateFilter: any;
}

const DashboardCalendar: FC<DashboardCalendarProps> = ({
  date,
  calendarShowToggle,
  calendarMonth,
  setHeatmapOptions,
  options,
  estateFilter,
}) => {
  const [calendarActivity, setCalendarActivity] = useState<any>({});
  const [dateNavigation, setDateNavigation] = useState<any>({});
  const theme = useTheme();

  useQuery(
    [QUERY_KEY.GET_CROP_COLLECTION_DATA_BY_DATE, estateFilter],
    () => getCropCollectionCalendarDayData({ date: date, estateId: estateFilter?.levelId }),
    {
      onSuccess: (data: any) => {
        setDailyData(data.data, date);
      },
    }
  );

  const getCalendarDetails = async (date: string) => {
    const response = await getCropCollectionCalendarDayData({ date: date, estateId: estateFilter?.levelId });
    setDailyData(response.data, date);
  };

  const setDailyData = (data: [], date: string) => {
    const result = data.map((item: any) => {
      return {
        gangName: item.GangName,
        bunches: item.TotalBunches,
        workType: item.worktype,
        id: item.Id,
        estateName: item.EstateName,
        blockName: item.Blockname,
        divName: item.DivName,
      };
    });
    setCalendarActivity(result);
    setDates(date);
  };

  const setDates = (date: string) => {
    const currentDate = moment(date).format('YYYY-MM-DD').toString();
    const prevDate = moment(date).subtract(1, 'd').format('YYYY-MM-DD').toString();
    const prevTwoDate = moment(date).subtract(2, 'd').format('YYYY-MM-DD').toString();
    const prevThreeDate = moment(date).subtract(3, 'd').format('YYYY-MM-DD').toString();
    const nextDate = moment(date).add(1, 'd').format('YYYY-MM-DD').toString();
    const nextTwoDate = moment(date).add(2, 'd').format('YYYY-MM-DD').toString();
    const nextThreeDate = moment(date).add(3, 'd').format('YYYY-MM-DD').toString();
    calendarMonth(moment(currentDate).format('YYYY-MM').toString());

    setDateNavigation({
      currentDate: currentDate,
      prevDate: prevDate,
      prevTwoDate: prevTwoDate,
      prevThreeDate: prevThreeDate,
      nextDate: nextDate,
      nextTwoDate: nextTwoDate,
      nextThreeDate: nextThreeDate,
    });
  };

  return (
    <>
      <Button
        sx={{ color: theme.palette.color.black1 }}
        onClick={() => {
          calendarShowToggle(true);
          calendarMonth(moment(dateNavigation.currentDate).format('YYYY-MM').toString());
          options.calendar.range = moment(dateNavigation.currentDate).format('YYYY-MM').toString();
          setHeatmapOptions(options);
        }}>
        {'< Back'}
      </Button>

      <ButtonGroup fullWidth={true} sx={{ paddingX: 2.5, whiteSpace: 'pre' }} variant={'text'}>
        {/*previous two days*/}
        <Button
          sx={{ color: theme.palette.color.black1 }}
          onClick={async () => {
            await getCalendarDetails(dateNavigation.prevDate);
          }}>
          {'<'}
        </Button>
        <Button
          sx={{ color: theme.palette.color.black1 }}
          onClick={async () => {
            await getCalendarDetails(dateNavigation.prevTwoDate);
          }}>
          {moment(dateNavigation.prevTwoDate).format('ddd[\n]DD').toString()}
        </Button>
        <Button
          sx={{ color: theme.palette.color.black1 }}
          onClick={async () => {
            await getCalendarDetails(dateNavigation.prevDate);
          }}>
          {moment(dateNavigation.prevDate).format('ddd[\n]DD').toString()}
        </Button>
        {/*current day*/}
        <Button sx={{ color: theme.palette.color.black1 }}>
          {moment(dateNavigation.currentDate).format('ddd[\n]DD').toString()}
        </Button>
        {/*next two days*/}
        <Button
          sx={{ color: theme.palette.color.black1 }}
          onClick={async () => {
            await getCalendarDetails(dateNavigation.nextDate);
          }}>
          {moment(dateNavigation.nextDate).format('ddd[\n]DD').toString()}
        </Button>
        <Button
          sx={{ color: theme.palette.color.black1 }}
          onClick={async () => {
            await getCalendarDetails(dateNavigation.nextTwoDate);
          }}>
          {moment(dateNavigation.nextTwoDate).format('ddd[\n]DD').toString()}
        </Button>
        <Button
          sx={{ color: theme.palette.color.black1 }}
          onClick={async () => {
            await getCalendarDetails(dateNavigation.nextDate);
          }}>
          {'>'}
        </Button>
      </ButtonGroup>
      {/*Calendar Details*/}
      <Box sx={{ paddingX: 2.5, paddingTop: 2 }}>
        {/*for each activity on current date*/}
        <Stack sx={{ overflow: 'hidden', overflowY: 'scroll', height: 300 }} textAlign="center" spacing={2}>
          {calendarActivity.length > 0
            ? calendarActivity.map((item: any) => {
                return (
                  <Paper
                    key={item.id}
                    elevation={0}
                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 2 }}>
                    <Typography
                      gutterBottom
                      variant="h2"
                      component="div"
                      paddingLeft={1}
                      paddingTop={1}
                      paddingRight={1}>
                      <Typography variant="h3" textAlign="left">
                        {item.estateName}
                        <Chip
                          sx={{ borderRadius: 0, marginLeft: 1, fontSize: 10 }}
                          label={item.divName}
                          size={'small'}
                        />
                        <Chip
                          sx={{ borderRadius: 0, marginLeft: 1, fontSize: 10 }}
                          label={item.blockName}
                          size={'small'}
                        />
                      </Typography>
                      <Typography align={'right'} variant="h3" sx={{ fontWeight: '800' }}>
                        <Typography align={'left'}>{item.gangName}</Typography>
                        {item.bunches}
                        <Typography component="span" variant="h4">
                          {' Bunches'}
                        </Typography>
                      </Typography>
                    </Typography>
                  </Paper>
                );
              })
            : null}
        </Stack>
      </Box>
    </>
  );
};
export default DashboardCalendar;
