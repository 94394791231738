import { makeStyles } from '@mui/styles';

export const useMapViewPageStyle = makeStyles((theme: any) => ({
  navBarButton: {
    width: '27px !important',
    minWidth: '27px !important',
    height: '27px !important',
    top: '80px',
    border: `1px solid ${theme.palette.color.grey8} !important`,
    backgroundColor: `${theme.palette.color.white} !important`,
    zIndex: 9,
  },
  arrowIcon: {
    color: theme.palette.color.grey7,
  },
  columnNavbar: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.color.white2} `,
  },
  showPanelBtn: {
    width: '27px !important',
    minWidth: '27px !important',
    height: '27px !important',
    border: `1px solid ${theme.palette.color.grey8} !important`,
    top: '100px',
    display: 'inline-flex',
    right: '588px',
    backgroundColor: `${theme.palette.color.white} !important`,
    zIndex: 9,
  },
  showPanelBtnInactive: {
    width: '24px !important',
    minWidth: '24px !important',
    height: '24px !important',
    borderRadius: '20px',
    boxShadow: `0px 2px 10px ${theme.palette.color.grey5}`,
    top: '40px',
    display: 'none',
    right: 'none',
    backgroundColor: `${theme.palette.color.white} !important`,
    zIndex: 9,
  },
}));
