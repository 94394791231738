import { Grid } from '@mui/material';
import TreeChartCommon from 'components/Common/TreeChartCommon';
import { useCallback, useMemo } from 'react';

interface ILandUseSubChart {
  treeData: any;
  areaSetting: (value: any) => string;
  areaUnit: string;
}

export const LandUseSubChart = ({ treeData, areaSetting, areaUnit }: ILandUseSubChart) => {
  const dataPie = useMemo(() => {
    if (!treeData) return [];
    let dataWithPath: any[] = [];
    treeData.forEach((element: any) => {
      const duplicateDataIndex = dataWithPath.findIndex((i) => i.name === element.landUse);
      if (duplicateDataIndex > -1) {
        const indexChild = dataWithPath[duplicateDataIndex].children.findIndex(
          (_item: any) => _item.name === element.category
        );
        // find child with same name and merger value
        if (indexChild > -1) {
          dataWithPath[duplicateDataIndex].children[indexChild] = {
            ...dataWithPath[duplicateDataIndex].children[indexChild],
            value: +areaSetting(element.area) + +dataWithPath[duplicateDataIndex].children[indexChild].value,
          };
          dataWithPath[duplicateDataIndex] = {
            ...dataWithPath[duplicateDataIndex],
            value: (Number(areaSetting(element.area)) + Number(dataWithPath[duplicateDataIndex].value)).toString(),
            children: [...dataWithPath[duplicateDataIndex].children],
          };
          return;
        }
        // find child with same landUse and merger value
        dataWithPath[duplicateDataIndex] = {
          ...dataWithPath[duplicateDataIndex],
          value: (Number(areaSetting(element.area)) + Number(dataWithPath[duplicateDataIndex].value)).toString(),
          children: [
            ...dataWithPath[duplicateDataIndex].children,
            {
              name: element.category,
              value: areaSetting(element.area),
              path: `${element.landUse}/${element.category}`,
            },
          ],
        };
        return;
      }
      dataWithPath.push(
        element.category
          ? {
              name: element.landUse,
              value: areaSetting(element.area),
              path: element.landUse,
              children: [
                {
                  name: element.category,
                  value: areaSetting(element.area),
                  path: `${element.landUse}/${element.category}`,
                },
              ],
            }
          : {
              name: element.landUse,
              value: areaSetting(element.area),
              path: element.landUse,
            }
      );
    });
    return dataWithPath;
  }, [areaSetting, treeData]);

  const formatText = useCallback(
    (info: any) => {
      return `${info.name}: ${areaSetting(+info.value)} ${areaUnit}`;
    },
    [areaSetting, areaUnit]
  );

  return (
    <>
      {dataPie.length > 0 && (
        <Grid sx={{ mt: 1 }}>
          <TreeChartCommon
            title={`Tree Chart Categories`}
            dataChart={dataPie}
            chartType={'treemap'}
            formatText={formatText}
          />
        </Grid>
      )}
    </>
  );
};
