import { styled, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getSurfaceExposedWaterStatisticsInfo } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeIsDisplayBoundary, mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import SurfaceExposedWaterCardLayer from '../../Layer/SurfaceExposedWater';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';

interface ISummaryInfo {
  totalArea: number;
  numberOfFeature: number;
}

const SurfaceExposedAnalytics = () => {
  const [summaryInfo, setSummaryInfo] = useState<ISummaryInfo>({ totalArea: 0, numberOfFeature: 0 });
  const theme = useTheme();
  const { analyticId } = useAppSelector(mapViewSelector);
  const { areaSetting } = useQueryClientSetting();
  const { analyticName } = useAppSelector(rightBarSelector);
  const dispatch = useAppDispatch();

  const tableData = [
    {
      key: 1,
      label: 'Total Area',
      value: `${new BigNumber(summaryInfo.totalArea).toFixed(areaSetting.decimalPlace)} m²`,
    },
    { key: 2, label: 'Number Of Features', value: summaryInfo.numberOfFeature },
  ];

  useQuery([QUERY_KEY.GET_SURFACE_EXPOSED_WATER_STATISTIC], () => getSurfaceExposedWaterStatisticsInfo(analyticId!), {
    enabled: !!analyticId && analyticName === TYPE_ANALYTICS_MAP_VIEW.SURFACE_EXPOSED_WATER,
    onSuccess(res) {
      const resData = res.data as ISummaryInfo;
      setSummaryInfo(resData);
    },
  });

  // set 2dContour option is default
  useEffect(() => {
    dispatch(changeIsDisplayBoundary(true));
  }, [dispatch]);

  const CustomTableCell = styled(TableCell)({
    fontWeight: 'bold',
    color: theme.palette.mode === 'dark' ? '#fff !important' : '#6E6B7B !important',
  });

  const renderAnalyticTab = (
    <Table>
      <TableBody>
        {tableData.map((row) => (
          <TableRow key={row.key} sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontWeight: 'bold' }}>
            <CustomTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
              {row.label}
            </CustomTableCell>
            <CustomTableCell align="center" sx={{ fontWeight: 'bold' }}>
              {row.value}
            </CustomTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <SwipeableViewsCustom
      isDisableLabel
      analyticName={TYPE_ANALYTICS_MAP_VIEW.SURFACE_EXPOSED_WATER}
      analyticTab={renderAnalyticTab}
      layerTab={
        <>
          <SurfaceExposedWaterCardLayer />
        </>
      }
      isShowDownloadButton={false}
    />
  );
};

export default SurfaceExposedAnalytics;
