import { distance } from '@turf/turf';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { MeasurementUnit, SettingUIName, SettingUnitName } from '../common/defines/clients';
import { defaultClientUISetting, defaultClientUnitSetting } from '../common/dummy/dummyClients';
import { MANAGE_CLIENT_SETTING_VIEW, QUERY_KEY } from '../constants/constants';
import { getClientSettingsById } from '../services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { superAdminSelector } from 'store/slices/superAdminSlice';

export const useClientData = () => {
  const { clientId } = useParams();
  const { permissions } = useAppSelector(superAdminSelector)

  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => permissions[MANAGE_CLIENT_SETTING_VIEW] ? getClientSettingsById(clientId || '') : undefined,
    {
      enabled: !!clientId,
    }
  );

  const contourSetting = useMemo(() => {
    if (!clientData || !clientData.uiSetting) return 65;
    const contourHeightSetting =
      clientData.uiSetting.find((val: any) => val.name === SettingUIName.CONTOUR_3D_HEIGHT) ||
      defaultClientUISetting[1];
    return contourHeightSetting.value || 65;
  }, [clientData]);

  const cylindarSetting = useMemo(() => {
    if (!clientData || !clientData.uiSetting) return 1;
    const cylindarRadiusSetting =
      clientData.uiSetting.find((val: any) => val.name === SettingUIName.CYLINDAR_3D_RADIUS) ||
      defaultClientUISetting[2];
    return cylindarRadiusSetting.value || 1;
  }, [clientData]);

  const lengthSetting = useMemo(() => {
    return (
      clientData?.unitSetting.find((data: any) => data.name === SettingUnitName.LENGTH) || defaultClientUnitSetting[1]
    );
  }, [clientData]);

  const lengthUnit = useMemo(() => {
    const unitLength = lengthSetting.unit;
    if (unitLength === MeasurementUnit.M) {
      return 'm';
    }
    if (unitLength === MeasurementUnit.MILES) {
      return 'mi';
    }
    if (unitLength === MeasurementUnit.KM) {
      return 'km';
    }
    return unitLength;
  }, [lengthSetting]);

  const distanceCalculator = useCallback(
    (pointFrom: any, pointTo: any, unit: string) => {
      const distancePerKm = distance(pointFrom, pointTo, { units: 'kilometers' });
      const distancePerMiles = distance(pointFrom, pointTo, { units: 'miles' });
      let distantByUnit = 0;
      switch (unit) {
        case 'm':
          distantByUnit = distancePerKm * 1000;
          // code block
          break;
        case 'km':
          distantByUnit = distancePerKm;
          // code block
          break;
        case 'mi':
          distantByUnit = distancePerMiles;
          break;
        default:
        // code block
      }
      return distantByUnit.toFixed(lengthSetting.decimalPlace);
    },
    [lengthSetting]
  );

  return { clientData, contourSetting, cylindarSetting, lengthUnit, distanceCalculator };
};
