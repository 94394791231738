import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Stack, Typography, useTheme } from '@mui/material';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

interface DragAndDropFileProps {
  setImageList: (arg: File[]) => void;
  description?: string;
}

const DragAndDropFile: FC<DragAndDropFileProps> = ({ description, setImageList }) => {
  const theme = useTheme();

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop: (files: File[]) => {
      setImageList(files);
    },
    onDropRejected(fileRejections, event) {
      toast.error('Drop file failed', { toastId: 1 });
    },
  });

  return (
    <Stack
      {...getRootProps({ className: 'dropzone' })}
      sx={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        border: theme.palette.mode === 'dark' ? '2px dashed red' : '2px dashed #bdbdbd',
        borderColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#bdbdbd',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
      }}>
      <input {...getInputProps()} />
      <Stack sx={{ alignItems: 'center' }}>
        <CloudUploadOutlinedIcon />
      </Stack>
      <Typography sx={{ textAlign: 'center', mt: 1 }}>Drag&Drop files here</Typography>
      {description && <Typography sx={{ textAlign: 'center', mt: 1 }}>{description}</Typography>}
      <ButtonCustom
        title="Browse Files"
        border="0px"
        colorLabel="#FFF"
        sx={{ width: '100px', height: '32px !important', minHeight: '32px !important', mt: 2 }}
        backgroundColor={theme.palette.primary.main}
        onClick={open}
      />
    </Stack>
  );
};

export default DragAndDropFile;
