import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { QUERY_KEY } from 'constants/constants';
import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteTreeManual, deleteTreePolygonMode } from 'services/analytics/apiAnalyticsData.services';
import { useAppSelector } from 'store/hooks';
import { clearStandCountAnalytic } from 'store/slices/map-view/standCountAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const DialogDeleteStandPoint = ({ open, setOpen, idPoints, isPointerMode }: any) => {
  const queryClient = useQueryClient();
  const { analyticId } = useAppSelector(mapViewSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const mutationDeletePoint = useMutation((id: any) => deleteTreeManual(id), {
    onSuccess: () => {
      toast.success('Point deleted successfully');
      dispatch(clearStandCountAnalytic());
      queryClient.invalidateQueries([QUERY_KEY.STAND_COUNT_ANALYSIS, analyticId]);
      queryClient.invalidateQueries([QUERY_KEY.STAND_COUNT_CHART_ANALYSIS, analyticId]);
      setOpen(false);
    },
    onError: () => {
      toast.error('Error deleting point');
      dispatch(clearStandCountAnalytic());
      setOpen(false);
    },
  });

  const mutationDeletePolygonMode = useMutation((arrayId: any) => deleteTreePolygonMode(arrayId), {
    onSuccess: () => {
      toast.success('Points deleted successfully');
      dispatch(clearStandCountAnalytic());
      queryClient.invalidateQueries([QUERY_KEY.STAND_COUNT_ANALYSIS, analyticId]);
      queryClient.invalidateQueries([QUERY_KEY.STAND_COUNT_CHART_ANALYSIS, analyticId]);
      setOpen(false);
    },
    onError: () => {
      toast.error('Error deleting points');
      dispatch(clearStandCountAnalytic());
      setOpen(false);
    },
  });

  const handleDeletePoint = () => {
    if (!idPoints) return;
    if (isPointerMode) {
      mutationDeletePoint.mutate(idPoints);
    } else {
      mutationDeletePolygonMode.mutate({ ids: idPoints });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage: 'none',
          },
        }}>
        <DialogTitle
          style={{
            color: 'red',
            fontWeight: 'bold',
            fontSize: '20px',
          }}>
          Delete Stand Count Points
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete this selected points? This process cannot be undone{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#64748B'),
              border: (theme) => theme.palette.divider,
              '&:hover': {
                border: `1px solid #64748B`,
                boxShadow: 'none',
              },
            }}
            autoFocus
            onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDeletePoint}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogDeleteStandPoint;
