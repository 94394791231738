import { Box, Card, CardContent, CardHeader, Slider, styled, Typography } from '@mui/material';
import { BOTTOM_BAR_MONTH_LIST } from 'constants/constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { bottomBarSelector, changeDateRange } from 'store/slices/map-view/bottomBarSlice';
import YearSelectDropdown from './YearPicker';

const ArrowPointer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 8,
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderTop: `10px solid ${theme.palette.primary.main}`,
  transform: 'translateX(-50%)',
}));

const StyledMonthSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#202329' : theme.palette.background.default,
  height: 2,
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: 'grey',
  },
}));

const subHeaderFromDateFormat = 'DD MMMM';
const subHeaderToDateFormat = 'DD MMMM YYYY';
const bottomBarHeader = 'Data Tabulation Monthly';

const MonthSlider: React.FC = () => {
  const { dateRange } = useAppSelector(bottomBarSelector);

  const [subHeaderDate, setSubHeaderDate] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fromDate = moment(dateRange.startDate).format(subHeaderFromDateFormat);
    const toDate = moment(dateRange.endDate).format(subHeaderToDateFormat);
    setSubHeaderDate(fromDate + ' - ' + toDate);
  }, [dateRange]);

  const handleChange = (event: Event | React.MouseEvent, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      dispatch(changeDateRange({ month: newValue, year: Number(moment(dateRange.startDate).format('YYYY')) }));
    }
  };

  const sliderWidth = '90%';

  return (
    <Card sx={{ width: '80%', position: 'absolute', bottom: '15px', left: '13%', zIndex: '10' }}>
      <CardHeader
        sx={{
          textAlign: 'center',
          paddingBottom: '0px',
        }}
        action={<YearSelectDropdown />}
        title={bottomBarHeader}
        subheader={subHeaderDate}
      />
      <CardContent sx={{ padding: '0px 10px' }}>
        <Box sx={{ position: 'relative', width: sliderWidth, margin: 'auto' }}>
          <StyledMonthSlider
            track={false}
            value={dateRange.month}
            min={0}
            max={BOTTOM_BAR_MONTH_LIST.length - 1}
            step={1}
            onChange={handleChange}
            components={{ Thumb: ArrowPointer }}
          />
          {BOTTOM_BAR_MONTH_LIST.map((month, index) => (
            <Box
              key={month}
              onClick={(e: React.MouseEvent) => handleChange(e, index)}
              sx={{
                position: 'absolute',
                left: `calc(${(index / (BOTTOM_BAR_MONTH_LIST.length - 1)) * 100}% - 10px)`,
                top: '1rem',
                textAlign: 'center',
                textTransform: index === dateRange.month ? 'uppercase' : 'unset',
                cursor: 'pointer',
              }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: index === dateRange.month ? 'bold' : '',
                }}>
                {month}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MonthSlider;
