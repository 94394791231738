import { Box, FormControlLabel, Grid, Switch, Typography, useTheme } from '@mui/material';
import { RenderTree } from 'common/defines/clients';
import TreeStructureChartCommon from 'components/Common/TreeStructureChartCommon';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY } from 'constants/constants';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  getLevelCountByCropType,
  getLevelListByCropType,
  getUIDisplay,
  queryMe,
} from 'services/clients/apiClient.services';
import ChangeFormula from './ChangeFormula';

interface SwitchProps {
  key: number;
  isDisabled: boolean;
  checked: boolean;
}

interface CountByLevel {
  level: number;
  count: number;
}

const NoDataLayout: FC<{ currentTypeNoData: boolean }> = ({ currentTypeNoData }) => {
  return currentTypeNoData ? (
    <Typography sx={{ padding: '14px', fontSize: '16px' }}>No Data Available</Typography>
  ) : (
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <WaitingPageAPI />
    </Grid>
  );
};

const TreeStructureDisplay = (props: any) => {
  const theme = useTheme();
  const { selectedCropType, clientId, clientName } = props;
  const [switchStatusList, setSwitchStatusList] = useState<SwitchProps[]>([]);
  const [listCountUnitByLevel, setListCountUnitByLevel] = useState<CountByLevel[]>([]);
  const [isDisabledAll, setIsDisabledAll] = useState<boolean>(false);
  const [isShowFormula, setIsShowFormula] = useState<boolean>(false);
  const [currentTypeNoData, setCurrentTypeNoData] = useState<boolean>(false);

  const { data: userInfo } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: false,
  });

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const sumAreaMode = useMemo(() => {
    return userInfo?.data.sumAreaMode;
  }, [userInfo]);

  useQuery([QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId], () => getUIDisplay(clientId!, userId), {
    enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    onSuccess(data) {
      const flagTabList = data.data?.flagTab;
      if (flagTabList.includes('UTILITY-SETTINGS-FORMULA-BUTTON')) {
        setIsShowFormula(true);
      } else {
        setIsShowFormula(false);
      }
    },
  });

  const { data: levelList, isLoading } = useQuery(
    [QUERY_KEY.LEVEL_LIST, selectedCropType, sumAreaMode],
    () => getLevelListByCropType(clientId, selectedCropType, sumAreaMode),
    {
      enabled: !!selectedCropType && !!clientId && !!listCountUnitByLevel.length,
      onSuccess(data) {
        const maxLevel = findMaxLevel(data);
        setSwitchStatusList(
          Array(maxLevel)
            .fill(null)
            .map((_, i) => {
              const result = { key: i, isDisabled: false, checked: true };
              const lastUnitLevel = listCountUnitByLevel?.find(
                (countItem: CountByLevel) => countItem?.level === maxLevel
              );
              if (i === maxLevel - 1 && lastUnitLevel && lastUnitLevel?.count > 50) result.checked = false;
              return result;
            })
        );
      },
    }
  );

  useQuery([QUERY_KEY.COUNT_LIST, selectedCropType], () => getLevelCountByCropType(clientId, selectedCropType), {
    enabled: !!selectedCropType && !!clientId,
    onSuccess(data) {
      setListCountUnitByLevel(data);
      setCurrentTypeNoData(data.length === 0);
    },
  });

  const findMaxLevel = (tree: RenderTree[]): number => {
    let maxValue = 0;
    for (const item of tree) {
      if (item.children?.length) {
        return findMaxLevel(item.children);
      } else {
        if (maxValue < item.level) maxValue = item.level;
      }
    }
    return maxValue;
  };

  const dataSummaryTreeStructure = useMemo(() => {
    let result = {
      name: clientName,
      children: [],
    } as {
      name: string;
      children: any[];
    };
    if (!levelList || levelList.length === 0) return result;
    result.children.push(...levelList);
    return result;
  }, [levelList]);

  const handleToggleButton = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDisabledAll(true);
    setTimeout(() => {
      setIsDisabledAll(false);
    }, 2000);
    const selectedIndex = Number(event.target.name.slice(-1));

    let newCheckedList = switchStatusList.map((item, index) => {
      return {
        ...item,
        checked: selectedIndex === index ? !item.checked : item.checked,
      };
    });
    const firstKeyUnchecked = newCheckedList.find((item) => !item.checked)?.key ?? -1;
    newCheckedList = newCheckedList.map((item, index) => ({
      ...item,
      isDisabled: firstKeyUnchecked >= 0 && firstKeyUnchecked < index,
    }));
    setSwitchStatusList(newCheckedList);
  };

  const getUnitByLevel = (level: number) => {
    const count = listCountUnitByLevel?.find((countItem: CountByLevel) => countItem?.level === level)?.count;
    return count && `(${count} ${count > 1 ? 'units' : 'unit'})`;
  };

  const isShowFormulaButton = () => {
    return isSuperAdmin || isSuperUser || isShowFormula;
  };

  const renderLabel = (index: number) => (
    <>
      <Typography sx={{ display: 'inline' }}>{`Level ${index + 1}  `}</Typography>
      &nbsp;
      <Typography sx={{ display: 'inline', fontWeight: '500' }}>{getUnitByLevel(index + 1)}</Typography>
    </>
  );

  return (
    <>
      {selectedCropType && clientId ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: '100%',
            height: '100%',
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.color.white1,
            mx: '12px',
            borderRadius: '10px',
            position: 'relative',
          }}>
          {listCountUnitByLevel.length === 0 || switchStatusList.length === 0 || isLoading ? (
            <NoDataLayout currentTypeNoData={currentTypeNoData} />
          ) : (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  zIndex: '100',
                }}>
                Summary Tree Structure of
                <b> {clientName}</b> - unit <b>ha</b>
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '40px',
                  left: '10px',
                  zIndex: 100,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                {listCountUnitByLevel &&
                  switchStatusList.length &&
                  switchStatusList.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Switch
                          disabled={isDisabledAll || switchStatusList[index].isDisabled}
                          checked={switchStatusList[index].checked}
                          name={`toggle${index}`}
                          onChange={handleToggleButton}
                        />
                      }
                      label={renderLabel(index)}
                    />
                  ))}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: 'calc(100% - 16px)',
                  position: 'absolute',
                  top: '16px',
                  left: '0',
                  right: '0',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}>
                <TreeStructureChartCommon
                  data={dataSummaryTreeStructure}
                  cropTypeId={selectedCropType}
                  switchStatusList={switchStatusList}
                />
              </Box>
              {isShowFormulaButton() && <ChangeFormula />}
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default TreeStructureDisplay;
