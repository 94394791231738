import { Box, Skeleton, useTheme } from '@mui/material';
import { IStreamlineOrderDisplay } from 'common/defines/clients';
import { clientLengthUnitMeasurement } from 'components/MapView/RightBarOld/IssuesTab/utils';
import ReactECharts from 'echarts-for-react';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { FC } from 'react';

interface StreamlineOrderPieChartProps {
  data: IStreamlineOrderDisplay[];
  isLoading: boolean;
}

const StreamlineOrderPieChart: FC<StreamlineOrderPieChartProps> = ({ data, isLoading }) => {
  const theme = useTheme();
  const { lengthSetting } = useQueryClientSetting();

  const convertedData = data
    .filter((item) => item.visible)
    .map((item) => ({
      value: item.length,
      itemStyle: { color: item.colorCode },
      order: item.order,
      lengthPercentage: item.lengthPercentage,
    }));

  const option = {
    tooltip: {
      trigger: 'item',
      backgroundColor: theme.palette.background.paper,
      borderWidth: 0,
      position: 'top',
      textStyle: {
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#3C4043',
      },
      formatter: (item: any) => {
        const { value: length, order, lengthPercentage } = item.data;
        return `Order: ${order}<br/>
          Length: ${clientLengthUnitMeasurement(length, lengthSetting)}${lengthSetting.unit}<br/>
          Length percentage: ${Number.parseFloat(lengthPercentage).toFixed(2)}%
          `;
      },
    },
    series: [
      {
        type: 'pie',
        radius: '80%',
        data: convertedData,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };
  return (
    <Box sx={{ mt: '0px' }}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '32px' }}>
          <Skeleton variant="circular" width={200} height={200} />
        </Box>
      ) : (
        <ReactECharts option={option} />
      )}
    </Box>
  );
};

export default StreamlineOrderPieChart;
