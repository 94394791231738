import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { getFromDateAndToDate } from 'common/utils/util';

interface IBottomBarSlice {
    dateRange: {
        startDate: string;
        endDate: string;
        month: number;
    };
}

const currentDate = new Date();

let dateRange = getFromDateAndToDate(currentDate.getMonth(), currentDate.getFullYear());

const initialState: IBottomBarSlice = {
    dateRange: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        month: currentDate.getMonth(),
    },
};

export const bottomBarSlice = createSlice({
    name: 'bottomBarSlice',
    initialState,
    reducers: {
        changeDateRange: (state, action: PayloadAction<{ month: number, year: number }>) => {
            dateRange = getFromDateAndToDate(action.payload.month, action.payload.year);
            state.dateRange.startDate = dateRange.startDate;
            state.dateRange.endDate = dateRange.endDate;
            state.dateRange.month = action.payload.month;
        },
    },
});

export const { changeDateRange } = bottomBarSlice.actions;

export const bottomBarSelector = (state: RootState) => state.bottomBar;

export default bottomBarSlice.reducer;
