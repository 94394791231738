import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ICreateClientSettings } from 'common/defines/clients';
import { defaultClientSettings, defaultIconFavoriteSetting } from 'common/dummy/dummyClients';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateClientSettingsById } from 'services/clients/apiClient.services';

const DialogFavoriteIcon = (props: any) => {
  const { open, onClose, refetch, clientData, clientId, settingId } = props;
  const [iconFavorite, setIconFavorite] = useState(
    clientData?.favouriteSetting?.icon || defaultIconFavoriteSetting.icon
  );
  const [colorFavoriteIcon, setColorFavoriteIcon] = useState(
    clientData?.favouriteSetting?.color || defaultIconFavoriteSetting.color
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (clientData?.favouriteSetting?.icon === undefined || clientData?.favouriteSetting?.color === undefined) {
      setIconFavorite(defaultIconFavoriteSetting.icon);
      setColorFavoriteIcon(defaultIconFavoriteSetting.color);
    } else {
      setIconFavorite(clientData.favouriteSetting?.icon);
      setColorFavoriteIcon(clientData.favouriteSetting?.color);
    }
  }, [clientData]);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const { control, handleSubmit, setValue } = useForm<ICreateClientSettings>({
    defaultValues: defaultClientSettings,
  });

  const handleUpdateMeasure = useMutation(
    (data: ICreateClientSettings) => updateClientSettingsById(settingId || '', data),
    {
      onSuccess: () => {
        onClose();
        refetch();
        toast.success('Change settings successfully');
      },
    }
  );

  const handleSubmitForm: SubmitHandler<ICreateClientSettings> = (data) => {
    handleUpdateMeasure.mutate({
      ...data,
      clientId,
      favouriteSetting: {
        icon: iconFavorite,
        color: colorFavoriteIcon,
        name: data.favouriteSetting?.name || '',
      },
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleChangeIconFavorite = (event: any) => {
    setValue('favouriteSetting.icon', event.target.value);
    setIconFavorite(event.target.value as string);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeField = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const trimInputValue = event.target.value.replace(/\s+/g, ' ');
    setColorFavoriteIcon(trimInputValue);
  }, []);

  const handleChangeColor = useCallback(
    (color: string) => {
      setValue('favouriteSetting.color', color);
      setColorFavoriteIcon(color);
    },
    [setValue]
  );

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            backgroundColor: (theme) => theme.palette.background.paper,
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle
        sx={{
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
          fontSize: '20px',
          fontWeight: '600',
        }}>
        Client Settings Edit
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
        }}>
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          padding: '1rem 2rem',
        }}>
        <Typography
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}
          onSubmit={handleSubmit(handleSubmitForm)}>
          <DialogContent
            sx={{
              padding: 0,
            }}>
            <Grid container alignItems="center" sx={{ overflow: 'hidden' }}>
              <Grid item xs={12} sx={{ paddingLeft: '24px', display: 'flex', flexDirection: 'column' }}>
                <Controller
                  name="favouriteSetting.icon"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        label=""
                        control={
                          <Box
                            sx={{
                              flex: 1,
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '-8px',
                              marginBottom: '1rem',
                            }}>
                            <Box
                              sx={{
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}>
                              <Typography
                                sx={{
                                  minWidth: '120px',
                                  // color: '#737373',
                                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#737373'),
                                }}>
                                Select Favorite Icon
                              </Typography>
                            </Box>
                            <Box sx={{ paddingLeft: '10px' }}>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={iconFavorite}
                                onChange={handleChangeIconFavorite}>
                                <MenuItem value={'StarRoundedIcon'}>
                                  <StarRoundedIcon
                                    sx={{
                                      color: colorFavoriteIcon,
                                    }}
                                  />
                                </MenuItem>
                                <MenuItem value={'FavoriteIcon'}>
                                  <FavoriteIcon
                                    sx={{
                                      color: colorFavoriteIcon,
                                    }}
                                  />
                                </MenuItem>
                              </Select>
                            </Box>
                          </Box>
                        }
                      />
                    </>
                  )}
                />
                <Controller
                  name="favouriteSetting.color"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        label=""
                        control={
                          <Box
                            sx={{
                              flex: 1,
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              padding: '24px 0 !important',
                              marginLeft: '-8px',
                              marginBottom: '1rem',
                            }}>
                            <Box
                              sx={{
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}>
                              <Typography
                                sx={{
                                  minWidth: '120px',
                                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#737373'),
                                }}>
                                Custom Color
                              </Typography>
                              <Tooltip
                                title={openPopover ? 'Close pick color' : 'Open pick color'}
                                placement="right"
                                arrow>
                                <Box
                                  onClick={(e: any) => handleClick(e)}
                                  sx={{
                                    backgroundColor: colorFavoriteIcon,
                                    height: '37.13px',
                                    width: '50px',
                                    borderRadius: '5px',
                                    border: '2px solid white',
                                    marginLeft: '16px',
                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {
                                      cursor: 'pointer',
                                      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
                                    },
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Box sx={{ paddingLeft: '10px' }}>
                              <TextField
                                id="cropTypeColor"
                                type="text"
                                size="small"
                                sx={{
                                  color: colorFavoriteIcon,
                                  border: `1px solid ${colorFavoriteIcon}`,
                                  borderRadius: '5px',
                                  width: '130px',
                                }}
                                fullWidth
                                value={colorFavoriteIcon}
                                onChange={handleChangeField}
                              />
                            </Box>
                            <Popover
                              id={id}
                              open={openPopover}
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              sx={{
                                '.MuiPopover-paper': {
                                  overflowX: 'initial',
                                  overflowY: 'initial',
                                },
                              }}
                              transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              anchorOrigin={{
                                vertical: 130,
                                horizontal: 210,
                              }}>
                              <Box
                                sx={{
                                  height: '200px',
                                  width: '200px',
                                  zIndex: 10,
                                  borderRadius: '10px',
                                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.6)',
                                }}>
                                <HexColorPickerDebounce
                                  style={{
                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '10px',
                                  }}
                                  color={colorFavoriteIcon}
                                  onChange={handleChangeColor}
                                />
                              </Box>
                            </Popover>
                          </Box>
                        }
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              fullWidth
              sx={{
                background: (theme) => theme.palette.primary.main,
                width: '200px',
                height: '40px',
                align: 'right',
                color: '#fff',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#6FE4A5',
                },
              }}>
              Save
            </Button>
          </DialogActions>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default DialogFavoriteIcon;
