import { useAppDispatch, useAppSelector } from 'store/hooks';
import { bottomBarSelector, changeDateRange } from 'store/slices/map-view/bottomBarSlice';
import moment from 'moment';
import { MenuItem, Select, styled } from '@mui/material';

const YearSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select': {
        paddingTop: '10px',
        paddingBottom: '10px'
    }
}));

const YearSelectDropdown = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
    const { dateRange } = useAppSelector(bottomBarSelector)
    const dispatch = useAppDispatch();

    const handleYearChange = (event: any) => {
        dispatch(changeDateRange({ month: Number(moment(dateRange).format('MM')) - 1, year: Number(event.target.value) }));
    };

    return (
        <YearSelect value={moment(dateRange.startDate).format('YYYY')} onChange={handleYearChange}>
            {years.map((year) => (
                <MenuItem key={year} value={year}>
                    {year}
                </MenuItem>
            ))}
        </YearSelect>
    );
};

export default YearSelectDropdown;
