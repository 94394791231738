import { Box, Typography } from '@mui/material';
import configs from 'constants/config';
import useQueryWorkspaceInfo from 'hooks/workspace/useQueryWorkspaceInfo';
import { IEventSourceData, IField, IRawFileUpload, OdmStatusCode } from 'interfaces/workspace';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import LinearProgressWithLabel from './components/LinearProgressWithLabel';

interface ProgressingDataProps {
  rawFileUpload: IRawFileUpload | undefined;
  fieldDetail: IField | undefined;
  handleClose: () => void;
}

const ProgressingData: FC<ProgressingDataProps> = ({ rawFileUpload, fieldDetail, handleClose }) => {
  const [progressData, setProgressData] = useState<IEventSourceData>();
  const [isError, setIsError] = useState<boolean>(false);

  const { workspaceData } = useQueryWorkspaceInfo();

  let stopwatchOffset = new Date();
  if (fieldDetail) {
    stopwatchOffset.setSeconds(Math.round(moment(new Date()).diff(moment(fieldDetail.updatedAt)) / 1000));
  }

  const stopwatch = useStopwatch({ offsetTimestamp: stopwatchOffset, autoStart: true });
  let eventSource: EventSource;

  // get progress bar information upload raw file
  useEffect(() => {
    let info = { projectId: '', taskId: '', status: false };
    if (rawFileUpload) {
      info = { projectId: rawFileUpload.project, taskId: rawFileUpload.id, status: false };
    } else if (fieldDetail) {
      info = { projectId: workspaceData?.projectId!, taskId: fieldDetail.taskId, status: fieldDetail.status };
    }
    const { taskId, projectId, status } = info;

    if (taskId && projectId && !status) {
      eventSource = new EventSource(`${configs.API_DOMAIN}/field/sse/${projectId}/${taskId}`);
      eventSource.onmessage = (event) => {
        const progress = JSON.parse(event.data) as IEventSourceData;
        setProgressData(progress);
        if (progress.status === OdmStatusCode.COMPLETED) {
          eventSource.close();
        }
      };
      eventSource.onerror = (error) => {
        eventSource.close();
        setIsError(true);
        console.error(error);
      };
    }

    return () => {
      eventSource?.close();
    };
  }, [rawFileUpload, workspaceData?.projectId]);

  return (
    <Box>
      {fieldDetail?.name && (
        <Box>
          <Typography>Name: {fieldDetail?.name}</Typography>
        </Box>
      )}
      <LinearProgressWithLabel
        isError={isError}
        value={progressData ? Math.round(progressData.runningProgress * 100) : 0}
        status={progressData?.status}
        stopwatch={stopwatch}
      />
    </Box>
  );
};

export default ProgressingData;
