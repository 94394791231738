import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, Typography } from '@mui/material';
import { RasterLayerDTO } from 'common/defines/analytic';
import { LABEL_DATA_RASTERS, LayerTypeEnum, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import VectorCollapse from 'components/SwitchRasterComp/VectorCollapse';
import { QUERY_KEY } from 'constants/constants';
import { filter, find, get, isEmpty, uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getRasterDetails, getRasterLayer } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  mapViewSelector,
  selectRasterLayer,
  setLayerTypeCurrentList,
  unSelectRasterLayer,
} from 'store/slices/mapViewSlice';

const TypeRasterConditons = {
  [TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE]: [LayerTypeEnum.VIGOR_FIELD, LayerTypeEnum.VIGOR_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE]: [LayerTypeEnum.CHLOROPHYLL_FIELD, LayerTypeEnum.CHLOROPHYLL_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE]: [LayerTypeEnum.STRESS_FIELD, LayerTypeEnum.STRESS_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE]: [LayerTypeEnum.WATER_UPTAKE_FIELD, LayerTypeEnum.WATER_UPTAKE_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE]: [LayerTypeEnum.PLANT_HEALTH_FIELD, LayerTypeEnum.PLANT_HEALTH_TREE],
  [TYPE_ANALYTICS_MAP_VIEW.SURFACE_EXPOSED_WATER]: [
    LayerTypeEnum.SURFACE_EXPOSED_WATER_FIELD,
    LayerTypeEnum.SURFACE_EXPOSED_WATER_POLYGON,
  ],

  [TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD]: [LayerTypeEnum.PLANT_HEALTH_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD]: [LayerTypeEnum.VIGOR_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD]: [LayerTypeEnum.CHLOROPHYLL_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD]: [LayerTypeEnum.STRESS_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD]: [LayerTypeEnum.WATER_UPTAKE_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.SOIL_SATURATION_FIELD]: [LayerTypeEnum.SOIL_SATURATION_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD]: [LayerTypeEnum.SOIL_EROSION_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD]: [LayerTypeEnum.LEAF_PHENOLOGY_FIELD],
  [TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD]: [LayerTypeEnum.SLOPE_FIELD],
};

export const SwitchRaster = ({ analyticType, isShowInlineBlock }: any) => {
  const [isRaster, setRaster] = useState(true);
  const [layerByRasterVector, setLayerByRasterVector] = useState(false);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();
  const dispatch = useAppDispatch();
  const { layerTypeList, dateAnalyticSelected, levelId, analyticId } = useAppSelector(mapViewSelector);

  const { data } = useQuery(
    [QUERY_KEY.RASTER_LAYER, dateAnalyticSelected, levelId],
    () => getRasterLayer(levelId || '', dateAnalyticSelected || ''),
    {
      enabled: !isEmpty(levelId) && !isEmpty(dateAnalyticSelected),
    }
  );

  const { data: rasterDetail } = useQuery([QUERY_KEY.RASTER_DETAILS], () => getRasterDetails(analyticId!), {
    enabled: !!analyticId && !!data?.data,
  });

  const uniqueRasterLayer = useMemo(() => {
    return uniqBy(layerTypeList, '_id');
  }, [layerTypeList]);

  const filterData = (dataRasterList: any, conditions: any) => {
    return filter(dataRasterList, (item) => conditions.includes(item?.layerType));
  };

  const rasterList = useMemo(() => {
    const rasterListFromDetail = rasterDetail?.data?.uploadHistory;

    const convertedRasterList: any[] = Array.isArray(rasterListFromDetail)
      ? rasterListFromDetail.map((item) => ({
        ...item,
        analysisId: {
          _id: item.analysisId,
          name: item.name,
        },
      }))
      : [];
    const dataRasterList = data?.data;

    if (dataRasterList && convertedRasterList) {
      const rasterRGB = dataRasterList?.find((i: RasterLayerDTO) => i.layerType === LayerTypeEnum.RGB_ORTHOIMAGE);
      const fullRasterList = [rasterRGB, ...convertedRasterList];

      dispatch(setLayerTypeCurrentList(fullRasterList));
      if (rasterRGB) {
        dispatch(selectRasterLayer([rasterRGB, ...uniqueRasterLayer]));
      } else {
        dispatch(selectRasterLayer([]));
      }
      const rasterData = () => {
        switch (analyticType) {
          case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
          case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_SATURATION_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD:
          case TYPE_ANALYTICS_MAP_VIEW.SURFACE_EXPOSED_WATER:
            return filterData(fullRasterList, get(TypeRasterConditons, analyticType));
          default:
            return [];
        }
      };

      return rasterData();
    }
    dispatch(selectRasterLayer([]));
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, analyticType]);

  const getNameRaster = useCallback((_item: RasterLayerDTO) => {
    if (_item.layerType === LayerTypeEnum.RGB_ORTHOIMAGE) {
      return `${LABEL_DATA_RASTERS[_item.layerType]} - ${_item.analysisId.name}`;
    }
    return LABEL_DATA_RASTERS[_item.layerType];
  }, []);

  const orthoimageData = useMemo(() => {
    const dataRasterList = data?.data;
    if (!dataRasterList) return;
    return find(dataRasterList, (item) => item?.layerType === LayerTypeEnum.RGB_ORTHOIMAGE);
  }, [data?.data]);

  const handleChangeRaster = (item: any, isCheckedOld: boolean) => {
    dispatch(selectRasterLayer(item));
    if (!isCheckedOld) {
      const data = rasterList?.find((i: RasterLayerDTO) => i._id !== item._id);
      if (data) {
        dispatch(unSelectRasterLayer(data));
      }
    }
  };

  return (
    <>
      {/* Orthoimage - RGB Multispectral */}
      {!isShowInlineBlock ? (
        <Box
          className={classes2.boxLayout}
          style={{ padding: '20px 20px', borderRadius: '20px', marginBottom: '20px' }}>
          <Button
            endIcon={
              layerByRasterVector ? (
                <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
              ) : (
                <ChevronRightIcon style={{ fontWeight: 'bold' }} />
              )
            }
            onClick={() => setLayerByRasterVector(!layerByRasterVector)}
            classes={{ root: classes.buttonTextStyle }}
            style={{ width: '100%' }}>
            <Typography
              className={classes.buttonTextStyle}
              style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '14px !important' }}>
              Common rasters and vectors
            </Typography>
          </Button>
          <Collapse in={layerByRasterVector} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Button
              startIcon={
                isRaster ? (
                  <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
                ) : (
                  <ChevronRightIcon style={{ fontWeight: 'bold' }} />
                )
              }
              onClick={() => setRaster(!isRaster)}
              classes={{ root: classes.buttonTextStyle }}
              style={{ width: '100%' }}>
              <Typography
                className={classes.buttonTextStyle}
                style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '13px !important' }}>
                Orthoimage
              </Typography>
            </Button>
            <Collapse in={isRaster} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
              {orthoimageData?.layerType && (
                <Grid item xs={12} md={12} key={orthoimageData._id}>
                  <FormControlLabel
                    control={
                      <SwitchCustom
                        checked={layerTypeList.some((i) => i._id === orthoimageData._id)}
                        onClick={() => {
                          dispatch(selectRasterLayer(orthoimageData));
                        }}
                      />
                    }
                    label={
                      <Typography classes={{ root: classes.selectText }}>{getNameRaster(orthoimageData)}</Typography>
                    }
                  />
                </Grid>
              )}
            </Collapse>
            <VectorCollapse dataLayer={data} />
          </Collapse>
        </Box>
      ) : (
        <Grid container>
          {rasterList &&
            rasterList
              .sort((a: any, b: any) => a?.layerType.localeCompare(b?.layerType))
              .map(
                (_item: RasterLayerDTO) =>
                  _item.layerType && (
                    <Grid item xs={6} key={_item._id}>
                      <FormControlLabel
                        control={
                          <SwitchCustom
                            checked={layerTypeList.some((i) => i._id === _item._id)}
                            onClick={(e: any) =>
                              handleChangeRaster(
                                _item,
                                layerTypeList.some((i) => i._id === _item._id)
                              )
                            }
                          />
                        }
                        label={<Typography classes={{ root: classes.selectText }}>{getNameRaster(_item)}</Typography>}
                      />
                    </Grid>
                  )
              )}
        </Grid>
      )}
    </>
  );
};
