import { IEditWorkspace, IWorkspacePagination, Map2dOptionEnum } from 'interfaces/workspace';
import { sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest } from 'services';

export const getListWorkspace = (data: IWorkspacePagination) => sendGetRequest('/workspace/pagination', data);

export const createNewWorkspace = (data: Omit<IEditWorkspace, '_id'>) => sendPostRequest('/workspace', data);

export const editWorkspaceInfo = (data: IEditWorkspace) => sendPatchRequest(`/workspace/${data._id}`, data);

export const deleteWorkspace = (id: string) => sendDeleteRequest(`/workspace/${id}`);

export const getWorkspaceInfo = (id: string) => sendGetRequest(`/workspace/${id}`);

export const handleRawImage = (data: FormData) => sendPostRequest('/field', data);

export const getFieldList = (data: IWorkspacePagination) => sendGetRequest('/field/pagination', data);

export const getFieldDetail = (_id: string) => sendGetRequest(`/field/${_id}`);

export const getMinMaxZoom = (projectId: string, taskId: string, layerType: Map2dOptionEnum) =>
  sendGetRequest(`/field/getMinMaxZoom/${projectId}/${taskId}`, { layerType });

export const getAvailableAsset = (projectId: string, taskId: string) =>
  sendGetRequest(`/field/getAvailableAsset/${projectId}/${taskId}`);

export const downloadAsset = (req: { projectId: string; taskId: string; asset: string }) => {
  const { projectId, taskId, asset } = req;
  return sendGetRequest(`/field/downloadAsset/${projectId}/${taskId}/${asset}`);
};

export const getView3D = (projectId: string, taskId: string) => {
  return sendGetRequest(`/field/3D/${projectId}/${taskId}`);
};

export const updateFieldBoundary = (data: { _id: string; geojson: GeoJSON.FeatureCollection }) => {
  const { _id, geojson } = data;
  return sendPatchRequest(`/field/${_id}`, { boundary: geojson });
};
