import { PictureAsPdf } from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { IAnalyticsTabs3DData } from 'common/defines/clients';
import { MapStateKey } from 'common/defines/constants';
import pdfDownload from 'common/utils/pdfDownload';
import {
  getColorPaletteForAnalyticsTab,
  getTopThreeSortedPieChartData,
  IPieChatData,
} from 'components/MapView/RightBar/AnalyticsTab/analyticsTabConstants';
import {
  ANALYTICS_TABS,
  BOTTOM_BAR_MONTH_LIST,
  CHART_TYPE,
  CROP_HIERARCHY,
  DASHBOARD_PDF_KEYS,
  ECHARTS_NAME,
  NO_DATA_LABEL,
  PDF_REPORT_TABLE_HEADERS,
  PIE_CHART_NO_DATA_LABEL,
  PRODUCTION_TRENDS_TYPES,
  QUERY_KEY,
  SEVERITY,
} from 'constants/constants';
import ReactEcharts from 'echarts-for-react';
import { EChartsOption } from 'echarts-for-react/src/types';
import GlobalModel from 'echarts/types/src/model/Global';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getCropCollectionCalendarData,
  getDashboardProductionCostByBlock,
  getDashboardProductionTrends,
  getDashboardTonnageByEstate,
  getDashboardTripStatus,
  getDashboardTripStatusByBlockProduct,
  getLevelListByCropType,
  queryMe,
} from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import {
  changeIs3D,
  setAnalyticActiveTab,
  setLogisticTripStatus,
  setProductionCost,
  setProductionExpenditure,
  setTonnageDistribution,
  setWeighbridgeByProduct,
} from 'store/slices/dashboardWidgetSlice';
import CardWidget from '../Common/CardWidget';
import DashboardCalendar from './DashboardCalendar';
import { DashboardMapView } from './DashboardMapView';
import DataViewModal from './DataViewModal';

const OverviewClient = () => {
  const theme = useTheme();
  const [productionTrendsPieData, setProductionTrendsPieData] = useState<EChartsOption>({ series: [{}] });
  const [overallTripStatusData, setOverallTripStatusData] = useState<EChartsOption>({});
  const [tripStatusDataByBlockProduct, setTripStatusDataByBlockProduct] = useState<EChartsOption>({});
  const [monthlyProductWeightData, setMonthlyProductWeightData] = useState<EChartsOption>({});
  const [productionCostPMTData, setProductionCostPMTData] = useState<EChartsOption>({ series: [{}] });
  const [productionCostPHData, setProductionCostPHData] = useState<EChartsOption>({ series: [{}] });
  const [productionExpenditureData, setProductionExpenditureData] = useState<EChartsOption>({ series: [{}] });
  const [calendarDetail, setCalendarDetail] = useState<string>('');
  const [tripStatusByBlock, setTripStatusByBlock] = useState<EChartsOption>({});
  const [tonnageDistributionPieChart, setTonnageDistributionPieChart] = useState<EChartsOption>({ series: [{}] });
  const [tonnageDistributionStackedBar, setTonnageDistributionStackedBar] = useState<EChartsOption>({});
  const [calendarYearlyData, setCalendarYearlyData] = useState<EChartsOption>({});
  const [calendarNavigation, setCalendarNavigation] = useState<string>(moment(new Date()).format('YYYY-MM').toString());
  const [showCalendar, setShowCalendar] = useState<boolean>(true);
  const [dataViewModal, setDataViewModal] = useState<boolean>(false);
  const [dataViewOptions, setDataViewOptions] = useState<EChartsOption>({});
  const [estateFilter, setEstateFilter] = useState<any>('all');
  const [selectEstate, setSelectEstate] = useState<any>('');
  const [dateRange, setDateRange] = useState<any>({
    startDate: moment(new Date()).startOf('year').format('YYYY-MM-DD').toString(),
    endDate: moment(new Date()).endOf('year').format('YYYY-MM-DD').toString(),
  });
  const [datePicker, setDatePicker] = useState<any>({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    year: moment(dateRange.startDate).toString(),
  });
  const [showAllPieChartData, setShowAllPieChartData] = useState<{ [key: string]: boolean }>({
    tonnageDistribution: false,
    productionTrends: false,
    productionCostMTB: false,
    productionCostHB: false,
    productionExpenditure: false,
  });
  const [pieChartData, setPieChartData] = useState<{
    tonnageDistribution: IPieChatData[];
    productionTrends: IPieChatData[];
    productionCostMTB: IPieChatData[];
    productionCostHB: IPieChatData[];
    productionExpenditure: IPieChatData[];
    [key: string]: IPieChatData[];
  }>({
    tonnageDistribution: [{ name: '', value: 0 }],
    productionTrends: [{ name: '', value: 0 }],
    productionCostMTB: [{ name: '', value: 0 }],
    productionCostHB: [{ name: '', value: 0 }],
    productionExpenditure: [{ name: '', value: 0 }],
  });

  const pieChartOptionSetFuncs: { [key: string]: React.Dispatch<any> } = {
    tonnageDistribution: setTonnageDistributionPieChart,
    productionTrends: setProductionTrendsPieData,
    productionCostMTB: setProductionCostPMTData,
    productionCostHB: setProductionCostPHData,
    productionExpenditure: setProductionExpenditureData,
  };

  const iconPath = 'path://M21 8H3V4h18v4zm0 2H3v4h18v-4zm0 6H3v4h18v-4z';
  const dispatch: any = useAppDispatch();
  const echartsRef = useRef<Map<string, ReactEcharts>>(new Map());
  const [totalTrips, setTotalTrips] = useState<string>('0');
  const [totalTonnageProduction, setTotalTonnageProduction] = useState<string>('0');
  const [tonnageBarTableData, setTonnageBarTableData] = useState<any[]>([]);
  const [tripStatusByBlockBarData, setTripStatusByBlockBarData] = useState<any[]>([]);
  const [isMobileM, setIsMobileM] = useState(window.innerWidth <= 350);
  const [isPdfIconPositionChange, setIsPdfIconPositionChange] = useState(
    window.innerWidth >= 900 && window.innerWidth <= 1060
  );

  const windowSizing = () => {
    let width: string;
    if (window.innerWidth >= 0 && window.innerWidth < 900) {
      width = '100%';
    } else if (window.innerWidth >= 900 && window.innerWidth <= 1000) {
      width = '92%';
    } else if (window.innerWidth >= 1001 && window.innerWidth <= 1400) {
      width = '94%';
    } else {
      width = '96%';
    }

    return width;
  };

  const [eChartWidth, setEChartWidth] = useState<string>(windowSizing);

  useEffect(() => {
    const handleResize = () => {
      // setIsMobile(window.innerWidth <= 768);
      setIsMobileM(window.innerWidth <= 350);
      setIsPdfIconPositionChange(window.innerWidth >= 900 && window.innerWidth <= 1060);
      setEChartWidth('100%');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tonnageRef = useRef<ReactEcharts>(null);
  const tonnageBarRef = useRef<ReactEcharts>(null);
  const productionTrendsRef = useRef<ReactEcharts>(null);
  const tripStatusByBlockRef = useRef<ReactEcharts>(null);
  const tripStatusByBlockBarChartRef = useRef<ReactEcharts>(null);
  const tripStatusBySecoundWeightRef = useRef<ReactEcharts>(null);
  const productionCostPMTDataRef = useRef<ReactEcharts>(null);
  const productionCostPHDataRef = useRef<ReactEcharts>(null);
  const productionExpenditureDataRef = useRef<ReactEcharts>(null);

  const cropType = '6530a9367b5f7ad253f36da7';
  const { clientId } = useParams();

  useQuery([QUERY_KEY.LEVEL_LIST, cropType], () => getLevelListByCropType(clientId, cropType), {
    onSuccess: (data: any) => {
      setLevelList(data);
    },
  });

  useEffect(() => {
    dispatch(changeIs3D(false));
    dispatch(setAnalyticActiveTab(ANALYTICS_TABS.PRODUCTION));
  }, []);

  const pieDataRevamp = (pieList: IPieChatData[], optionSetFunc: React.Dispatch<any>) => {
    optionSetFunc((prevState: any) => ({ ...prevState, series: [{ ...prevState?.series[0], data: pieList }] }));
  };

  const findSeverity = (totalValue: number = 0, value: number = 0): string => {
    const partSize = totalValue / 3;
    if (totalValue && value) {
      if (value <= partSize) {
        return SEVERITY.LOW;
      } else if (value <= partSize * 2) {
        return SEVERITY.MEDIUM;
      } else {
        return SEVERITY.HIGH;
      }
    } else {
      return SEVERITY.LOW;
    }
  };

  useEffect(() => {
    Object.keys(pieChartData)?.forEach((pieChart: string) => {
      if (pieChartData[pieChart]?.length) {
        if (showAllPieChartData[pieChart]) {
          pieDataRevamp(pieChartData[pieChart], pieChartOptionSetFuncs[pieChart]);
        } else if (pieChartData[pieChart]?.length > 3) {
          pieDataRevamp(getTopThreeSortedPieChartData(pieChartData[pieChart]), pieChartOptionSetFuncs[pieChart]);
        }
      } else {
        pieDataRevamp(PIE_CHART_NO_DATA_LABEL, pieChartOptionSetFuncs[pieChart]);
      }
    });
  }, [showAllPieChartData, pieChartData]);

  const colorPalette = getColorPaletteForAnalyticsTab(theme.palette.background.paper);

  const chartDataForMapView = async (chartDataList: any[] = [], newWeightParam: string = '', type: string = '') => {
    let groupKey: string = estateFilter?.levelId ? CROP_HIERARCHY.DIV_NAME : CROP_HIERARCHY.LOCATION;
    const groupedChartBarData: any = {};
    for (let item of chartDataList) {
      const name = item[groupKey];
      if (!groupedChartBarData[name]) {
        groupedChartBarData[name] = 0;
      }

      if (type === 'cost') {
        groupedChartBarData[name] += item[newWeightParam];
      } else {
        groupedChartBarData[name] += item[newWeightParam] / 1000;
      }
    }

    let mappedChartBarData = Object.keys(groupedChartBarData).map((item) => ({
      name: item,
      value: groupedChartBarData[item],
    }));

    let finalChartBarData: IAnalyticsTabs3DData[] = [];
    if (mappedChartBarData.length) {
      const highestValueObject = mappedChartBarData.reduce((prev: any, current: any) => {
        return prev.value > current.value ? prev : current;
      }, {});

      finalChartBarData = mappedChartBarData.map((ele: any) => {
        return {
          ...ele,
          severity: findSeverity(highestValueObject?.value || 0, ele?.value || 0),
        };
      });
    }

    return finalChartBarData;
  };

  useQuery(
    [QUERY_KEY.GET_TONNAGE_BY_ESTATE, dateRange, estateFilter],
    () =>
      getDashboardTonnageByEstate({
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        estateId: estateFilter?.levelId || '',
      }),
    {
      onSuccess: async (data: any) => {
        let calendarMap = new Map();
        let tonnageDistributionPerEstate = new Map();

        data.data.forEach((item: any) => {
          let date = moment(item.weighingDate).format('YYYY-MM-DD').toString();
          let currentWeight = calendarMap.get(date)?.totalNetWeight || 0;
          calendarMap.set(date, {
            date: date,
            totalNetWeight: currentWeight + item.TotalNetWeight,
          });

          let estate = item.Location;
          let currentTonnageWeight = tonnageDistributionPerEstate.get(estate)?.totalNetWeight || 0;
          let currentRunningTrip = tonnageDistributionPerEstate.get(estate)?.runningTrips || 0;
          let currentConfirmedTrip = tonnageDistributionPerEstate.get(estate)?.confirmedTrips || 0;
          tonnageDistributionPerEstate.set(estate, {
            estate: estate,
            totalNetWeight: currentTonnageWeight + item.TotalNetWeight,
            runningTrips: currentRunningTrip + item.RunningTrip,
            confirmedTrips: currentConfirmedTrip + item.ConfirmedTrip,
          });
        });

        let xAxis: any[] = [];
        let TDRunningData: any[] = [];
        let TDConfirmedData: any[] = [];
        const tonnageDataList: any[] = [];

        const TDPieChartData = Array.from(
          tonnageDistributionPerEstate.values(),
          ({ estate, totalNetWeight, runningTrips, confirmedTrips }) => {
            xAxis.push(estate);
            TDRunningData.push(runningTrips);
            TDConfirmedData.push(confirmedTrips);
            tonnageDataList.push([estate, confirmedTrips, runningTrips]);

            return {
              name: estate,
              value: totalNetWeight / 1000,
            };
          }
        );

        const tonnageDistributionPie = {
          title: {
            padding: 15,
            text: 'Tonnage Distribution',
            show: true,
            subtext: 'Per Estate & Trip Status',
          },
          legend: {
            show: false,
          },
          tooltip: {
            trigger: 'item',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },

              saveAsImage: {
                show: true,
              },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'tonnageDistributionPie');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          series: [
            {
              name: 'Tonnage Distribution',
              type: 'pie',
              radius: ['30%', '60%'],
              top: '30%',
              padding: 30,
              label: {
                fontSize: 10,
              },
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                },
              },
              color: TDPieChartData?.length ? colorPalette.pieChart : ['#ddd'],
              data: TDPieChartData,
            },
          ],
        };

        setPieChartData((prevState) => ({ ...prevState, tonnageDistribution: TDPieChartData }));
        setTonnageDistributionPieChart(tonnageDistributionPie);

        const tonnageDistributionStackedBarOptions = {
          grid: {
            top: '40%',
          },
          color: colorPalette.stackedBarChart,
          emphasis: {
            label: {
              show: true,
              fontSize: 8,
              fontWeight: 'bold',
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'tonnageStack');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          title: {
            top: '30%',
            show: false,
            text: 'Tonnage Distribution',
            subtext: 'by Trip Status',
          },
          xAxis: {
            data: xAxis?.length ? xAxis : ['No Data Avaliable'],
            axisLabel: { interval: 0, rotate: 20, fontSize: 9 },
          },
          yAxis: {
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          series: [
            {
              top: '2%',
              name: 'Confirmed',
              data: TDConfirmedData,
              type: 'bar',
              stack: 'x',
              itemStyle: {},
            },
            {
              top: '2%',
              name: 'Running',
              data: TDRunningData,
              type: 'bar',
              stack: 'x',
              itemStyle: {},
            },
          ],
        };

        setTonnageBarTableData(tonnageDataList);

        setTonnageDistributionStackedBar(tonnageDistributionStackedBarOptions);

        let tonnageData = data?.data && data?.data.length ? [...data?.data] : [];
        let totatlProduction: number = tonnageData?.length
          ? tonnageData.reduce((sum, entry) => sum + entry?.TotalNetWeight / 1000, 0)
          : 0;
        setTotalTonnageProduction(totatlProduction ? totatlProduction.toFixed(2) : '0');
        let chartData = await chartDataForMapView(tonnageData, 'TotalNetWeight');
        dispatch(setTonnageDistribution(chartData));
      },
    }
  );

  useQuery(
    [QUERY_KEY.GET_PRODUCTION_TREND_DATA, dateRange, estateFilter],
    () =>
      getDashboardProductionTrends({
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        type: PRODUCTION_TRENDS_TYPES.MONTH,
        estateId: estateFilter?.levelId || '',
      }),
    {
      onSuccess: async (data: any) => {
        let seriesData: any[] = [];
        const result = data.data.map((item: any) => {
          let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          item.monthWiseInfo.forEach((info: any) => {
            array[info.WeighingMonth - 1] = info.NetWeight / 1000;
          });
          let series = {
            name: item.Product,
            data: array,
            type: 'line',
            top: '30%',
            areaStyle: {
              opacity: 0.2,
            },
            smooth: true,
          };
          seriesData.push(series);
          return {
            value: item.NetWeight / 1000,
            name: item.Product,
          };
        });

        const weighbridgeByProductLine = {
          title: {
            padding: 15,
            text: 'Weighbridge by Product',
            show: true,
            subtext: 'Month to Date Product Chart',
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            top: '15%',
            right: '1%',
            textStyle: {
              fontSize: 10,
            },
            selector: [
              {
                type: 'all',
                title: 'Select All',
              },
              {
                type: 'inverse',
                title: 'Unselect All',
              },
            ],
            selectorLabel: {
              borderRadius: 2,
            },
          },
          grid: {
            top: '30%',
            left: '5%',
            bottom: '15%',
            width: '70%',
          },
          color: colorPalette.lineAreaChart,
          emphasis: {
            label: {
              show: true,
              fontSize: 10,
              fontWeight: 'bold',
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: `{a} <b>{c}</b>mt<br>{b}`,
          },
          xAxis: {
            data: seriesData?.length ? BOTTOM_BAR_MONTH_LIST : NO_DATA_LABEL,
          },
          yAxis: {
            axisLabel: {
              formatter: '{value} mt',
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },

              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'productionTrendsLine');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          series: seriesData,
          media: [
            {
              query: {
                minWidth: 1,
                maxWidth: 375,
              },
              option: {
                legend: {
                  orient: 'horizontal',
                  top: '91%',
                },
                grid: {
                  width: '70%',
                  left: '25%',
                },
              },
            },
            {
              query: {
                minWidth: 376,
                maxWidth: 500,
              },
              option: {
                legend: {
                  orient: 'horizontal',
                  top: '91%',
                },
                grid: {
                  width: '75%',
                  left: '18%',
                },
              },
            },
            {
              query: {
                minWidth: 501,
                maxWidth: 768,
              },
              option: {
                legend: {
                  orient: 'vertical',
                  top: '15%',
                  right: '1%',
                },
                grid: {
                  width: '55%',
                  left: '14%',
                },
              },
            },
            {
              query: {
                minWidth: 769,
                maxWidth: 1024,
              },
              option: {
                legend: {
                  orient: 'vertical',
                  top: '15%',
                  right: '1%',
                },
                grid: {
                  width: '72%',
                  top: '30%',
                  left: '8%',
                },
              },
            },
            {
              query: {
                minWidth: 1025,
                maxWidth: 1440,
              },
              option: {
                legend: {
                  orient: 'vertical',
                  top: '15%',
                  right: '1%',
                },
                grid: {
                  width: '75%',
                  top: '30%',
                  left: '7%',
                },
              },
            },
            {
              query: {
                minWidth: 1441,
              },
              option: {
                legend: {
                  orient: 'vertical',
                  top: '15%',
                  right: '1%',
                },
                grid: {
                  width: '73%',
                  top: '30%',
                  left: '5%',
                },
              },
            },
          ],
        };

        const productionTrendPie = {
          title: {
            padding: 15,
            text: 'Production Trends',
            show: true,
            subtext: 'Month to date Product Chart',
          },
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>{c}</b>mt <b>{d}%</b>`,
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'productionTrendsPie');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          legend: {
            show: false,
          },
          series: [
            {
              top: '30%',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                show: true,
                fontSize: 10,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                  formatter: 'RM {c}',
                },
              },
              labelLine: {
                show: true,
              },
              color: result?.length ? colorPalette.pieChart : ['#ddd'],
              data: result,
            },
          ],
        };

        setPieChartData((prevState) => ({ ...prevState, productionTrends: result }));
        setProductionTrendsPieData(productionTrendPie);
        setMonthlyProductWeightData(weighbridgeByProductLine);

        let weighbridgeData = data?.data && data?.data.length ? [...data?.data] : [];
        let chartData = await chartDataForMapView(weighbridgeData, 'NetWeight');
        dispatch(setWeighbridgeByProduct(chartData));
      },
    }
  );

  useQuery(
    [QUERY_KEY.GET_PRODUCTION_COST, dateRange, estateFilter],
    () =>
      getDashboardProductionCostByBlock({
        year: moment(dateRange.startDate).format('YYYY').toString(),
        estateId: estateFilter?.levelId || '',
      }),
    {
      onSuccess: async (data: any) => {
        let productionExpenditure: any[] = [];
        let productionCost: any[] = [];
        let productionCostPMT: any[] = [];
        if (data?.data?.length) {
          data.data.forEach((item: any) => {
            productionExpenditure.push({ value: item.productionExpenditure.toFixed(2), name: item.BlackName });
            productionCostPMT.push({ value: item.perMetricTonCost?.toFixed(2), name: item.BlackName });
            productionCost.push({ value: item.perHectrageCost.toFixed(2), name: item.BlackName });
          });
        }

        const productionCostPMTDonut = {
          color: colorPalette.donutProductionChart,
          colorBy: 'series',
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>RM {c}</b>`,
          },
          title: {
            padding: 15,
            text: 'Production Cost',
            show: true,
            subtext: 'Per Metric Tonne, Blocks',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'productionCostPMT');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          series: [
            {
              top: '30%',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                show: true,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                  formatter: 'RM {c}',
                },
              },
              labelLine: {
                show: true,
              },
              color: productionCostPMT?.length ? null : ['#ddd'],
              data: productionCostPMT,
            },
          ],
        };

        const productionCostByBlockDonut = {
          color: colorPalette.donutProductionChart,
          colorBy: 'series',
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>RM {c}</b>`,
          },
          title: {
            padding: 15,
            text: 'Production Cost',
            show: true,
            subtext: 'Per Hectare, Block',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },

              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'productionCostPerHectare');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          series: [
            {
              top: '30%',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                show: true,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                  formatter: 'RM {c}',
                },
              },
              labelLine: {
                show: true,
              },
              color: productionCost?.length ? null : ['#ddd'],
              data: productionCost,
            },
          ],
        };

        const productionExpenditureDonut = {
          color: colorPalette.donutProductionChart,
          colorBy: 'series',
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>RM {c}</b>`,
          },
          title: {
            padding: 15,
            text: 'Production Expenditure',
            show: true,
            subtext: 'Per Block',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'productionExpenditure');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },

          series: [
            {
              top: '30%',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                show: true,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                  formatter: 'RM {c}',
                },
              },
              labelLine: {
                show: true,
              },
              color: productionExpenditure?.length ? null : ['#ddd'],
              data: productionExpenditure,
            },
          ],
        };

        setPieChartData((prevState) => ({
          ...prevState,
          productionExpenditure: productionExpenditure,
          productionCostHB: productionCost,
          productionCostMTB: productionCostPMT,
        }));
        setProductionCostPMTData(productionCostPMTDonut);
        setProductionCostPHData(productionCostByBlockDonut);
        setProductionExpenditureData(productionExpenditureDonut);

        let costData = data?.data && data?.data.length ? [...data?.data] : [];
        let chartDataForMetricTonCost = await chartDataForMapView(costData, 'perMetricTonCost', 'cost');
        let chartDataForExpenditure = await chartDataForMapView(costData, 'productionExpenditure');
        dispatch(setProductionCost(chartDataForMetricTonCost));
        dispatch(setProductionExpenditure(chartDataForExpenditure));
      },
    }
  );

  useQuery(
    [QUERY_KEY.GET_TRIP_STATUS, dateRange],
    () => getDashboardTripStatus(dateRange.startDate, dateRange.endDate),
    {
      onSuccess: (data: any) => {
        const result = data.data.map((item: any) => {
          return {
            value: item.Count,
            name: item.Status,
          };
        });
        const tripStatusBySecondWeightPie = {
          title: {
            padding: 15,
            text: 'Trip Status',
            show: true,
            subtext: 'Based on Second Weight',
          },
          tooltip: {
            trigger: 'item',
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },

              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'tripStatusSecondWeight');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },

          series: [
            {
              top: '30%',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                fontSize: 12,
              },
              color: colorPalette.donutTripStatusChart,
              data: result,
            },
          ],
        };
        setOverallTripStatusData(tripStatusBySecondWeightPie);
      },
    }
  );

  const handleDataViewModal = (isShow: boolean, EChartOption: EChartsOption, chartName: string) => {
    setDataViewOptions({ ...EChartOption, chartName: chartName });
    setDataViewModal(isShow);
  };

  useQuery(
    [QUERY_KEY.GET_TRIP_STATUS_BY_BLOCK_PRODUCT, dateRange, estateFilter],
    () =>
      getDashboardTripStatusByBlockProduct({
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        estateId: estateFilter?.levelId || '',
      }),
    {
      onSuccess: async (data: any) => {
        const result = [
          {
            value: data.data.firstRow[0].running,
            name: 'Running',
          },
          {
            value: data.data.firstRow[0].confirmed,
            name: 'Confirmed',
          },
        ];

        const tripStatusByBlockPie = {
          title: {
            padding: 15,
            text: 'Trip Status',
            show: true,
            subtext: 'Second Weight, by Block and Product',
          },
          tooltip: {
            trigger: 'item',
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'tripStatusByBlockPie');
                },
                icon: iconPath,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          series: [
            {
              top: '30%',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                fontSize: 12,
              },
              color: colorPalette.pieChart,
              data: result,
            },
          ],
        };

        let blockMap = new Map();
        let productMap = new Map();
        let xAxis: any[] = [];
        let runningData: any[] = [];
        let confirmedData: any[] = [];
        let xAxisProduct: any[] = [];
        let productRunningData: any[] = [];
        let productConfirmedData: any[] = [];
        const tripStatusByBlockBarData: any[] = [];

        data.data.secoundRow.forEach((item: any) => {
          let currentConfirmedTrips = blockMap.get(item.BlockName)?.confirmedTrips || 0;
          let currentRunningTrips = blockMap.get(item.BlockName)?.runningTrips || 0;
          let productCurrentConfirmedTrips = productMap.get(item.Product)?.confirmedTrips || 0;
          let productCurrentRunningTrips = productMap.get(item.Product)?.runningTrips || 0;

          blockMap.set(item.BlockName, {
            name: item.BlockName,
            confirmedTrips: currentConfirmedTrips + item.Confirmed,
            runningTrips: currentRunningTrips + item.Trips,
          });

          productMap.set(item.Product, {
            name: item.Product,
            confirmedTrips: productCurrentConfirmedTrips,
            runningTrips: productCurrentRunningTrips,
          });
        });

        Array.from(blockMap.values(), ({ name, confirmedTrips, runningTrips }) => {
          xAxis.push(name);
          runningData.push(runningTrips);
          confirmedData.push(confirmedTrips);
          tripStatusByBlockBarData?.push([name, confirmedTrips, runningTrips]);
        });

        Array.from(productMap.values(), ({ name, confirmedTrips, runningTrips }) => {
          xAxisProduct.push(name);
          productRunningData.push(runningTrips);
          productConfirmedData.push(confirmedTrips);
        });

        const tripStatusByBlockStackedBar = {
          grid: {
            top: '30%',
          },
          color: colorPalette.stackedBarChart,
          emphasis: {
            label: {
              show: true,
              fontSize: 9,
              fontWeight: 'bold',
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              saveAsImage: { show: true },
              myTool1: {
                show: true,
                readOnly: true,
                title: 'Data View',
                onclick: function (opt: GlobalModel) {
                  handleDataViewModal(true, opt, 'tripStack');
                },
                icon: iconPath,
              },
              dataZoom: {
                yAxisIndex: 'none',
                type: 'slider',
                zoomLock: true,
                width: 10,
                right: 10,
                start: 25,
                end: 100,
                handleSize: 20,
              },
            },
            iconStyle: {
              opacity: 0.5,
            },
            right: 10,
            top: 5,
          },
          title: {
            show: false,
            text: 'Trip Status',
            subtext: 'by Block',
          },
          xAxis: {
            data: xAxis?.length ? xAxis : NO_DATA_LABEL,
            axisLabel: { interval: 0, rotate: 50, fontSize: 9 },
          },
          yAxis: {
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          series: [
            {
              name: 'Confirmed',
              data: confirmedData,
              type: 'bar',
              stack: 'total',
              itemStyle: {},
            },
            {
              name: 'Running',
              data: runningData,
              type: 'bar',
              stack: 'total',
              itemStyle: {},
            },
          ],
        };

        setTripStatusByBlockBarData(tripStatusByBlockBarData);
        setTripStatusByBlock(tripStatusByBlockStackedBar);
        setTripStatusDataByBlockProduct(tripStatusByBlockPie);

        let logisticFirstRowData = data?.data?.firstRow && data?.data?.firstRow.length ? [...data?.data?.firstRow] : [];
        let totalTrips: number = logisticFirstRowData?.length
          ? logisticFirstRowData[0]?.confirmed + logisticFirstRowData[0]?.running
          : 0;
        setTotalTrips(totalTrips ? totalTrips.toString() : '0');

        let logisticSecondRowData =
          data?.data?.secoundRow && data?.data?.secoundRow.length ? [...data?.data?.secoundRow] : [];
        let chartData = await chartDataForMapView(logisticSecondRowData, 'Netweight');
        dispatch(setLogisticTripStatus(chartData));
      },
    }
  );

  const getCalendarData = async (startDate: string, endDate: string) => {
    const response = await getCropCollectionCalendarData({
      fromDate: startDate,
      toDate: endDate,
      estateId: estateFilter?.levelId,
    });
    if (response.status === 200) {
      let min = 0;
      let max = 0;
      if (response.data.length > 0) {
        const numbers = response.data.map((item: any) => {
          return item[1];
        });
        min = Math.min(...numbers);
        max = Math.max(...numbers);
      }

      return {
        visualMap: {
          min: min,
          max: max,
        },
        series: {
          data: response.data,
        },
      };
    }
  };

  useQuery(
    [QUERY_KEY.GET_CROP_COLLECTION_DATA_BY_DATE, dateRange, estateFilter],
    () =>
      getCropCollectionCalendarData({
        fromDate: moment(dateRange.startDate).startOf('y').format('YYYY-MM-DD').toString(),
        toDate: moment(dateRange.endDate).endOf('y').format('YYYY-MM-DD').toString(),
        estateId: estateFilter?.levelId || '',
      }),
    {
      onSuccess: (data: any) => {
        // console.log(data.data);
        let min = 0;
        let max = 0;
        if (data.data.length > 0) {
          const numbers = data.data.map((item: any) => {
            return item[1];
          });
          min = Math.min(...numbers);
          max = Math.max(...numbers);
        }

        const heatmapOptions = {
          title: {
            show: false,
            top: 30,
            left: 'center',
            text: 'Crop Calendar',
          },
          tooltip: {
            formatter: function (a: any) {
              let split = a.data.toString().split(',');
              let date = moment(split[0]).format('Do MMM YYYY');
              return `<b>${date}</b><br>${split[1]} bunches`;
            },
          },
          visualMap: {
            show: true,
            min: min,
            max: max,
            splitNumber: 3,
            type: 'piecewise',
            orient: 'horizontal',
            left: 'center',
            top: 25,
            inRange: {
              color: ['rgba(209,238,234,0.6)', '#50a197'],
            },
            formatter: function (value: number, value2: number) {
              return Math.round(value) + ' - ' + Math.round(value2);
            },
          },
          calendar: {
            top: 'center',
            left: 25,
            right: 25,
            cellSize: ['auto', 50],
            range: calendarNavigation,
            yearLabel: { show: false },
            monthLabel: {
              show: false,
            },
            dayLabel: {
              show: true,
              nameMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
            },
            splitLine: {
              show: false,
            },
            orient: 'vertical',
          },
          series: {
            top: '30%',
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: data.data,
            label: {
              show: false,
            },
            itemStyle: {
              borderWidth: 2,
              borderType: 'solid',
              borderColor: colorPalette.borderColor,
            },
          },
        };
        setCalendarYearlyData(heatmapOptions);
      },
    }
  );

  const setClickEvent = (calendarRef: ReactEcharts) => {
    calendarRef.getEchartsInstance().on('click', 'series', (params: any) => {
      setCalendarDetail(params.data[0]);
      setShowCalendar(false);
    });
  };

  const setEchartRef = (ref: ReactEcharts, whichChart: string) => {
    if (ref) {
      echartsRef.current = echartsRef.current.set(whichChart, ref);
    }
  };

  const applyFilter = (startDate: string, endDate: string) => {
    setDateRange({
      startDate: startDate,
      endDate: endDate,
    });
    echartsRef.current.get(ECHARTS_NAME.WEIGHBRIDGE_BY_PRODUCT_LINE)?.getEchartsInstance().clear();
    echartsRef.current.get(ECHARTS_NAME.PRODUCTION_COST_PMT_DONUT)?.getEchartsInstance().clear();
  };

  const { allLocationCropType } = useAppSelector(clientSelector);
  const [plantationArea, setPlantationArea] = useState<string>('0');
  const { data: checkSuperAdmin } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: true,
  });

  const isSuperAdmin = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperAdmin;
  }, [checkSuperAdmin]);

  const isSuperUser = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperUser;
  }, [checkSuperAdmin]);

  const [levelList, setLevelList] = useState<any[]>([]);

  useEffect(() => {
    // Using reduce to calculate the total area
    let totalArea =
      allLocationCropType && allLocationCropType?.length
        ? allLocationCropType.reduce((accumulator, currentValue) => {
            // Convert the area to a number before adding to the accumulator
            let area = parseFloat(currentValue.area);

            // Check if the conversion is successful (not NaN)
            if (!isNaN(area)) {
              return accumulator + area;
            } else {
              // If the conversion fails, just return the accumulator unchanged
              return accumulator;
            }
          }, 0)
        : 0;

    setPlantationArea(totalArea ? totalArea.toFixed(3) : '0');
  }, [allLocationCropType]);

  const getDataValueListFromOptions = (options: any) => {
    return options?.series[0]?.data?.map((item: any) => [item?.name, item?.value]);
  };

  const getPdfTableData = (type: string) => {
    switch (type) {
      case DASHBOARD_PDF_KEYS.TONNAGE_PIECHART: {
        return getDataValueListFromOptions(tonnageDistributionPieChart);
      }
      case DASHBOARD_PDF_KEYS.TONNAGE_BARCHART: {
        return tonnageBarTableData;
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_TRENDS: {
        return getDataValueListFromOptions(productionTrendsPieData);
      }
      case DASHBOARD_PDF_KEYS.WEIGHBRIDGE_PRODUCT: {
        return monthlyProductWeightData?.series?.map((item: any) => [item?.name, ...item?.data]);
      }
      case DASHBOARD_PDF_KEYS.TRIP_STATUS: {
        return tripStatusDataByBlockProduct?.series[0]?.data?.map((item: any) => [item?.name, item?.value]);
      }
      case DASHBOARD_PDF_KEYS.TRIP_STATUS_BARCHART: {
        return tripStatusByBlockBarData;
      }
      case DASHBOARD_PDF_KEYS.TRIP_STATUS_SECOND_WEIGHT: {
        return overallTripStatusData?.series[0]?.data?.map((item: any) => [item?.name, item?.value]);
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_COST_PMTDATA: {
        return getDataValueListFromOptions(productionCostPMTData);
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_COST_PHDATA: {
        return getDataValueListFromOptions(productionCostPHData);
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_EXPENDITURE: {
        return getDataValueListFromOptions(productionExpenditureData);
      }
      default: {
        return getDataValueListFromOptions(tonnageDistributionPieChart);
      }
    }
  };

  const getPdfChartRef = (type: string) => {
    switch (type) {
      case DASHBOARD_PDF_KEYS.TONNAGE_PIECHART: {
        return tonnageRef;
      }
      case DASHBOARD_PDF_KEYS.TONNAGE_BARCHART: {
        return tonnageBarRef;
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_TRENDS: {
        return productionTrendsRef;
      }
      case DASHBOARD_PDF_KEYS.WEIGHBRIDGE_PRODUCT: {
        return echartsRef?.current?.get(ECHARTS_NAME.WEIGHBRIDGE_BY_PRODUCT_LINE);
      }
      case DASHBOARD_PDF_KEYS.TRIP_STATUS: {
        return tripStatusByBlockRef;
      }
      case DASHBOARD_PDF_KEYS.TRIP_STATUS_BARCHART: {
        return tripStatusByBlockBarChartRef;
      }
      case DASHBOARD_PDF_KEYS.TRIP_STATUS_SECOND_WEIGHT: {
        return tripStatusBySecoundWeightRef;
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_COST_PMTDATA: {
        return productionCostPMTDataRef;
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_COST_PHDATA: {
        return productionCostPHDataRef;
      }
      case DASHBOARD_PDF_KEYS.PRODUCTION_EXPENDITURE: {
        return productionExpenditureDataRef;
      }
      default: {
        return tonnageRef;
      }
    }
  };

  const downloadPdf = (
    fileName: string,
    chartType: string,
    header?: string,
    orientation?: 'portrait' | 'landscape'
  ) => {
    const pdfFilters = {
      dateRange: dateRange,
      estateList: estateFilter?.levelId ? [estateFilter] : levelList,
    };
    pdfDownload(
      fileName,
      {
        head: PDF_REPORT_TABLE_HEADERS[fileName],
        data: getPdfTableData(fileName),
      },
      getPdfChartRef(fileName),
      pdfFilters,
      header,
      chartType,
      orientation
    );
  };

  const updateCalendar = async (direction: string) => {
    const updatedDate =
      direction === 'next' ? moment(calendarNavigation).add(1, 'M') : moment(calendarNavigation).subtract(1, 'M');
    const formattedDate = updatedDate.format('YYYY-MM').toString();

    if (updatedDate.year() !== moment(calendarNavigation).year()) {
      const startDate = updatedDate.startOf('year').format('YYYY-MM-DD').toString();
      const endDate = updatedDate.endOf('year').format('YYYY-MM-DD').toString();
      let options: any = await getCalendarData(startDate, endDate);
      echartsRef.current.get(ECHARTS_NAME.CALENDAR_HEATMAP)?.getEchartsInstance().setOption(options);
      setDateRange({ startDate: startDate, endDate: endDate });
      setDatePicker({ startDate: startDate, endDate: endDate, year: updatedDate.toString() });
    }

    setCalendarNavigation(formattedDate);
    echartsRef.current
      .get(ECHARTS_NAME.CALENDAR_HEATMAP)
      ?.getEchartsInstance()
      .setOption({ calendar: { range: formattedDate } });
    setCalendarYearlyData(echartsRef.current.get(ECHARTS_NAME.CALENDAR_HEATMAP)?.getEchartsInstance().getOption());
  };

  const validate = () => {
    return moment(datePicker.endDate).diff(moment(datePicker.startDate), 'days') > 0;
  };

  return (
    <>
      <DataViewModal
        EChartsOption={dataViewOptions}
        dataViewModal={dataViewModal}
        setDataViewModal={setDataViewModal}
      />
      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box sx={{ margin: '10px' }}>
            <FormControl fullWidth size={'small'}>
              <InputLabel id="estate-filter-label">Estate</InputLabel>
              <Select
                labelId="estate-filter-label"
                id="estate-filter-select"
                value={estateFilter}
                label="Estate"
                onChange={(e: SelectChangeEvent) => {
                  setEstateFilter(e.target.value);
                }}>
                <MenuItem value="all">All Estates</MenuItem>
                {levelList.map((estate: any) => (
                  <MenuItem value={estate}>{estate.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <Box sx={{ margin: '10px' }}>
            <DatePicker
              onChange={(date: any) => {
                setDatePicker({
                  year: moment(date).toString(),
                  startDate: moment(date).startOf('year').format('YYYY-MM-DD').toString(),
                  endDate: moment(date).endOf('year').format('YYYY-MM-DD').toString(),
                });
                setDateRange({
                  startDate: moment(date).startOf('year').format('YYYY-MM-DD').toString(),
                  endDate: moment(date).endOf('year').format('YYYY-MM-DD').toString(),
                });
                setCalendarNavigation(moment(date).startOf('year').format('YYYY-MM').toString());
              }}
              value={datePicker.year}
              renderInput={(params: TextFieldProps) => <TextField type="text" fullWidth {...params} size="small" />}
              label="Year"
              views={['year']}
            />
          </Box>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Box sx={{ margin: '10px' }}>
            <DatePicker
              onChange={(date: any) => {
                setDatePicker({
                  year: moment(date).format('YYYY-MM-DD').toString(),
                  startDate: moment(date).format('YYYY-MM-DD').toString(),
                  endDate: datePicker.endDate,
                });
              }}
              value={datePicker.startDate}
              renderInput={(params: TextFieldProps) => <TextField type="text" fullWidth {...params} size="small" />}
              label="Start Date"
              minDate={moment(datePicker.year).startOf('year')}
              maxDate={moment(datePicker.endDate)}
              views={['month', 'day']}
              openTo="month"
            />
          </Box>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Box sx={{ margin: '10px' }}>
            <DatePicker
              onChange={(date: any) => {
                setDatePicker({
                  year: moment(date).format('YYYY-MM-DD').toString(),
                  startDate: datePicker.startDate,
                  endDate: moment(date).format('YYYY-MM-DD').toString(),
                });
              }}
              value={datePicker.endDate}
              renderInput={(params: TextFieldProps) => <TextField type="text" fullWidth {...params} size="small" />}
              label="End Date"
              minDate={moment(datePicker.startDate)}
              maxDate={moment(datePicker.year).endOf('year')}
              views={['month', 'day']}
              openTo="month"
            />
          </Box>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Box>
            <Button
              style={{ marginLeft: '10px' }}
              variant={'contained'}
              onClick={() => {
                //TODO: Add more validation
                if (validate()) {
                  applyFilter(datePicker.startDate, datePicker.endDate);
                  setCalendarNavigation(moment(datePicker.startDate).format('YYYY-MM').toString());
                } else {
                  //TODO: Add validation error message
                }
              }}>
              Apply Date Range
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          {/*Production Area - Ha*/}
          <Paper elevation={0}>
            <CardWidget title={'Plantation Area'} desc={plantationArea} unit={'Ha'}></CardWidget>
            {/*Plantation Area*/}
          </Paper>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          {/*Total Production*/}
          <Paper elevation={0}>
            <CardWidget title={'Total Production'} desc={totalTonnageProduction}></CardWidget>
          </Paper>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          {/*Total Trips*/}
          <Paper elevation={0}>
            <CardWidget title={'Total Trips'} desc={totalTrips}></CardWidget>
          </Paper>
        </Grid>

        <Grid item container xl={8} lg={8} md={12} sm={12} xs={12} spacing={1}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper sx={{ borderRadius: 3 }} elevation={0}>
              {/*Mapbox*/}
              {/* <MapGL
                mapStyle={mapStyle}
                accessToken={MAPBOX_ACCESS_TOKEN}
                longitude={viewport.longitude}
                latitude={viewport.latitude}
                zoom={viewport.zoom}
                style={{
                  width: '100%',
                  height: '40vh',
                  borderRadius: 3,
                }}
                ref={mapRef}
                onViewportChange={setViewport}
                viewportChangeMethod="flyTo"
              /> */}
              {/* <Hidden mdDown smDown xsDown> */}
              <div>
                <DashboardMapView
                  navbarMapWidth="100%"
                  analyticPanelWidth={0}
                  showAnalytics={false}
                  isSuperAdmin={isSuperAdmin}
                  isSuperUser={isSuperUser}
                  openAnalytic={() => {}}
                  mapStateKey={MapStateKey.MAP_VIEW}
                  levelId={estateFilter?._id || 'null'}
                />
              </div>
              {/* </Hidden> */}
            </Paper>
          </Grid>

          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Paper sx={{ minHeight: 350, width: '100%', borderRadius: 3 }} elevation={0}>
                <Grid container>
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ position: 'relative', boxSizing: 'border-box' }}>
                    {pieChartData?.tonnageDistribution?.length > 3 && (
                      <Button
                        sx={{
                          position: 'absolute',
                          top: isMobileM ? '30px' : '30px',
                          right: isMobileM ? '38px' : '10px',
                          zIndex: '1',
                        }}
                        onClick={() =>
                          setShowAllPieChartData((prevState) => ({
                            ...prevState,
                            tonnageDistribution: !prevState?.tonnageDistribution,
                          }))
                        }>
                        {showAllPieChartData?.tonnageDistribution ? 'Hide Others' : 'Show All'}
                      </Button>
                    )}

                    <PictureAsPdf
                      color="action"
                      sx={{
                        position: 'absolute',
                        right: isMobileM ? '15px' : '70px',
                        top: isMobileM ? '36px' : '10px',
                        zIndex: '1',
                        cursor: 'pointer',
                      }}
                      onClick={() => downloadPdf(DASHBOARD_PDF_KEYS.TONNAGE_PIECHART, CHART_TYPE.PIE, '', 'portrait')}
                    />

                    <ReactEcharts
                      ref={tonnageRef}
                      option={tonnageDistributionPieChart}
                      style={{ width: eChartWidth }}
                    />
                    {/* Tonnage Distribution - Per Estate & Trip Status */}
                  </Grid>

                  <Grid item xl={7} lg={7} md={6} sm={12} xs={12} sx={{ position: 'relative' }}>
                    <PictureAsPdf
                      color="action"
                      sx={{ position: 'absolute', right: '70px', top: '10px', zIndex: '1', cursor: 'pointer' }}
                      onClick={() =>
                        downloadPdf(
                          DASHBOARD_PDF_KEYS.TONNAGE_BARCHART,
                          CHART_TYPE.BAR,
                          'Tonnage Distribution',
                          'portrait'
                        )
                      }
                    />
                    <ReactEcharts
                      ref={tonnageBarRef}
                      option={tonnageDistributionStackedBar}
                      style={{ width: eChartWidth }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={4} lg={4} md={12} xs={12}>
          <Paper sx={{ height: '100%', borderRadius: 3 }} elevation={0}>
            <Box padding={1.5}>
              <Typography variant={'h1'} sx={{ fontWeight: '800' }}>
                Crop Collection Calendar
              </Typography>
              <Typography variant={'h3'}>Harvesting Round</Typography>
            </Box>
            <Box textAlign="center">
              {showCalendar ? (
                <Button sx={{ color: theme.palette.color.black1 }} onClick={() => updateCalendar('prev')}>
                  {'<'}
                </Button>
              ) : null}
              <Button sx={{ color: theme.palette.color.black1 }}>
                {moment(calendarNavigation).format('MMM YYYY').toString()}
              </Button>
              {showCalendar ? (
                <Button sx={{ color: theme.palette.color.black1 }} onClick={() => updateCalendar('next')}>
                  {'>'}
                </Button>
              ) : null}
            </Box>
            <Divider />
            {showCalendar ? (
              <>
                <ReactEcharts
                  ref={(e: ReactEcharts) => {
                    setEchartRef(e, ECHARTS_NAME.CALENDAR_HEATMAP);
                    if (e) {
                      setClickEvent(e);
                    }
                  }}
                  option={calendarYearlyData}
                  style={{ width: eChartWidth, height: '500px' }}
                />
              </>
            ) : (
              <DashboardCalendar
                date={calendarDetail}
                calendarShowToggle={setShowCalendar}
                calendarMonth={setCalendarNavigation}
                setHeatmapOptions={setCalendarYearlyData}
                options={calendarYearlyData}
                estateFilter={estateFilter}
              />
            )}
          </Paper>
        </Grid>

        <Grid item xl={3} lg={3} md={4} sm={12} xs={12} sx={{ position: 'relative' }}>
          {pieChartData?.productionTrends?.length > 3 && (
            <Button
              sx={{ position: 'absolute', top: '40px', right: '10px', zIndex: '1' }}
              onClick={() =>
                setShowAllPieChartData((prevState) => ({
                  ...prevState,
                  productionTrends: !prevState?.productionTrends,
                }))
              }>
              {showAllPieChartData?.productionTrends ? 'Hide Others' : 'Show All'}
            </Button>
          )}
          <PictureAsPdf
            color="action"
            sx={{ position: 'absolute', right: '70px', top: '18px', zIndex: '1', cursor: 'pointer' }}
            onClick={() => downloadPdf(DASHBOARD_PDF_KEYS.PRODUCTION_TRENDS, CHART_TYPE.PIE, '', 'portrait')}
          />
          <Paper sx={{ height: 350, borderRadius: 3 }} elevation={0}>
            {/*Production Trends - Month to Date Product Chart*/}
            <ReactEcharts ref={productionTrendsRef} option={productionTrendsPieData} style={{ width: eChartWidth }} />
          </Paper>
        </Grid>

        <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
          <Paper sx={{ height: 350, borderRadius: 3, position: 'relative' }} elevation={0}>
            <PictureAsPdf
              color="action"
              sx={{
                position: 'absolute',
                right: isMobileM ? '15px' : '70px',
                top: isMobileM ? '40px' : '10px',
                zIndex: '1',
                cursor: 'pointer',
              }}
              onClick={() => downloadPdf(DASHBOARD_PDF_KEYS.WEIGHBRIDGE_PRODUCT, CHART_TYPE.LINE, '', 'landscape')}
            />
            <ReactEcharts
              ref={(e: ReactEcharts) => {
                setEchartRef(e, ECHARTS_NAME.WEIGHBRIDGE_BY_PRODUCT_LINE);
              }}
              option={monthlyProductWeightData}
              style={{ width: eChartWidth }}
            />
            {/*Weighbridge by Product - Month to Date Product Chart*/}
          </Paper>
        </Grid>

        <Grid item container md={9} spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ minHeight: 350, borderRadius: 3 }} elevation={0}>
              <Grid container>
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ position: 'relative' }}>
                  <PictureAsPdf
                    color="action"
                    sx={{ position: 'absolute', right: '70px', top: '10px', zIndex: '1', cursor: 'pointer' }}
                    onClick={() => downloadPdf(DASHBOARD_PDF_KEYS.TRIP_STATUS, CHART_TYPE.PIE, '', 'portrait')}
                  />
                  <ReactEcharts
                    ref={tripStatusByBlockRef}
                    option={tripStatusDataByBlockProduct}
                    style={{ width: eChartWidth }}
                  />
                  {/*Trip Status - Second Weight, by Block and Product/*/}
                </Grid>

                <Grid item xl={8} lg={8} md={6} sm={12} xs={12} sx={{ position: 'relative' }}>
                  <PictureAsPdf
                    color="action"
                    sx={{ position: 'absolute', right: '120px', top: '10px', zIndex: '1', cursor: 'pointer' }}
                    onClick={() =>
                      downloadPdf(
                        DASHBOARD_PDF_KEYS.TRIP_STATUS_BARCHART,
                        CHART_TYPE.BAR,
                        'Trip Status By Block',
                        'portrait'
                      )
                    }
                  />
                  <ReactEcharts
                    ref={tripStatusByBlockBarChartRef}
                    option={tripStatusByBlock}
                    style={{ width: eChartWidth }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid item container md={3} spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ height: 350, borderRadius: 3, position: 'relative' }} elevation={0}>
              <PictureAsPdf
                color="action"
                sx={{ position: 'absolute', right: '70px', top: '10px', zIndex: '1', cursor: 'pointer' }}
                onClick={() =>
                  downloadPdf(DASHBOARD_PDF_KEYS.TRIP_STATUS_SECOND_WEIGHT, CHART_TYPE.PIE, '', 'portrait')
                }
              />
              <ReactEcharts
                ref={tripStatusBySecoundWeightRef}
                option={overallTripStatusData}
                style={{ width: eChartWidth }}
              />
              {/*Trip Status - Based on Second Weight*/}
            </Paper>
          </Grid>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ position: 'relative' }}>
          {pieChartData?.productionCostMTB?.length > 3 && (
            <Button
              sx={{ position: 'absolute', top: '40px', right: '10px', zIndex: '1' }}
              onClick={() =>
                setShowAllPieChartData((prevState) => ({
                  ...prevState,
                  productionCostMTB: !prevState?.productionCostMTB,
                }))
              }>
              {showAllPieChartData?.productionCostMTB ? 'Hide Others' : 'Show All'}
            </Button>
          )}
          <PictureAsPdf
            color="action"
            sx={{ position: 'absolute', right: '70px', top: '18px', zIndex: '1', cursor: 'pointer' }}
            onClick={() => downloadPdf(DASHBOARD_PDF_KEYS.PRODUCTION_COST_PMTDATA, CHART_TYPE.PIE, '', 'portrait')}
          />
          <Paper sx={{ borderRadius: 3 }} elevation={0}>
            <ReactEcharts
              ref={productionCostPMTDataRef}
              option={productionCostPMTData}
              style={{ width: eChartWidth }}
            />
            {/*Production Cost - Per Metric Tonne, Blocks*/}
          </Paper>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ position: 'relative' }}>
          {pieChartData?.productionCostHB?.length > 3 && (
            <Button
              sx={{ position: 'absolute', top: '40px', right: '10px', zIndex: '1' }}
              onClick={() =>
                setShowAllPieChartData((prevState) => ({
                  ...prevState,
                  productionCostHB: !prevState?.productionCostHB,
                }))
              }>
              {showAllPieChartData?.productionCostHB ? 'Hide Others' : 'Show All'}
            </Button>
          )}
          <PictureAsPdf
            color="action"
            sx={{ position: 'absolute', right: '70px', top: '18px', zIndex: '1', cursor: 'pointer' }}
            onClick={() => downloadPdf(DASHBOARD_PDF_KEYS.PRODUCTION_COST_PHDATA, CHART_TYPE.PIE, '', 'portrait')}
          />
          <Paper sx={{ borderRadius: 3 }} elevation={0}>
            <ReactEcharts ref={productionCostPHDataRef} option={productionCostPHData} style={{ width: eChartWidth }} />
            {/*Production Cost - Per Block*/}
          </Paper>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ position: 'relative' }}>
          {pieChartData?.productionExpenditure?.length > 3 && (
            <Button
              sx={{
                position: 'absolute',
                top: isMobileM || isPdfIconPositionChange ? '40px' : '40px',
                right: isMobileM || isPdfIconPositionChange ? '38px' : '10px',
                zIndex: '1',
              }}
              onClick={() =>
                setShowAllPieChartData((prevState) => ({
                  ...prevState,
                  productionExpenditure: !prevState?.productionExpenditure,
                }))
              }>
              {showAllPieChartData?.productionExpenditure ? 'Hide Others' : 'Show All'}
            </Button>
          )}
          <PictureAsPdf
            color="action"
            sx={{
              position: 'absolute',
              right: isMobileM || isPdfIconPositionChange ? '15px' : '70px',
              top: isMobileM || isPdfIconPositionChange ? '46px' : '18px',
              zIndex: '1',
              cursor: 'pointer',
            }}
            onClick={() => downloadPdf(DASHBOARD_PDF_KEYS.PRODUCTION_EXPENDITURE, CHART_TYPE.PIE, '', 'portrait')}
          />
          <Paper sx={{ borderRadius: 3 }} elevation={0}>
            <ReactEcharts
              ref={productionExpenditureDataRef}
              option={productionExpenditureData}
              style={{ width: eChartWidth }}
            />
            {/*Production Expenditure - Per Block*/}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default OverviewClient;
