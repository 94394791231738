import { Box, Grid, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientById } from 'services/clients/apiClient.services';
import { getAllSettingsDataCroptype } from 'services/settings/settings.services';
import CardTreeStructure from './CardTreeStructure/CardTreeStructure';
import { useOverviewClient } from './style';
import TreeStructureDisplay from './TreeStructureDisplay/TreeStructureDisplay';

const OverviewClientOld = () => {
  const classes = useOverviewClient();
  const theme = useTheme();
  const { data: cropTypeData } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE], () => getAllSettingsDataCroptype());
  const { clientId } = useParams();
  const [selectedCropType, setSelectedCropType] = useState<string | undefined>(undefined);

  const dataSettingsAnalyticsValue = useMemo(() => {
    return cropTypeData?.data || [];
  }, [cropTypeData]);

  const { data: clientData } = useQuery([QUERY_KEY.CLIENT_DATA, clientId], () => getClientById(clientId!), {
    enabled: !!clientId,
  });

  const cropTypeOptions = useMemo(() => {
    return clientData?.cropType || [];
  }, [clientData]);

  const test = useMemo(() => {
    return dataSettingsAnalyticsValue.filter((item: any) => cropTypeOptions.some((val: any) => val._id === item._id));
  }, [dataSettingsAnalyticsValue, cropTypeOptions]);

  return (
    <Grid className={classes.dataConfigurationCard}>
      <Grid container spacing={2} sx={{ height: '100%' }} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            pt: '28px',
          }}>
          <Box
            sx={{
              minWidth: '300px',
              flex: '1',
              borderRight: `1px solid ${theme.palette.divider}`,
            }}>
            {/* Tree structure */}
            <CardTreeStructure
              cropTypeOptions={cropTypeOptions}
              testData={test}
              setSelectedCropType={setSelectedCropType}
            />
          </Box>
          {/* Display Box */}
          <Box
            sx={{
              flex: '4',
              borderLeft: `1px solid ${theme.palette.divider}`,
            }}>
            <TreeStructureDisplay
              selectedCropType={selectedCropType}
              clientId={clientId}
              clientName={clientData?.name}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OverviewClientOld;
