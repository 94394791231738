export const getColorPaletteForAnalyticsTab = (color: string) => ({
  stackedBarChart: ['#ADD7D2', '#A2A7D6'],
  donutProductionChart: ['#8D95D8', '#D88DA8', '#F3D2AA', '#FBEDBC', '#CBE3C5', '#A7D5DB', '#86B4D6'],
  donutTripChart: ['#8D95D8', '#ADD7D2'],
  donutTripStatusChart: ['#F3D2AA', '#A7D5DB'],
  horizontalBarChart: [
    '#E6B7B7',
    '#E6C2B7',
    '#E5D0B7',
    '#F4EBC8',
    '#D7E5BE',
    '#CAE2C5',
    '#BFE4C2',
    '#B8E2C6',
    '#B3DCD4',
    '#A7D5DC',
  ],
  lineAreaChart: ['#8D95D8', '#ADD7D2', '#D88DA8', '#F3D2AA'],
  pieChart: ['#ADD7D2', '#86B4D6', '#8D95D8', '#D88DA8', '#F3D2AA', '#FBEDBC', '#E6B7B7', '#E6C2B7'],
  borderColor: color,
  noDataColor: ['#ddd'],
});

export interface IPieChatData {
  name: string;
  value: number;
}

export const getTopThreeSortedPieChartData = (pieData: IPieChatData[]) => {
  // Sort data in descending order
  const sortedData = [...pieData]?.sort((a, b) => (+b.value || 0) - (+a.value || 0));

  // Extract top 3 values and aggregate the rest into "Others"
  const top3 = sortedData?.slice(0, 3);
  const othersTotal = sortedData?.slice(3)?.reduce((acc, cur) => acc + (+cur.value || 0), 0);
  return [...top3, { name: 'Others', value: Number(othersTotal?.toFixed(2)) }];
};
