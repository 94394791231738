import { Box, Button, useTheme } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { addYears, endOfDay, endOfYear, isSameDay, startOfYear } from 'date-fns';
import { FC, useState } from 'react';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { IDateRange } from '../../interfaces';
import { calendarStyle } from '../../styles/calendar';

interface CalendarModalProps {
  handleClose: () => void;
  isShow: boolean;
  setSelectedDate: (arg: IDateRange) => void;
  selectedDate: IDateRange;
}

const CalendarModal: FC<CalendarModalProps> = ({ isShow, selectedDate, handleClose, setSelectedDate }) => {
  const theme = useTheme();
  const styles = calendarStyle();
  const [dateSelect, setDateSelect] = useState<any>([
    {
      startDate: startOfYear(new Date()),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const handlerCloseModal = () => {
    setDateSelect([
      {
        startDate: startOfYear(new Date()),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    handleClose();
  };

  return (
    <CommonModal isShow={isShow} handleClose={handleClose}>
      <Box className={styles.modal}>
        <DateRangePicker
          onChange={(item) => setDateSelect([item.selection] as any)}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateSelect}
          direction="horizontal"
          rangeColors={[theme.palette.primary.main, theme.palette.primary.contrastText]}
          color={theme.palette.primary.main}
          staticRanges={[
            ...defaultStaticRanges,
            {
              label: 'This year',
              range: () => ({
                startDate: startOfYear(new Date()),
                endDate: endOfDay(new Date()),
              }),
              isSelected(range: any) {
                const definedRange: any = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                );
              },
            },
            {
              label: 'Last year',
              range: () => ({
                startDate: startOfYear(addYears(new Date(), -1)),
                endDate: endOfYear(addYears(new Date(), -1)),
              }),
              isSelected(range: any) {
                const definedRange: any = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                );
              },
            },
          ]}
        />
        <Box width={'225px'} display={'flex'} style={{ marginLeft: 'auto' }}>
          <Button
            style={{ marginRight: '10px', marginLeft: 'auto', color: '#fff', backgroundColor: '#4fe3ae' }}
            onClick={() => {
              setSelectedDate(dateSelect);
              handleClose();
            }}
            variant="contained">
            Apply
          </Button>
          <Button style={{ marginRight: '10px' }} color="error" onClick={handlerCloseModal} variant="contained">
            Cancel
          </Button>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default CalendarModal;
