import { makeStyles } from '@mui/styles';

export const useRightBarStyle = makeStyles((theme: any) => {
  return {
    Percentage: {
      color: theme.palette.color.green4,
      position: 'absolute',
      top: '60px',
      width: '40%',
      textAlign: 'end',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    borderRight: {
      borderRight: `${theme.palette.color.grey3} 1px solid`,
    },
    active: {
      color: `${theme.palette.color.white} !important`,
      backgroundColor: `${theme.palette.color.green4} !important`,
      // '& img': {
      //     filter: 'invert(1)'
      // }
    },
    shadowUnsetBorderRadius: {
      marginTop: '20px',
      padding: '20px',
      boxShadow: 'unset !important',
      borderRadius: '12px',
    },
    totalProductionCardStyle: {
      padding: '20px',
      boxShadow: 'unset !important',
    },
  };
});
