import { Grid, styled, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useMapViewStyle } from '../MapViewStyle';
import { TabPanel } from '../TabPanel';
import { FavoritesLocation } from './FavoriteLocation';
import { HomeLocation } from './HomeLocation';
import RecentLocation from './RecentLocation';
const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

const TabStyled = styled(Tab)(({ theme }) => ({
  textTransform: 'unset',
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey3,
  fontWeight: 500,
  fontSize: '12px',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));
const tabMenu = [
  {
    id: 0,
    label: 'Home',
  },
  {
    id: 1,
    label: 'Favorites',
  },
  {
    id: 2,
    label: 'Recent',
  },
];

export const NavbarMapView = () => {
  const classes = useMapViewStyle();
  const [tabNumber, setTabNumber] = useState<number>(0);

  const handleChange = (_event: SyntheticEvent<Element, Event>, newValue: any) => {
    setTabNumber(newValue);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default),
      }}
      height="100%">
      <Grid direction="row" sx={{ height: '104px' }}>
        <Grid direction="row">
          <Tabs value={tabNumber} onChange={handleChange} variant="fullWidth" className={classes.mapViewTab}>
            {tabMenu.map((item, i) => (
              <TabStyled key={i} label={item.label} {...a11yProps(item.id)} />
            ))}
          </Tabs>
        </Grid>
        <Grid direction="row">
          <SwipeableViews axis={'x-reverse'} index={tabNumber}>
            <TabPanel value={tabNumber} index={0}>
              <HomeLocation />
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
              <FavoritesLocation />
            </TabPanel>
            <TabPanel value={tabNumber} index={2}>
              {/* <HomeLocation /> */}
              <RecentLocation />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Grid>
  );
};
