import { Box, InputLabel, TextField } from '@mui/material';
import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { uploadMedia } from 'services/media';
import { useAppDispatch } from 'store/hooks';
import { changeFileProperty } from 'store/slices/clientSlice';

export const FieldUploadFile = ({
  name,
  control,
  propertyItem,
  setValue,
  sx,
  index,
  properties,
  setEditMode,
  handleSubmitForm,
}: any) => {
  const dispatch = useAppDispatch();

  const uploadFileMutation = useMutation((file: any) => uploadMedia(file), {
    onSuccess: (response) => {
      if (!response?.data?._id) {
        toast.error('Upload file failed. Please try again!');
        return;
      }
      const _dataNew = properties.map((_item: any, idx: number) => {
        if (idx === index) {
          return {
            ..._item,
            mediaId: response.data._id,
            value: [response.data.path],
          };
        }
        return _item;
      });
      dispatch(changeFileProperty(_dataNew));
      setValue(name, response.data.path);
      index !== undefined && setValue(`properties.${index}.mediaId`, response.data._id);
      index !== undefined && setValue(`additionalInformation.${index}.mediaId`, response.data._id);

      if (setEditMode && handleSubmitForm) {
        handleSubmitForm({ properties: _dataNew });
        setEditMode(false);
      }
    },
  });

  const uploadFile = useCallback(
    async (target: any) => {
      const formData = new FormData();
      formData.append('file', target.files[0]);
      uploadFileMutation.mutate(formData);
    },
    [uploadFileMutation]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box sx={sx}>
            <InputLabel>{propertyItem?.name}</InputLabel>
            <TextField
              type="file"
              size="small"
              sx={{
                width: '100%',
                mt: '7px',
                '.MuiOutlinedInput-root': {
                  height: '40px',
                  justifyContent: 'end',
                },
              }}
              onChange={({ target }: any) => uploadFile(target)}
            />
          </Box>
        );
      }}
    />
  );
};
