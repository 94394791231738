import { Box, LinearProgress, Typography } from '@mui/material';
import { OdmStatusCode } from 'interfaces/workspace';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { StopwatchResult } from 'react-timer-hook';

interface LinearProgressWithLabelProps {
  value: number;
  status: OdmStatusCode | undefined;
  stopwatch: StopwatchResult;
  isError: boolean;
}

const LinearProgressWithLabel: FC<LinearProgressWithLabelProps> = ({ value, status, stopwatch, isError }) => {
  const { seconds, minutes, hours, pause } = stopwatch;

  // stop watch when success
  useEffect(() => {
    if (status === OdmStatusCode.COMPLETED) pause();
  }, [pause, status]);

  const formattedTime = moment(`${hours}:${minutes}:${seconds}`, 'H:m:s').format('HH:mm:ss');

  const renderStatusLabel = () => {
    if (isError) return 'Stopped! Error happened!';
    else return status === OdmStatusCode.COMPLETED ? 'Success!' : 'Progressing';
  };

  const renderProcessing = (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography>{`${formattedTime}`}</Typography>
        <Typography sx={{ color: isError ? 'red' : '' }}>{renderStatusLabel()}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography>{`${value}%`}</Typography>
        </Box>
      </Box>
    </>
  );

  const renderQueuing = (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mb: '8px' }}>{'Processing in another field!'}</Typography>
      <LinearProgress />
    </Box>
  );

  const renderWithStatus = () => {
    switch (status) {
      case OdmStatusCode.QUEUED:
        return renderQueuing;
      case OdmStatusCode.RUNNING:
      case OdmStatusCode.COMPLETED:
      default:
        return renderProcessing;
    }
  };
  return <>{renderWithStatus()}</>;
};

export default LinearProgressWithLabel;
