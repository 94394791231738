import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const myStyles = {
  popUpTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
  },
  popUpContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  popUpContentText: {
    textAlign: 'center',
    marginBottom: '12px',
  },
  popUpContentNumber: {
    marginBottom: '12px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    lineHeight: '40px',
  },
  popUpLoading: {
    color: 'red',
    width: '20px',
    height: '20px',
    position: 'absolute',
    left: '0%',
  },
  popUpContentNumberText: {
    textAlign: 'center',
    width: '40px',
    height: '40px',
    color: 'red',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '40px',
    position: 'absolute',
    cursor: 'default',
  },
  popUpAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px 24px',
  },
  popUpActionButton: {
    width: '128px',
  },
};

const IdlePopUp = ({ handleClose, handleLogout }: any) => {
  const timeOutWarning = 10000;
  const [timedOut, setTimedOut] = useState(timeOutWarning);
  const theme = useTheme();
  const styles = {
    ...myStyles,
    popUpContentText: { ...myStyles.popUpContentText, color: theme.palette.mode === 'dark' ? '#ffffff' : '#424242' },
  };

  const logOut = () => {
    toast.error('Logout successfully!', {
      theme: 'colored',
      toastId: 1,
    });
    handleLogout();
  };

  useEffect(() => {
    const a = setTimeout(() => {
      toast.error('Your idle session has expired. Please login again!', {
        theme: 'colored',
        toastId: 1,
      });
      handleLogout();
    }, timeOutWarning + 1000);
    return () => {
      clearTimeout(a);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimedOut(timedOut - 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeOutWarning, timedOut]);

  const handleKeepActive = () => {
    handleClose();
    toast.success('You are now active again.', {
      toastId: 1,
    });
  };

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root ': {
          maxWidth: '320px',
          borderRadius: '12px',
        },
      }}
      fullWidth
      maxWidth="xs"
      disableEscapeKeyDown
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        sx={{
          ...styles.popUpTitle,
        }}
        id="alert-dialog-title">
        You Have Been Idle!
      </DialogTitle>
      <DialogContent
        sx={{
          ...styles.popUpContent,
        }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            ...styles.popUpContentText,
          }}>
          You will be automatically logged out. If you wish to stay, then click on the green button.
        </DialogContentText>
        <DialogContentText
          sx={{
            ...styles.popUpContentText,
          }}
          id="alert-dialog-description">
          You will logout after
        </DialogContentText>
        <DialogContentText
          sx={{
            ...styles.popUpContentNumber,
            transition: 'all 0.3s ease-in-out',
            position: 'relative',
            backgroundColor: timedOut > 0 ? '#FC18171A' : 'transparent',
          }}
          id="alert-dialog-description">
          {timedOut > 0 ? (
            <Typography
              sx={{
                ...styles.popUpContentNumberText,
              }}
              variant="body2"
              color="textSecondary"
              component="p">
              {timedOut / 1000}
            </Typography>
          ) : (
            <CircularProgress
              sx={{
                ...styles.popUpLoading,
              }}
              size={40}
            />
          )}
        </DialogContentText>
        <DialogContentText
          sx={{
            ...styles.popUpContentText,
          }}
          id="alert-dialog-description">
          Seconds
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          ...styles.popUpAction,
        }}>
        <Button
          sx={{
            ...styles.popUpActionButton,
          }}
          variant="contained"
          onClick={handleKeepActive}
          autoFocus
          disabled={timedOut <= 0}>
          Stay logged in
        </Button>
        <Button
          sx={{
            ...styles.popUpActionButton,
          }}
          variant="contained"
          color="error"
          onClick={logOut}
          disabled={timedOut <= 0}>
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdlePopUp;
