import { Button, Grid, useTheme } from '@mui/material';
import { IAnalyticsTabs3DData } from 'common/defines/clients';
import { CROP_HIERARCHY, PIE_CHART_NO_DATA_LABEL, QUERY_KEY, SEVERITY } from 'constants/constants';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getDashboardTonnageByEstate } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTonnageDistribution } from 'store/slices/dashboardWidgetSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { getColorPaletteForAnalyticsTab, getTopThreeSortedPieChartData, IPieChatData } from '../analyticsTabConstants';

export const TonnageDistributionTab = (props: { dateRange: any }) => {
  const theme = useTheme();
  const colorPalette = useMemo(() => getColorPaletteForAnalyticsTab(theme.palette.background.paper), [theme]);
  const dispatch = useAppDispatch();
  const { selectedLevelHierarchyList } = useAppSelector(mapViewSelector);
  const { dateRange } = props;
  const [tonnageDistributionPieChart, setTonnageDistributionPieChart] = useState<EChartsOption>({ series: [{}] });
  const [levelHierarchyObject, setLevelHierarchyObject] = useState<any>(null);
  const [showAllData, setShowAllData] = useState(false);
  const [tonnagePieChartdata, setTonnagePieChartdata] = useState<IPieChatData[]>([{ name: '', value: 0 }]);

  const findSeverity = (totalValue: number = 0, value: number = 0): string => {
    const partSize = totalValue / 3;
    if (totalValue && value) {
      if (value <= partSize) {
        return SEVERITY.LOW;
      } else if (value <= partSize * 2) {
        return SEVERITY.MEDIUM;
      } else {
        return SEVERITY.HIGH;
      }
    } else {
      return SEVERITY.LOW;
    }
  };

  useEffect(() => {
    let levelData: any[] =
      selectedLevelHierarchyList && selectedLevelHierarchyList.length ? [...selectedLevelHierarchyList] : [];

    if (levelData.length) {
      let estateObj = levelData.filter((ele) => ele?.level === 1)[0] || {};
      let divisionObj = levelData.filter((ele) => ele?.level === 2)[0] || {};
      let yopObj = levelData.filter((ele) => ele?.level === 3)[0] || {};
      let blockObj = levelData.filter((ele) => ele?.level === 4)[0] || {};
      setLevelHierarchyObject({
        estateId: estateObj?.levelId || '',
        divisionName: divisionObj?.levelName || '',
        yop: yopObj?.levelName || '',
        blockName: blockObj?.levelName || '',
      });
    } else {
      setLevelHierarchyObject(null);
    }
  }, [selectedLevelHierarchyList]);

  const pieDataRevamp = (pieList: IPieChatData[]) => {
    setTonnageDistributionPieChart((prevState: any) => ({
      ...prevState,
      series: [{ ...prevState?.series[0], data: pieList }],
    }));
  };

  useEffect(() => {
    if (tonnagePieChartdata?.length) {
      if (showAllData) {
        pieDataRevamp(tonnagePieChartdata);
      } else if (tonnagePieChartdata?.length > 3) {
        pieDataRevamp(getTopThreeSortedPieChartData(tonnagePieChartdata));
      }
    }
  }, [showAllData, tonnagePieChartdata]);

  useQuery(
    [QUERY_KEY.GET_TONNAGE_BY_ESTATE, dateRange, levelHierarchyObject],
    () =>
      getDashboardTonnageByEstate({
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        estateId: levelHierarchyObject?.estateId || '',
        divName: levelHierarchyObject?.divisionName || '',
        yop: levelHierarchyObject?.yop || '',
        blockName: levelHierarchyObject?.blockName || '',
      }),
    {
      onSuccess: (data: any) => {
        let tonnageData = data?.data && data?.data.length ? [...data?.data] : [];
        let groupKey: string = CROP_HIERARCHY.LOCATION;
        if (
          levelHierarchyObject?.estateId &&
          !levelHierarchyObject?.divisionName &&
          !levelHierarchyObject?.yop &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.DIV_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          !levelHierarchyObject?.yop &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.YOP;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          levelHierarchyObject?.yop &&
          !levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else if (
          levelHierarchyObject?.estateId &&
          levelHierarchyObject?.divisionName &&
          levelHierarchyObject?.yop &&
          levelHierarchyObject?.blockName
        ) {
          groupKey = CROP_HIERARCHY.BLOCK_NAME;
        } else {
          groupKey = CROP_HIERARCHY.LOCATION;
        }

        const groupedChartBarData: any = {};
        for (let item of tonnageData) {
          const name = item[groupKey];
          if (!groupedChartBarData[name]) {
            groupedChartBarData[name] = 0;
          }

          groupedChartBarData[name] += item.TotalNetWeight / 1000;
        }

        let mappedChartBarData = Object.keys(groupedChartBarData).map((item) => ({
          name: item,
          value: Number(groupedChartBarData[item]?.toFixed(2)),
        }));

        let finalChartBarData: IAnalyticsTabs3DData[] = [];
        if (mappedChartBarData.length) {
          const highestValueObject = mappedChartBarData.reduce((prev: any, current: any) => {
            return prev.value > current.value ? prev : current;
          }, {});

          finalChartBarData = mappedChartBarData.map((ele: any) => {
            return {
              ...ele,
              severity: findSeverity(highestValueObject?.value || 0, ele?.value || 0),
            };
          });
        }

        const tonnageDistributionPie = {
          title: {
            text: 'Tonnage Distribution',
            show: true,
            subtext: 'Per Estate & Trip Status',
          },
          legend: {
            show: false,
          },
          tooltip: {
            trigger: 'item',
          },
          series: [
            {
              name: 'Tonnage Distribution',
              type: 'pie',
              radius: ['40%', '70%'],
              top: '10%',
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 10,
                  fontWeight: 'bold',
                },
              },
              color: mappedChartBarData?.length ? colorPalette.pieChart : colorPalette.noDataColor,
              data: mappedChartBarData?.length ? mappedChartBarData : PIE_CHART_NO_DATA_LABEL,
            },
          ],
        };
        setTonnagePieChartdata(mappedChartBarData);
        setTonnageDistributionPieChart(tonnageDistributionPie);
        dispatch(setTonnageDistribution(finalChartBarData));
      },
    }
  );

  return (
    <Grid sx={{ position: 'relative' }}>
      {tonnagePieChartdata?.length > 3 && (
        <Button sx={{ position: 'absolute', right: '30px', zIndex: '1' }} onClick={() => setShowAllData(!showAllData)}>
          {showAllData ? 'Hide Others' : 'Show All'}
        </Button>
      )}
      <ReactEcharts option={tonnageDistributionPieChart} />
    </Grid>
  );
};
