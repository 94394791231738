import { Box, Button, Grid, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAnalyticCardTabStyle } from '../../MapViewStyle';

export const AnalyticCardTab = ({ icon, label, isSelected, onSelect }: any) => {
  const classes = useAnalyticCardTabStyle();
  const [isStateSelected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  return (
    <Grid
      direction="column"
      container
      alignItems="center"
      justifyContent="center"
      spacing={0}
      width="fit-content"
      sx={{
        mb: '16px',
      }}>
      <Button
        onClick={() => {
          if (isStateSelected) {
            setSelected(!isStateSelected);
            onSelect();
            return;
          }
          onSelect();
          setSelected(true);
        }}
        classes={{ root: isStateSelected ? classes.buttonStyleActive : classes.buttonStyle }}>
        <Box>
          {icon}
          <Box className={classes.labelBox}>
            <InputLabel
              sx={{ textTransform: 'capitalize' }}
              classes={{ root: isStateSelected ? classes.labelTextActive : classes.labelText }}>
              {label}
            </InputLabel>
          </Box>
        </Box>
      </Button>
    </Grid>
  );
};
