import SkeletonCharts from 'components/Common/SkeletonCharts';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getDataChlorophyllAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsChlorophyll } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataChlorophyllPreview,
  changeDataChlorophyllAnalytics,
  changeDataChlorophyllInterval,
  changeDataPolygonChlorophyllAnalytics,
} from 'store/slices/map-view/chlorophyllAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import { chlorophyllAnalyticsSelector } from '../../../../../../store/slices/map-view/chlorophyllAnalytics';
import { ChlorophyllLayer } from '../../Layer/Chlorophyll';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const ChlorophyllAnalytics = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataChlorophyllInterval, dataChlorophyllPolygon, chartDataChlorophyllPreview } =
    useAppSelector(chlorophyllAnalyticsSelector);

  const { data: dataChlorophyllBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.CHLOROPHYLL_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'chlorophyll'),
    { enabled: !!analyticId }
  );

  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.CHLOROPHYLL_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'chlorophyll', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        if (data.data?.docs) {
          dispatch(changeDataPolygonChlorophyllAnalytics([...dataChlorophyllPolygon, ...data.data?.docs]));
        }
      },
    }
  );

  useQuery(
    [QUERY_KEY.CHLOROPHYTE_ANALYTIC, analyticId],
    () => {
      return getDataChlorophyllAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData) => {
        dispatch(changeDataChlorophyllAnalytics(mainData?.data || []));
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_CHLOROPHYLL_ANALYTIC, isDefaultInterval],
    () => getChartsChlorophyll(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataChlorophyllInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataChlorophyllInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_CHLOROPHYLL_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataChlorophyllBoxPlot?.data) return [];
    return dataChlorophyllBoxPlot.data;
  }, [dataChlorophyllBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataChlorophyllPreview
                      ? chartDataChlorophyllPreview.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE}
                  getCharts={getChartsChlorophyll}
                  intervalRange={
                    chartDataChlorophyllPreview
                      ? chartDataChlorophyllPreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_CHLOROPHYLL_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataChlorophyllPreview}
                />
              )}
            </>
          ) : (
            <>
              <SkeletonCharts count={1} />
            </>
          )}
        </>
      }
      layerTab={<ChlorophyllLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
