export const NewDashboardIcon = ({ isSelected }: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={isSelected ? '#23BE6A' : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <rect x="7.5" y="8.5" width="17" height="17" rx="3.5" stroke="#737373" />
      <path
        d="M19.8906 16.1479C19.8906 16.1479 19.8886 22.0701 19.8887 21.955"
        stroke="#737373"
        stroke-linecap="round"
      />
      <path
        d="M15.9004 14.0376C15.9004 14.0376 15.9004 23.3043 15.9004 23.0888"
        stroke="#737373"
        stroke-linecap="round"
      />
      <path
        d="M11.8965 20.3496C11.8965 20.3496 11.8965 22.3963 11.8965 22.3487"
        stroke="#737373"
        stroke-linecap="round"
      />
      <path
        d="M20.8984 22.501C12.7029 22.501 11.4542 22.501 10.8984 22.501"
        stroke="#737373"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9475 18.8218C11.1161 19.8866 11.9796 20.2245 11.9796 20.2245C11.9796 20.2245 12.6964 19.6363 12.5278 18.5715C12.3591 17.5066 11.5103 17.2614 11.5103 17.2614C11.5103 17.2614 10.7788 17.7569 10.9475 18.8218Z"
        stroke="#737373"
      />
      <path
        d="M21.0877 14.6477C20.8251 15.6933 19.935 15.9531 19.935 15.9531C19.935 15.9531 19.2733 15.3036 19.5359 14.2579C19.7986 13.2123 20.6658 13.0434 20.6658 13.0434C20.6658 13.0434 21.3504 13.602 21.0877 14.6477Z"
        stroke="#737373"
      />
      <path
        d="M15.8368 12.2493C15.4142 13.2411 15.9665 13.986 15.9665 13.986C15.9665 13.986 16.8862 13.8683 17.3088 12.8764C17.7314 11.8846 17.1424 11.226 17.1424 11.226C17.1424 11.226 16.2594 11.2574 15.8368 12.2493Z"
        stroke="#737373"
      />
      <path
        d="M16.586 18.4165C15.9135 19.2591 16.2464 20.1245 16.2464 20.1245C16.2464 20.1245 17.164 20.2572 17.8366 19.4145C18.5091 18.5719 18.1178 17.7798 18.1178 17.7798C18.1178 17.7798 17.2586 17.5738 16.586 18.4165Z"
        stroke="#737373"
      />
      <path
        d="M20.6134 18.1425C19.9408 18.9852 20.2737 19.8506 20.2737 19.8506C20.2737 19.8506 21.1914 19.9833 21.8639 19.1406C22.5364 18.298 22.1451 17.5059 22.1451 17.5059C22.1451 17.5059 21.2859 17.2999 20.6134 18.1425Z"
        stroke="#737373"
      />
      <path
        d="M15.1752 15.6405C14.4446 14.8476 13.5386 15.0451 13.5386 15.0451C13.5386 15.0451 13.268 15.9319 13.9986 16.7247C14.7292 17.5176 15.5716 17.2512 15.5716 17.2512C15.5716 17.2512 15.9058 16.4333 15.1752 15.6405Z"
        stroke="#737373"
      />
    </svg>
  );
};
