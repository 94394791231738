import styled from '@emotion/styled';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined';
import { Box, Checkbox, Stack, Typography, useTheme } from '@mui/material';
import { ISettingsClientMeasure } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { ChangeEvent, FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { changeIssueStatus } from 'services/MapView/apiMapViewConfig.services';
import { DrawIssueShapeEnum, IIssue } from '../../interfaces';
import { clientAreaUnitMeasurement, clientLengthUnitMeasurement } from '../../utils';
import DeleteIssue from './DeleteIssue';
import DownloadIssue from './DownloadIssue';
import EditIssue from './EditIssue';
import FindIssueLocation from './FindIssueLocation';
import ShareIssue from './ShareIssue';

interface CommonIssueCardProps {
  data: IIssue;
  handleChangeStatus: (id: string, value: boolean) => void;
}

const CommonIssueCard: FC<CommonIssueCardProps> = ({ data, handleChangeStatus }) => {
  const [unitSetting, setUnitSetting] = useState<ISettingsClientMeasure[]>(defaultClientUnitSetting);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const colorByTheme = theme.palette.mode === 'dark' ? '#c2c4c5 !important' : '#3C4043';

  const { clientId } = useParams();
  useQuery([QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId], () => getClientSettingsById(clientId || ''), {
    enabled: !!clientId,
    onSuccess(data) {
      setUnitSetting(data?.unitSetting);
    },
  });

  const handleClickCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.checked;
    handleChangeStatus(data._id, checkedValue);
    changeStatusMutation.mutate({ id: data._id, status: checkedValue });
  };

  const changeStatusMutation = useMutation(changeIssueStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_DISPLAYED_ISSUES]);
    },
  });

  const renderIconWithType = () => {
    switch (data.type) {
      case DrawIssueShapeEnum.LINE:
        return <LinearScaleIcon style={{ color: colorByTheme }} />;
      case DrawIssueShapeEnum.CIRCLE:
        return <CircleOutlinedIcon style={{ color: colorByTheme }} />;
      case DrawIssueShapeEnum.POINT:
        return <GpsFixedOutlinedIcon sx={{ color: colorByTheme }} />;
      case DrawIssueShapeEnum.POLYGON:
        return <PolylineOutlinedIcon style={{ color: colorByTheme }} />;
      case DrawIssueShapeEnum.SQUARE:
        return <SquareOutlinedIcon style={{ color: colorByTheme }} />;
      case DrawIssueShapeEnum.IMAGE:
      default:
        return <PhotoOutlinedIcon style={{ color: colorByTheme }} />;
    }
  };

  const CustomBox = styled(Box)({
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <CustomBox>
      <Checkbox
        sx={{ flexBasis: '10%' }}
        checked={data.status}
        onChange={handleClickCheckbox}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Box
        sx={{
          flexBasis: '10%',
          display: 'contents',
          '& svg': {
            width: '24px',
            color: colorByTheme,
          },
        }}>
        {renderIconWithType()}
      </Box>
      <Stack sx={{ flexBasis: '40%', overflow: 'hidden', mx: '8px' }}>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            fontWeight: '600',
            fontSize: '15px',
            color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
          }}>
          {data.description}
        </Typography>
        <Box style={{ lineHeight: '12px' }}>
          <Typography
            sx={{
              display: 'inline',
              fontWeight: '500',
              fontSize: '12px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
            }}>
            Date:{' '}
          </Typography>
          <Typography
            sx={{
              display: 'inline',
              fontSize: '12px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
            }}>
            {moment(data.date).format('DD-MM-YYYY')}
          </Typography>
        </Box>
        {Number(data.area) ? (
          <Box style={{ lineHeight: '13px', paddingBottom: '5px' }}>
            <Typography
              sx={{
                display: 'inline',
                fontSize: '12px',
                lineHeight: '12px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              Area:{' '}
            </Typography>
            <Typography
              sx={{
                display: 'inline',
                fontSize: '12px',
                lineHeight: '12px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              {clientAreaUnitMeasurement(Number(data.area!), unitSetting.find((item) => item.name === 'AREA')!)}
              &nbsp;
              {unitSetting.find((item) => item.name === 'AREA')?.unit}
            </Typography>
          </Box>
        ) : null}
        {Number(data.length) ? (
          <Box style={{ lineHeight: '13px', paddingBottom: '5px' }}>
            <Typography
              sx={{
                display: 'inline',
                fontSize: '12px',
                lineHeight: '12px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              Length:{' '}
            </Typography>
            <Typography
              sx={{
                display: 'inline',
                fontSize: '12px',
                lineHeight: '12px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#c2c4c5' : '#3C4043'),
              }}>
              {clientLengthUnitMeasurement(Number(data.length!), unitSetting.find((item) => item.name === 'LENGTH')!)}
              &nbsp;
              {unitSetting.find((item) => item.name === 'LENGTH')?.unit}
            </Typography>
          </Box>
        ) : null}
      </Stack>
      <Box sx={{ flexBasis: '40%', display: 'flex', justifyContent: 'flex-end', mr: '16px' }}>
        <FindIssueLocation data={data} colorByTheme={colorByTheme} />
        <DownloadIssue data={data} colorByTheme={colorByTheme} />
        <EditIssue initialData={data} colorByTheme={colorByTheme} />
        <DeleteIssue id={data._id} colorByTheme={colorByTheme} />
        <ShareIssue data={data} colorByTheme={colorByTheme} />
      </Box>
    </CustomBox>
  );
};

export default CommonIssueCard;
