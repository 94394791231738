import DescriptionIcon from '@mui/icons-material/Description';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { RoleType } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { HomeLocation } from 'components/MapView/SideBar/HomeLocation';
import { QUERY_KEY } from 'constants/constants';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getExportAnalyticScript, getRasterListByPagination } from 'services/analytics/apiAnalyticsConfig.services';
import { generateClientKey, getClientSettingsById, queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  anaLyticConfigSelector,
  changePageRasterPagination,
  changeRasterPagination,
  resetRasterPagination,
} from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const RasterItemEnable = [
  TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT,
  TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
  TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
  TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
  TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
  TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
  TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
];

export const ClientApis = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { levelId, isLastLevelId } = useAppSelector(mapViewSelector);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchClient, setSearchClient] = useState(null);
  const [analysisArea, setAnalysisArea] = useState(null);
  const [clientKey, setClientKey] = useState(null);
  const { listRasterPagination, pageRaster } = useAppSelector(anaLyticConfigSelector);
  const { clientId } = useParams();

  const listRasterData = useMemo(() => {
    if (!listRasterPagination) {
      return [];
    }
    return listRasterPagination;
  }, [listRasterPagination]);

  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  useEffect(() => {
    setClientKey(clientData?.clientKey);
  }, [clientData]);

  const { data: dataConfiguration } = useQuery(
    [QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId],
    () => getRasterListByPagination(pageRaster, rowsPerPage, searchClient, analysisArea, levelId || null),
    {
      cacheTime: 1,
      enabled: !isEmpty(levelId),
      onSuccess: (data: any) => {
        const Array = data?.data.docs || [];
        const newArray = pageRaster === 1 ? Array : [...listRasterData, ...Array];
        dispatch(changeRasterPagination(newArray));
      },
    }
  );

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const hasPermission = useMemo(() => {
    const currentRole = userInfo?.data?.role?.find((role: RoleType) => role.clientId === clientId);
    return currentRole?.permissions?.some((permission: string) => permission === 'MANAGE_ANALYSIS_EXPORT_SCRIPT_FILE');
  }, [clientId, userInfo]);
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  useEffect(() => {
    dispatch(changePageRasterPagination(1));
    dispatch(resetRasterPagination());
  }, [dispatch, levelId]);

  const handleDownloadFile = useMutation((id: string) => getExportAnalyticScript(id), {
    onSuccess: (res: any) => {
      const blob = new Blob([res.data.toString()], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, res.headers.filename);
      toast.success(`Download File Successful`, { toastId: 1 });
    },
    onError: () => {
      toast.error(`Download File Error`, { toastId: 2 });
    },
  });

  const handleDownload = (id: string) => {
    handleDownloadFile.mutate(id);
  };

  const handleGenerateClientKey = useMutation(() => generateClientKey(clientData?._id || null), {
    onSuccess: (res: any) => {
      setClientKey(res?.data?.clientKey);
      toast.success('Generate key successfully');
    },
  });

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: '6px',
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
          padding: '20px',
        }}>
        <Box sx={{ pt: 2 }}>
          <Box
            sx={{
              border: `1px solid ${theme.palette.divider}`,
            }}
          />
          <Box sx={{ px: 2.5 }}>
            <Grid container sx={{ minWidth: '300px', pt: 2 }}>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>Client Key</InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => handleGenerateClientKey.mutate()} variant="outlined" size="small">
                  Generate Key
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ paddingBottom: '15px' }}>{clientKey && `Key: ${clientKey}`}</Box>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              border: `1px solid ${theme.palette.divider}`,
            }}
          />
          <Box
            sx={{
              py: 2.5,
              paddingBottom: '15px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}>
            <Box sx={{ width: '300px', height: 'auto' }}>
              <HomeLocation onDashboard={true} />
            </Box>
            <Box sx={{ marginLeft: '30px', paddingLeft: '30px', borderLeft: `1px solid ${theme.palette.divider}` }}>
              {listRasterData.length > 0 &&
                listRasterData.map((raster: any) => (
                  <Box sx={{ marginBottom: '30px' }}>
                    <InputLabel>{moment(raster?.date).format('YYYY-MM-DD')}</InputLabel>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                            }}>
                            <TableCell
                              sx={{
                                border: `1px solid ${theme.palette.divider}`,
                                width: '300px',
                              }}>
                              Analytic Name
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${theme.palette.divider}`,
                                width: '300px',
                              }}>
                              Id
                            </TableCell>
                            <TableCell
                              sx={{
                                border: `1px solid ${theme.palette.divider}`,
                              }}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {raster?.analysis?.map((rasterItem: any) => (
                            <TableRow
                              key={rasterItem?._id}
                              sx={{
                                border: `1px solid ${theme.palette.divider}`,
                              }}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  border: `1px solid ${theme.palette.divider}`,
                                  width: '300px',
                                }}>
                                {rasterItem?.name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: `1px solid ${theme.palette.divider}`,
                                  width: '300px',
                                }}>
                                {rasterItem?._id}
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: `1px solid ${theme.palette.divider}`,
                                }}>
                                {(hasPermission || isSuperAdmin || isSuperUser) &&
                                  RasterItemEnable.includes(rasterItem?.name as TYPE_ANALYTICS_MAP_VIEW) && (
                                    <DescriptionIcon
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => handleDownload(rasterItem?._id)}
                                    />
                                  )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
