import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { find, isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';

const styles = {
  userListTitle: {
    py: '10px',
    // color: '#3C4043',
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18.2px',
    textAlign: 'center',
  },
  clientUpdateTime: {
    // color: '#6E6B7B',
    textAlign: 'center',
    px: '16px',
  },
};

export const CropCoverTable = () => {
  const { allLocationCropType } = useAppSelector(clientSelector);
  const [typeSortCrop, setTypeSortCrop] = useState(true);
  const [typeSortNonCrop, setTypeSortNonCrop] = useState(true);
  const [typeSort, setTypeSort] = useState('Crop');

  const { clientId } = useParams();
  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const tableCropCoverage = useMemo(() => {
    const formatArray: any = allLocationCropType
      ?.filter((v: any) => !isEmpty(v.cropCoverAnalysisSummary))
      ?.map((item: any) => {
        const nonCropDataFilter = find(item.cropCoverAnalysisSummary, (i) => i.title === 'Noncrop');
        const cropDataFilter = find(item.cropCoverAnalysisSummary, (i) => i.title === 'Crop');

        return {
          name: item.name,
          nonCrop: nonCropDataFilter?.value || null,
          crop: cropDataFilter?.value || null,
          cropCoverAnalysisSummary: item?.cropCoverAnalysisSummary,
        };
      });

    const sortedDataCropCover = formatArray?.sort((a: any, b: any) => {
      if (typeSort === 'Crop') {
        return typeSortCrop ? a.crop - b.crop : b.crop - a.crop;
      } else {
        return typeSortNonCrop ? a.nonCrop - b.nonCrop : b.nonCrop - a.nonCrop;
      }
    });

    if (sortedDataCropCover && sortedDataCropCover.length > 0) {
      return sortedDataCropCover.map((dataCropCover: any, index: number) => {
        const isDisplayRowCropCover = dataCropCover.cropCoverAnalysisSummary.some((col: any) => !!col.value);
        const sumCropCoverAnalysisSummary = dataCropCover.cropCoverAnalysisSummary.reduce(
          (total: number, cropCoverData: any) => (total += cropCoverData.value),
          0
        );

        return (
          <TableRow key={index}>
            {isDisplayRowCropCover && dataCropCover.landUseArea !== 0 && (
              <TableCell
                component="td"
                sx={{
                  ...styles.clientUpdateTime,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                  textAlign: 'left',
                  minWidth: '60px',
                }}>
                {dataCropCover.name}
              </TableCell>
            )}
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                textAlign: 'left',
                minWidth: '60px',
              }}>
              {clientAreaUnitMeasurement(dataCropCover.crop)} {`${areaUnit}`}
              <Typography>
                ({(Math.round((dataCropCover.crop / sumCropCoverAnalysisSummary) * 100 * 100) / 100).toFixed(2)} %)
              </Typography>
            </TableCell>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B'),
                textAlign: 'left',
                minWidth: '60px',
              }}>
              {clientAreaUnitMeasurement(dataCropCover.nonCrop)} {`${areaUnit}`}
              <Typography>
                ({(Math.round((dataCropCover.nonCrop / sumCropCoverAnalysisSummary) * 100 * 100) / 100).toFixed(2)} %)
              </Typography>
            </TableCell>
          </TableRow>
        );
      });
    }
    return <></>;
  }, [allLocationCropType, areaUnit, clientAreaUnitMeasurement, typeSort, typeSortCrop, typeSortNonCrop]);

  const handleSortTable = useCallback(
    (type: string) => {
      if (type === 'Crop') {
        setTypeSortCrop(!typeSortCrop);
      } else {
        setTypeSortNonCrop(!typeSortNonCrop);
      }
      setTypeSort(type);
    },
    [typeSortNonCrop, typeSortCrop]
  );

  const theme = useTheme();

  return (
    <TableContainer
      sx={{
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        mt: '-1px',
        mb: '12px',
      }}>
      <Table>
        <TableHead>
          {
            <TableRow
              component="tr"
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
              }}>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                  textAlign: 'left',
                  minWidth: '60px',
                }}>
                Level
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                  textAlign: 'left',
                  minWidth: '60px',
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Crop
                  <SvgIcon
                    component={typeSortCrop ? DescIcon : AscIcon}
                    inheritViewBox
                    sx={{ fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => handleSortTable('Crop')}
                  />
                </Box>
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.userListTitle,
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                  textAlign: 'left',
                  minWidth: '60px',
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Non Crop
                  <SvgIcon
                    component={typeSortNonCrop ? DescIcon : AscIcon}
                    inheritViewBox
                    sx={{ fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => handleSortTable('NonCrop')}
                  />
                </Box>
              </TableCell>
            </TableRow>
          }
        </TableHead>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
            minHeight: '510px',
            maxHeight: '510px',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          {tableCropCoverage}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
