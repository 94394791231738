import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeoJSONObject, Point } from '@turf/turf';
import { RasterLayerDTO } from 'common/defines/analytic';
import { IPlantHealthAnalysis, RenderTree } from 'common/defines/clients';
import update from 'immutability-helper';
import { isArray } from 'lodash';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../common/defines/constants';
import type { RootState } from '../store';
import cropTreeLevelSearch from 'common/utils/cropTreeLevelSearch';


export interface ICropTreeLevelObj {
  _id: string,
  parent: string,
  name: string,
  area: number,
  value: number,
  level: number,
  levelName: string,
  levelId: string,
  isLastLevel: boolean,
  children: ICropTreeLevelObj[],
  positionNumber: number,
  totalTree: number,
  levelCount: number,
  key: string,
  isFavorite?: any;
  refetch?: any;
  recentLevel?: string;
  deleteId?: string | '';
}
interface IMapViewSlice {
  clientId: string | null;
  cropType?: any | null;
  levelId?: string | null;
  isLastLevelId: boolean;
  pointIdSelected?: string | null;
  parentAnalyticsId?: string | null;
  pointCenters: { longitude: number; latitude: number; id: string }[];
  pointCenterSelect: string | null;
  polygonLandUseAnalytics: Array<any>;
  isLayer3D: boolean;
  isShowLayerRaster: boolean;
  isShowAllLayer: boolean;
  analyticId: string | null;
  dateAnalyticSelected: string | null;
  isDisplayBoundaryLine: boolean;
  isFill: boolean;
  ratersSelected: Array<{ _id: string; name: string }>;
  sideSurfaceOpacity: number;
  colorSideSurface: string;
  isSameSideSurface: boolean;
  topSurfaceOpacity: number;
  layerTypeList: Array<RasterLayerDTO>;
  layerTypeCurrentList: Array<RasterLayerDTO>;
  sizeCropCoverageList: Array<number>;
  sizeCropCoverageSelected?: number;
  sizePlantHealthSelected?: number;
  sizeTilerDensitySelected?: number;
  sizeVacantAreaSelected?: number;
  sizeChlorophyllPaddySelected?: number;
  sizeStressFieldSelected?: number;
  sizeWaterUptakeFieldSelected?: number;
  sizeVigorPaddySelected?: number;
  sizeSoilSaturationFieldSelected?: number;
  sizeSoilErosionFieldSelected?: number;
  sizeLeafPhenologyFieldSelected?: number;
  sizeSlopeFieldSelected?: number;
  dataCropCoverage: any;
  dataStandCount: any;
  dataCircumference: any;
  sizeWeedInvasionSelected?: number;
  dataWeedInvasion: any;
  sizeWeedInvasionList: Array<number>;
  dataInterval: any;
  pointDataLandUse: Array<Point>;
  isShowPointDataLandUse: boolean;
  isSameColorTopSurface: boolean;
  plantationCropCoverage: any;
  colorBorderPlantation: string;
  isDisplay3DCylinder: boolean;
  isDefaultInterval: boolean;
  isShowAllInfoTooltip: boolean;
  dataIntervalAllLimit: any;
  dataPlantHealth: IPlantHealthAnalysis[];
  sizePlantHealthList: Array<number>;
  dataTilerDensity: any;
  dataVacantAreaSpot: any;
  sizeTilerDensityList: Array<number>;
  dataVacantArea: any;
  sizeVacantAreaList: Array<number>;
  dataChlorophyllPaddy: any;
  sizeChlorophyllPaddyList: Array<number>;
  dataStressField: any;
  sizeStressFieldList: Array<number>;
  dataWaterUptakeField: any;
  sizeWaterUptakeFieldList: Array<number>;
  dataVigorPaddy: any;
  sizeVigorPaddyList: Array<number>;
  dataSoilSaturationField: any;
  sizeSoilSaturationFieldList: Array<number>;
  dataSoilErosionField: any;
  sizeSoilErosionFieldList: Array<number>;
  dataLeafPhenologyField: any;
  sizeLeafPhenologyFieldList: Array<number>;
  dataSlopeField: any;
  sizeSlopeFieldList: Array<number>;
  drawerShow: {
    leftBar: boolean;
    rightBar: boolean;
  };
  mapViewState: {
    longitude: number;
    latitude: number;
    zoom: number;
  };
  selectedLevelHierarchyList: ICropTreeLevelObj[] | RenderTree[] | [],
  resetMapViewTrigger: boolean,
  defaultMapViewData: {
    longitude: number;
    latitude: number;
    zoom: number;
  }
}

const initialState: IMapViewSlice = {
  clientId: null,
  cropType: null,
  levelId: null,
  isLastLevelId: false,
  pointIdSelected: null,
  parentAnalyticsId: null,
  pointCenters: [],
  pointCenterSelect: null,
  polygonLandUseAnalytics: [],
  isLayer3D: true,
  isShowLayerRaster: true,
  isShowAllLayer: true,
  analyticId: null,
  dateAnalyticSelected: null,
  isDisplayBoundaryLine: false,
  isFill: true,
  ratersSelected: [],
  sideSurfaceOpacity: 1,
  colorSideSurface: 'rgb(255, 255, 255)',
  isSameSideSurface: false,
  topSurfaceOpacity: 1,
  layerTypeList: [],
  layerTypeCurrentList: [],
  sizeCropCoverageList: [],
  sizePlantHealthList: [],
  sizeTilerDensityList: [],
  sizeVacantAreaList: [],
  sizeWeedInvasionList: [],
  sizeChlorophyllPaddyList: [],
  sizeStressFieldList: [],
  sizeWaterUptakeFieldList: [],
  sizeVigorPaddyList: [],
  sizeSoilSaturationFieldList: [],
  sizeSoilErosionFieldList: [],
  sizeLeafPhenologyFieldList: [],
  sizeSlopeFieldList: [],
  dataCropCoverage: undefined,
  dataStandCount: undefined,
  dataCircumference: undefined,
  dataInterval: undefined,
  pointDataLandUse: [],
  isShowPointDataLandUse: false,
  isSameColorTopSurface: true,
  plantationCropCoverage: undefined,
  colorBorderPlantation: '#1EBADB',
  isDisplay3DCylinder: false,
  isDefaultInterval: true,
  isShowAllInfoTooltip: false,
  dataIntervalAllLimit: undefined,
  dataPlantHealth: [],
  dataTilerDensity: undefined,
  dataVacantAreaSpot: undefined,
  dataVacantArea: undefined,
  dataWeedInvasion: undefined,
  dataChlorophyllPaddy: undefined,
  dataStressField: undefined,
  dataWaterUptakeField: undefined,
  dataVigorPaddy: undefined,
  dataSoilSaturationField: undefined,
  dataSoilErosionField: undefined,
  dataLeafPhenologyField: undefined,
  dataSlopeField: undefined,
  drawerShow: {
    leftBar: false,
    rightBar: false,
  },
  mapViewState: {
    longitude: 0,
    latitude: 0,
    zoom: 10,
  },
  selectedLevelHierarchyList: [],
  resetMapViewTrigger: false,
  defaultMapViewData: {
    longitude: 0,
    latitude: 0,
    zoom: 10,
  }
};

const getStateAnalytics = (typeOfAnalytic: string) => {
  switch (typeOfAnalytic) {
    case TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS:
      return 'polygonLandUseAnalytics';
    case TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE:
      return 'dataCropCoverage';
    case TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT:
      return 'dataStandCount';
    case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
      return 'dataCircumference';
    default:
      return null;
  }
};

export const mapViewSlice = createSlice({
  name: 'mapView',
  initialState,
  reducers: {
    clearState: (state) => {
      state.levelId = null;
      state.isLastLevelId = false;
      state.pointIdSelected = null;
      state.parentAnalyticsId = null;
      state.analyticId = null;
      state.pointCenters = [];
      state.pointCenterSelect = null;
      state.polygonLandUseAnalytics = [];
      state.isDisplayBoundaryLine = false;
      state.isShowAllLayer = true;
      state.isFill = true;
      state.ratersSelected = [];
      state.isLayer3D = true;
      state.isShowLayerRaster = true;
      state.layerTypeList = [];
      state.layerTypeCurrentList = [];
      state.sizeCropCoverageList = [];
      state.sizeWeedInvasionList = [];
      state.sizeCropCoverageSelected = undefined;
      state.sizePlantHealthSelected = undefined;
      state.sizeTilerDensitySelected = undefined;
      state.sizeVacantAreaSelected = undefined;
      state.sizeChlorophyllPaddySelected = undefined;
      state.sizeStressFieldSelected = undefined;
      state.sizeWaterUptakeFieldSelected = undefined;
      state.sizeVigorPaddySelected = undefined;
      state.sizeWeedInvasionSelected = undefined;
      state.sizeSoilSaturationFieldSelected = undefined;
      state.sizeSoilErosionFieldSelected = undefined;
      state.sizeLeafPhenologyFieldSelected = undefined;
      state.sizeSlopeFieldSelected = undefined;
      state.dataWeedInvasion = [];
      state.dataCropCoverage = [];
      state.dataStandCount = [];
      state.dataCircumference = [];
      state.pointDataLandUse = [];
      state.isShowPointDataLandUse = false;
      state.dateAnalyticSelected = null;
      state.colorBorderPlantation = '#1EBADB';
      state.isDefaultInterval = true;
    },
    changeCropType: (state, action: PayloadAction<any>) => {
      state.cropType = action.payload;
      state.pointIdSelected = null;
      state.pointCenters = [];
      state.pointCenterSelect = null;
    },
    changeLevelId: (state, action: PayloadAction<{ levelId?: string; isLevelLasted?: boolean, levels: ICropTreeLevelObj[] | RenderTree[] | [] }>) => {
      state.levelId = action.payload.levelId;
      state.isLastLevelId = !!action.payload.isLevelLasted;
      state.pointIdSelected = null;
      state.parentAnalyticsId = null;
      state.analyticId = null;
      state.pointCenters = [];
      state.pointCenterSelect = null;
      state.polygonLandUseAnalytics = [];
      state.isDisplayBoundaryLine = false;
      state.isShowAllLayer = true;
      state.isFill = true;
      state.ratersSelected = [];
      state.isLayer3D = true;
      state.isShowLayerRaster = true;
      state.layerTypeList = [];
      state.layerTypeCurrentList = [];
      state.sizeCropCoverageList = [];
      state.sizeWeedInvasionList = [];
      state.sizeWeedInvasionSelected = undefined;
      state.sizeCropCoverageSelected = undefined;
      state.dataCropCoverage = [];
      state.dataWeedInvasion = [];
      state.dataStandCount = [];
      state.dataCircumference = [];
      state.pointDataLandUse = [];
      state.isShowPointDataLandUse = false;
      state.dateAnalyticSelected = null;
      state.colorBorderPlantation = '#1EBADB';
      state.isDefaultInterval = true;
      state.selectedLevelHierarchyList = cropTreeLevelSearch(action.payload.levels, action.payload.levelId)
    },
    changePointIdSelected: (state, action: PayloadAction<string | null>) => {
      state.pointIdSelected = action.payload;
    },
    changeCropTypeLevelId: (
      state,
      action: PayloadAction<{ cropType: any | null; levelId: string; isLastLevel: boolean }>
    ) => {
      state.cropType = action.payload.cropType;
      state.levelId = action.payload.levelId;
      state.isLastLevelId = action.payload.isLastLevel;
      state.pointIdSelected = null;
      state.parentAnalyticsId = null;
      state.analyticId = null;
      state.pointCenters = [];
      state.pointCenterSelect = null;
      state.polygonLandUseAnalytics = [];
      state.dataCropCoverage = [];
      state.dataStandCount = [];
      state.dataCircumference = [];
      state.ratersSelected = [];
      state.layerTypeList = [];
      state.layerTypeCurrentList = [];
      state.dateAnalyticSelected = null;
      state.isDefaultInterval = true;
    },
    changeParentAnalyticsId: (state, action: PayloadAction<{ parentId: string | null; analyticId: string | null }>) => {
      state.parentAnalyticsId = action.payload.parentId;
      state.analyticId = action.payload.analyticId;
      state.polygonLandUseAnalytics = [];
      state.dataCropCoverage = [];
      state.dataStandCount = [];
      state.dataCircumference = [];
      state.isDisplayBoundaryLine = false;
      state.isLayer3D = true;
      state.isDefaultInterval = true;
    },
    changePointCenters: (state, action: PayloadAction<{ longitude: number; latitude: number; id: string }[]>) => {
      state.pointCenters = action.payload;
      state.pointCenterSelect = null;
    },
    changePointCenterSelected: (state, action: PayloadAction<string>) => {
      state.pointCenterSelect = action.payload;
    },
    changePolygonAnalytics: (state, action: PayloadAction<any>) => {
      state.polygonLandUseAnalytics = action.payload as Array<GeoJSONObject>;
    },
    changeVisibleLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.polygonLandUseAnalytics.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.polygonLandUseAnalytics.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.polygonLandUseAnalytics = update(state.polygonLandUseAnalytics, {
        [indexPolygon]: {
          visible: { $set: !state.polygonLandUseAnalytics[indexPolygon].visible },
        },
      });
    },

    changeVisibleAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.polygonLandUseAnalytics = update(state.polygonLandUseAnalytics, {
        $apply: (polygonLandUseAnalytics: Array<any>) =>
          polygonLandUseAnalytics.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleCropCoverLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataCropCoverage.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataCropCoverage.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataCropCoverage = update(state.dataCropCoverage, {
        [indexPolygon]: {
          visible: { $set: !state.dataCropCoverage[indexPolygon].visible },
        },
      });
    },

    changeVisibleWeedInvasionLayer: (state, action: PayloadAction<any>) => {
      const indexPolygon = state.dataWeedInvasion.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataWeedInvasion.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataWeedInvasion = update(state.dataWeedInvasion, {
        [indexPolygon]: {
          visible: { $set: !state.dataWeedInvasion[indexPolygon].visible },
        },
      });
    },

    changeVisiblePlantHealthLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataPlantHealth.findIndex((_item) => _item._id === action.payload);
      state.isShowAllLayer = state.dataPlantHealth.every((_item, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataPlantHealth = update(state.dataPlantHealth, {
        [indexPolygon]: {
          visible: { $set: !state.dataPlantHealth[indexPolygon].visible },
        },
      });
    },

    changeVisibleTilerDensityLayer: (state, action: PayloadAction<any>) => {
      const indexPolygon = state.dataTilerDensity.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataTilerDensity.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataTilerDensity = update(state.dataTilerDensity, {
        [indexPolygon]: {
          visible: { $set: !state.dataTilerDensity[indexPolygon].visible },
        },
      });
    },

    changeVisibleVacantAreaLayer: (state, action: PayloadAction<any>) => {
      const indexPolygon = state.dataVacantArea.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataVacantArea.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataVacantArea = update(state.dataVacantArea, {
        [indexPolygon]: {
          visible: { $set: !state.dataVacantArea[indexPolygon].visible },
        },
      });
    },

    changeVisibleChlorophyllPaddyLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataChlorophyllPaddy.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataChlorophyllPaddy.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataChlorophyllPaddy = update(state.dataChlorophyllPaddy, {
        [indexPolygon]: {
          visible: { $set: !state.dataChlorophyllPaddy[indexPolygon].visible },
        },
      });
    },

    changeVisibleStressFieldLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataStressField.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataStressField.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataStressField = update(state.dataStressField, {
        [indexPolygon]: {
          visible: { $set: !state.dataStressField[indexPolygon].visible },
        },
      });
    },

    changeVisibleWaterUptakeFieldLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataWaterUptakeField.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataWaterUptakeField.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataWaterUptakeField = update(state.dataWaterUptakeField, {
        [indexPolygon]: {
          visible: { $set: !state.dataWaterUptakeField[indexPolygon].visible },
        },
      });
    },

    changeVisibleSoilSaturationFieldLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataSoilSaturationField.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataSoilSaturationField.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataSoilSaturationField = update(state.dataSoilSaturationField, {
        [indexPolygon]: {
          visible: { $set: !state.dataSoilSaturationField[indexPolygon].visible },
        },
      });
    },

    changeVisibleSoilErosionFieldLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataSoilErosionField.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataSoilErosionField.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataSoilErosionField = update(state.dataSoilErosionField, {
        [indexPolygon]: {
          visible: { $set: !state.dataSoilErosionField[indexPolygon].visible },
        },
      });
    },

    changeVisibleLeafPhenologyFieldLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataLeafPhenologyField.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataLeafPhenologyField.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataLeafPhenologyField = update(state.dataLeafPhenologyField, {
        [indexPolygon]: {
          visible: { $set: !state.dataLeafPhenologyField[indexPolygon].visible },
        },
      });
    },

    changeVisibleSlopeFieldLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataSlopeField.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataSlopeField.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataSlopeField = update(state.dataSlopeField, {
        [indexPolygon]: {
          visible: { $set: !state.dataSlopeField[indexPolygon].visible },
        },
      });
    },

    changeVisibleVigorPaddyLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataVigorPaddy.findIndex((_item: any) => _item._id === action.payload);
      state.isShowAllLayer = state.dataVigorPaddy.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataVigorPaddy = update(state.dataVigorPaddy, {
        [indexPolygon]: {
          visible: { $set: !state.dataVigorPaddy[indexPolygon].visible },
        },
      });
    },

    changeVisibleCropCoverAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataCropCoverage = update(state.dataCropCoverage, {
        $apply: (dataCropCoverage: Array<any>) =>
          dataCropCoverage.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleWeedInvasionAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataWeedInvasion = update(state.dataWeedInvasion, {
        $apply: (dataWeedInvasion: Array<any>) =>
          dataWeedInvasion.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisiblePlantHealthAllLayers: (state, action: PayloadAction<boolean>) => {
      console.log('insoddee');

      state.isShowAllLayer = action.payload;
      state.dataPlantHealth = update(state.dataPlantHealth, {
        $apply: (dataPlantHealth: IPlantHealthAnalysis[]) =>
          dataPlantHealth.map((item) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleTilerDensityAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataTilerDensity = update(state.dataTilerDensity, {
        $apply: (dataTilerDensity: Array<any>) =>
          dataTilerDensity.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleVacantAreaAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataVacantArea = update(state.dataVacantArea, {
        $apply: (dataVacantArea: Array<any>) =>
          dataVacantArea.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleChlorophyllPaddyAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataChlorophyllPaddy = update(state.dataChlorophyllPaddy, {
        $apply: (dataChlorophyllPaddy: Array<any>) =>
          dataChlorophyllPaddy.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleStressFieldAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataStressField = update(state.dataStressField, {
        $apply: (dataStressField: Array<any>) =>
          dataStressField.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleWaterUptakeFieldAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataWaterUptakeField = update(state.dataWaterUptakeField, {
        $apply: (dataWaterUptakeField: Array<any>) =>
          dataWaterUptakeField.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleSoilSaturationFieldAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataSoilSaturationField = update(state.dataSoilSaturationField, {
        $apply: (dataSoilSaturationField: Array<any>) =>
          dataSoilSaturationField.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleSoilErosionFieldAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataSoilErosionField = update(state.dataSoilErosionField, {
        $apply: (dataSoilErosionField: Array<any>) =>
          dataSoilErosionField.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleLeafPhenologyFieldAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataLeafPhenologyField = update(state.dataLeafPhenologyField, {
        $apply: (dataLeafPhenologyField: Array<any>) =>
          dataLeafPhenologyField.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleSlopeFieldAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataSlopeField = update(state.dataSlopeField, {
        $apply: (dataSlopeField: Array<any>) =>
          dataSlopeField.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleVigorPaddyAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataVigorPaddy = update(state.dataVigorPaddy, {
        $apply: (dataVigorPaddy: Array<any>) =>
          dataVigorPaddy.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleStandCountLayer: (state, action: PayloadAction<string>) => {
      const indexPolygon = state.dataStandCount.findIndex((_item: any) => _item.status === action.payload);
      state.isShowAllLayer = state.dataStandCount.every((_item: any, index: number) => {
        if (indexPolygon === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataStandCount = update(state.dataStandCount, {
        [indexPolygon]: {
          visible: { $set: !state.dataStandCount[indexPolygon].visible },
        },
      });
    },

    changeVisibleStandCountAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataStandCount = update(state.dataStandCount, {
        $apply: (dataStandCount: Array<any>) =>
          dataStandCount.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleIntervalAllLayers: (state, action: PayloadAction<boolean>) => {
      state.isShowAllLayer = action.payload;
      state.dataInterval.range = update(state.dataInterval.range, {
        $apply: (intervalRange: Array<any>) =>
          intervalRange.map((item: any) => {
            return {
              ...item,
              visible: action.payload,
            };
          }),
      });
    },

    changeVisibleInterval: (state, action: PayloadAction<number>) => {
      state.isShowAllLayer = state.dataInterval.range.every((_item: any, index: number) => {
        if (action.payload === index) {
          return !_item.visible;
        }
        return _item.visible;
      });
      state.dataInterval.range = update(state.dataInterval.range, {
        [action.payload]: {
          visible: { $set: !state.dataInterval.range[action.payload].visible },
        },
      });
    },

    changeLayer3D: (state, action: PayloadAction<boolean>) => {
      state.isLayer3D = action.payload;
    },
    changeDateAnalyticSelected: (state, action: PayloadAction<string | null>) => {
      state.dateAnalyticSelected = action.payload;
    },
    changeIsDisplayBoundary: (state, action: PayloadAction<boolean>) => {
      state.isDisplayBoundaryLine = action.payload;
    },

    changeIsDisplay3DCyliner: (state, action: PayloadAction<boolean>) => {
      state.isDisplay3DCylinder = action.payload;
    },

    changeRastersSelected: (state, action: PayloadAction<Array<{ _id: string; name: string }>>) => {
      state.ratersSelected = action.payload;
    },
    changeSideSurfaceOpacity: (state, action: PayloadAction<number>) => {
      state.sideSurfaceOpacity = action.payload;
    },
    changeColorSideSurface: (state, action: PayloadAction<string>) => {
      state.colorSideSurface = action.payload;
    },
    changeIsSameSideSurface: (state, action: PayloadAction<boolean>) => {
      state.isSameSideSurface = action.payload;
    },
    changeTopSurfaceOpacity: (state, action: PayloadAction<number>) => {
      state.topSurfaceOpacity = action.payload;
    },

    changePointDataLandUse: (state, action: PayloadAction<Array<Point>>) => {
      state.pointDataLandUse = action.payload;
    },

    selectRasterLayer: (state, action: PayloadAction<Array<RasterLayerDTO> | RasterLayerDTO>) => {
      if (isArray(action.payload)) {
        state.layerTypeList = action.payload;
        return;
      }
      const payload = action.payload as RasterLayerDTO;
      if (state.layerTypeList.some((layerType) => layerType._id === payload._id)) {
        state.layerTypeList = state.layerTypeList.filter((layerType) => layerType._id !== payload._id);
        return;
      }
      state.layerTypeList = [...state.layerTypeList, payload];
    },

    unSelectRasterLayer: (state, action: PayloadAction<RasterLayerDTO>) => {
      const payload = action.payload;
      state.layerTypeList = state.layerTypeList.filter((layerType) => layerType._id !== payload._id);
    },

    changeSizeWeedInvasionList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeWeedInvasionList = action.payload;

      if (!state.sizeWeedInvasionSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeWeedInvasionSelected = action.payload[indexMax];
      }
    },

    changeSizeWeedInvasionSelected: (state, action: PayloadAction<number>) => {
      state.sizeWeedInvasionSelected = action.payload;
    },

    setDataWeedInvasion: (state, action: PayloadAction<Array<any>>) => {
      state.dataWeedInvasion = action.payload;
    },

    changeSizeCropCoverageList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeCropCoverageList = action.payload;

      if (!state.sizeCropCoverageSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeCropCoverageSelected = action.payload[indexMax];
      }
    },
    changeSizePlantHealthList: (state, action: PayloadAction<Array<number>>) => {
      state.sizePlantHealthList = action.payload;

      if (!state.sizePlantHealthSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizePlantHealthSelected = action.payload[indexMax];
      }
    },

    changeSizeTilerDensityList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeTilerDensityList = action.payload;

      if (!state.sizeTilerDensitySelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeTilerDensitySelected = action.payload[indexMax];
      }
    },

    changeSizeVacantAreaList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeVacantAreaList = action.payload;

      if (!state.sizeVacantAreaSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeVacantAreaSelected = action.payload[indexMax];
      }
    },

    changeSizeChlorophyllPaddyList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeChlorophyllPaddyList = action.payload;

      if (!state.sizeChlorophyllPaddySelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeChlorophyllPaddySelected = action.payload[indexMax];
      }
    },

    changeSizeStressFieldList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeStressFieldList = action.payload;

      if (!state.sizeStressFieldSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeStressFieldSelected = action.payload[indexMax];
      }
    },

    changeSizeWaterUptakeFieldList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeWaterUptakeFieldList = action.payload;

      if (!state.sizeWaterUptakeFieldSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeWaterUptakeFieldSelected = action.payload[indexMax];
      }
    },

    changeSizeSoilSaturationFieldList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeSoilSaturationFieldList = action.payload;

      if (!state.sizeSoilSaturationFieldSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeSoilSaturationFieldSelected = action.payload[indexMax];
      }
    },

    changeSizeSoilErosionFieldList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeSoilErosionFieldList = action.payload;

      if (!state.sizeSoilErosionFieldSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeSoilErosionFieldSelected = action.payload[indexMax];
      }
    },

    changeSizeLeafPhenologyFieldList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeLeafPhenologyFieldList = action.payload;

      if (!state.sizeLeafPhenologyFieldSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeLeafPhenologyFieldSelected = action.payload[indexMax];
      }
    },

    changeSizeSlopeFieldList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeSlopeFieldList = action.payload;

      if (!state.sizeSlopeFieldSelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeSlopeFieldSelected = action.payload[indexMax];
      }
    },

    changeSizeVigorPaddyList: (state, action: PayloadAction<Array<number>>) => {
      state.sizeVigorPaddyList = action.payload;

      if (!state.sizeVigorPaddySelected) {
        const max = Math.max(...action.payload);
        const indexMax = action.payload.indexOf(max);
        state.sizeVigorPaddySelected = action.payload[indexMax];
      }
    },

    changeSizeCropCoverageSelected: (state, action: PayloadAction<number>) => {
      state.sizeCropCoverageSelected = action.payload;
    },

    changeSizePlantHealthSelected: (state, action: PayloadAction<number>) => {
      state.sizePlantHealthSelected = action.payload;
    },

    changeSizeTilerDensitySelected: (state, action: PayloadAction<number>) => {
      state.sizeTilerDensitySelected = action.payload;
    },

    changeSizeVacantAreaSelected: (state, action: PayloadAction<number>) => {
      state.sizeVacantAreaSelected = action.payload;
    },

    changeSizeChlorophyllPaddySelected: (state, action: PayloadAction<number>) => {
      state.sizeChlorophyllPaddySelected = action.payload;
    },

    changeSizeStressFieldSelected: (state, action: PayloadAction<number>) => {
      state.sizeStressFieldSelected = action.payload;
    },

    changeSizeWaterUptakeFieldSelected: (state, action: PayloadAction<number>) => {
      state.sizeWaterUptakeFieldSelected = action.payload;
    },

    changeSizeSoilSaturationFieldSelected: (state, action: PayloadAction<number>) => {
      state.sizeSoilSaturationFieldSelected = action.payload;
    },

    changeSizeSoilErosionFieldSelected: (state, action: PayloadAction<number>) => {
      state.sizeSoilErosionFieldSelected = action.payload;
    },

    changeSizeLeafPhenologyFieldSelected: (state, action: PayloadAction<number>) => {
      state.sizeLeafPhenologyFieldSelected = action.payload;
    },

    changeSizeSlopeFieldSelected: (state, action: PayloadAction<number>) => {
      state.sizeSlopeFieldSelected = action.payload;
    },

    changeSizeVigorPaddySelected: (state, action: PayloadAction<number>) => {
      state.sizeVigorPaddySelected = action.payload;
    },

    setDataCropCoverage: (state, action: PayloadAction<any>) => {
      state.dataCropCoverage = action.payload;
    },

    setDataPlantHealth: (state, action: PayloadAction<any>) => {
      state.dataPlantHealth = action.payload;
    },

    setDataTilerDensity: (state, action: PayloadAction<any>) => {
      state.dataTilerDensity = action.payload;
    },

    setDataVacantAreaSpot: (state, action: PayloadAction<any>) => {
      state.dataVacantAreaSpot = action.payload;
    },

    setDataVacantArea: (state, action: PayloadAction<any>) => {
      state.dataVacantArea = action.payload;
    },

    setDataChlorophyllPaddy: (state, action: PayloadAction<any>) => {
      state.dataChlorophyllPaddy = action.payload;
    },

    setDataStressField: (state, action: PayloadAction<any>) => {
      state.dataStressField = action.payload;
    },

    setDataWaterUptakeField: (state, action: PayloadAction<any>) => {
      state.dataWaterUptakeField = action.payload;
    },

    setDataSoilSaturationField: (state, action: PayloadAction<any>) => {
      state.dataSoilSaturationField = action.payload;
    },

    setDataSoilErosionField: (state, action: PayloadAction<any>) => {
      state.dataSoilErosionField = action.payload;
    },

    setDataLeafPhenologyField: (state, action: PayloadAction<any>) => {
      state.dataLeafPhenologyField = action.payload;
    },

    setDataSlopeField: (state, action: PayloadAction<any>) => {
      state.dataSlopeField = action.payload;
    },

    setDataVigorPaddy: (state, action: PayloadAction<any>) => {
      state.dataVigorPaddy = action.payload;
    },

    setDataStandCount: (state, action: PayloadAction<any>) => {
      state.dataStandCount = action.payload;
    },

    setDataCircumference: (state, action: PayloadAction<any>) => {
      state.dataCircumference = action.payload;
    },

    setdataInterval: (state, action: PayloadAction<any>) => {
      state.dataInterval = action.payload;
    },

    changeVisiblePointDataLandUse: (state, action: PayloadAction<boolean>) => {
      state.isShowPointDataLandUse = action.payload;
    },

    changeColorAnalyticLayers: (
      state,
      action: PayloadAction<{ id: string; color: string; typeOfAnalytic: string }>
    ) => {
      const stateKey = getStateAnalytics(action.payload.typeOfAnalytic);
      const idKey = action.payload.typeOfAnalytic === TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT ? 'status' : '_id';
      const indexPolygon = state[stateKey!].findIndex((_item: any) => _item[idKey] === action.payload.id);
      state[stateKey!] = update(state[stateKey!], {
        [indexPolygon]: {
          color: { $set: action.payload.color },
        },
      });
    },

    changeSameColorTopSurface: (state, action: PayloadAction<boolean>) => {
      state.isSameColorTopSurface = action.payload;
    },
    changePlantationDataCropCoverage: (state, action: PayloadAction<any>) => {
      state.plantationCropCoverage = action.payload;
    },
    changeColorBorderPlantation: (state, action: PayloadAction<string>) => {
      state.colorBorderPlantation = action.payload;
    },
    setLayerTypeCurrentList: (state, action: PayloadAction<Array<RasterLayerDTO>>) => {
      state.layerTypeCurrentList = action.payload;
    },
    changeIsDefaultInterval: (state, action: PayloadAction<boolean>) => {
      state.isDefaultInterval = action.payload;
    },
    changeIsShowAllInfoTooltip: (state, action: PayloadAction<boolean>) => {
      state.isShowAllInfoTooltip = action.payload;
    },
    changeDataIntervalAllLimit: (state, action: PayloadAction<any>) => {
      state.dataIntervalAllLimit = action.payload;
    },
    changeDrawerShow: (state, action: PayloadAction<{ leftBar?: boolean; rightBar?: boolean }>) => {
      state.drawerShow = { ...state.drawerShow, ...action.payload };
    },
    changeMapViewState: (state, action: PayloadAction<{ longitude: number; latitude: number; zoom: number }>) => {
      state.mapViewState = action.payload;
    },
    clearData: (state) => {
      state.dataVacantArea = undefined;
      state.dataChlorophyllPaddy = undefined;
      state.dataPlantHealth = [];
      state.dataWeedInvasion = undefined;
      state.dataTilerDensity = undefined;
      state.dataVacantAreaSpot = undefined;
      state.dataVigorPaddy = undefined;
      state.dataStressField = undefined;
      state.dataWaterUptakeField = undefined;
      state.dataSoilSaturationField = undefined;
      state.dataSoilErosionField = undefined;
      state.dataLeafPhenologyField = undefined;
      state.dataSlopeField = undefined;
      state.sizeTilerDensityList = [];
      state.sizeTilerDensitySelected = undefined;
      state.sizeVacantAreaList = [];
      state.sizeVacantAreaSelected = undefined;
      state.sizeChlorophyllPaddyList = [];
      state.sizeStressFieldSelected = undefined;
      state.sizeStressFieldList = [];
      state.sizeWaterUptakeFieldSelected = undefined;
      state.sizeWaterUptakeFieldList = [];
      state.sizeSoilSaturationFieldSelected = undefined;
      state.sizeSoilSaturationFieldList = [];
      state.sizeSoilErosionFieldSelected = undefined;
      state.sizeSoilErosionFieldList = [];
      state.sizeLeafPhenologyFieldSelected = undefined;
      state.sizeLeafPhenologyFieldList = [];
      state.sizeSlopeFieldSelected = undefined;
      state.sizeSlopeFieldList = [];
      state.sizeChlorophyllPaddySelected = undefined;
      state.sizePlantHealthList = [];
      state.sizePlantHealthSelected = undefined;
      state.sizeVacantAreaList = [];
      state.sizeVacantAreaSelected = undefined;
      state.sizeVigorPaddyList = [];
      state.sizeVigorPaddySelected = undefined;
    },
    onMapViewReset: (state) => {
      state.resetMapViewTrigger = !state.resetMapViewTrigger
    },
    setDefaultMapView: (state, action: PayloadAction<{ longitude: number; latitude: number; zoom: number }>) => {
      state.defaultMapViewData.latitude = action.payload.latitude;
      state.defaultMapViewData.longitude = action.payload.longitude;
      state.defaultMapViewData.zoom = action.payload.zoom;
    }
  },
});

export const {
  changeLevelId,
  changeCropType,
  changePointIdSelected,
  changeCropTypeLevelId,
  changeParentAnalyticsId,
  changePointCenters,
  changePointCenterSelected,
  changePolygonAnalytics,
  changeVisibleLayer,
  changeLayer3D,
  changeVisibleAllLayers,
  changeDateAnalyticSelected,
  changeIsDisplayBoundary,
  changeRastersSelected,
  changeSideSurfaceOpacity,
  changeColorSideSurface,
  changeIsSameSideSurface,
  changeTopSurfaceOpacity,
  selectRasterLayer,
  changeSizeCropCoverageList,
  changeSizeWeedInvasionList,
  setDataCropCoverage,
  setDataPlantHealth,
  setDataTilerDensity,
  setDataVacantAreaSpot,
  setDataVacantArea,
  setDataChlorophyllPaddy,
  setDataStressField,
  setDataWaterUptakeField,
  setDataSoilSaturationField,
  setDataSoilErosionField,
  setDataLeafPhenologyField,
  setDataSlopeField,
  setDataWeedInvasion,
  setDataVigorPaddy,
  setDataStandCount,
  setDataCircumference,
  setdataInterval,
  changeVisibleIntervalAllLayers,
  changeVisibleInterval,
  changeVisibleCropCoverLayer,
  changeVisiblePlantHealthLayer,
  changeVisibleTilerDensityLayer,
  changeVisibleVacantAreaLayer,
  changeVisibleChlorophyllPaddyLayer,
  changeVisibleStressFieldLayer,
  changeVisibleWaterUptakeFieldLayer,
  changeVisibleSoilSaturationFieldLayer,
  changeVisibleSoilErosionFieldLayer,
  changeVisibleLeafPhenologyFieldLayer,
  changeVisibleSlopeFieldLayer,
  changeVisibleVigorPaddyLayer,
  changeVisibleCropCoverAllLayers,
  changeVisiblePlantHealthAllLayers,
  changeVisibleTilerDensityAllLayers,
  changeVisibleVacantAreaAllLayers,
  changeVisibleChlorophyllPaddyAllLayers,
  changeVisibleStressFieldAllLayers,
  changeVisibleWaterUptakeFieldAllLayers,
  changeVisibleSoilSaturationFieldAllLayers,
  changeVisibleSoilErosionFieldAllLayers,
  changeVisibleLeafPhenologyFieldAllLayers,
  changeVisibleSlopeFieldAllLayers,
  changeVisibleWeedInvasionLayer,
  changeVisibleWeedInvasionAllLayers,
  changeVisibleVigorPaddyAllLayers,
  changeVisibleStandCountLayer,
  changeVisibleStandCountAllLayers,
  changeSizeCropCoverageSelected,
  changeSizePlantHealthSelected,
  changeSizeTilerDensitySelected,
  changeSizeVacantAreaSelected,
  changeSizeChlorophyllPaddySelected,
  changeSizeStressFieldSelected,
  changeSizeWaterUptakeFieldSelected,
  changeSizeSoilSaturationFieldSelected,
  changeSizeSoilErosionFieldSelected,
  changeSizeLeafPhenologyFieldSelected,
  changeSizeSlopeFieldSelected,
  changeSizeWeedInvasionSelected,
  changeSizeVigorPaddySelected,
  changePointDataLandUse,
  changeVisiblePointDataLandUse,
  changeColorAnalyticLayers,
  changeSameColorTopSurface,
  changePlantationDataCropCoverage,
  changeColorBorderPlantation,
  unSelectRasterLayer,
  changeIsDisplay3DCyliner,
  clearState,
  setLayerTypeCurrentList,
  changeIsDefaultInterval,
  changeIsShowAllInfoTooltip,
  changeDataIntervalAllLimit,
  changeSizePlantHealthList,
  changeSizeTilerDensityList,
  changeSizeVacantAreaList,
  changeSizeChlorophyllPaddyList,
  changeSizeStressFieldList,
  changeSizeWaterUptakeFieldList,
  changeSizeSoilSaturationFieldList,
  changeSizeSoilErosionFieldList,
  changeSizeLeafPhenologyFieldList,
  changeSizeSlopeFieldList,
  changeSizeVigorPaddyList,
  changeDrawerShow,
  changeMapViewState,
  clearData,
  onMapViewReset,
  setDefaultMapView
} = mapViewSlice.actions;

export const mapViewSelector = (state: RootState) => state.mapView;

export default mapViewSlice.reducer;
