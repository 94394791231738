import { Box, CircularProgress, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import { FC, useEffect } from 'react';

interface StatusCheckingModalProps {
  isShow: boolean;
  handleClose: () => void;
  setIsShowAddFieldModal: (arg: boolean) => void;
}

const StatusCheckingModal: FC<StatusCheckingModalProps> = ({ isShow, handleClose, setIsShowAddFieldModal }) => {
  const { fieldDetail } = useQueryFieldDetail();

  useEffect(() => {
    if (fieldDetail) {
      setTimeout(() => {
        handleClose();
        if (!fieldDetail.status) {
          setIsShowAddFieldModal(true);
        }
      }, 2000);
    }
  }, [fieldDetail, handleClose, setIsShowAddFieldModal]);

  return (
    <CommonModal isShow={isShow} handleClose={handleClose}>
      <Box sx={{ width: '600px', p: '32px', textAlign: 'center' }}>
        <Typography sx={{ fontSize: '18px', mb: '30px' }}>Checking field status</Typography>
        <CircularProgress />
      </Box>
    </CommonModal>
  );
};

export default StatusCheckingModal;
