import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser, queryMe } from 'services/clients/apiClient.services';
import IdlePopUp from './IdlePopUp';
import { useDispatch } from 'react-redux';
import { clearUserPermissionsAndUserType } from 'store/slices/superAdminSlice';

function IdleTimerContainer(props: any) {
  const navigate = useNavigate();
  const idleTimerRef = useRef(null);
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data: userData } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe(), {
    keepPreviousData: false,
  });

  const timeOutValue = useMemo(() => {
    if (!userData) return 43200000;
    const tokenExpiredIn = userData?.data?.tokenExpiredIn;
    switch (tokenExpiredIn) {
      case '':
        // 30days
        return 43200000;
      case '5m':
        return 300000;
      case '15m':
        return 900000;
      case '30m':
        return 1800000;
      case '1h':
        return 3600000;
      case '4h':
        return 14400000;
      case '12h':
        return 43200000;
      default:
        return 43200000;
    }
  }, [userData]);

  const useLogoutUser = useMutation((sessionId: string) => logoutUser(sessionId || ''), {
    onSuccess: () => {
      navigate('/login');
      dispatch(clearUserPermissionsAndUserType())
    },
    onError: (err) => {
      console.log('err', err);
    },
  });

  const handleLogout = () => {
    const sessionId = localStorage.getItem('sessionLogin');
    if (!sessionId) {
      navigate('/login');
      const theme = localStorage.getItem('theme');
      localStorage.clear();
      localStorage.setItem('theme', theme || 'light');
    } else {
      const theme = localStorage.getItem('theme');
      localStorage.clear();
      localStorage.setItem('theme', theme || 'light');
      useLogoutUser.mutate(sessionId);
    }
  };

  const handleSetLastTimeout = () => {
    const timeOutCheck = localStorage.getItem('lastTimeout');
    const currentTime = moment().unix();
    if (!timeOutCheck) {
      localStorage.setItem('lastTimeout', currentTime + '');
      return;
    }
    +currentTime - +timeOutCheck >= timeOutValue / 1000
      ? handleLogout()
      : localStorage.setItem('lastTimeout', currentTime + '');
  };

  const onIdle = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={timeOutValue - 11000}
        onIdle={onIdle}
        onAction={() => handleSetLastTimeout()}
      />
      {modalIsOpen && (
        <IdlePopUp
          handleClose={() => {
            setModalIsOpen(false);
            toast.success('You are now active again.', {
              toastId: 1,
            });
          }}
          handleLogout={handleLogout}
        />
      )}
    </>
  );
}
export default IdleTimerContainer;
