import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Grid, IconButton, Skeleton, Slider, Stack, styled, Typography, useTheme } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import ReactECharts from 'echarts-for-react';
import { get } from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ICircumferenceSetting } from './ChartsAnlytic.type';
import { IntervalSettingModal } from './IntervalSettingModal';

interface IBarChartCommon {
  title: string;
  dataChart?: any;
  intervalRange: any[];
  handleUpdateIntervals?: any;
  queryKey: string;
  getCharts: (id: string, isDefaultInterval: boolean) => Promise<AxiosResponse<any, any>>;
  dataBoxPlot?: Array<number>;
  changeChartDataPreview?: ActionCreatorWithPayload<any>;
}

const ChartsAnalytics = ({
  title,
  dataChart,
  intervalRange,
  handleUpdateIntervals,
  queryKey,
  getCharts,
  dataBoxPlot = [],
  changeChartDataPreview,
}: IBarChartCommon) => {
  const [isOpenSettingModal, setIsOpenSettingModal] = useState<boolean>(false);
  let chartBarRef: any = undefined;
  let chartPieRef: any = undefined;
  const theme = useTheme();

  const form = useForm<ICircumferenceSetting>({
    defaultValues: {
      range: intervalRange,
    },
  });

  const [minSlider, maxSlider] = useMemo(() => {
    const min = get(intervalRange, '[0].from', 0);
    const max = get(intervalRange, `[${intervalRange?.length - 1}].to`, 0);
    return [min, max];
  }, [intervalRange]);

  const dataCharts = useMemo(() => {
    return dataChart.map((item: any) => ({
      name: Object.keys(item)[0],
      value: Object.values(item)[0],
      itemStyle: { color: intervalRange.find((_item: any) => _item.lable === Object.keys(item)[0])?.color },
    }));
  }, [dataChart, intervalRange]);

  const options = useMemo(() => {
    const maxValue = Math.max(...dataCharts.map((item: any) => item.value));
    const lengthMax = (maxValue.toString().length + 0.5) * 10;
    return {
      grid: {
        left: lengthMax, // Add a 10% margin to the left side of the chart
        // right: '5%', // Add a 5% margin to the right side of the chart
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          fontSize: 12, // Set the font size of the y-axis labels
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: '{b}: {c}',
        backgroundColor: theme.palette.background.paper,
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      series: [
        {
          data: dataCharts,
          emphasis: {
            focus: 'series',
          },
          type: 'bar',
          barWidth: '100%',
        },
      ],
      backgroundColor: theme.palette.background.paper,
    };
  }, [dataCharts, theme.palette]);

  const SliderCustom = useMemo(
    () =>
      styled(Slider)(({ theme }) => ({
        '& .MuiSlider-rail': {
          height: '6px',
          borderRadius: '3px',
          backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#00f',
          opacity: theme.palette.mode === 'dark' ? 1 : 0.5,
        },
        '& .MuiSlider-thumb': {
          borderColor: theme.palette.mode === 'dark' ? '#4992ff' : theme.palette.color.white1,
          backgroundColor: theme.palette.mode === 'dark' ? '#4992ff' : theme.palette.color.white1,
        },
        '& .MuiSlider-valueLabel': {
          color: theme.palette.mode === 'dark' ? '#fff' : '#757575',
          backgroundColor: 'transparent',
          fontSize: '16px',
        },
        '& .MuiSlider-valueLabelOpen': {
          transform: 'translateY(-75%) scale(1)',
        },
      })),
    []
  );

  const handleUpdateRange = (value: number[]) => {
    const dataNew = intervalRange.map((item: any, index: number) => {
      if (index === 0) {
        return {
          ...item,
          to: value[index],
        };
      } else if (index === intervalRange.length - 1) {
        return {
          ...item,
          from: value[index - 1],
          to: maxSlider,
        };
      }
      return {
        ...item,
        from: value[index - 1],
        to: value[index],
      };
    });
    handleUpdateIntervals.mutate(dataNew);
  };

  const pieChartOptions = useMemo(() => {
    return {
      legend: {
        show: false,
        orient: 'vertical',
        left: 0,
        top: 0,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {d}%',
        padding: 5,
        backgroundColor: theme.palette.background.paper,
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      backgroundColor: theme.palette.background.paper,
      series: [
        {
          name: 'Circumference',
          type: 'pie',
          radius: ['40%', '70%'],
          visibleMin: 300,
          label: {
            show: false,
            position: 'center',
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            borderColor: '#fff',
          },
          levels: [
            {
              itemStyle: {
                borderColor: '#777',
                borderWidth: 0,
                gapWidth: 1,
              },
              upperLabel: {
                show: false,
              },
            },
            {
              itemStyle: {
                borderColor: '#555',
                borderWidth: 5,
                gapWidth: 1,
              },
              emphasis: {
                itemStyle: {
                  borderColor: '#ddd',
                },
              },
            },
            {
              colorSaturation: [0.35, 0.5],
              itemStyle: {
                borderWidth: 5,
                gapWidth: 1,
                borderColorSaturation: 0.6,
              },
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          data: dataCharts,
        },
      ],
    };
  }, [dataCharts, theme.palette]);

  useEffect(() => {
    if (!chartBarRef || !chartPieRef) {
      return;
    }
    chartBarRef.getEchartsInstance().group = 'group1';
    chartPieRef.getEchartsInstance().group = 'group1';
  }, [chartBarRef, chartPieRef]);

  const findMedian = (data: any) => {
    data.sort((a: any, b: any) => a - b);
    let mid = Math.floor(data.length / 2);
    return data.length % 2 ? data[mid] : (data[mid - 1] + data[mid]) / 2.0;
  };

  const findQuartiles = (data: any) => {
    data.sort((a: any, b: any) => a - b);
    let mid = Math.floor(data.length / 2);
    let even = data.length % 2 === 0;

    let lower = data.filter((_: any, i: any) => i < mid);
    let higher = data.filter((_: any, i: any) => (even ? i >= mid : i > mid));

    return [findMedian(lower), findMedian(higher)];
  };

  const boxPlotOptions = useMemo(() => {
    if (!dataBoxPlot) return {};
    let [lowerQ, upperQ] = findQuartiles(dataBoxPlot);
    let [max, min] = [Math.max(...dataBoxPlot), Math.min(...dataBoxPlot)];
    let median = findMedian(dataBoxPlot);
    let iqr = upperQ - lowerQ;
    let minimum = lowerQ - 1.5 * iqr;
    let maximum = upperQ + 1.5 * iqr;
    return {
      title: [
        {
          text: '',
          left: 'center',
        },
        {
          text: '',
          borderColor: '#999',
          borderWidth: 1,
          textStyle: {
            fontSize: 14,
          },
          left: '10%',
          top: '90%',
          show: false,
        },
      ],
      dataset: [
        {
          source: [dataBoxPlot],
        },
        {
          transform: {
            type: 'boxplot',
            config: {
              itemNameFormatter: function (params: any) {},
            },
          },
        },
        {
          fromDatasetIndex: 1,
          fromTransformResult: 1,
        },
        {
          source: dataBoxPlot,
          id: 'dataDetail',
        },
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: theme.palette.background.paper,
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
        position: (pos: any, params: any, el: any, elRect: any, size: any) => {
          var obj: any = { top: 60 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 36;
          return obj;
        },
      },
      backgroundColor: theme.palette.background.paper,
      grid: {
        left: '10%',
        right: '10%',
        bottom: '20%',
      },
      yAxis: {
        type: 'category',
        boundaryGap: true,
        nameGap: 30,
        splitArea: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          show: true,
          symbol: ['none', 'arrow'],
          symbolSize: [7, 10],
        },
      },
      xAxis: {
        type: 'value',
        name: '',
        splitArea: {
          show: true,
        },
        min: min,
        axisLine: {
          show: true,
          symbol: ['none', 'arrow'],
          symbolSize: [7, 10],
        },
      },
      dataZoom: [
        {
          type: 'inside',
        },
        {
          type: 'slider',
          height: 20,
        },
      ],
      legend: {
        selected: { detail: false },
      },
      series: [
        {
          name: 'boxplot',
          type: 'boxplot',
          datasetIndex: 1,
          tooltip: {
            formatter: function (param: any) {
              return [
                'Min: ' + minimum + '<br/>',
                'Q1: ' + lowerQ + '<br/>',
                'Median: ' + median + '<br/>',
                'Q3: ' + upperQ + '<br/>',
                'Max: ' + maximum + '<br/>',
              ].join('');
            },
            position: ['25%', '0%'],
            backgroundColor: theme.palette.background.paper,
            textStyle: {
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            },
          },
        },
        {
          name: 'detail',
          type: 'scatter',
          data: dataBoxPlot.map((item: number) => [item, undefined]),
          datasetIndex: 1,
          color: 'green',
        },
        {
          name: 'detail',
          type: 'scatter',
          encode: { x: 1, y: 0 },
          datasetIndex: 2,
          color: '#fc0505',
          position: ['25%', '0%'],
        },
      ],
    };
  }, [dataBoxPlot, findQuartiles]);

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
      }}>
      <Box
        sx={{
          border: (theme) => (theme.palette.mode === 'dark' ? 'none' : `1px solid ${theme.palette.divider}`),
          boxShadow: (theme) => (theme.palette.mode === 'dark' ? 'none' : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'),
        }}>
        <Grid container direction="row" sx={{ px: 2.5, display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#FFFFFF' : '#3C4043'),
              alignSelf: 'center',
              px: 3,
            }}>
            {title}
          </Typography>
          <Box sx={{ pr: 2.5, display: 'flex' }}>
            <IconButton
              aria-label="setting"
              onClick={() => {
                setIsOpenSettingModal(true);
              }}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </Grid>
        <Stack sx={{ px: 4, mb: '15px', zIndex: 1000 }} direction="column" spacing={3}>
          {dataBoxPlot.length === 0 ? (
            <Skeleton variant="rectangular" height={200} animation="wave" />
          ) : (
            <ReactECharts
              ref={(e) => {
                chartBarRef = e;
              }}
              option={boxPlotOptions}
              style={{
                height: `250px`,
                width: '100%',
                borderRadius: '5px',
                zIndex: 1000,
              }}
              theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
            />
          )}
        </Stack>
        <Stack sx={{ px: 2.5 }} direction="column" spacing={3}>
          <ReactECharts
            ref={(e) => {
              chartBarRef = e;
            }}
            option={options}
            style={{ height: `400px`, width: '100%', marginTop: '-40px' }}
            theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
          />
        </Stack>
        <Stack sx={{ px: 4, pb: '15px' }} direction="column" spacing={3}>
          <SliderCustom
            min={minSlider}
            max={maxSlider}
            step={0.001}
            track={false}
            aria-labelledby="track-false-range-slider"
            value={intervalRange.filter((_, index) => index !== intervalRange.length - 1).map((_item: any) => _item.to)}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => value.toFixed(3)}
            onChangeCommitted={(e, value) => handleUpdateRange(value as number[])}
          />
        </Stack>
        {isOpenSettingModal && (
          <IntervalSettingModal
            open={isOpenSettingModal}
            onClose={() => {
              setIsOpenSettingModal(false);
            }}
            getCharts={getCharts}
            form={form}
            queryKey={queryKey}
            title={title}
            changeChartDataPreview={changeChartDataPreview}
          />
        )}
      </Box>
      <Box
        sx={{
          border: (theme) => (theme.palette.mode === 'dark' ? 'none' : `1px solid ${theme.palette.divider}`),
          boxShadow: (theme) => (theme.palette.mode === 'dark' ? 'none' : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'),
        }}>
        <Grid container direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#FFFFFF' : '#3C4043'),
              alignSelf: 'center',
              px: 3,
              pt: 1,
            }}>
            {title}
          </Typography>
        </Grid>
        <Stack sx={{ px: 2.5, py: '15px', height: '100%' }} direction="row" spacing={3}>
          <ReactECharts
            ref={(e) => {
              chartPieRef = e;
            }}
            option={pieChartOptions}
            style={{ height: '350px', width: '100%' }}
            theme={theme.palette.mode === 'dark' ? 'dark' : 'light'}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default memo(ChartsAnalytics);
