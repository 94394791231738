import { Box, Button, Grid, styled, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import { getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { TabPanel } from '../TabPanel';
import { AnalyticsTab } from './AnalyticsTab';
import InformationTab from './InfomationTab';

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

const TabStyled = styled(Tab)(() => ({
  textTransform: 'unset',
  color: '#CACED3',
  fontWeight: 500,
  fontSize: '12px',
  '&.Mui-selected': {
    color: '#3C4043',
  },
}));

const tabMenu = [
  {
    id: 0,
    label: 'Analytics',
    disabled: false,
  },
  {
    id: 1,
    label: 'Information',
    disabled: false,
  },
];

export const RightBar = () => {
  const [tabNumber, setTabNumber] = useState<number>(0);
  const { clientId } = useParams();
  const { levelId, isLastLevelId, cropType } = useAppSelector(mapViewSelector);
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const dispatch = useAppDispatch();

  useAppSelector(rightBarSelector);

  useEffect(() => {
    if (tabNumber !== 2) {
      // 2 as tab "Issues"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabNumber]);

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const { data: userInfoByClient } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !userInfo?.data?.isSuperAdmin && !userInfo?.data?.isSuperUser,
    }
  );

  const flagTabData = useMemo(() => {
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser) {
      return tabMenu;
    } else {
      return userInfoByClient?.data?.flagTab;
    }
  }, [userInfo, userInfoByClient]);

  const newTabMenu = useMemo(() => {
    const newTabMenuData: any[] = [];
    if (userInfo?.data?.isSuperAdmin || userInfo?.data?.isSuperUser) {
      return flagTabData;
    } else {
      tabMenu.forEach((item: any) => {
        switch (item.label) {
          case 'Analytics':
            if (flagTabData.includes('MAPVIEW-ANALYSIS')) {
              newTabMenuData.push(item);
              return item;
            }
            break;
          case 'Information':
            if (flagTabData.includes('MAPVIEW-INFORMATION')) {
              newTabMenuData.push(item);
              return item;
            }
            break;
          default:
            break;
        }
      });
      return newTabMenuData;
    }
  }, [flagTabData, userInfo]);

  useLayoutEffect(() => {
    setTabNumber(0);
  }, [levelId]);

  const theme = useTheme();

  return (
    <Grid
      direction="column"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default),
        height: 'fit-content',
        width: '100%',
      }}>
      <Grid direction="row" width="100%" height="100%">
        <Grid direction="row">
          <Tabs
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`,
              padding: '0px',
              margin: '0px',
            }}
            value={tabNumber}
            children={newTabMenu.map((item: any, index: number) => {
              return (
                <TabStyled
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: '90px',
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                  key={item.id}
                  label={item.label}
                  {...a11yProps(item.id)}
                  onClick={() => {
                    setTabNumber(item.id);
                  }}
                  value={item.id}
                />
              );
            })}
          />
        </Grid>
        <Grid direction="row">
          <SwipeableViews index={tabNumber}>
            <TabPanel value={tabNumber} index={0}>
              <Box
                sx={{
                  width: '100%',
                  height: '85vh',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Scrollbars style={{ width: '100%', height: '100%' }} autoHide>
                  <AnalyticsTab />
                </Scrollbars>
              </Box>
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
              <InformationTab />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Grid>
  );
};
