import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { area as turfArea } from '@turf/turf';
import { BigNumber } from 'bignumber.js';
import { QUERY_KEY } from 'constants/constants';
import { useCreateTreeLevel } from 'hooks/useClientProperties';
import { isEmpty } from 'lodash';
import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getClientById,
  getLevelListByCropType,
  sendGetAllLocation,
  sendGetHierarchyStructureById,
} from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clientSelector, setLevelProperties, updateAllLocationCropType } from 'store/slices/clientSlice';
import { ClientFormState, CropArrayOptionsType, ICropTypes } from '../../common/defines/clients';
import { ClientProperties } from './ClientProperties';
import LevelTree from './LevelTree';
import { MapCropType } from './MapCropType';

type TLevelContext = {
  cropType?: ICropTypes;
  setCropType: Dispatch<SetStateAction<ICropTypes | undefined>>;
  parentId: string | null;
  setParentId: Dispatch<SetStateAction<string | null>>;
  levelId?: string;
  setLevelId: Dispatch<SetStateAction<string | undefined>>;
  refetchLevelTree: any;
};

export const LevelContext = createContext<TLevelContext>({} as TLevelContext);

const ClientAssetStructure = ({ nextStep }: ClientFormState) => {
  const { clientId: clientIdParamUrl } = useParams();
  const { clientData: clientDataHook } = useAppSelector(clientSelector);

  const clientId = clientIdParamUrl || clientDataHook?._id;

  const theme = useTheme();

  const styles = {
    labelPrimaryColor: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#616161',
    },
    labelSecondaryColor: {
      color: '#737373',
    },
    fontStyles: {
      fontFamily: 'Barlow',
    },
  };

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const mutationCreateTreeLevel = useCreateTreeLevel();

  const [cropType, setCropType] = useState<ICropTypes | undefined>();
  const [levelId, setLevelId] = useState<string | undefined>();
  const [level, setLevel] = useState<number>(0);
  const [parentId, setParentId] = useState<string | null>(null);

  const { data: clientData } = useQuery([QUERY_KEY.CLIENT_DATA, clientId], () => getClientById(clientId || ''), {
    enabled: !!clientId,
  });

  const { data: levelList, refetch: refetchLevelTree } = useQuery(
    [QUERY_KEY.LEVEL_LIST, cropType],
    () => getLevelListByCropType(clientId, cropType),
    {
      enabled: !!cropType && !!clientId,
    }
  );

  const {
    data: levelProperties,
    refetch: refetchLevelData,
    isFetching: isFetchingLevelData,
    remove: removeLevelProperties,
  } = useQuery(QUERY_KEY.LEVEL_PROPERTIES, () => sendGetHierarchyStructureById(levelId!), {
    enabled: !!levelId,
    cacheTime: Infinity,
  });

  const { data: dataParent } = useQuery(QUERY_KEY.DATA_PARENT, () => sendGetHierarchyStructureById(parentId!), {
    enabled: !!parentId,
  });

  const { data: allLocationCropType, refetch: refetchAllLocation } = useQuery(
    QUERY_KEY.ALL_LOCATION_CROP_TYPE,
    () => sendGetAllLocation(clientId || '', cropType, levelProperties?.data?.parent || 'null'),
    {
      enabled: !!levelProperties?.data,
    }
  );

  useEffect(() => {
    if (levelId) {
      refetchLevelData();
    }
  }, [levelId]);

  useEffect(() => {
    if (levelProperties?.data) {
      refetchAllLocation();
      setLevel(levelProperties?.data?.level)
    }
  }, [levelProperties]);

  useEffect(() => {
    dispatch(updateAllLocationCropType(allLocationCropType?.data));
  }, [allLocationCropType]);

  useEffect(() => {
    // set data location
    if (isFetchingLevelData) {
      return;
    }
    dispatch(
      setLevelProperties({
        location: levelProperties?.data?.location,
        properties: levelProperties?.data?.properties || [],
        personInCharge: levelProperties?.data?.personInCharge,
        area: levelProperties?.data?.area || 0,
        fileBoundaryInfo: levelProperties?.data?.boundaryHistory,
      })
    );
  }, [levelProperties, isFetchingLevelData]);

  const maxLevel = useMemo(() => {
    if (!clientData || !cropType) {
      return 0;
    }

    return clientData.numberOfLevel?.find((item: any) => item.type === cropType)?.value || 0;
  }, [clientData, cropType]);

  const handleGetCropTypeText = (id: string) => {
    const allCropTypeData = queryClient.getQueryData(QUERY_KEY.USER_SETTINGS_CROPTYPE);
    if (!allCropTypeData) return;
    const selectedCropType = (allCropTypeData as any)?.data.find((val: any) => val._id === id);
    return selectedCropType.name;
  };

  const onCreateLevel = (parent: string | null, clientIdParam: string, cropTypeParam: ICropTypes, name: string) =>
    mutationCreateTreeLevel.mutate(
      { parent, clientId: clientIdParam, cropType: cropTypeParam, name },
      {
        onSuccess: (res) => {
          refetchLevelTree();
          if (res?.data?.status === 400) {
            toast.error(res.data.response.message, { toastId: 1 });
            return;
          }
        },
      }
    );

  const cropArrayOptions = useMemo((): CropArrayOptionsType[] => {
    if (!clientData?.cropType) {
      return [];
    }
    return clientData.cropType.map((type: any) => {
      return {
        value: type._id,
        text: handleGetCropTypeText(type._id),
        level: [],
        icon: type.icon,
      };
    });
  }, [clientData]);

  const clientSizeLimit = useMemo(() => {
    if (!parentId || !dataParent?.data?.location || !allLocationCropType?.data) {
      return null;
    }
    const area = new BigNumber(turfArea(dataParent.data.location as any) || 0);
    const allLocationBro = allLocationCropType.data.filter((item: any) => item._id !== levelId);
    if (isEmpty(allLocationBro)) {
      return area.toNumber();
    }
    let sizeTotal = new BigNumber(0);
    allLocationBro.forEach((item: any) => {
      sizeTotal = new BigNumber(turfArea(item.location as any) || 0).plus(sizeTotal);
    });
    return area.minus(sizeTotal).toNumber();
  }, [dataParent, parentId, allLocationCropType, levelId]);

  const chooseCropTypeItem = useCallback(
    (type: CropArrayOptionsType) => {
      if (type.value === cropType) {
        setCropType(undefined);
      } else {
        setCropType(type.value);
      }

      setLevelId(undefined);
      removeLevelProperties();
      queryClient.setQueryData(QUERY_KEY.CLIENT_LOCATION, undefined);
    },
    [setCropType, queryClient, cropType]
  );

  useEffect(() => {
    return () => {
      removeLevelProperties();
      queryClient.setQueryData(QUERY_KEY.CLIENT_LOCATION, undefined);
    };
  }, []);

  const addNewLevelHierarchyStructure = (parent: string | null) => {
    if (cropType && clientId) {
      onCreateLevel(parent, clientId, cropType, 'Untitled');
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} columns={24}>
          <Grid item xs={24} sm={8} md={8} lg={6} xl={4}>
            <Typography
              gutterBottom
              component="div"
              sx={{
                ...styles.labelPrimaryColor,
                fontSize: '16px',
                fontWeight: '600',
                fontStyle: 'normal',
              }}>
              Asset Structure
            </Typography>
            <Typography gutterBottom component="div" variant="h5">
              Type
            </Typography>
            <Box sx={{ maxWidth: '299px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}>
                {cropArrayOptions.map((type, index) => {
                  return (
                    <Grid item xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                      <Button
                        sx={{
                          mt: 2.5,
                          minWidth: '120px',
                          height: '40px',
                          textTransform: 'none',
                          fontWeight: '400',
                          color: cropType === type.value ? (theme) => theme.palette.primary.main : '#616161',
                          border:
                            cropType === type.value
                              ? `1px solid ${theme.palette.primary.main}`
                              : `1px solid ${theme.palette.divider}`,
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '10px',
                        }}
                        key={type.value}
                        onClick={() => chooseCropTypeItem(type)}>
                        <img
                          style={{
                            width: '15px',
                            height: '15px',
                            color: '#616161',
                            borderRadius: '50%',
                            filter:
                              cropType === type.value
                                ? 'invert(56%) sepia(16%) saturate(1896%) hue-rotate(95deg) brightness(105%) contrast(88%)'
                                : 'invert(24%) sepia(7%) saturate(390%) hue-rotate(163deg) brightness(97%) contrast(96%)',
                          }}
                          src={type.icon}
                          alt="icon"
                        />
                        <Typography
                          sx={{
                            fontSize: '.875rem',
                            fontWeight: '400',
                            lineHeight: '1.4375em',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginLeft: '8px',
                          }}>
                          {type.text}
                        </Typography>
                      </Button>
                    </Grid>
                  );
                })}
              </Box>
            </Box>
            <Grid item xs={24}>
              <Typography
                component="div"
                sx={{
                  width: '100%',
                }}>
                <Button
                  endIcon={<Add sx={{ ml: 9 }} />}
                  sx={{
                    my: '24px',
                    textTransform: 'none',
                    fontFamily: 'Barlow',
                    fontSize: '14px',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#FFFFFF' : '#616161'),
                    border: (theme) => theme.palette.divider,
                  }}
                  disabled={cropType === undefined}
                  onClick={() => {
                    addNewLevelHierarchyStructure(null);
                  }}>
                  Add New Level
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={24}>
              <LevelContext.Provider
                value={{ parentId, setParentId, cropType, setCropType, refetchLevelTree, levelId, setLevelId }}>
                <Scrollbars style={{ height: 'calc(100vh - 480px)' }} autoHide>
                  <LevelTree levels={levelList || []} />
                </Scrollbars>
              </LevelContext.Provider>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: '8px',
                }}>
                <Button
                  data-testid="back-btn"
                  color="neutral"
                  variant="outlined"
                  onClick={() => {
                    nextStep && nextStep(1);
                  }}
                  sx={{
                    width: '90px',
                    marginRight: '1rem',
                    textTransform: 'none',
                  }}>
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <ClientProperties
            isLoading={isFetchingLevelData}
            nextStep={nextStep}
            levelId={levelId}
            clientSizeLimit={clientSizeLimit}
            level={level}
          />
          <Grid item xs={24} sm={8} md={8} lg={12} xl={16} sx={{ position: 'relative' }}>
            <MapCropType levelId={levelId} themeMode={theme.palette.mode} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ClientAssetStructure;
