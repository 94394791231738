import { Box, Grid, Paper, Stack, styled, Typography } from '@mui/material';
import { ANALYTICS_TABS } from 'constants/constants';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useAppDispatch } from 'store/hooks';
import { setAnalyticActiveTab } from 'store/slices/dashboardWidgetSlice';
import { useRightBarStyle } from '../RightBarStyle';
import { CostTab } from './Cost';
import { ExpenditureTab } from './Expenditure';
import { LogisticTab } from './Logistic';
import { ProductionTab } from './Production';
import { WeighbridgeTab } from './Weighbridge';

export const AnalyticsTab = () => {
  const classes = useRightBarStyle();
  const dispatch = useAppDispatch();
  const [tabType, setMyVariable] = useState(ANALYTICS_TABS.PRODUCTION);
  const AnalysisTabPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: 90,
    padding: theme.spacing(1),
    ...theme.typography.body2,
    textAlign: 'center',
    minWidth: '90px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    boxShadow: 'unset',
  }));

  const renderTab = () => {
    switch (tabType) {
      case ANALYTICS_TABS.PRODUCTION:
        return <ProductionTab />;
      case ANALYTICS_TABS.WEIGHBRIDGE:
        return <WeighbridgeTab />;
      case ANALYTICS_TABS.LOGISTIC:
        return <LogisticTab />;
      case ANALYTICS_TABS.COST:
        return <CostTab />;
      case ANALYTICS_TABS.EXPENDITURE:
        return <ExpenditureTab />;
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(setAnalyticActiveTab(tabType));
    renderTab();
  }, [tabType]);

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default),
        height: 'fit-content',
        width: '100%',
      }}>
      <Box sx={{ marginBottom: '20px' }}>
        <Stack direction="row" spacing={2}>
          <AnalysisTabPaper
            square={false}
            onClick={() => setMyVariable(ANALYTICS_TABS.PRODUCTION)}
            className={tabType === ANALYTICS_TABS.PRODUCTION ? classes.active : 'inactive'}>
            <img src="../Production.svg" alt="Production" loading="lazy" style={{ height: '25px' }} />
            <Typography variant="body2" component="p">
              Production
            </Typography>
          </AnalysisTabPaper>
          <AnalysisTabPaper
            square={false}
            onClick={() => setMyVariable(ANALYTICS_TABS.WEIGHBRIDGE)}
            className={tabType === ANALYTICS_TABS.WEIGHBRIDGE ? classes.active : 'inactive'}>
            <img src="../Weighbridge.svg" alt="Weighbridge" loading="lazy" style={{ height: '25px' }} />
            <Typography variant="body2" component="p">
              Weighbridge
            </Typography>
          </AnalysisTabPaper>
          <AnalysisTabPaper
            square={false}
            onClick={() => setMyVariable(ANALYTICS_TABS.LOGISTIC)}
            className={tabType === ANALYTICS_TABS.LOGISTIC ? classes.active : 'inactive'}>
            <img src="../Logistic.svg" alt="Logistic" loading="lazy" style={{ height: '25px' }} />
            <Typography variant="body2" component="p">
              Logistic
            </Typography>
          </AnalysisTabPaper>
          <AnalysisTabPaper
            square={false}
            onClick={() => setMyVariable(ANALYTICS_TABS.COST)}
            className={tabType === ANALYTICS_TABS.COST ? classes.active : 'inactive'}>
            <img src="../Cost.svg" alt="Cost" loading="lazy" style={{ height: '25px' }} />
            <Typography variant="body2" component="p">
              Cost
            </Typography>
          </AnalysisTabPaper>
          <AnalysisTabPaper
            square={false}
            onClick={() => setMyVariable(ANALYTICS_TABS.EXPENDITURE)}
            className={tabType === ANALYTICS_TABS.EXPENDITURE ? classes.active : 'inactive'}>
            <img src="../Expenditure.svg" alt="Expenditure" loading="lazy" style={{ height: '25px' }} />
            <Typography variant="body2" component="p">
              Expenditure
            </Typography>
          </AnalysisTabPaper>
        </Stack>
      </Box>
      <Scrollbars style={{ height: 'calc(100vh - 150px)' }} autoHide>
        <Box>{renderTab()}</Box>
      </Scrollbars>
    </Grid>
  );
};
