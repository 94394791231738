import { DEFAULT_BORDER_COLOR, IMetadata, ITreeTiltFallenData, SettingUIName } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getTreeTiltFallenList } from 'services/analytics/apiAnalyticsConfig.services';
import { queryMe } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { rightBarSelector } from 'store/slices/rightBarSlice';

const TreeTiltFallenLayer = () => {
  const [treeTiltFallenData, setTreeTiltFallenData] = useState<ITreeTiltFallenData[]>([]);
  const [page, setPage] = useState<number>(1);

  const {
    treeTiltFallen: { sensorId, visible },
  } = useAppSelector(rightBarSelector);
  const { clientSetting, colorAnalysisLayer } = useQueryClientSetting();

  const uiSetting = clientSetting?.uiSetting;
  const strokeWidthValue = uiSetting && uiSetting.find((item) => item.name === SettingUIName.STROKE_WIDTH)?.value;
  // with vector related, replace analytic by sensorId
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const colorFromClientSetting = colorAnalysisLayer?.find(
    (item) => item.analysisId === sensorId && item.userId === userInfo?.data?._id
  )?.color;

  useQuery([QUERY_KEY.GET_TREE_TILT_FALLEN_LIST, sensorId, page], () => getTreeTiltFallenList({ page, sensorId }), {
    enabled: !!sensorId,
    onSuccess(res) {
      const resData = res.data?.data as ITreeTiltFallenData[];
      const metaData = res.data?.metadata as IMetadata;
      if (metaData?.nextPage) {
        setPage((prev) => prev + 1);
      }
      setTreeTiltFallenData((prev) => [...prev, ...resData]);
    },
  });

  // reset tree tilt/fallen data
  useEffect(() => {
    setPage(1);
    setTreeTiltFallenData([]);
  }, [sensorId]);

  const convertDataVector = (): GeoJSON.FeatureCollection => {
    return {
      type: 'FeatureCollection',
      features: treeTiltFallenData.map(
        (item) =>
          ({
            type: 'Feature',
            geometry: item.geometry,
            properties: {},
          } as GeoJSON.Feature)
      ),
    };
  };

  return (
    <>
      {treeTiltFallenData.length && (
        <Source id="tree_tilt_fallen_source" type="geojson" data={convertDataVector()} lineMetrics={true}>
          <Layer
            id={`tree_tilt_fallen`}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
              visibility: visible ? 'visible' : 'none',
            }}
            paint={{
              'line-opacity': 1,
              'line-width': strokeWidthValue || 1.4,
              'line-color': colorFromClientSetting || DEFAULT_BORDER_COLOR,
            }}
          />
        </Source>
      )}
    </>
  );
};

export default TreeTiltFallenLayer;
