import { Box } from '@mui/material';
import { Cesium3DTileset as ICesium3DTileset, CesiumTerrainProvider, Ion, Viewer as CesiumViewer } from 'cesium';
import configs from 'constants/config';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import useQueryWorkspaceInfo from 'hooks/workspace/useQueryWorkspaceInfo';
import { Map3dOptionEnum } from 'interfaces/workspace';
import { FC, useEffect, useRef } from 'react';
import { Cesium3DTileset, CesiumComponentRef, Viewer } from 'resium';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

Ion.defaultAccessToken = process.env.REACT_APP_CESIUM || '';

interface React3dMapProps {
  isShowRightBar: boolean;
}

const React3dMap: FC<React3dMapProps> = ({ isShowRightBar }) => {
  const map3dRef = useRef<CesiumComponentRef<CesiumViewer>>(null);
  const model3dRef = useRef<CesiumComponentRef<ICesium3DTileset>>(null);
  const { workspaceData } = useQueryWorkspaceInfo();
  const { fieldDetail } = useQueryFieldDetail();
  const baseURl = configs.API_DOMAIN;
  const {
    rightBar: { options3d },
  } = useAppSelector(tilingMapSelector);
  const typeViewName = options3d.find((item) => item.value)?.name;

  const getTerrainProvider = async () => {
    const terrainProvider = await CesiumTerrainProvider.fromIonAssetId(3957, {
      requestVertexNormals: true,
    });
    return terrainProvider;
  };

  const isShowPointCloud = () => {
    if (typeViewName === Map3dOptionEnum.POINT_CLOUD) return true;
    return false;
  };

  // remove terrain cesium ion pick options
  useEffect(() => {
    if (map3dRef.current?.cesiumElement?.baseLayerPicker?.viewModel) {
      map3dRef.current.cesiumElement.baseLayerPicker.viewModel.terrainProviderViewModels = [];
    }
  }, [map3dRef?.current?.cesiumElement]);

  return (
    <Box
      sx={{
        mt: '40px',
        '& .cesium-viewer': {
          top: '64px',
        },
        '& .cesium-viewer-toolbar': {
          right: isShowRightBar ? '400px' : '100px',
        },
      }}>
      {workspaceData?.projectId && fieldDetail?.taskId && (
        <Viewer
          requestRenderMode
          full
          ref={map3dRef}
          timeline={false}
          scene3DOnly
          terrainProvider={getTerrainProvider()}
          baseLayerPicker={true}
          homeButton={false}
          vrButton={false}
          navigationHelpButton={false}>
          <Cesium3DTileset
            ref={model3dRef}
            show={isShowPointCloud()}
            url={`${baseURl}/field/3D/${workspaceData?.projectId}/${fieldDetail?.taskId}/tileset.json?typeView=${Map3dOptionEnum.POINT_CLOUD}`}
            onReady={(tileset) => {
              map3dRef.current?.cesiumElement?.flyTo(tileset);
            }}
          />
          <Cesium3DTileset
            ref={model3dRef}
            show={!isShowPointCloud()}
            url={`${baseURl}/field/3D/${workspaceData?.projectId}/${fieldDetail?.taskId}/tileset.json?typeView=${Map3dOptionEnum.MODEL_3D}`}
            onReady={(tileset) => {
              map3dRef.current?.cesiumElement?.flyTo(tileset);
            }}
          />
        </Viewer>
      )}
    </Box>
  );
};

export default React3dMap;
