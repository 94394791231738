import { Box, Grid, Tooltip } from '@mui/material';
import { LandUsageIcon } from 'assets/icons/MapView';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { SliderSelectDate } from 'components/Common/SliderSelectDate';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { sendGetDataAnalysisByLevelIdDate } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearPlantHealthOilPailmAnalytics } from 'store/slices/map-view/plantHealthOilPailmAnalytics';
import { changeDataPointClicked } from 'store/slices/map-view/standCountAnalytics';
import {
  changeLayer3D,
  changeParentAnalyticsId,
  clearData,
  mapViewSelector,
  selectRasterLayer,
} from 'store/slices/mapViewSlice';
import { changeAnalyticName, rightBarSelector } from 'store/slices/rightBarSlice';
import { clearChlorophyllAnalytics } from '../../../../store/slices/map-view/chlorophyllAnalytics';
import { clearCircumferenceAnalytics } from '../../../../store/slices/map-view/circumferenceAnalytics';
import { clearCrownAreaAnalytics } from '../../../../store/slices/map-view/crownAreaAnalytics';
import { clearPlantStressAnalytics } from '../../../../store/slices/map-view/plantStressAnalytics';
import { clearVigorAnalytics } from '../../../../store/slices/map-view/vigorAnalytics';
import { clearWaterUptakeAnalytics } from '../../../../store/slices/map-view/waterUptakeAnalytics';
import { clearWeedInvasionAnalytics } from '../../../../store/slices/map-view/weedInvasionAnalytics';
import { useAnalyticTabStyle } from '../../MapViewStyle';
import { AnalyticCardTab } from './AnalyticCardTab';
import { CategoriesTab } from './Category';
import { SwitchRaster } from './Layer/SwitchRaster';
import TemporalButton from './TemporalButton';
import TemporalModal from './TemporalModal';

export const AnalyticsTab = () => {
  const [isShowTemporalModal, setIsShowTemporalModal] = useState<boolean>(false);

  const classes = useAnalyticTabStyle();
  const dispatch = useAppDispatch();
  const { dateAnalyticSelected, levelId, isLastLevelId, analyticId, ratersSelected } = useAppSelector(mapViewSelector);
  const { analyticName } = useAppSelector(rightBarSelector);
  const { data } = useQuery(
    [QUERY_KEY.DATA_ANALYSIS, levelId, dateAnalyticSelected],
    () => sendGetDataAnalysisByLevelIdDate(levelId || '', dateAnalyticSelected || ''),
    {
      enabled: !!dateAnalyticSelected && !!levelId,
    }
  );

  const dataAnalysis = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const clearDataAnalytics = useCallback(() => {
    dispatch(clearCircumferenceAnalytics());
    dispatch(clearVigorAnalytics());
    dispatch(clearPlantStressAnalytics());
    dispatch(clearCrownAreaAnalytics());
    dispatch(clearWaterUptakeAnalytics());
    dispatch(clearChlorophyllAnalytics());
    dispatch(clearPlantHealthOilPailmAnalytics());
    dispatch(clearWeedInvasionAnalytics());
    dispatch(clearData());
    dispatch(changeDataPointClicked(undefined));
    dispatch(selectRasterLayer([]));
  }, [dispatch]);

  // clear all data when change date
  useEffect(() => {
    clearDataAnalytics();
  }, [clearDataAnalytics, dateAnalyticSelected]);

  const handleChange = useCallback(
    (dataTab: any) => {
      clearDataAnalytics();
      if (dataTab._id === analyticId) {
        dispatch(changeAnalyticName(dataTab.name));
        return;
      }
      dispatch(changeParentAnalyticsId({ parentId: dataTab.parentId, analyticId: dataTab._id }));
      dispatch(changeAnalyticName(dataTab.name));
      if (dataTab.name !== TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS) dispatch(changeLayer3D(false));
      // setAnalyticName(dataTab.name);
    },
    [analyticId, clearDataAnalytics, dispatch]
  );

  return (
    <Grid container className={classes.tabContainer} direction="column" width="100%">
      {levelId && (
        <Grid container>
          <Grid item xs={8}>
            <SliderSelectDate
              sx={{
                marginBottom: '8px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#202329' : theme.palette.background.default,
              }}
              levelId={levelId}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', pr: '4px' }}>
            <TemporalButton setIsShowTemporalModal={setIsShowTemporalModal} />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={0.5} columns={24}>
        {dataAnalysis.map((_item: any) => {
          return (
            <Tooltip title={_item.name} placement="bottom" key={_item._id}>
              <Grid item xs={4}>
                <AnalyticCardTab
                  key={_item._id}
                  icon={<LandUsageIcon width="28px" height="28px" isSelected={analyticName === _item.name} />}
                  label={_item.name}
                  isSelected={analyticName === _item.name}
                  onSelect={() => (analyticName !== _item.name ? handleChange(_item) : handleChange(''))}
                />
              </Grid>
            </Tooltip>
          );
        })}
      </Grid>
      <Box>
        <SwitchRaster analyticType={analyticName} isShowInlineBlock={false} />
      </Box>
      {isLastLevelId && ratersSelected.length > 0 && <CategoriesTab analyticType={analyticName} />}
      {isShowTemporalModal && <TemporalModal isShow={true} handleClose={() => setIsShowTemporalModal(false)} />}
    </Grid>
  );
};
