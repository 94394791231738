import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Box, Button, Collapse, Grid, Tooltip } from '@mui/material';
import { SettingSectionName } from 'common/defines/clients';
import { uiIconSettingFavorite, uiSettingDescriptionMapping, uiSizePointConfig, uiViewAnalysisProperties } from 'common/defines/constants';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useClientSettingsStyle } from './ClientSettingsStyle';

export const SizePointCard = ({ data, type }: any) => {
    const classes = useClientSettingsStyle();

    const [isOpenExpand, setIsOpenExpand] = useState(true)


    const settingData = useMemo(() => {
        switch (type) {
            case SettingSectionName.SIZE_POINT_SETTING:
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        {data?.value ? (`Fixed Size: ${data?.value} `) : `Fixed Size Point Analysis : ${data?.isFixedPoint || 'false'}`}
                    </Box>
                )
            default:
                return <></>;
        }
    }, [data, type]);
    return (
        <Grid>
            <Box>
                <Button
                    startIcon={isOpenExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    onClick={() => setIsOpenExpand(!isOpenExpand)}>
                    {/* {data.name} */}
                    SIZE POINT CONFIG
                </Button>
            </Box>
            <Collapse in={isOpenExpand} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                <Grid container>{settingData}</Grid>
            </Collapse>
        </Grid>
    );
};
