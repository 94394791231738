import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ITilingMapField } from 'interfaces/workspace';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import DragAndDropFile from '../DragAndDropFile';

interface ReconstructedImagesProps {
  form: UseFormReturn<ITilingMapField>;
  imageList: File[];
  setImageList: (arg: File[]) => void;
}

const reconstructedList = [
  { key: 1, name: 'RBand', label: 'R Band' },
  { key: 3, name: 'GBand', label: 'G Band' },
  { key: 5, name: 'BBand', label: 'B Band' },
  { key: 2, name: 'RgbBand', label: 'RGB Band' },
  { key: 4, name: 'REBand', label: 'RE Band' },
  { key: 6, name: 'NirBand', label: 'NIR Band' },
];

const ReconstructedImages: FC<ReconstructedImagesProps> = ({ form, imageList, setImageList }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        border: `2px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        p: '12px',
        pt: '8px',
      }}>
      {reconstructedList.map((item) => (
        <Grid item xs={6} key={item.key}>
          <Box sx={{ p: '4px' }}>
            <Typography sx={{ fontWeight: 500, mb: '4px' }}>{item.label}</Typography>
            <DragAndDropFile setImageList={setImageList} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReconstructedImages;
