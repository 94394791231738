import { Grid, InputLabel, styled } from '@mui/material';
import {
  FILE_TYPE,
  LABEL_DATA_RASTERS,
  LayerTypeEnum,
  TypeRaster,
  TYPE_ANALYTICS_MAP_VIEW,
} from 'common/defines/constants';
import { useMemo } from 'react';
import {
  createChlorophyllAnalytic,
  createChlorophyllPaddyAnalytic,
  createLeafPhenologyFieldAnalytic,
  createPlantHealthAnalytic,
  createSlopeFieldAnalytic,
  createSoilErosionFieldAnalytic,
  createSoilSaturationFieldAnalytic,
  createStressAnalytic,
  createStressFieldAnalytic,
  createTensionCrackAnalytic,
  createVigor,
  createVigorPaddyAnalytic,
  createWaterUptakeAnalytic,
  createWaterUptakeFieldAnalytic,
  deleteChlorophyllFieldGeojsonFile,
  deleteChlorophyllTreeGeojsonFile,
  deleteCircumferenceAnalysisData,
  deleteCropCoverAnalysisData,
  deleteCropPlantationData,
  deleteCrownAreaAnalysisData,
  deleteLandUseAnalysisData,
  deleteLeafPhenologyFieldAnalysisData,
  deletePlantHealthAnalysisGeojsonFile,
  deletePlantHealthOilPalmGeojsonFile,
  deleteRasterAnalysisData,
  deleteSlopeFieldAnalysisData,
  deleteSoilErosionFieldAnalysisData,
  deleteSoilSaturationFieldAnalysisData,
  deleteStandCountAnalysisData,
  deleteStressFieldGeojsonFile,
  deleteStressTreeGeojsonFile,
  deleteSurfaceExposedWaterAnalysisData,
  deleteTensionCrackAnalysisData,
  deleteTillerDensityAnalysisData,
  deleteVacantAreaAnalysisData,
  deleteVirgoFieldGeojsonFile,
  deleteVirgoTreeGeojsonFile,
  deleteWaterUptakeFieldGeojsonFile,
  deleteWaterUptakeTreeGeojsonFile,
  deleteWeekInvasionAnalysisData,
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector } from 'store/slices/analyticsConfigSlice';
import { CircumferenceForm } from './Form/CircumferenceForm';
import CommonFieldForm from './Form/CommonFieldForm';
import CommonTreeForm from './Form/CommonTreeForm';
import { CropCoverForm } from './Form/CropCoverForm';
import { CrownAreaForm } from './Form/CrownForm';
import { LandUseForm } from './Form/LandUseForm';
import { StandCountForm } from './Form/StandCountForm';
import SurfaceExposedWaterForm from './Form/SurfaceExposedWaterForm';
import TillerDensityForm from './Form/TillerDensityForm';
import { UpdateRaster } from './Form/UpdateRaster';
import VacantAreaForm from './Form/VacantAreaForm';
import WeedInvasionForm from './Form/WeedInvasionForm';

const Card = styled(Grid)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: 'fit-content',
  minHeight: 'calc( 100vh - 400px )',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
}));

export const InfoRasterCard = () => {
  const { sensorId, typeOfAnalytics } = useAppSelector(anaLyticConfigSelector);

  const form = useMemo(() => {
    if (!sensorId) {
      return <></>;
    }
    switch (typeOfAnalytics) {
      case TypeRaster.RGB:
      case TypeRaster.RGB_MULTI_SPECTRAL:
        return (
          <UpdateRaster
            sensorId={sensorId}
            name={typeOfAnalytics as TypeRaster}
            deleteFileGeojson={deleteRasterAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.LAND_USE_ANALYSIS:
        return <LandUseForm sensorId={sensorId} deleteFileGeojson={deleteLandUseAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE:
        return (
          <CropCoverForm
            sensorId={sensorId}
            deleteAnalysisData={deleteCropCoverAnalysisData}
            deleteCropPlantation={deleteCropPlantationData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT:
        return <StandCountForm sensorId={sensorId} deleteFileGeojson={deleteStandCountAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
        return <CircumferenceForm sensorId={sensorId} deleteFileGeojson={deleteCircumferenceAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
        return <CrownAreaForm sensorId={sensorId} deleteFileGeojson={deleteCrownAreaAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION:
        return <WeedInvasionForm sensorId={sensorId} deleteFileGeojson={deleteWeekInvasionAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA:
        return <VacantAreaForm sensorId={sensorId} deleteFileGeojson={deleteVacantAreaAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY:
        return <TillerDensityForm sensorId={sensorId} deleteFileGeojson={deleteTillerDensityAnalysisData} />;
      case TYPE_ANALYTICS_MAP_VIEW.SURFACE_EXPOSED_WATER:
        return (
          <SurfaceExposedWaterForm sensorId={sensorId} deleteFileGeojson={deleteSurfaceExposedWaterAnalysisData} />
        );

      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createVigor}
            fileType={FILE_TYPE.VIGOR_FILE}
            label={LABEL_DATA_RASTERS.VIGOR_TREE}
            layerType={LayerTypeEnum.VIGOR_TREE}
            deleteFileGeojson={deleteVirgoTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createStressAnalytic}
            fileType={FILE_TYPE.STRESS_FILE}
            label={LABEL_DATA_RASTERS.STRESS_TREE}
            layerType={LayerTypeEnum.STRESS_TREE}
            deleteFileGeojson={deleteStressTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createWaterUptakeAnalytic}
            fileType={FILE_TYPE.WATER_UPTAKE_FILE}
            label={LABEL_DATA_RASTERS.WATER_UPTAKE_TREE}
            layerType={LayerTypeEnum.WATER_UPTAKE_TREE}
            deleteFileGeojson={deleteWaterUptakeTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createChlorophyllAnalytic}
            fileType={FILE_TYPE.CHLOROPHYLL_FILE}
            label={LABEL_DATA_RASTERS.CHLOROPHYLL_TREE}
            layerType={LayerTypeEnum.CHLOROPHYLL_TREE}
            deleteFileGeojson={deleteChlorophyllTreeGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
        return (
          <CommonTreeForm
            sensorId={sensorId}
            createAnalyticFunc={createPlantHealthAnalytic}
            fileType={FILE_TYPE.PLAN_HEALTH_OILPALM_FILE}
            label={LABEL_DATA_RASTERS.PLANT_HEALTH_TREE}
            layerType={LayerTypeEnum.PLANT_HEALTH_TREE}
            deleteFileGeojson={deletePlantHealthAnalysisGeojsonFile}
          />
        );

      case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createPlantHealthAnalytic}
            fileType={FILE_TYPE.PLANT_HEALTH_GEOJSON}
            label={LABEL_DATA_RASTERS.PLANT_HEALTH_FIELD}
            layerType={LayerTypeEnum.PLANT_HEALTH_FIELD}
            deleteFileGeojson={deletePlantHealthOilPalmGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createChlorophyllPaddyAnalytic}
            fileType={FILE_TYPE.CHLOROPHYLL_PADDY_GEOJSON}
            label={LABEL_DATA_RASTERS.CHLOROPHYLL_FIELD}
            layerType={LayerTypeEnum.CHLOROPHYLL_FIELD}
            deleteFileGeojson={deleteChlorophyllFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createVigorPaddyAnalytic}
            fileType={FILE_TYPE.VIGOR_PADDY_GEOJSON}
            label={LABEL_DATA_RASTERS.VIGOR_FIELD}
            layerType={LayerTypeEnum.VIGOR_FIELD}
            deleteFileGeojson={deleteVirgoFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createStressFieldAnalytic}
            fileType={FILE_TYPE.STRESS_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.STRESS_FIELD}
            layerType={LayerTypeEnum.STRESS_FIELD}
            deleteFileGeojson={deleteStressFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createWaterUptakeFieldAnalytic}
            fileType={FILE_TYPE.WATER_UPTAKE_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.WATER_UPTAKE_FIELD}
            layerType={LayerTypeEnum.WATER_UPTAKE_FIELD}
            deleteFileGeojson={deleteWaterUptakeFieldGeojsonFile}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_SATURATION_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createSoilSaturationFieldAnalytic}
            fileType={FILE_TYPE.SOIL_SATURATION_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.SOIL_SATURATION_FIELD}
            layerType={LayerTypeEnum.SOIL_SATURATION_FIELD}
            deleteFileGeojson={deleteSoilSaturationFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createSoilErosionFieldAnalytic}
            fileType={FILE_TYPE.SOIL_EROSION_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.SOIL_EROSION_FIELD}
            layerType={LayerTypeEnum.SOIL_EROSION_FIELD}
            deleteFileGeojson={deleteSoilErosionFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createLeafPhenologyFieldAnalytic}
            fileType={FILE_TYPE.LEAF_PHENOLOGY_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.LEAF_PHENOLOGY_FIELD}
            layerType={LayerTypeEnum.LEAF_PHENOLOGY_FIELD}
            deleteFileGeojson={deleteLeafPhenologyFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createSlopeFieldAnalytic}
            fileType={FILE_TYPE.SLOPE_FIELD_GEOJSON}
            label={LABEL_DATA_RASTERS.SLOPE_FIELD}
            layerType={LayerTypeEnum.SLOPE_FIELD}
            deleteFileGeojson={deleteSlopeFieldAnalysisData}
          />
        );
      case TYPE_ANALYTICS_MAP_VIEW.TENSION_CRACK:
        return (
          <CommonFieldForm
            sensorId={sensorId}
            createAnalyticFunc={createTensionCrackAnalytic}
            fileType={FILE_TYPE.TENSION_CRACK_GEOJSON}
            label={LABEL_DATA_RASTERS.TENSION_CRACK}
            layerType={LayerTypeEnum.TENSION_CRACK}
            deleteFileGeojson={deleteTensionCrackAnalysisData}
            acceptFile="(.geojson|.json)"
          />
        );
      default:
        return <></>;
    }
  }, [typeOfAnalytics, sensorId]);

  return (
    <>
      {typeOfAnalytics && sensorId && (
        <Card sx={{ height: '100%' }}>
          <Grid width="100%">
            <InputLabel sx={{ fontWeight: '600', pt: 2 }}>{typeOfAnalytics}</InputLabel>
            {form}
          </Grid>
        </Card>
      )}
    </>
  );
};
