import { Box } from '@mui/material';
import configs from 'constants/config';
import { DEFAULT_VIEWPORT, QUERY_KEY } from 'constants/constants';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import useQueryWorkspaceInfo from 'hooks/workspace/useQueryWorkspaceInfo';
import { Map2dOptionEnum } from 'interfaces/workspace';
import { FC, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getMinMaxZoom } from 'services/workspaces';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';
import DrawBoundary from './DrawBoundary';
import ShowBoundaryLayer from './ShowBoundaryLayer';

interface ReactMapGLTilingProps {
  mapStyle: string;
  isShowNavbar: boolean;
}

const ReactMapGLTiling: FC<ReactMapGLTilingProps> = ({ mapStyle, isShowNavbar }) => {
  const [metaData, setMetaData] = useState<{ center: number[] }>();
  const mapRef = useRef<any>(null);
  const {
    selectedFieldId,
    rightBar: { options2d: options },
    drawingBoundary: { isLoading },
  } = useAppSelector(tilingMapSelector);

  const { workspaceData } = useQueryWorkspaceInfo();
  const { projectId } = workspaceData || {};
  const { fieldDetail } = useQueryFieldDetail();
  const { taskId, status, _id: fieldId, maxX, maxY, zoom } = fieldDetail || {};

  const selectedLayer = options.find((item) => item.value)?.name;

  const isEnableLogic = status && taskId && projectId && fieldId;

  useQuery(
    [QUERY_KEY.GET_MIN_MAX_ZOOM, projectId, taskId],
    () => getMinMaxZoom(projectId!, taskId!, Map2dOptionEnum.dsm),
    {
      onSuccess(res) {
        setMetaData(res.data);
      },
      enabled: !!isEnableLogic,
    }
  );

  useEffect(() => {
    const renderCenterCoordinates = () => {
      if (maxX && maxY && zoom) {
        return [maxX, maxY];
      } else {
        return metaData?.center?.slice(0, 2) || [DEFAULT_VIEWPORT.longitude, DEFAULT_VIEWPORT.latitude];
      }
    };

    if (selectedFieldId) {
      mapRef.current?.flyTo({
        center: renderCenterCoordinates(),
        zoom: zoom || metaData?.center[2] || 20,
        essential: true,
        speed: 1.0,
        curve: 1,
      });
    }
  }, [maxX, maxY, metaData?.center, selectedFieldId, zoom]);

  // reload 2d tiles layer after change dependency list
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (map.getLayer(`layer-tiles`)) {
        map.removeLayer(`layer-tiles`);
      }
      if (map.getSource(`raster-tiles`)) {
        map.removeSource(`raster-tiles`);
      }
    }
  }, [selectedLayer, taskId, isLoading]);

  return (
    <Box
      sx={{
        height: '100%',
        '& .mapboxgl-ctrl-top-left': {
          left: isShowNavbar ? '400px' : '0px',
        },
        '& .finish-drawing': {
          left: isShowNavbar ? '450px' : '50px',
        },
      }}>
      <ReactMapGL
        initialViewState={DEFAULT_VIEWPORT}
        mapboxAccessToken={configs.MAP_BOX_API}
        ref={mapRef}
        mapStyle={mapStyle}>
        <DrawBoundary />
        <ShowBoundaryLayer />
        {isEnableLogic && selectedLayer && (
          <Source
            key={`raster-tiles`}
            id={`raster-tiles`}
            type="raster"
            scheme={'xyz'}
            tileSize={256}
            tiles={[`${configs.API_DOMAIN}/field/getWebODMAssets/${fieldId}/{z}/{x}/{y}?layerType=${selectedLayer}`]}>
            <Layer
              id={`layer-tiles`}
              type="raster"
              layout={{
                visibility: 'visible',
              }}
            />
          </Source>
        )}
      </ReactMapGL>
    </Box>
  );
};

export default ReactMapGLTiling;
