import { Button, Grid, useTheme } from '@mui/material';
import { PIE_CHART_NO_DATA_LABEL, PRODUCTION_TRENDS_TYPES, QUERY_KEY } from 'constants/constants';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getDashboardProductionTrends } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { getColorPaletteForAnalyticsTab, getTopThreeSortedPieChartData, IPieChatData } from '../analyticsTabConstants';

export const ProductionTrandsChart = (props: { dateRange: any }) => {
  const theme = useTheme();
  const colorPalette = useMemo(() => getColorPaletteForAnalyticsTab(theme.palette.background.paper), [theme]);
  const { dateRange } = props;
  const [productionTrendsPieData, setProductionTrendsPieData] = useState<EChartsOption>({ series: [{}] });
  const { selectedLevelHierarchyList } = useAppSelector(mapViewSelector);
  const [levelHierarchyObject, setLevelHierarchyObject] = useState<any>(null);
  const [showAllData, setShowAllData] = useState(false);
  const [productionPieChartdata, setProductionPieChartdata] = useState<IPieChatData[]>([{ name: '', value: 0 }]);

  useEffect(() => {
    let levelData: any[] =
      selectedLevelHierarchyList && selectedLevelHierarchyList.length ? [...selectedLevelHierarchyList] : [];

    if (levelData.length) {
      let estateObj = levelData.filter((ele) => ele?.level === 1)[0] || {};
      let divisionObj = levelData.filter((ele) => ele?.level === 2)[0] || {};
      let yopObj = levelData.filter((ele) => ele?.level === 3)[0] || {};
      let blockObj = levelData.filter((ele) => ele?.level === 4)[0] || {};
      setLevelHierarchyObject({
        estateId: estateObj?.levelId || '',
        divisionName: divisionObj?.levelName || '',
        yop: yopObj?.levelName || '',
        blockName: blockObj?.levelName || '',
      });
    } else {
      setLevelHierarchyObject(null);
    }
  }, [selectedLevelHierarchyList]);

  const pieDataRevamp = (pieList: IPieChatData[], fontSize: number) => {
    setProductionTrendsPieData((prevState: any) => ({
      ...prevState,
      series: [{ ...prevState?.series[0], data: pieList, label: { fontSize: fontSize } }],
    }));
  };

  useEffect(() => {
    if (productionPieChartdata?.length) {
      if (showAllData) {
        pieDataRevamp(productionPieChartdata, 7);
      } else if (productionPieChartdata?.length > 3) {
        pieDataRevamp(getTopThreeSortedPieChartData(productionPieChartdata), 10);
      }
    }
  }, [showAllData, productionPieChartdata]);

  useQuery(
    [QUERY_KEY.GET_PRODUCTION_TREND_DATA, dateRange, levelHierarchyObject],
    () =>
      getDashboardProductionTrends({
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        estateId: levelHierarchyObject?.estateId || '',
        divName: levelHierarchyObject?.divisionName || '',
        yop: levelHierarchyObject?.yop || '',
        blockName: levelHierarchyObject?.blockName || '',
        type: PRODUCTION_TRENDS_TYPES.MONTH,
      }),
    {
      onSuccess: (data: any) => {
        let seriesData: any[] = [];
        const result = data.data.map((item: any) => {
          let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          item.monthWiseInfo.forEach((info: any) => {
            array[info.WeighingMonth - 1] = info.NetWeight / 1000;
          });
          let series = {
            name: item.Product,
            data: array,
            type: 'line',
            areaStyle: {
              opacity: 0.2,
            },
            smooth: true,
          };
          seriesData.push(series);
          return {
            value: item.NetWeight / 1000,
            name: item.Product,
          };
        });

        const productionTrendPie = {
          title: {
            text: 'Production Trends',
            show: true,
            subtext: 'Month to date Product Chart',
          },
          tooltip: {
            trigger: 'item',
            formatter: `{b} <b>{c}</b>mt <b>{d}%</b>`,
          },
          legend: {
            show: false,
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              top: '10%',
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                borderColor: colorPalette.borderColor,
              },
              label: {
                fontSize: 7,
              },
              color: result?.length ? colorPalette.pieChart : colorPalette?.noDataColor,
              data: result?.length ? result : PIE_CHART_NO_DATA_LABEL,
            },
          ],
        };
        setProductionPieChartdata(result);
        setProductionTrendsPieData(productionTrendPie);
      },
    }
  );

  return (
    <Grid sx={{ position: 'relative' }}>
      {productionPieChartdata?.length > 3 && (
        <Button sx={{ position: 'absolute', right: '30px', zIndex: '1' }} onClick={() => setShowAllData(!showAllData)}>
          {showAllData ? 'Hide Others' : 'Show All'}
        </Button>
      )}
      <ReactEcharts option={productionTrendsPieData} />
    </Grid>
  );
};
