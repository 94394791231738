import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { DashboardIcon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changePassword } from 'services/clients/apiClient.services';
import * as yup from 'yup';
import { IResetPasswordData } from '../../common/defines/auth';
import { useAppDispatch } from '../../store/hooks';
import { clearAuthState } from '../../store/slices/authSlice';
import { useChangePasswordStyle } from './ChangePasswordStyle';

const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  crossLineStyles: {
    borderTop: 1,
    width: '94px',
    mt: 1.25,
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
  textStyles: {
    fontSize: '14px',
    height: '17px',
  },
  fontStyles: {
    fontFamily: 'Barlow',
  },
};

const confirmValidationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Password and Confirm Password does not match!'),
});

const ChangePassword = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const toastId = React.useRef('');
  const classes = useChangePasswordStyle();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IResetPasswordData>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(confirmValidationSchema),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangePassword = useMutation((data: IResetPasswordData) => changePassword(data, tokenId || ''), {
    onSuccess: () => {
      toast.success('Change password successed');
      navigate('/login');
    },
    onError: () => {
      toast.error('Change password failed');
    },
  });

  const handleSubmitForm: SubmitHandler<IResetPasswordData> = (data) => {
    handleChangePassword.mutate(data);
    toast.dismiss(toastId.current);
  };

  useEffect(() => {
    return () => {
      dispatch(clearAuthState());
    };
  }, []);

  const handleChangeField = (e: any, fieldName: any) => {
    setValue(fieldName, e.target.value.trim());
  };

  return (
    <>
      {/* {isLoggingIn && <LoadingOverlay />} */}
      <Typography
        component="div"
        sx={{
          height: '100vh',
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1F1F1F' : '#f9f9f9'),
          display: ' flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Stack
          direction="row"
          sx={{
            height: 'fit-content',
            maxWidth: '1130px',
            m: 'auto',
            borderRadius: '10px',
            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#3C4043' : '#fff'),
            py: 5,
          }}>
          <Box
            alignItems="center"
            className={classes.baseStyles}
            sx={{
              width: '500px',
            }}>
            <Typography
              component="div"
              sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <DashboardIcon />
              <Typography className={classes.brandStyles} sx={{ px: 2 }}>
                {' '}
                Agrimor
              </Typography>
            </Typography>
            <Typography
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '310px',
                ...styles.formStyles,
              }}
              onSubmit={handleSubmit(handleSubmitForm)}>
              <Typography
                component="div"
                sx={{
                  mt: '52px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
                  fontSize: '24px',
                  fontWeight: '600',
                }}>
                {'Set New Password'}
              </Typography>

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Password
                    </Typography>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      sx={{
                        mt: '4px',
                        ...styles.fontStyles,
                        'input::-ms-reveal': {
                          display: 'none',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword}
                              sx={{
                                height: '24px',
                                width: '24px',
                              }}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'password')}
                    />
                    {errors.password && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.password.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Confirm Password
                    </Typography>
                    <TextField
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Confirm password"
                      sx={{
                        mt: '4px',
                        ...styles.fontStyles,
                        'input::-ms-reveal': {
                          display: 'none',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              sx={{
                                height: '24px',
                                width: '24px',
                              }}>
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'confirmPassword')}
                    />
                    {errors.confirmPassword && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.confirmPassword.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <LoadingButton
                type="submit"
                sx={{
                  ...styles.fontStyles,
                  textTransform: 'none',
                }}
                className={classes.loginBtn}
                loading={handleChangePassword.isLoading}
                data-testid="login-btn">
                Reset Password
              </LoadingButton>
              <Button
                onClick={() => navigate('/login')}
                sx={{
                  ...styles.fontStyles,
                  textTransform: 'none',
                }}
                className={classes.returnLogin}
                data-testid="login-btn">
                Return to Login
              </Button>
            </Typography>
          </Box>
        </Stack>
      </Typography>
    </>
  );
};

export default ChangePassword;
