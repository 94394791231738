import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { useProductionScale } from './MapViewStyle';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { dashboardWidgetSelector, setPolygonFilter } from 'store/slices/dashboardWidgetSlice';
import { SEVERITY } from 'constants/constants';

interface CheckBoxDetails {
  name: string;
  label: string;
}

const filterCheckBoxList: CheckBoxDetails[] = [
  { name: SEVERITY.HIGH, label: 'High' },
  { name: SEVERITY.MEDIUM, label: 'Medium' },
  { name: SEVERITY.LOW, label: 'Low' },
];

const ProductionScale = ({ showAnalytics }: { showAnalytics: boolean }) => {
  const classes = useProductionScale();
  const dispatch: any = useAppDispatch();
  const { polygonFilter } = useAppSelector(dashboardWidgetSelector);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    dispatch(setPolygonFilter({ ...polygonFilter, [name]: checked }));
  };

  return (
    <Card sx={{ position: 'absolute', right: showAnalytics ? '55px' : '60px', top: '9px' }}>
      <Typography variant="h6" gutterBottom sx={{ padding: '8px 0px 0px 16px', fontSize: '1.1rem' }}>
        Production
      </Typography>
      <CardContent className={classes.checkBoxLabelDivStyle} sx={{ padding: '0px 8px 8px 16px !important' }}>
        <div className={classes.redToOrnageToGreenGradient}></div>
        <div className={classes.checkBoxAndScaleWrapper}>
          {filterCheckBoxList.map((checkbox, index) => (
            <div
              className={classes.checkBoxLabelDivStyle}
              style={{ justifyContent: 'space-between' }}
              key={index + 1}>
              <Typography sx={{ paddingRight: '40px' }}>{checkbox.label}</Typography>
              <Checkbox checked={polygonFilter[checkbox.name]} onChange={handleCheckboxChange} name={checkbox.name} />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductionScale;
