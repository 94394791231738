import { Grid, Typography } from '@mui/material';
import { MousePositionEnum } from 'common/defines/constants';
import { FC } from 'react';
import { Marker } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';

const fixedSize = {
  height: 250,
  width: 350,
};

interface StandCountMarkerProps {
  hoverInfo: any;
  mouseRef: any;
}

const StandCountMarker: FC<StandCountMarkerProps> = ({ hoverInfo, mouseRef }) => {
  const { drawerShow } = useAppSelector(mapViewSelector);

  const detectMousePosition = () => {
    // 64px is width of menu bar
    let clientX = mouseRef.current.clientX - 64;
    let clientY = mouseRef.current.clientY;

    let innerWidth = window.innerWidth - 64;
    const innerHeight = window.innerHeight;

    // 300px is width of left sideBar
    if (drawerShow.leftBar) {
      clientX -= 300;
      innerWidth -= 300;
    }

    // 454px is width of right sideBar
    if (drawerShow.rightBar) {
      innerWidth -= 454;
    }

    if (clientY < innerHeight / 2) {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.TOP_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.TOP_RIGHT;
      else return MousePositionEnum.TOP_CENTER;
    } else {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_RIGHT;
      else return MousePositionEnum.BOTTOM_CENTER;
    }
  };

  const calculateOffsetYWithPosition = () => {
    if (detectMousePosition().includes('top')) return 8;
    else return -10;
  };

  return (
    <Marker
      longitude={hoverInfo.properties.longX}
      latitude={hoverInfo.properties.latY}
      anchor={detectMousePosition()}
      style={{ zIndex: 10 }}
      offset={[0, calculateOffsetYWithPosition()]}>
      <Grid
        maxWidth={350}
        minWidth={350}
        height={'fit-content'}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          border: 0,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '5px',
          display: 'flex',
          paddingX: '4px',
          alignItems: 'center',
          pointerEvents: 'none',
          transformOrigin: '0 0 0',
          position: 'relative',
          zIndex: 10,
        }}>
        <Grid container padding={1} gap={0.25}>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              }}>
              Tree Id
            </Typography>
            <Typography
              // component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                padding: '2px',
                color: '#1C9855',
                width: '80%',
                wordWrap: 'break-word',
              }}>
              {hoverInfo.properties.treeId}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              }}>
              Longitude
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                padding: '2px',
                color: '#1C9855',
                width: 'fit-content',
              }}>
              {hoverInfo.properties.longX}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              }}>
              Latitude
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                padding: '2px',
                color: '#1C9855',
                width: 'fit-content',
              }}>
              {hoverInfo.properties.latY}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              }}>
              Status
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                padding: '2px',
                color: '#1C9855',
                width: 'fit-content',
              }}>
              {hoverInfo.properties.status}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Marker>
  );
};

export default StandCountMarker;
