import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useNavBarBasedPath = (path: string) => {
  const [isNewVersion, setIsNewVersion] = useState(false); // Initially set to OldNavBar
  const location = useLocation();

  useEffect(() => {
    setIsNewVersion(location.pathname.includes(path));
  }, [location, path]); // Run once on mount

  return isNewVersion;
};

export default useNavBarBasedPath;
