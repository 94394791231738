export const NewMapIcon = ({ isSelected }: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={isSelected ? '#23BE6A' : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13.9653L15.8052 8L25 13.9653L15.8052 20L7 13.9653Z"
        stroke="#737373"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M16 16C16 16 16 18.0068 16 18" stroke="#737373" stroke-width="1.4" stroke-linecap="round" />
      <path
        d="M18.8422 14.9988C17.6045 16.0396 16.2518 15.5235 16.2518 15.5235C16.2518 15.5235 15.9754 14.1024 17.2131 13.0615C18.4508 12.0207 19.6959 12.6272 19.6959 12.6272C19.6959 12.6272 20.08 13.9579 18.8422 14.9988Z"
        stroke="#737373"
        stroke-width="1.4"
      />
      <path
        d="M13.2759 15.0895C12.0382 14.0487 12.3146 12.6275 12.3146 12.6275C12.3146 12.6275 13.6672 12.1114 14.905 13.1522C16.1427 14.193 15.7587 15.5236 15.7587 15.5236C15.7587 15.5236 14.5136 16.1303 13.2759 15.0895Z"
        stroke="#737373"
        stroke-width="1.4"
      />
      <path
        d="M10.2311 16.2834L7 18.8712L16 25L25 18.9401L21.5 16.5"
        stroke="#737373"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
