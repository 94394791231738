import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Modal, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface CommonModalProps {
  isShow: boolean;
  handleClose: () => void;
  children: ReactNode;
  style?: SxProps<Theme>;
}

const CommonModal: FC<CommonModalProps> = ({ isShow, handleClose, children, style }) => {
  const initialStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  return (
    <Modal
      open={isShow}
      keepMounted={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ ...initialStyle, ...(style as any) }}>
        <CloseOutlinedIcon
          sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer', fontSize: '18px', zIndex: 10 }}
          onClick={handleClose}
        />
        {children}
      </Box>
    </Modal>
  );
};

export default CommonModal;
