import { useTheme } from '@mui/material';
import { IRasterCardItemProps } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { SortableTree } from 'dnd-kit-sortable-tree';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateSortTreeAnalyticConfig } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { anaLyticConfigSelector, changePageRasterPagination } from 'store/slices/analyticsConfigSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import RasterCardItem from './RasterCardItem';

export const AnalyticListDnd = ({ item }: any) => {
  const [items, setItems] = useState(item);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { levelId } = useAppSelector(mapViewSelector);
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);

  useEffect(() => {
    setItems(item);
  }, [item]);

  const mutateUpdateSort = useMutation((data: any) => updateSortTreeAnalyticConfig(data), {
    onSuccess: () => {
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, levelId]);
    },
  });

  const updateSort = (items: IRasterCardItemProps[], parent: any) => {
    const childrenSort = items[0].children.map((item: any, index: number) => ({
      id: item.id,
      positionNumber: index + 1,
    }));
    mutateUpdateSort.mutate({
      dataAnalysisPositionNumber: childrenSort,
    });
  };

  const onItemsChanged = (items: any, reason: any) => {
    switch (reason.type) {
      case 'dropped':
        if (!reason.draggedFromParent && !reason.droppedToParent) {
          setItems(items);
          updateSort(items, reason.draggedFromParent);
          return;
        }
        if (
          (reason.draggedFromParent && !reason.droppedToParent) ||
          (!reason.draggedFromParent && reason.droppedToParent)
        ) {
          toast.error('You cannot move to another parent');
          return;
        }
        if (reason.draggedFromParent.id === reason.droppedToParent.id) {
          setItems(items);
          updateSort(items, reason.draggedFromParent);
          return;
        }
        toast.error('You cannot move to another parent');
        return;
      default:
        setItems(items);
    }
    // updateSort(items, reason.draggedFromParent);
  };
  return <SortableTree indicator items={items} onItemsChanged={onItemsChanged} TreeItemComponent={RasterCardItem} />;
};
