import {
  ClientAdminRole,
  IAnalyticsTabs,
  ICropHierarchy,
  IPolygonFilterCheckBoxState,
  IPolygonFilterSeverity,
  IProductionTrendsTypes,
} from 'common/defines/clients';

export const URI = {
  CREATE_CLIENT: '/create-clients',
  EDIT_CLIENT: '/clients/edit',
  NOT_PERMIT: '/403',
  NOT_FOUND: '/404',
  NOT_AUTH: '/401',
};

export const ROLE_OPTIONS = {
  [ClientAdminRole.USER]: 'User',
  [ClientAdminRole.SUPER_ADMIN]: 'Super Admin',
  [ClientAdminRole.CLIENT_ADMIN]: 'Client Admin',
};

export const QUERY_KEY = {
  CLIENT_LOCATION: 'CLIENT_LOCATION',
  CLIENT_DATA: 'CLIENT_DATA',
  LEVEL_LIST: 'LEVEL_LIST',
  COUNT_LIST: 'COUNT_LIST',
  LEVEL_PROPERTIES: 'LEVEL_PROPERTIES',
  CLIENT_DETAILS: 'CLIENT_DETAILS',
  CLIENT_LIST: 'CLIENT_LIST',
  CLIENT_LIST_ALL: 'CLIENT_LIST_ALL',
  ESTATE_LIST: 'ESTATE_LIST_ALL',
  CLIENT_LIST_IN_SUMMARY: 'CLIENT_LIST_IN_SUMMARY',
  CLIENT_SUMMARY: 'CLIENT_SUMMARY',
  DATA_PARENT: 'DATA_PARENT',
  ALL_LOCATION_CROP_TYPE: 'ALL_LOCATION_CROP_TYPE',
  EXPORT_KML_FILE: 'EXPORT_KML_FILE',
  DATA_MAP_KEY: 'DATA_MAP_KEY',
  DATA_DETAIL_INFOR_LEVEL: 'DATA_DETAIL_INFOR_LEVEL',
  USER_DETAILS: 'USER_DETAILS',
  ROLE_DETAILS: 'ROLE_DETAILS',
  USER_SETTINGS_ANALYTICS: 'USER_SETTINGS_ANALYTICS',
  USER_SETTINGS_ANALYTICS_BY_ID: 'USER_SETTINGS_ANALYTICS_BY_ID',
  ANALYTICS_CONFIGURATIONS: 'ANALYTICS_CONFIGURATIONS',
  SENSORS: 'SENSORS',
  USER_SETTINGS_CROPTYPE: 'USER_SETTINGS_CROPTYPE',
  USER_SETTINGS_CROPTYPE_BY_ID: 'USER_SETTINGS_CROPTYPE_BY_ID',
  ANALYSIS_UPLOAD_HISTORY: 'AnalysisUploadHistory',
  DATA_ANALYSIS: 'DATA_ANALYSIS',
  LAND_USAGE_ANALYSIS: 'LAND_USAGE_ANALYSIS',
  STAND_COUNT_ANALYSIS: 'STAND_COUNT_ANALYSIS',
  CIRCUMFERENCE_ANALYSIS: 'CIRCUMFERENCE_ANALYSIS',
  CIRCUMFERENCE_INTERVALS: 'CIRCUMFERENCE_INTERVALS',
  STAND_COUNT_CHART_ANALYSIS: 'STAND_COUNT_CHART_ANALYSIS',
  DATE_HISTORY_LEVEL_M: 'DATE_HISTORY_LEVEL_M',
  SESSION_LOGIN_USER: 'SESSION_LOGIN_USER',
  RASTER_DETAILS: 'RASTER_DETAILS',
  RASTER_LAYER: 'RASTER_LAYER',
  CLIENT_SETTINGS_BY_ID: 'CLIENT_SETTINGS_BY_ID',
  CROP_COVER_ANALYTIC: 'CROP_COVER_ANALYTIC',
  PLANT_HEALTH_ANALYTIC: 'CROP_COVER_ANALYTIC',
  TILER_DENSITY_ANALYTIC: 'TILER_DENSITY_ANALYTIC',
  VACANT_AREA_ANALYTIC: 'VACANT_AREA_ANALYTIC',
  IS_EXIST_VACANT_AREA: 'IS_EXIST_VACANT_AREA',
  CHLOROPHYLL_PADDY_ANALYTIC: 'VACANT_AREA_ANALYTIC',
  VIGOR_PADDY_ANALYTIC: 'VIGOR_PADDY_ANALYTIC',
  SIZE_CROP_COVER_ANALYTIC: 'SIZE_CROP_COVER_ANALYTIC',
  SIZE_PLANT_HEALTH_ANALYTIC: 'SIZE_PLANT_HEALTH_ANALYTIC',
  SIZE_TILER_DENSITY_ANALYTIC: 'SIZE_TILER_DENSITY_ANALYTIC',
  SIZE_VACANT_AREA_ANALYTIC: 'SIZE_VACANT_AREA_ANALYTIC',
  SIZE_CHLOROPHYLL_PADDY_ANALYTIC: 'SIZE_CHLOROPHYLL_PADDY_ANALYTIC',
  SIZE_STRESS_FIELD_ANALYTIC: 'SIZE_STRESS_FIELD_ANALYTIC',
  SIZE_WATER_UPTAKE_FIELD_ANALYTIC: 'SIZE_WATER_UPTAKE_FIELD_ANALYTIC',
  SIZE_SOIL_SATURATION_FIELD_ANALYTIC: 'SIZE_SOIL_SATURATION_FIELD_ANALYTIC',
  SIZE_SOIL_EROSION_FIELD_ANALYTIC: 'SIZE_SOIL_EROSION_FIELD_ANALYTIC',
  SIZE_LEAF_PHENOLOGY_FIELD_ANALYTIC: 'SIZE_LEAF_PHENOLOGY_FIELD_ANALYTIC',
  SIZE_SLOPE_FIELD_ANALYTIC: 'SIZE_SLOPE_FIELD_ANALYTIC',
  SIZE_VIGOR_PADDY_ANALYTIC: ' SIZE_VIGOR_PADDY_ANALYTIC',
  LAND_USAGE_POINT_ANALYTIC: 'LAND_USAGE_POINT_ANALYTIC',
  ENABLE_SLOPE_AREA: 'ENABLE_SLOPE_AREA',
  STRESS_FIELD_ANALYTIC: 'STRESS_FIELD_ANALYTIC',
  WATER_UPTAKE_FIELD_ANALYTIC: 'WATER_UPTAKE_FIELD_ANALYTIC',
  SOIL_SATURATION_FIELD_ANALYTIC: 'SOIL_SATURATION_FIELD_ANALYTIC',
  SOIL_EROSION_FIELD_ANALYTIC: 'SOIL_EROSION_FIELD_ANALYTIC',
  LEAF_PHENOLOGY_FIELD_ANALYTIC: 'LEAF_PHENOLOGY_FIELD_ANALYTIC',
  SLOPE_FIELD_ANALYTIC: 'SLOPE_FIELD_ANALYTIC',
  ROLES_DATA: 'ROLES_DATA',
  RASTER_PAGINATION: 'RASTER_PAGINATION',
  CHECK_IS_EXIST_POINT: 'CHECK_IS_EXIST_POINT',
  COLOR_ANALYTIC_LAYER: 'COLOR_ANALYTIC_LAYER',
  ANALYTIC_BY_ID: 'ANALYTIC_BY_ID',
  FAVORITE_ANALYTIC: 'FAVORITE_ANALYTIC',
  GET_FAVORITE_ANALYTIC: 'GET_FAVORITE_ANALYTIC',
  CHECK_SUPER_ADMIN: 'CHECK_SUPER_ADMIN',
  RECENT_LOCATION: 'RECENT_LOCATION',
  REMOVE_RECENT_LOCATION: 'REMOVE_RECENT_LOCATION',
  VIGOR_ANALYSIS: 'VIGOR_ANALYSIS',
  VIGOR_INTERVALS: 'VIGOR_INTERVALS',
  PLANT_STRESS_ANALYTIC: 'PLANT_STRESS_ANALYTIC',
  PLANT_STRESS_INTERVALS: 'PLANT_STRESS_INTERVALS',
  CROWN_AREA_ANALYTIC: 'CROWN_AREA_ANALYTIC',
  CROWN_AREA_INTERVALS: 'CROWN_AREA_INTERVALS',
  USER_INFO_BY_CLIENT: 'USER_INFO_BY_CLIENT',
  CHLOROPHYTE_ANALYTIC: 'CHLOROPHYTE_ANALYTIC',
  WEED_INVASION_ANALYTIC: 'WEED_INVASION_ANALYTIC',
  SIZE_WEED_INVASION_ANALYTIC: 'SIZE_WEED_INVASION_ANALYTIC',
  CHART_WEED_INVASION_ANALYTIC: 'CHART_WEED_INVASION_ANALYTIC',
  CHARTS_CIRCUMFERENCE_ANALYTIC: 'CHARTS_CIRCUMFERENCE_ANALYTIC',
  CHARTS_PLANT_HEALTH_OIL_PAILM_ANALYTIC: 'CHARTS_PLANT_HEALTH_OIL_PAILM_ANALYTIC',
  CHARTS_VIGOR_ANALYTIC: 'CHARTS_VIGOR_ANALYTIC',
  CHARTS_WATER_ANALYTIC: 'CHARTS_WATER_ANALYTIC',
  CHARTS_STRESS_ANALYTIC: 'CHARTS_STRESS_ANALYTIC',
  CHARTS_CHLOROPHYLL_ANALYTIC: 'CHARTS_CHLOROPHYLL_ANALYTIC',
  CHARTS_CROWN_AREA_ANALYTIC: 'CHARTS_CROWN_AREA_ANALYTIC',
  FAVORITE_LOCATION_LIST: 'FAVORITE_LOCATION_LIST',
  LIST_RECENT_RECORD: 'LIST_RECENT_RECORD',
  GET_TYPE_INTERVAL: 'GET_TYPE_INTERVAL',
  GET_ALL_LIMIT_INTERVAL: 'GET_ALL_LIMIT_INTERVAL',
  CLIENT_DATA_NEW: 'CLIENT_DATA_NEW',
  GET_TREEID_TEMPLATE: 'GET_TREEID_TEMPLATE',
  VIGOR_BOX_PLOT: 'VIGOR_BOX_PLOT',
  WATER_UPTAKE_BOX_PLOT: 'WATER_UPTAKE_BOX_PLOT',
  VIGOR_POLYGON: 'VIGOR_POLYGON',
  CIRCUMFERENCES_BOX_PLOT: 'CIRCUMFERENCES_BOX_PLOT',
  CIRCUMFERENCE_POLYGON: 'CIRCUMFERENCE_POLYGON',
  CHLOROPHYLL_BOX_PLOT: 'CHLOROPHYLL_BOX_PLOT',
  CHLOROPHYLL_POLYGON: 'CHLOROPHYLL_POLYGON',
  WATER_BOX_PLOT: 'WATER_BOX_PLOT',
  WATER_POLYGON: 'WATER_POLYGON',
  WATER_ANALYTIC: 'WATER_ANALYTIC',
  STRESS_BOX_PLOT: 'STRESS_BOX_PLOT',
  STRESS_POLYGON: 'STRESS_POLYGON',
  STRESS_ANALYTIC: 'STRESS_ANALYTIC',
  CROWN_AREA_BOX_PLOT: 'CROWN_AREA_BOX_PLOT',
  CROWN_AREA_POLYGON: 'CROWN_AREA_POLYGON',
  GET_LABEL_ANALYTIC: 'GET_LABEL_ANALYTIC',
  PLANT_HEALTH_OIL_PAILM_BOX_PLOT: 'PLANT_HEALTH_OIL_PAILM_BOX_PLOT',
  PLANT_HEALTH_OIL_PAILM_POLYGON: 'PLANT_HEALTH_OIL_PAILM_POLYGON',
  PLANT_HEALTH_OIL_PAILM_ANALYTIC: 'PLANT_HEALTH_OIL_PAILM_ANALYTIC',
  GET_LIST_DISPLAYED_ISSUES: 'GET_LIST_DISPLAYED_ISSUES',
  GET_LIST_ISSUE_PAGINATION: 'GET_LIST_ISSUE_PAGINATION',
  USER_SHARING: 'GET_USER_USER_SHARING',
  GET_LAYER_VECTOR: 'GET_LAYER_VECTOR',
  GET_VECTOR_CONTOUR: 'GET_VECTOR_CONTOUR',
  GET_STREAMLINE_ORDER_LIST: 'GET_STREAMLINE_ORDER_LIST',
  GET_TREE_TILT_FALLEN_LIST: 'GET_TREE_TILT_FALLEN_LIST',
  MEDIA_DETAIL: 'MEDIA_DETAIL',
  S3_USAGE_BY_CLIENT: 'S3_USAGE_BY_CLIENT',
  GET_SURFACE_EXPOSED_WATER_PAGINATION: 'GET_SURFACE_EXPOSED_WATER_PAGINATION',
  GET_SURFACE_EXPOSED_WATER_STATISTIC: 'GET_SURFACE_EXPOSED_WATER_STATISTIC',
  GET_TENSION_CRACK_PAGINATION: 'GET_TENSION_CRACK_PAGINATION',
  GET_TENSION_CRACK_STATISTIC: 'GET_TENSION_CRACK_STATISTIC',
  GET_LIST_WORKSPACE: 'GET_LIST_WORKSPACE',
  GET_WORKSPACE_INFO: 'GET_WORKSPACE_INFO',
  GET_LIST_FIELD: 'GET_LIST_FIELD',
  GET_FIELD_DETAIL: 'GET_FIELD_DETAIL',
  GET_MIN_MAX_ZOOM: 'GET_MIN_MAX_ZOOM',
  GET_AVAILABLE_ASSET: 'GET_AVAILABLE_ASSET',
  GET_3D_VIEW: 'GET_3D_VIEW',
  GET_PRODUCTION_TREND_DATA: 'GET_PRODUCTION_TRENDS',
  GET_TRIP_STATUS: 'GET_TRIP_STATUS',
  GET_TRIP_STATUS_BY_BLOCK_PRODUCT: 'GET_TRIP_STATUS_BY_BLOCK_PRODUCT',
  GET_PRODUCTION_COST: 'GET_PRODUCTION_COST',
  GET_TONNAGE_BY_ESTATE: 'GET_TONNAGE_BY_ESTATE',
  GET_CROP_COLLECTION_DATA_BY_DATE: 'GET_CROP_COLLECTION_DATA_BY_DATE',
  GET_CROP_COLLECTION_DATA: 'GET_CROP_COLLECTION_DATA',
};

export const DEFAULT_VIEWPORT = {
  longitude: 108.42040555811695,
  latitude: 1.7624571391453259,
  zoom: 4.683092264597182,
};

export const rowPerPageOptions = [5, 10, 15, 20];
export const userTypeNameOptionsAerodyne = ['AERODYNE-SR-ADMIN', 'AERODYNE-JR-ADMIN', 'AERODYNE-PILOT'];
export const userTypeNameOptionsClient = ['CLIENT-ADMIN', 'CLIENT-USER'];

export const permissionsData = [
  {
    key: 'DASHBOARD-OVERVIEW',
    name: 'DASHBOARD-OVERVIEW',
    label: 'Overview',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
    ],
  },
  {
    key: 'MANAGE-DASHBOARD',
    name: 'MANAGE-DASHBOARD',
    label: 'Dashboard',
    items: [
      {
        key: 'MANAGE_DASHBOARD_VIEW',
        name: 'permission view dashboard',
        checked: true,
      },
      {
        key: 'MANAGE_DASHBOARD_EDIT',
        name: 'permission edit dashboard',
        checked: true,
      },
    ],
  },
  {
    key: 'MANAGE-ANALYTICAL-INFORMATION',
    name: 'MANAGE-ANALYTICAL-INFORMATION',
    label: 'Analytical_Information',
    items: [
      {
        key: 'MANAGE_ANALYTICAL_INFORMATION_VIEW',
        name: 'permission view analytical information',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYTICAL_INFORMATION_EDIT',
        name: 'permission edit analytical information',
        checked: true,
      },
    ],
  },
  {
    key: 'DASHBOARD-USER-MANAGEMENT',
    name: 'DASHBOARD-USER-MANAGEMENT',
    label: 'User_Management',
    items: [
      {
        key: 'MANAGE_USERS_VIEW',
        name: 'permission view users',
        checked: true,
      },
      {
        key: 'MANAGE_USERS_ADD',
        name: 'permission add users',
        checked: true,
      },
      {
        key: 'MANAGE_USERS_EDIT',
        name: 'permission edit users',
        checked: true,
      },
      {
        key: 'MANAGE_USERS_DELETE',
        name: 'permission delete users',
        checked: true,
      },
    ],
  },
  {
    key: 'DASHBOARD-DATA-CONFIGURATION',
    name: 'DASHBOARD-DATA-CONFIGURATION',
    label: 'Data_Configuration',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_VIEW',
        name: 'permission view analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CROP_TYPE_VIEW',
        name: 'permission view croptype',
        checked: true,
      },
      {
        key: 'MANAGE_HIERARCHY_STRUCTURE_VIEW',
        name: 'permission view hierarchy structure ',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_ADD',
        name: 'permission add analytics',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_EDIT',
        name: 'permission edit analytics',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_DELETE',
        name: 'permission delete analytics',
        checked: true,
      },
      {
        key: 'MANAGE_LANDUSE_ANALYSIS_ADD',
        name: 'permission import landuse analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STAND_COUNT_ANALYSIS_ADD',
        name: 'permission import stand count analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CROP_COVER_ANALYSIS_ADD',
        name: 'permission import crop cover analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CIRCUMFERENCE_ANALYSIS_ADD',
        name: 'permission import circumference analytics',
        checked: true,
      },
      {
        key: 'MANAGE_PLANT_VIGOR_ANALYSIS_ADD',
        name: 'permission import vigor analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CROWN_AREA_ANALYSIS_ADD',
        name: 'permission import crown area analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STRESS_ANALYSIS_ADD',
        name: 'permission import stress analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CHLOROPHYLL_ANALYSIS_ADD',
        name: 'permission import chlorophyll analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WATER_UPTAKE_ANALYSIS_ADD',
        name: 'permission import water uptake analytics',
        checked: true,
      },
      {
        key: 'MANAGE_VACANT_AREA_ANALYSIS_ADD',
        name: 'permission import vacant area analytics',
        checked: true,
      },
      {
        key: 'MANAGE_TILLER_DENSITY_ANALYSIS_ADD',
        name: 'permission import tiller density analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WEED_INVASION_ANALYSIS_ADD',
        name: 'permission import weed invasion analytics',
        checked: true,
      },
      {
        key: 'MANAGE_VIGOR_PADDY_ANALYSIS_ADD',
        name: 'permission import vigor paddy analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CHLOROPHYLL_PADDY_ANALYSIS_ADD',
        name: 'permission import chlorophyll paddy analytics',
        checked: true,
      },
      {
        key: 'MANAGE_PLANT_HEALTH_FIELD_ANALYSIS_ADD',
        name: 'permission import plant health field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STRESS_FIELD_ANALYSIS_ADD',
        name: 'permission import stress field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WATER_UPTAKE_FIELD_ANALYSIS_ADD',
        name: 'permission import water uptake field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_IMPORT_ZIP_FILE',
        name: 'permission import raster data',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_EXPORT_SCRIPT_FILE',
        name: 'export script',
        checked: true,
      },
    ],
  },
  {
    key: 'DASHBOARD-ACTIVITY',
    name: 'DASHBOARD-ACTIVITY',
    label: ' Activity',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
    ],
  },
  {
    key: 'DASHBOARD-AUDIT-TRAIL',
    name: 'DASHBOARD-AUDIT-TRAIL',
    label: 'Audit_Trial',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
      {
        key: 'MANAGE_AUDIT_TRAIL_VIEW',
        name: 'permission view audit trail',
        checked: true,
      },
    ],
  },
  {
    key: 'DASHBOARD-CLIENT-APIS',
    name: 'DASHBOARD-CLIENT-APIS',
    label: 'Client_Apis',
    items: [
      {
        key: 'MANAGE_CLIENT_SETTING_GENERATE_CLIENT_KEY',
        name: 'Generate Client Key',
        checked: true,
      },
    ],
  },
  {
    key: 'DASHBOARD-CLIENT-SETTINGS',
    name: 'DASHBOARD-CLIENT-SETTINGS',
    label: 'Client_Settings',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
      {
        key: 'MANAGE_CLIENT_SETTING_EDIT',
        name: 'permission edit client settings',
        checked: true,
      },
      {
        key: 'MANAGE_CLIENT_SETTING_VIEW',
        name: 'permission view client settings',
        checked: true,
      },
    ],
  },
  {
    key: 'MAPVIEW',
    name: 'MAPVIEW',
    label: 'Map_View',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
      {
        key: 'MANAGE_CROP_TYPE_VIEW',
        name: 'permission view croptype',
        checked: true,
      },
      {
        key: 'MANAGE_CLIENT_SETTING_VIEW',
        name: 'permission view client settings',
        checked: true,
      },
      {
        key: 'MANAGE_HIERARCHY_STRUCTURE_VIEW',
        name: 'permission view hierarchy structure',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_VIEW',
        name: 'permission view analytics',
        checked: true,
      },
    ],
  },
  {
    key: 'MAPVIEW-ANALYSIS',
    name: 'MAPVIEW-ANALYSIS',
    label: 'Analytics',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
      {
        key: 'MANAGE_CLIENT_SETTING_EDIT',
        name: 'permission edit client settings',
        checked: true,
      },
      {
        key: 'MANAGE_LANDUSE_ANALYSIS_VIEW',
        name: 'permission view landuse analytics',
        checked: true,
      },
      {
        key: 'MANAGE_ANALYSIS_VIEW',
        name: 'permission view analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CROP_COVER_ANALYSIS_VIEW',
        name: 'permission view crop cover analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CIRCUMFERENCE_ANALYSIS_VIEW',
        name: 'permission view circumference analytics',
        checked: true,
      },
      {
        key: 'MANAGE_PLANT_VIGOR_ANALYSIS_VIEW',
        name: 'permission view vigor analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CROWN_AREA_ANALYSIS_VIEW',
        name: 'permission view crown area analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STRESS_ANALYSIS_VIEW',
        name: 'permission view stress analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STAND_COUNT_ANALYSIS_VIEW',
        name: 'permission view stand count analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CHLOROPHYLL_ANALYSIS_VIEW',
        name: 'permission view chlorophyll analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WATER_UPTAKE_ANALYSIS_VIEW',
        name: 'permission view water uptake analytics',
        checked: true,
      },
      {
        key: 'MANAGE_VACANT_AREA_ANALYSIS_VIEW',
        name: 'permission view vacant area analytics',
        checked: true,
      },
      {
        key: 'MANAGE_TILLER_DENSITY_ANALYSIS_VIEW',
        name: 'permission view tiller density analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WEED_INVASION_ANALYSIS_VIEW',
        name: 'permission view weed invasion analytics',
        checked: true,
      },
      {
        key: 'MANAGE_VIGOR_PADDY_ANALYSIS_VIEW',
        name: 'permission view vigor paddy analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CHLOROPHYLL_PADDY_ANALYSIS_VIEW',
        name: 'permission view chlorophyll paddy analytics',
        checked: true,
      },
      {
        key: 'MANAGE_PLANT_HEALTH_FIELD_ANALYSIS_VIEW',
        name: 'permission view plant health field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_PLANT_HEALTH_TREE_ANALYSIS_VIEW',
        name: 'permission view plant health tree analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STRESS_FIELD_ANALYSIS_VIEW',
        name: 'permission view stress field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WATER_UPTAKE_FIELD_ANALYSIS_VIEW',
        name: 'permission view water uptake field analytics',
        checked: true,
      },

      // edit analysis part
      {
        key: 'MANAGE_VACANT_AREA_ANALYSIS_EDIT',
        name: 'permission edit vacant area analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WEED_INVASION_ANALYSIS_EDIT',
        name: 'permission edit weed invasion analytics',
        checked: true,
      },
      {
        key: 'MANAGE_PLANT_HEALTH_FIELD_ANALYSIS_EDIT',
        name: 'permission edit plant health field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_CHLOROPHYLL_PADDY_ANALYSIS_EDIT',
        name: 'permission edit chlorophyll field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_TILLER_DENSITY_ANALYSIS_EDIT',
        name: 'permission edit tiller density analytics',
        checked: true,
      },
      {
        key: 'MANAGE_VIGOR_PADDY_ANALYSIS_EDIT',
        name: 'permission edit vigor field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STRESS_FIELD_ANALYSIS_EDIT',
        name: 'permission edit stress field analytics',
        checked: true,
      },
      {
        key: 'MANAGE_WATER_UPTAKE_FIELD_ANALYSIS_EDIT',
        name: 'permission edit water uptake field analytics',
        checked: true,
      },
    ],
  },
  {
    key: 'MAPVIEW-ANALYSIS-TAB-EDIT',
    name: 'MAPVIEW-ANALYSIS-TAB-EDIT',
    label: 'Edit Stand Count Analytics',
    isChildren: true,
    items: [
      {
        key: 'MAPVIEW_ANALYSIS_STAND_COUNT_TAB_EDIT_VIEW',
        name: 'permission to view tab edit stand count analytics',
        checked: true,
      },
      {
        key: 'MANAGE_STAND_COUNT_ANALYSIS_EDIT',
        name: 'permission edit stand count analytics',
        checked: true,
      },
    ],
  },
  {
    key: 'MAPVIEW-INFORMATION',
    name: 'MAPVIEW-INFORMATION',
    label: 'Information',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
      {
        key: 'MANAGE_HIERARCHY_STRUCTURE_VIEW',
        name: 'permission view hierarchy structure',
        checked: true,
      },
      {
        key: 'MANAGE_CLIENT_SETTING_VIEW',
        name: 'permission view client settings',
        checked: true,
      },
      {
        key: 'MANAGE_HIERARCHY_STRUCTURE_EDIT',
        name: 'permission to edit the upload file',
        checked: true,
      },
    ],
  },
  // {
  //   key: 'MAPVIEW-ALERT',
  //   name: 'MAPVIEW-ALERT',
  //   label: 'Issue',
  //   items: [
  //     {
  //       key: 'MANAGE_CLIENTS_VIEW',
  //       name: 'permission view clients',
  //       checked: true,
  //     },
  //   ],
  // },
  {
    key: 'MAPVIEW-MANAGE-ISSUE',
    name: 'MAPVIEW-MANAGE-ISSUE',
    label: 'Issue',
    items: [
      {
        key: 'MANAGE_ISSUE_VIEW',
        name: 'permission view issue',
        checked: true,
      },
      {
        key: 'MANAGE_ISSUE_ADD',
        name: 'permission add issue',
        checked: true,
      },
      {
        key: 'MANAGE_ISSUE_EDIT',
        name: 'permission edit issue',
        checked: true,
      },
      {
        key: 'MANAGE_ISSUE_DELETE',
        name: 'permission delete issue',
        checked: true,
      },
      {
        key: 'MANAGE_ISSUE_EXPORT',
        name: 'permission export issue',
        checked: true,
      },
      {
        key: 'MANAGE_ISSUE_SHARING',
        name: 'permission share issue',
        checked: true,
      },
    ],
  },
  {
    key: 'MAPVIEW-DRONE-OPERATION',
    name: 'MAPVIEW-DRONE-OPERATION',
    label: 'Flight Mission',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
    ],
  },
  {
    key: 'MAPVIEW-MEASUREMENT',
    name: 'MAPVIEW-MEASUREMENT',
    label: 'Flight Log',
    items: [
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        checked: true,
      },
    ],
  },
  {
    key: 'UTILITY-SETTINGS-FORMULA-BUTTON',
    name: 'UTILITY-SETTINGS-FORMULA-BUTTON',
    label: 'Sum Formula Button',
    items: [],
  },
  {
    key: 'UTILITY-SETTINGS-TEMPORAL-BUTTON',
    name: 'UTILITY-SETTINGS-TEMPORAL-BUTTON',
    label: 'Temporal Button',
    items: [],
  },
];

//
export const listOfApi = [
  {
    Type: 'VIEW',
    API: [
      {
        key: 'MANAGE_ISSUE_VIEW',
        name: 'permission view issue',
        description: 'Able to view issue in the system if user has access to the client',
      },
      {
        key: 'MANAGE_CLIENTS_VIEW',
        name: 'permission view clients',
        description: 'Able to view clients in the system if user has access to the client',
      },
      {
        key: 'MANAGE_USERS_VIEW',
        name: 'permission view users',
        description: 'Able user to view all users assigned to selected client or all users if user is super admin',
      },
      {
        key: 'MANAGE_CROP_TYPE_VIEW',
        name: 'permission view type',
        description: 'Able to view all types in the system',
      },
      {
        key: 'MANAGE_HIERARCHY_STRUCTURE_VIEW',
        name: 'permission view hierarchy structure ',
        description: 'Able to view all hierarchy structure in the system',
      },
      {
        key: 'MANAGE_AUDIT_TRAIL_VIEW',
        name: 'permission view audit trail',
        description: 'Able to view all audit trail of all users assigned to selected client',
      },
      {
        key: 'MANAGE_CLIENT_SETTING_VIEW',
        name: 'permission view client settings',
        description: 'Able to view all client settings of selected client',
      },
      {
        key: 'MANAGE_ANALYSIS_VIEW',
        name: 'permission view analytics',
        description: 'Able to view all analytics of selected client',
      },
      {
        key: 'MANAGE_LANDUSE_ANALYSIS_VIEW',
        name: 'permission view landuse analytics',
        description: 'Able to view all landuse analytics of selected client',
      },
      {
        key: 'MANAGE_CROP_COVER_ANALYSIS_VIEW',
        name: 'permission view crop cover analytics',
        description: 'Able to view all crop cover analytics of selected client',
      },
      {
        key: 'MANAGE_CIRCUMFERENCE_ANALYSIS_VIEW',
        name: 'permission view circumference analytics',
        description: 'Able to view all circumference analytics of selected client',
      },
      {
        key: 'MANAGE_PLANT_VIGOR_ANALYSIS_VIEW',
        name: 'permission view vigor analytics',
        description: 'Able to view all vigor analytics of selected client',
      },
      {
        key: 'MANAGE_CROWN_AREA_ANALYSIS_VIEW',
        name: 'permission view crown area analytics',
        description: 'Able to view all crown area analytics of selected client',
      },
      {
        key: 'MANAGE_STRESS_ANALYSIS_VIEW',
        name: 'permission view stress analytics',
        description: 'Able to view all stress analytics of selected client',
      },
      {
        key: 'MANAGE_STAND_COUNT_ANALYSIS_VIEW',
        name: 'permission view stand count analytics',
        description: 'Able to view all stand count analytics of selected client',
      },
      {
        key: 'MANAGE_CHLOROPHYLL_ANALYSIS_VIEW',
        name: 'permission view chlorophyll analytics',
        description: 'Able to view all chlorophyll analytics of selected client',
      },
      {
        key: 'MANAGE_WATER_UPTAKE_ANALYSIS_VIEW',
        name: 'permission view water uptake analytics',
        description: 'Able to view all water uptake analytics of selected client',
      },
      {
        key: 'MANAGE_VACANT_AREA_ANALYSIS_VIEW',
        name: 'permission view vacant area analytics',
        description: 'Able to view all vacant area analytics of selected client',
      },
      {
        key: 'MANAGE_TILLER_DENSITY_ANALYSIS_VIEW',
        name: 'permission view tiller density analytics',
        description: 'Able to view all tiller density analytics of selected client',
      },
      {
        key: 'MANAGE_WEED_INVASION_ANALYSIS_VIEW',
        name: 'permission view weed invasion analytics',
        description: 'Able to view all weed invasion analytics of selected client',
      },
      {
        key: 'MANAGE_VIGOR_PADDY_ANALYSIS_VIEW',
        name: 'permission view vigor paddy analytics',
        description: 'Able to view all vigor paddy analytics of selected client',
      },
      {
        key: 'MANAGE_CHLOROPHYLL_PADDY_ANALYSIS_VIEW',
        name: 'permission view chlorophyll paddy analytics',
        description: 'Able to view all chlorophyll paddy analytics of selected client',
      },
      {
        key: 'MANAGE_PLANT_HEALTH_FIELD_ANALYSIS_VIEW',
        name: 'permission view plant health field analytics',
        description: 'Able to view all plant health field analytics of selected client',
      },
      {
        key: 'MANAGE_PLANT_HEALTH_TREE_ANALYSIS_VIEW',
        name: 'permission view plant health tree analytics',
        description: 'Able to view all plant health tree analytics of selected client',
      },
      {
        key: 'MANAGE_STRESS_FIELD_ANALYSIS_VIEW',
        name: 'permission view stress field analytics',
        description: 'Able to view all stress field analytics of selected client',
      },
      {
        key: 'MANAGE_WATER_UPTAKE_FIELD_ANALYSIS_VIEW',
        name: 'permission view water uptake field analytics',
        description: 'Able to view all stress field analytics of selected client',
      },
      {
        key: 'MANAGE_DASHBOARD_VIEW',
        name: 'permission view dashboard',
        description: 'Able to view all dashboard data',
      },
      {
        key: 'MANAGE-ANALYTICAL-INFORMATION',
        name: 'permission view analytical information',
        description: 'Able to view all analytical information',
      },
    ],
  },
  {
    Type: 'ADD',
    API: [
      {
        key: 'MANAGE_ISSUE_ADD',
        name: 'permission add issue',
        description: 'Able to add new issue to selected client',
      },
      {
        key: 'MANAGE_USERS_ADD',
        name: 'permission add users',
        description: 'Able to add new user to selected client',
      },
      {
        key: 'MANAGE_ANALYSIS_ADD',
        name: 'permission add analytics',
        description: 'Able to add new user to selected client',
      },
    ],
  },
  {
    Type: 'IMPORT',
    API: [
      {
        key: 'MANAGE_LANDUSE_ANALYSIS_ADD',
        name: 'permission import landuse analytics',
        description: 'Able to import new landuse analytics to selected client',
      },
      {
        key: 'MANAGE_STAND_COUNT_ANALYSIS_ADD',
        name: 'permission import stand count analytics',
        description: 'Able to import new stand count analytics to selected client',
      },
      {
        key: 'MANAGE_CROP_COVER_ANALYSIS_ADD',
        name: 'permission import crop cover analytics',
        description: 'Able to import new crop cover analytics to selected client',
      },
      {
        key: 'MANAGE_CIRCUMFERENCE_ANALYSIS_ADD',
        name: 'permission import circumference analytics',
        description: 'Able to import new circumference analytics to selected client',
      },
      {
        key: 'MANAGE_PLANT_VIGOR_ANALYSIS_ADD',
        name: 'permission import vigor analytics',
        description: 'Able to import new vigor analytics to selected client',
      },
      {
        key: 'MANAGE_CROWN_AREA_ANALYSIS_ADD',
        name: 'permission import crown area analytics',
        description: 'Able to import new crown area analytics to selected client',
      },
      {
        key: 'MANAGE_STRESS_ANALYSIS_ADD',
        name: 'permission import stress analytics',
        description: 'Able to import new stress analytics to selected client',
      },
      {
        key: 'MANAGE_CHLOROPHYLL_ANALYSIS_ADD',
        name: 'permission import chlorophyll analytics',
        description: 'Able to import new chlorophyll analytics to selected client',
      },
      {
        key: 'MANAGE_WATER_UPTAKE_ANALYSIS_ADD',
        name: 'permission import water uptake analytics',
        description: 'Able to import new water uptake analytics to selected client',
      },
      {
        key: 'MANAGE_VACANT_AREA_ANALYSIS_ADD',
        name: 'permission import vacant area analytics',
        description: 'Able to import new vacant area analytics to selected client',
      },
      {
        key: 'MANAGE_TILLER_DENSITY_ANALYSIS_ADD',
        name: 'permission import tiller density analytics',
        description: 'Able to import new tiller density analytics to selected client',
      },
      {
        key: 'MANAGE_WEED_INVASION_ANALYSIS_ADD',
        name: 'permission import weed invasion analytics',
        description: 'Able to import new weed invasion analytics to selected client',
      },
      {
        key: 'MANAGE_VIGOR_PADDY_ANALYSIS_ADD',
        name: 'permission import vigor paddy analytics',
        description: 'Able to import new vigor paddy analytics to selected client',
      },
      {
        key: 'MANAGE_CHLOROPHYLL_PADDY_ANALYSIS_ADD',
        name: 'permission import chlorophyll paddy analytics',
        description: 'Able to import new chlorophyll paddy analytics to selected client',
      },
      {
        key: 'MANAGE_PLANT_HEALTH_FIELD_ANALYSIS_ADD',
        name: 'permission import plant health field analytics',
        description: 'Able to import new plant health field analytics to selected client',
      },
      {
        key: 'MANAGE_STRESS_FIELD_ANALYSIS_ADD',
        name: 'permission import stress field analytics',
        description: 'Able to import new stress field analytics to selected client',
      },
      {
        key: 'MANAGE_WATER_UPTAKE_FIELD_ANALYSIS_ADD',
        name: 'permission import water uptake field analytics',
        description: 'Able to import new water uptake field analytics to selected client',
      },
      {
        key: 'MANAGE_ANALYSIS_IMPORT_ZIP_FILE',
        name: 'permission import raster data',
        description: 'Able to import raster data to selected client',
      },
      {
        key: 'MANAGE_PLANT_HEALTH_TREE_ANALYSIS_ADD',
        name: 'permission import plant health tree analytics',
        description: 'Able to import new plant health tree analytics to selected client',
      },
    ],
  },
  {
    Type: 'EXPORT',
    API: [
      {
        key: 'MANAGE_ISSUE_EXPORT',
        name: 'permission export issue',
        description: 'Able to export issue analytics to selected client',
      },
      {
        key: 'MANAGE_ANALYSIS_EXPORT_SCRIPT_FILE',
        name: 'export script',
        description: 'Able to generate the information for analytics',
      },
    ],
  },
  {
    Type: 'EDIT',
    API: [
      {
        key: 'MANAGE_USERS_EDIT',
        name: 'permission edit users',
        description: 'Able to edit or reset password user assigned to selected client',
      },
      {
        key: 'MANAGE_ISSUE_EDIT',
        name: 'permission edit issue',
        description: 'Able to edit issue to selected client',
      },
      {
        key: 'MANAGE_VACANT_AREA_ANALYSIS_EDIT',
        name: 'permission edit vacant area analytics',
        description: 'Able to edit vacant area analytics to selected client',
      },
      {
        key: 'MANAGE_WEED_INVASION_ANALYSIS_EDIT',
        name: 'permission edit weed invasion analytics',
        description: 'Able to edit weed invasion analytics to selected client',
      },
      {
        key: 'MANAGE_PLANT_HEALTH_FIELD_ANALYSIS_EDIT',
        name: 'permission edit plant health field analytics',
        description: 'Able to edit plant health field analytics to selected client',
      },
      {
        key: 'MANAGE_CHLOROPHYLL_PADDY_ANALYSIS_EDIT',
        name: 'permission edit chlorophyll field analytics',
        description: 'Able to edit chlorophyll field analytics to selected client',
      },
      {
        key: 'MANAGE_TILLER_DENSITY_ANALYSIS_EDIT',
        name: 'permission edit tiller density analytics',
        description: 'Able to edit tiller density analytics to selected client',
      },
      {
        key: 'MANAGE_VIGOR_PADDY_ANALYSIS_EDIT',
        name: 'permission edit vigor field analytics',
        description: 'Able to edit vigor field analytics to selected client',
      },
      {
        key: 'MANAGE_STRESS_FIELD_ANALYSIS_EDIT',
        name: 'permission edit stress field analytics',
        description: 'Able to edit stress field analytics to selected client',
      },
      {
        key: 'MANAGE_WATER_UPTAKE_FIELD_ANALYSIS_EDIT',
        name: 'permission edit water uptake field analytics',
        description: 'Able to edit water uptake field analytics to selected client',
      },
    ],
  },
  {
    Type: 'DELETE',
    API: [
      {
        key: 'MANAGE_USERS_DELETE',
        name: 'permission delete users',
        description: 'Able to delete user assigned to selected client',
      },
      {
        key: 'MANAGE_ANALYSIS_DELETE',
        name: 'permission delete analytics',
        description: 'Able to delete analytics of selected client',
      },
      {
        key: 'MANAGE_ISSUE_DELETE',
        name: 'permission delete issue',
        description: 'Able to delete issue to selected client',
      },
    ],
  },
  {
    Type: 'SHARING',
    API: [
      {
        key: 'MANAGE_ISSUE_SHARING',
        name: 'permission share issue',
        description: 'Able to sharing issue to selected client',
      },
    ],
  },
];

export const MANAGE_CLIENT_SETTING_VIEW = 'MANAGE_CLIENT_SETTING_VIEW';

export const FORMAT_DATE = 'YYYY-MM-DD';

export const STATUS_API = {
  LOADING: 'loading',
  SUCCESS: 'success',
};

export const ECHARTS_NAME = {
  TONNAGE_DISTRIBUTION_PIE: 'tonnageDistributionPie',
  TONNAGE_DISTRIBUTION_STACKED_BAR: 'tonnageDistributionStackedBar',
  CALENDAR_HEATMAP: 'calendarHeatMap',
  PRODUCTION_TREND_PIE: 'productionTrendPie',
  WEIGHBRIDGE_BY_PRODUCT_LINE: 'weighbridgeByProductLine',
  TRIP_STATUS_BY_BLOCK_PRODUCT_PIE: 'tripStatusByBlockPie',
  TRIP_STATUS_BY_PRODUCT_STACKED_BAR: 'tripStatusByProductStackedBar',
  TRIP_STATUS_BY_SECOND_WEIGHT_PIE: 'tripStatusBySecondWeightPie',
  PRODUCTION_COST_PMT_DONUT: 'productionCostPMTDonut',
  PRODUCTION_COST_BY_BLOCK_DONUT: 'productionCostByBlockDonut',
  PRODUCTION_EXPENDITURE_DONUT: 'productionExpenditureDonut',
};

export const ANALYTICS_TABS: IAnalyticsTabs = {
  PRODUCTION: 'Production',
  WEIGHBRIDGE: 'Weighbridge',
  LOGISTIC: 'Logistic',
  COST: 'Cost',
  EXPENDITURE: 'Expenditure',
};
export const BOTTOM_BAR_MONTH_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const SEVERITY: IPolygonFilterSeverity = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
};

export const MAP_ANGLES = {
  DEGREE_90: 90,
  DEGREE_0: 0,
};
export const POLYGON_FILTER_CHECK_BOX_STATE: IPolygonFilterCheckBoxState = {
  low: true,
  medium: true,
  high: true,
};

export const CROP_HIERARCHY: ICropHierarchy = {
  LOCATION: 'Location',
  DIV_NAME: 'DivName',
  YOP: 'YOP',
  BLOCK_NAME: 'BlockName',
};

export const PRODUCTION_TRENDS_TYPES: IProductionTrendsTypes = {
  MONTH: 'month',
  DAY: 'day',
};

export const PIE_CHART_NO_DATA_LABEL = [{ value: 0, name: 'No data Available' }];

export const NO_DATA_LABEL = ['No data Available'];

export const DASHBOARD_PDF_KEYS = {
  TONNAGE_PIECHART: 'Tonnage-Distribution',
  TONNAGE_BARCHART: 'Tonnage-Distribution-Bar',
  PRODUCTION_TRENDS: 'Production-Trends',
  WEIGHBRIDGE_PRODUCT: 'Weighbridge-Product',
  TRIP_STATUS: 'Trip-Status',
  TRIP_STATUS_BARCHART: 'Trip-Status-Bar',
  TRIP_STATUS_SECOND_WEIGHT: 'Trip-Status-Second-Weight',
  PRODUCTION_COST_PMTDATA: 'Production-Cost-PMTData',
  PRODUCTION_COST_PHDATA: 'Production-Cost-PHData',
  PRODUCTION_EXPENDITURE: 'Production-Expenditure-Data',
};

export const PDF_REPORT_TABLE_HEADERS = {
  [DASHBOARD_PDF_KEYS.TONNAGE_PIECHART]: ['ESTATE', 'TOTAL'],
  [DASHBOARD_PDF_KEYS.TONNAGE_BARCHART]: ['ESTATE', 'CONFIRMED', 'RUNNING'],
  [DASHBOARD_PDF_KEYS.PRODUCTION_TRENDS]: ['PRODUCTION', 'TOTAL'],
  [DASHBOARD_PDF_KEYS.WEIGHBRIDGE_PRODUCT]: ['PRODUCTION/MONTH', ...BOTTOM_BAR_MONTH_LIST],
  [DASHBOARD_PDF_KEYS.TRIP_STATUS]: ['STATUS', 'TOTAL'],
  [DASHBOARD_PDF_KEYS.TRIP_STATUS_BARCHART]: ['BLOCK/ESTATE', 'CONFIRMED', 'RUNNING'],
  [DASHBOARD_PDF_KEYS.TRIP_STATUS_SECOND_WEIGHT]: ['STATUS', 'TOTAL'],
  [DASHBOARD_PDF_KEYS.PRODUCTION_COST_PMTDATA]: ['BLOCK', 'TONNE'],
  [DASHBOARD_PDF_KEYS.PRODUCTION_COST_PHDATA]: ['BLOCK', 'HECTARE'],
  [DASHBOARD_PDF_KEYS.PRODUCTION_EXPENDITURE]: ['BLOCK', 'EXPENDITURE'],
};

export const CHART_TYPE = {
  PIE: 'pie',
  BAR: 'bar',
  LINE: 'line',
};
