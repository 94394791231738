export const DASHBOARD_OLD_NAV_BAR = [
  'Overview',
  'Data Configuration',
  'User Management',
  'Activity',
  'Client Settings',
  'Audit Trail',
  'Client Apis',
];

export const DASHBOARD_NEW_NAV_BAR = ['Overview', 'User Management'];
