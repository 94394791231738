import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

export interface ISuperAdminSlice {
  isSuperAdmin: boolean;
  isSuperUser: boolean;
  permissions: { [key: string]: boolean }
}

const initialState: ISuperAdminSlice = {
  isSuperAdmin: false,
  isSuperUser: false,
  permissions: {}
};

export const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    saveSuperAdminOrSuperUser: (state, action) => {
      state.isSuperAdmin = action.payload?.isSuperAdmin;
      state.isSuperUser = action.payload?.isSuperUser
    },
    savePermissions: (state, action) => {
      state.permissions = action.payload
    },
    clearUserPermissionsAndUserType: (state) => {
      state.isSuperAdmin = false;
      state.isSuperUser = false;
      state.permissions = {}
    }
  },
});

export const { saveSuperAdminOrSuperUser, savePermissions, clearUserPermissionsAndUserType } = superAdminSlice.actions;

export const superAdminSelector = (state: RootState) => state.superAdmin;

export default superAdminSlice.reducer;
