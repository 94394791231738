import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, GridProps, IconButton, styled, Typography } from '@mui/material';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { deleteRasterAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  anaLyticConfigSelector,
  changePageRasterPagination,
  changeSensorSelected,
} from 'store/slices/analyticsConfigSlice';
import { DialogCreateAnalytics } from '../Dialog/DialogCreateAnalytics';

const Card = styled((props: { isSelected: boolean } & GridProps) => <Grid {...props} />)(({ theme, isSelected }) => ({
  border: `1px solid ${theme.palette.color.grey2}`,
  borderRadius: theme.spacing(0.5),
  width: '100%',
  height: theme.spacing(6),
  display: 'flex',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: isSelected ? theme.palette.color.green5 : '',
  '&:hover': {
    backgroundColor: theme.palette.color.green5,
  },
}));

const ButtonCustom = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(4),
  minWidth: 'initial',
  height: theme.spacing(4),
  marginLeft: theme.spacing(1),
}));

interface IRasterCard {
  sensor: any;
  isSelected: boolean;
  isLastLevelId?: boolean;
}

export const RasterCard = ({ sensor, isSelected, isLastLevelId }: IRasterCard) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateAnalytics, setOpenCreateAnalytics] = useState(false);
  const { pageRaster } = useAppSelector(anaLyticConfigSelector);

  const handleDelete = useMutation((id: string) => deleteRasterAnalytic(id), {
    onSuccess: () => {
      setOpenDeleteModal(false);
      queryClient.invalidateQueries([QUERY_KEY.SENSORS, sensor.levelId]);
      dispatch(changeSensorSelected({ sensor: null, type: null }));
      toast.success('Delete Analytics Configuration Successful', { toastId: 1 });
      dispatch(changePageRasterPagination(1));
      queryClient.invalidateQueries([QUERY_KEY.RASTER_PAGINATION, pageRaster, sensor.levelId]);
    },
    onError: () => {
      setOpenDeleteModal(false);
      toast.error('Delete Analytics Configuration Error', { toastId: 1 });
    },
  });

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onDelete = () => {
    handleDelete.mutate(sensor._id);
  };

  return (
    <Card
      sx={{ maxHeight: '37px' }}
      isSelected={isSelected}
      onClick={() => dispatch(changeSensorSelected({ sensor: sensor._id, type: sensor.name }))}>
      <Typography component={'span'}>{sensor.name}</Typography>
      <Grid sx={{ marginLeft: 'auto' }}>
        <Typography component={'span'}>{moment(sensor.date).format('DD/MM/YYYY')}</Typography>
        <ButtonCustom
          aria-label="delete"
          onClick={() => {
            setOpenDeleteModal(true);
          }}>
          <DeleteIcon fontSize="inherit" />
        </ButtonCustom>
        {isLastLevelId &&
          <ButtonCustom aria-label="add" onClick={() => setOpenCreateAnalytics(true)}>
            <AddIcon fontSize="inherit" />
          </ButtonCustom>
        }
      </Grid>
      {/* delete */}
      <ClientActionsDialog
        title="Delete Raster"
        open={openDeleteModal}
        onClose={closeDeleteModal}
        description="Are you sure about that?"
        onCancel={closeDeleteModal}
        onSave={onDelete}
        submit={false}
      />
      {openCreateAnalytics && (
        <DialogCreateAnalytics
          levelId={sensor.levelId}
          parentId={sensor._id}
          name={sensor.name}
          onClose={() => setOpenCreateAnalytics(false)}
        />
      )}
    </Card>
  );
};
