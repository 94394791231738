import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { MediaTypeEnum } from '../../../interfaces';

interface UploadFileProps {
  categoryUploadMedia: (arg1: FormData, arg2: MediaTypeEnum) => void;
  documentLink: string;
  setDocumentLink: (arg: string) => void;
}

const UploadFile: FC<UploadFileProps> = ({ categoryUploadMedia, documentLink, setDocumentLink }) => {
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : '';
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      categoryUploadMedia(formData, MediaTypeEnum.DOCUMENT);
    }
  };

  const handleRemoveSelectedDocument = () => {
    setDocumentLink('');
  };

  const showFileName = () => {
    const splitName = documentLink.split('/');
    const fileName = decodeURIComponent(splitName.slice(-1)[0]);
    return fileName;
  };

  return (
    <>
      <Grid item xs={3}>
        <Typography sx={{ fontWeight: '500' }}>Upload document: </Typography>
      </Grid>
      <Grid
        item
        xs={9}
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button size="small" variant="contained" sx={{ p: 0, minWidth: 'auto' }}>
            <label htmlFor="uploadDocument" style={{ padding: '2px 10px', cursor: 'pointer' }}>
              <Typography>File</Typography>
            </label>
          </Button>
          <input type="file" id="uploadDocument" style={{ display: 'none' }} onChange={handleUploadFile} />
          <Box sx={{ ml: '12px', maxWidth: '300px', overflowX: 'hidden' }}>
            <Typography>{showFileName()}</Typography>
          </Box>
        </Box>
        {showFileName() && (
          <DeleteOutlineOutlinedIcon
            color="error"
            sx={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={handleRemoveSelectedDocument}
          />
        )}
      </Grid>
    </>
  );
};

export default UploadFile;
