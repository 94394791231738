import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { ICropTypes } from 'common/defines/clients';
import CommonTextField from 'components/Common/CommonTextField';
import { QUERY_KEY } from 'constants/constants';
import useQueryWorkspaceInfo from 'hooks/workspace/useQueryWorkspaceInfo';
import {
  AgeEnum,
  CropIntelligenceEnum,
  FieldOptionEnum,
  IRawFileUpload,
  ITilingMapField,
  SensorTypeEnum,
} from 'interfaces/workspace';
import { FC, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { handleRawImage } from 'services/workspaces';
import SelectArea from './components/SelectArea';
import UploadImages from './components/upload-images';

const formDataOptionList = [
  { name: FieldOptionEnum['auto-boundary'], value: true },
  { name: FieldOptionEnum.dsm, value: true },
  { name: FieldOptionEnum.dtm, value: true },
  { name: FieldOptionEnum.tiles, value: true },
  { name: FieldOptionEnum['3d-tiles'], value: true },
];

export const initialValues: ITilingMapField = {
  fieldName: '',
  'auto-boundary': false,
  dsm: false,
  dtm: true,
  tiles: true,
  '3d-tiles': true,
  cropType: ICropTypes.OIL_PALM,
  age: AgeEnum.IMMATURE,
  type: CropIntelligenceEnum.RAW_IMAGE,
  sensorType: SensorTypeEnum.RGB,
};

interface FillFieldDataProps {
  form: UseFormReturn<ITilingMapField>;
  setRawFileUpload: (arg: IRawFileUpload) => void;
}

const FillFieldData: FC<FillFieldDataProps> = ({ form, setRawFileUpload }) => {
  const [imageList, setImageList] = useState<File[]>([]);
  const queryClient = useQueryClient();
  const { workspaceData } = useQueryWorkspaceInfo();
  const { projectId, _id: workspaceId } = workspaceData || {};
  const { control, handleSubmit } = form;

  const handleRawImageMutation = useMutation(handleRawImage, {
    onSuccess(res) {
      setRawFileUpload(res.data);
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
    },
  });

  const onSubmit = (data: ITilingMapField) => {
    const formData = new FormData();
    imageList.length &&
      imageList.forEach((item) => {
        formData.append('images', item);
      });
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'fieldName') formData.append('name', value);
      else formData.append(key, value);
    });
    if (projectId && workspaceId) {
      formData.append('projectId', projectId);
      formData.append('workspaceId', workspaceId);
    }
    formData.append('options', JSON.stringify(formDataOptionList));
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    handleRawImageMutation.mutate(formData);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: '8px' }}>Field name</Typography>
            <CommonTextField
              control={control}
              name="fieldName"
              rules={{
                required: {
                  value: true,
                  message: 'Name is required',
                },
              }}
            />
          </Box>
          {/* <UploadImages imageList={imageList} setImageList={setImageList} /> */}
          <SelectArea form={form} />
          <UploadImages form={form} imageList={imageList} setImageList={setImageList} />
        </Stack>
        <Box sx={{ mt: '16px', textAlign: 'right' }}>
          <LoadingButton loading={handleRawImageMutation.isLoading} variant="contained" type="submit">
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default FillFieldData;
