import { Box, FormControlLabel, Typography } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAllStreamlineOrderDisplay, rightBarSelector } from 'store/slices/rightBarSlice';
import SwitchStreamlineOrder from './SwitchStreamlineOrder';

interface SwitchStreamlineOrderListProps {}

const SwitchStreamlineOrderList: FC<SwitchStreamlineOrderListProps> = () => {
  const [isUnselectedAll, setIsUnselectedAll] = useState<boolean>(true);
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();

  const { streamlineOrderDisplay } = useAppSelector(rightBarSelector);

  const handleChangeSelectAll = () => {
    setIsUnselectedAll(!isUnselectedAll);
    const newStreamlineOrderDisplay = streamlineOrderDisplay.map((item) => ({ ...item, visible: isUnselectedAll }));
    dispatch(changeAllStreamlineOrderDisplay(newStreamlineOrderDisplay));
  };

  return (
    <Box sx={{ mt: '8px' }}>
      <FormControlLabel
        control={<SwitchCustom checked={!isUnselectedAll} onClick={handleChangeSelectAll} />}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography className={classes.selectText}>{isUnselectedAll ? 'Select All' : 'Unselect All'}</Typography>
          </Box>
        }
      />
      {streamlineOrderDisplay.length &&
        streamlineOrderDisplay.map((item, index: number) => <SwitchStreamlineOrder key={index} item={item} />)}
    </Box>
  );
};

export default SwitchStreamlineOrderList;
