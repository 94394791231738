import { Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import React, { useEffect } from 'react';
import Dark from '../../assets/images/Dark.png';
import Light from '../../assets/images/Light.png';
import NavigationDay from '../../assets/images/NavigationDay.png';
import NavigationNight from '../../assets/images/NavigationNight.png';
import Outdoors from '../../assets/images/Outdoors.png';
import Satellite from '../../assets/images/Satellite.png';
import SatelliteStreets from '../../assets/images/SatelliteStreets.png';
import Street from '../../assets/images/Street.png';
import Terrain from '../../assets/images/Terrain.png';
import './styles.scss';

interface MapTypeSelection {
  type: string;
  image: string;
}
interface MinimapProps {
  onChangeStyleMap: (style: string) => void;
}

const StylesMaps = [
  {
    id: '1',
    name: 'Street',
    style: 'mapbox://styles/mapbox/streets-v11',
  },
  {
    id: '2',
    name: 'Outdoors',
    style: 'mapbox://styles/mapbox/outdoors-v11',
  },
  {
    id: '3',
    name: 'Light',
    style: 'mapbox://styles/mapbox/light-v10',
  },
  {
    id: '4',
    name: 'Dark',
    style: 'mapbox://styles/mapbox/dark-v10',
  },
  {
    id: '5',
    name: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-v9',
  },
  {
    id: '6',
    name: 'Satellite Streets',
    style: 'mapbox://styles/mapbox/satellite-streets-v11',
  },
  {
    id: '7',
    name: 'Navigation Day',
    style: 'mapbox://styles/mapbox/navigation-day-v1',
  },
  {
    id: '8',
    name: 'Navigation Night',
    style: 'mapbox://styles/mapbox/navigation-night-v1',
  },
  {
    id: '9',
    name: '3D Terrain',
    style: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y',
  },
];

const Minimap: React.FC<MinimapProps> = ({ onChangeStyleMap }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const theme = useTheme();
  const [selected, setSelected] = React.useState<MapTypeSelection>({
    type: theme.palette.mode === 'dark' ? 'Dark' : 'Light',
    image: theme.palette.mode === 'dark' ? Dark : Light,
  });

  // set initial mapType
  useEffect(() => {
    setSelected({
      type: theme.palette.mode === 'dark' ? 'Dark' : 'Light',
      image: theme.palette.mode === 'dark' ? Dark : Light,
    });
  }, [theme.palette.mode]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleFilterImage = (name: string) => {
    let image = Dark;
    switch (name) {
      case 'Dark':
        image = Dark;
        break;
      case 'Light':
        image = Light;
        break;
      case 'Satellite':
        image = Satellite;
        break;
      case 'Outdoors':
        image = Outdoors;
        break;
      case 'Street':
        image = Street;
        break;
      case 'Satellite Streets':
        image = SatelliteStreets;
        break;
      case 'Navigation Day':
        image = NavigationDay;
        break;
      case 'Navigation Night':
        image = NavigationNight;
        break;
      case '3D Terrain':
        image = Terrain;
        break;
      default:
        image = Dark;
    }
    return image;
  };

  const onChangeTypeMap = (name: string) => {
    setSelected({
      type: name,
      image: handleFilterImage(name),
    });
    const styleMap = StylesMaps.find((item) => item.name === name);
    styleMap && onChangeStyleMap(styleMap.style || '');
    handlePopoverClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div id="minimap">
      <Stack className="minimap-box" sx={{ position: 'absolute', mr: '1' }} onClick={(e: any) => handleClick(e)}>
        <img src={selected.image} alt={selected.type.toString()} />
        <div
          style={{
            width: '79px',
            boxSizing: 'border-box',
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '-4px',
            cursor: 'pointer',
          }}
          className="minimap-box_overlay">
          {selected.type.toString()}
        </div>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 100,
          horizontal: 100,
        }}>
        <div className="list-type">
          <Stack direction="row" spacing={2} sx={{ m: 2 }}>
            {StylesMaps.map((item, id) => (
              <Box key={item.id} onClick={() => onChangeTypeMap(item.name)}>
                <Stack sx={{ maxWidth: '50px' }}>
                  <Stack className="type-map">
                    <img src={handleFilterImage(item.name)} alt="item.name" />
                  </Stack>
                  <Stack sx={{ textAlign: 'center', fontSize: '11px', paddingTop: '10px' }}>{item.name}</Stack>
                </Stack>
              </Box>
            ))}
          </Stack>
        </div>
      </Popover>
    </div>
  );
};

export default Minimap;
