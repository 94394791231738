import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import { CircularProgress, Typography } from '@mui/material';
import { IField } from 'interfaces/workspace';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDrawingBoundary, tilingMapSelector } from 'store/slices/tilingMapSlice';

interface BoundaryInteractionProps {
  fieldDetail: IField;
}

const BoundaryInteraction: FC<BoundaryInteractionProps> = ({ fieldDetail }) => {
  const dispatch = useAppDispatch();

  const {
    drawingBoundary: { isShow, isLoading },
  } = useAppSelector(tilingMapSelector);

  return (
    <>
      <Typography sx={{ fontSize: '16px', mb: '8px' }}>Boundary: </Typography>
      {isShow || isLoading ? (
        <EditOffOutlinedIcon color="disabled" />
      ) : (
        <BorderColorOutlinedIcon
          sx={{ cursor: 'pointer' }}
          onClick={() => dispatch(changeDrawingBoundary({ isShow: true, _id: fieldDetail._id }))}
          color="primary"
        />
      )}
      {isLoading && <CircularProgress color="primary" size={22} sx={{ ml: '12px' }} />}
    </>
  );
};

export default BoundaryInteraction;
