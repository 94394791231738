import { AddCircleOutline, Close, PersonRemove } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputLabel, List, Pagination, Paper, Popper, Stack, TextField, Tooltip, Typography, debounce, useTheme } from '@mui/material';
import AvatarUser from 'components/Common/AvatarUser';
import { useCropTypeSettingDialogStyles } from 'pages/SettingsPage/CroptypeSetting/CropTypeDialogSettingStyle';
import { ChangeEvent, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { uploadMedia } from 'services/media';
import CloseIcon from '@mui/icons-material/Close';
import { addLevelBasedUsersAxios, getUsersDataForListAxios, getUsersDataForSearchAxios } from 'services/clients/apiClient.services';
// import { InputSuggest } from 'components/Common/InputSuggest';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { IAddLevelBasedUsers, ISelectedUserObject } from 'common/defines/clients';
import { ClientActionsDialog } from '../ClientActionsDialog';
import { makeStyles } from '@mui/styles';

const PersonInfoForm = ({ control, setValue, remove, personIndex = 0, personInfo, level = 0, levelId = '' }: any) => {
  const theme = useTheme();
  const { clientId } = useParams();
  const inputRef = useRef<HTMLInputElement | null>(null);
  // const classes = useCropTypeSettingDialogStyles();
  const useStyles = makeStyles((theme: any) => ({
    dialogTitle: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontSize: '20px !important',
      fontWeight: '600 !important',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    dialogContent: {
      padding: '15px'
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    userListWrap: {
      margin: '0px 20px'
    },
    userListContentWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    dividerWrap: {
      margin: '10px 0px !important',
      border: '1px solid #e0e0e0 !important'
    },
    paginationWrap: {
      margin: '15px 20px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    totalRecordsSpan: {
      fontWeight: 600
    },
    scrollBarWrap: {
      height: '650px !important'
    },
    noDataWrap: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 500,
      fontSize: '16px'
    }
  }));

  const itemsPerPage: number = 10;
  const pageClasses = useStyles();
  const [avatar, setAvatar] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [userDropdownList, setUserDropdownList] = useState<any[]>([]);
  const [userActualList, setActualUserList] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [showAddUserBtn, setShowAddUserBtn] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [seletedRecordForDelete, setSeletedRecordForDelete] = useState<ISelectedUserObject | null>(null);

  useEffect(() => {
    setShowAddUserBtn(level === 1 ? true : false);
  }, [level]);

  useEffect(() => {
    if (showModal) {
      setCurrentPage(1);
      getUsersList(1);
    }
  }, [showModal])

  useEffect(() => {
    setAvatar(personInfo?.avatar);
  }, [personInfo?.avatar]);

  const uploadFileMutation = useMutation((file: any) => uploadMedia(file), {
    onSuccess: (response) => {
      setValue(`personInCharge.${personIndex}.avatar`, response?.data?.path);
      setAvatar(response?.data?.path);
    },
    onError: () => {
      toast.error('Upload file failed. Please try again!');
    },
  });

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files;
    const formData = new FormData();
    if (!file) return;
    const newFile = file[0];
    (newFile as any).preview = URL.createObjectURL(newFile);

    setAvatar((newFile as any).preview);
    formData.append('file', file[0]);
    uploadFileMutation.mutate(formData);
  };

  const handleCloseModal = () => {
    setShowModal(false)
  };

  const searchUsermutation = useMutation((searchKey: string) => getUsersDataForSearchAxios({
    page: 1,
    keyword: searchKey,
    perPage: 10,
    sortDate: 'desc',
    paginationVersion: 2,
    clientId: clientId || '',
  }), {
    onSuccess: (response) => {
      if (response && response.length) {
        let dataResult: any[] = (response[0]?.docs && response[0]?.docs.length) ? [...response[0]?.docs] : [];
        dataResult = dataResult.length ? dataResult.map(ele => ({
          _id: ele?._id || '',
          label: ele?.name || '',
          docs: { ...ele }
        })) : [];
        setUserDropdownList(dataResult);
      } else {
        setUserDropdownList([]);
      }
    },
    onError: () => {
      toast.error('Get User failed. Please try again!');
      searchUsermutation.reset();
    }
  });

  const PopperMy = (props: any) => {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
  };

  const debounceSearch = debounce((value) => searchUsermutation.mutate(value), 500);

  const onChangeAddLevelBasedUser = async (selectedUserObject: ISelectedUserObject) => {
    let payload: IAddLevelBasedUsers = {
      type: 'add',
      level: level.toString(),
      levelId: levelId
    }

    try {
      const response: any = await addLevelBasedUsersAxios(payload, selectedUserObject?._id);
      if (response?.data?._id) {
        toast.success('User has been added successfully');
        getUsersList(currentPage);
        if (inputRef.current) {
          inputRef.current.focus();
        }
        setUserDropdownList([]);
      } else {
        toast.error('Unable to add the user!..');
      }
    } catch (error) {
      let err: any = error;
      toast.error(err['data']['message'] || 'User already exists for this level');
    }
  }

  const removeLevelBasedUser = async (selectedUserObject: ISelectedUserObject | null) => {
    if (selectedUserObject?._id) {
      let payload: IAddLevelBasedUsers = {
        type: 'remove',
        level: level.toString(),
        levelId: levelId
      }
      const response: any = await addLevelBasedUsersAxios(payload, selectedUserObject?._id);
      if (response?.data?._id) {
        toast.success('User has been removed successfully');
        let currentPageNo: number = (userActualList.length === 1 && currentPage !== 1) ? currentPage - 1 : currentPage;
        setCurrentPage(currentPageNo);
        getUsersList(currentPageNo);
        setShowDeleteModal(false);
        setSeletedRecordForDelete(null);
      } else {
        toast.error('Unable to remove the user!..');
      }
    } else {
      toast.error('User does not exists!..');
    }
  }

  const getUsersList = async (currentPageNo: number = 1) => {
    let query = {
      page: currentPageNo,
      keyword: '',
      perPage: itemsPerPage,
      sortDate: 'desc',
      paginationVersion: 2,
      clientId: clientId || '',
      levelId: levelId,
      levelNo: level
    }
    const response: any = await getUsersDataForListAxios(query);
    if (response && response.length) {
      let dataResult: any[] = (response[0]?.docs && response[0]?.docs.length) ? [...response[0]?.docs] : [];
      let metadataResult: any[] = (response[0]?.metadata && response[0]?.metadata.length) ? [...response[0]?.metadata] : [];
      dataResult = dataResult.length ? dataResult.map(ele => ({
        _id: ele?._id || '',
        label: ele?.name || '',
        docs: { ...ele }
      })) : [];
      setActualUserList(dataResult);
      setTotalRecords(metadataResult.length ? (metadataResult[0]?.total || 0) : 0);
    }
  }

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
    getUsersList(value);
  };

  const findUserListSNo = (itemNo: number = 1) => {
    const sNoStartsFrom: number = (currentPage - 1) * itemsPerPage;
    return sNoStartsFrom + itemNo;
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <InputLabel sx={{ mb: 0 }}> {`Person in charge-${personIndex + 1}`} </InputLabel>
        <IconButton
          sx={{ mr: '3px' }}
          onClick={() => {
            remove(personIndex);
          }}>
          <Close fontSize="small" />
        </IconButton>
      </Box>

      <AvatarUser avatar={avatar} handleChangeFile={(e) => handleChangeFile(e, personIndex)}
        heightAvatar="50px" widthAvatar="50px" heightBtnUpload="30px" pic={true} />

      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.name`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Name</InputLabel>
              <TextField
                // required
                type="text"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>

      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.jobTitle`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Job Title</InputLabel>
              <TextField
                // required
                type="text"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>

      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.contactNumber`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Contact Number</InputLabel>
              <TextField
                // required
                type="text"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>

      <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.email`}
          control={control}
          render={({ field }) => (
            <Box>
              <InputLabel>Email</InputLabel>
              <TextField
                // required
                type="email"
                size="small"
                {...field}
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        />
      </Typography>

      {showAddUserBtn ? <Typography component="ul" sx={{ pl: 0, pb: 1, pt: 1 }}>
        <Controller
          name={`personInCharge.${personIndex}.email`}
          control={control}
          render={({ field }) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}>
              <InputLabel sx={{ mb: 0 }}>Users</InputLabel>
              <IconButton onClick={() => { setShowModal(true) }}>
                <AddCircleOutline fontSize="medium" />
              </IconButton>
            </Box>
          )}
        />
      </Typography> : <></>}

      <Dialog maxWidth="sm" fullWidth open={showModal} onClose={handleCloseModal}>
        <DialogTitle className={pageClasses.dialogTitle}>
          Users
        </DialogTitle>
        <Typography className={pageClasses.formContainer} >
          <DialogContent className={pageClasses.dialogContent}>
            <Grid container>
              <Grid item xs={12} style={{ margin: '5px 20px 20px' }}>
                <Autocomplete id="autocompleteuser" PopperComponent={PopperMy}
                  disablePortal options={userDropdownList}
                  renderInput={(params: any) => (
                    <Tooltip title={''} placement="top">
                      <TextField autoComplete="off" placeholder='Search user by name & email'
                        {...params} inputRef={inputRef}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          debounceSearch(event.target.value);
                        }}
                      />
                    </Tooltip>
                  )}
                  // onFocus={() => searchUsermutation.mutate('')}
                  isOptionEqualToValue={(option: any, value: any) => option._id === value._id}
                  renderOption={(props: any, option: any) => {
                    return <List {...props} >{option.label}</List>;
                  }}
                  getOptionLabel={(option: any) => option?.label || ''}
                  noOptionsText="No data"
                  disableClearable
                  filterOptions={(options: any) => options}
                  loading={searchUsermutation.isLoading as boolean}
                  onChange={(_: any, value: any) => {
                    onChangeAddLevelBasedUser(value);
                    // @ts-ignore
                    document.getElementById('id').getElementsByTagName('input')[0].value = '';
                  }}
                  disabled={false}
                />
              </Grid>

              <Grid item xs={12}>
                <Scrollbars autoHide className={pageClasses.scrollBarWrap}>
                  {userActualList.length ? <div>
                    <div className={pageClasses.userListWrap}>
                      {userActualList.map((ele, index: number) => {
                        return (
                          <>
                            <div className={pageClasses.userListContentWrap}>
                              <div>
                                <div key={`title-${ele?._id}`} style={{ fontWeight: 600 }}>{findUserListSNo(index + 1)}. {ele.label}</div>
                                <div key={`content-${ele?._id}`} style={{ wordBreak: 'break-all', paddingRight: '10px' }}>{ele?.docs?.email} | {ele?.docs?.jobTitle}</div>
                              </div>
                              <div>
                                <Button variant="outlined"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    let record: any = { ...ele };
                                    setSeletedRecordForDelete(record);
                                  }}
                                  sx={{ width: '90px', textTransform: 'none', color: 'red', border: '1px solid red' }}>
                                  <PersonRemove style={{ marginRight: '5px', fontSize: '18px' }} />
                                  Remove
                                </Button>
                              </div>
                            </div>

                            {userActualList.length !== (index + 1) ? (<Divider className={pageClasses.dividerWrap} />) : <></>}
                          </>
                        )
                      })}
                    </div>
                  </div>
                    : <div className={pageClasses.noDataWrap}>No users yet!...
                    </div>}
                </Scrollbars>
                {userActualList.length ? <div className={pageClasses.paginationWrap}>
                  <div>
                    <span className={pageClasses.totalRecordsSpan}>Total Records :</span> {totalRecords}
                  </div>
                  <div>
                    <Stack spacing={2}>
                      <Pagination count={Math.ceil(totalRecords / itemsPerPage)}
                        shape="rounded" page={currentPage} onChange={handlePageChange}
                        boundaryCount={2} siblingCount={0}
                      />
                    </Stack>
                  </div>
                </div> : <></>}
              </Grid>
            </Grid>
          </DialogContent>
        </Typography>
        <IconButton aria-label="close" onClick={handleCloseModal}
          sx={{
            padding: 0, position: 'absolute', right: 14,
            top: 22, border: '1px solid',
            '.MuiSvgIcon-root': {
              width: '16px',
              height: '16px',
            },
            color: (theme) => theme.palette.color.grey5,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <ClientActionsDialog
        title="Confirmation"
        open={showDeleteModal}
        onClose={() => { setShowDeleteModal(false); setSeletedRecordForDelete(null); }}
        description="Are you sure to delete the record?"
        saveLabel="Delete User"
        onCancel={() => { setShowDeleteModal(false); setSeletedRecordForDelete(null); }}
        onSave={() => removeLevelBasedUser(seletedRecordForDelete)}
        submit={false}
      />
    </div >
  );
};

export default PersonInfoForm;
