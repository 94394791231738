import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import { Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { downloadIssueFile } from 'services/MapView/apiMapViewConfig.services';
import { DrawIssueShapeEnum, ExportFileEnum, IIssue } from '../../interfaces';

interface DownloadIssueProps {
  data: IIssue;
  colorByTheme?: string;
}

const DownloadIssue: FC<DownloadIssueProps> = ({ data, colorByTheme }) => {
  const [selectedFileType, setSelectedFileType] = useState<ExportFileEnum>(ExportFileEnum.FILE);
  const downloadFileMutation = useMutation(downloadIssueFile, {
    onSuccess: (res, variables) => {
      let fileName = '';
      if (variables.fileType === ExportFileEnum.KML) {
        fileName = data._id + '.kml';
      } else if (variables.fileType === ExportFileEnum.FILE) {
        const splitName = data.document.split('/');
        fileName = decodeURIComponent(splitName.slice(-1)[0]);
      } else if (variables.fileType === ExportFileEnum.IMAGE) {
        fileName = data._id + (data.images.length >= 2 ? '.zip' : '.png');
      }

      if (fileName.includes('.pdf')) {
        window.open(data.document, '_blank');
      } else {
        saveAs(res.request.responseURL, fileName);
        toast.success('Download successful', { position: 'top-center' });
      }
    },
  });

  const handleDownloadFile = (fileType: ExportFileEnum) => {
    if (fileType === ExportFileEnum.FILE && !data.document) return;
    setSelectedFileType(fileType);
    downloadFileMutation.mutate({
      id: data._id,
      fileType,
    });
  };

  const renderLoopIcon = (
    <Tooltip title="Downloading">
      <LoopOutlinedIcon
        style={{
          fontSize: '24px',
          color: colorByTheme,
        }}
        sx={{
          animation: 'spin 2s linear infinite',
          '@keyframes spin': {
            '0%': {
              transform: 'rotate(360deg)',
            },
            '100%': {
              transform: 'rotate(0deg)',
            },
          },
        }}
      />
    </Tooltip>
  );

  return (
    <>
      <Tooltip title="Download kml">
        <PublicOutlinedIcon
          sx={{ cursor: 'pointer', color: colorByTheme }}
          onClick={() => handleDownloadFile(ExportFileEnum.KML)}
        />
      </Tooltip>
      {data.document && (
        <Tooltip title="Download document">
          <DescriptionOutlinedIcon
            sx={{ cursor: !data.document ? 'default' : 'pointer', color: colorByTheme }}
            onClick={() => handleDownloadFile(ExportFileEnum.FILE)}
            color={!data.document ? 'disabled' : undefined}
          />
        </Tooltip>
      )}
      <>
        {selectedFileType === ExportFileEnum.IMAGE && downloadFileMutation.isLoading
          ? renderLoopIcon
          : data.type === DrawIssueShapeEnum.IMAGE && (
              <Tooltip title={`Download picture${data.images.length >= 2 ? 's' : ''}`}>
                <FileDownloadOutlinedIcon
                  sx={{ cursor: 'pointer', color: colorByTheme }}
                  onClick={() => handleDownloadFile(ExportFileEnum.IMAGE)}
                />
              </Tooltip>
            )}
      </>
    </>
  );
};

export default DownloadIssue;
