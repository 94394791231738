import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Box, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { ITilingMapField, SensorTypeEnum } from 'interfaces/workspace';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import DragAndDropFile from '../DragAndDropFile';

const sensorTypeRadioList = [
  { key: 1, value: SensorTypeEnum.RGB, label: 'RGB' },
  { key: 2, value: SensorTypeEnum.RGB_MS, label: 'RGB + MS' },
];

interface RawImagesProps {
  form: UseFormReturn<ITilingMapField>;
  imageList: File[];
  setImageList: (arg: File[]) => void;
}

const RawImages: FC<RawImagesProps> = ({ form, imageList, setImageList }) => {
  const theme = useTheme();
  const { control } = form;

  const handleRemoveSelectedDocument = () => {
    setImageList([]);
  };

  const renderUploadedFile = () => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <PermMediaIcon fontSize="large" color="primary" />
      <Typography sx={{ fontSize: '16px', ml: '12px' }}>
        {imageList && `${imageList.length} images selected`}
      </Typography>
      {imageList.length && (
        <DeleteOutlineOutlinedIcon
          color="error"
          sx={{ fontSize: '24px', cursor: 'pointer', ml: '64px' }}
          onClick={handleRemoveSelectedDocument}
        />
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        px: '24px',
        pb: '24px',
      }}>
      <Controller
        name="sensorType"
        control={control}
        defaultValue={SensorTypeEnum.RGB}
        render={({ field }) => (
          <RadioGroup {...field} row>
            {sensorTypeRadioList.map((item) => (
              <FormControlLabel key={item.key} value={item.value} control={<Radio />} label={item.label} />
            ))}
          </RadioGroup>
        )}
      />
      <Box sx={{ width: '100%', mt: '16px' }}>
        {imageList.length ? (
          renderUploadedFile()
        ) : (
          <DragAndDropFile description={'(Please upload .jpg or .png files)'} setImageList={setImageList} />
        )}
      </Box>
    </Box>
  );
};

export default RawImages;
