import { IAnalyticsTabs3DData, SettingUIName } from 'common/defines/clients';
import { ANALYTICS_TABS, MANAGE_CLIENT_SETTING_VIEW, QUERY_KEY, SEVERITY } from 'constants/constants';
import { NEW_VERSION_PATHS } from 'constants/enums/version-enums';
import usePathForVersion from 'hooks/common/usePathForVersion';
import { useEffect, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { dashboardWidgetSelector } from 'store/slices/dashboardWidgetSlice';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { superAdminSelector } from 'store/slices/superAdminSlice';

const PolygonLayer = ({ item, isLastLevel, colorBorder = '#1EBADB' }: any) => {
  const { clientId } = useParams();
  const {
    tonnageDistribution,
    weighbridgeByProduct,
    logisticTripStatus,
    productionCost,
    productionExpenditure,
    analyticActiveTab,
    polygonFilter,
  } = useAppSelector(dashboardWidgetSelector);

  const { permissions } = useAppSelector(superAdminSelector);
  const isNewVersion = usePathForVersion(NEW_VERSION_PATHS.MAP_VIEW);

  const { isOpen } = useAppSelector(rightBarSelector);
  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => (permissions[MANAGE_CLIENT_SETTING_VIEW] ? getClientSettingsById(clientId || '') : undefined),
    { enabled: !!clientId }
  );

  const widthSetting = useMemo(() => {
    if (!clientData?.uiSetting) return 1.4;
    const strokeSetting = clientData.uiSetting.find((val: any) => val.name === SettingUIName.STROKE_WIDTH);
    return strokeSetting?.value || 1.4;
  }, [clientData]);

  const getSeverityColor = (severityType: string = ''): string => {
    switch (severityType) {
      case SEVERITY.LOW:
        return '#F87171';
      case SEVERITY.MEDIUM:
        return '#FBBF24';
      case SEVERITY.HIGH:
        return '#3EDC86';
      default:
        return '#b1edfc';
    }
  };

  const [polygonHeight, setPolygonHeight] = useState<number>(0);
  const [polygonColor, setPolygonColor] = useState<string>(getSeverityColor());

  let getLocationBasedData = () => {
    let analyticsData: IAnalyticsTabs3DData[] = [];
    switch (analyticActiveTab) {
      case ANALYTICS_TABS.PRODUCTION:
        analyticsData = [...tonnageDistribution];
        break;
      case ANALYTICS_TABS.WEIGHBRIDGE:
        analyticsData = [...weighbridgeByProduct];

        break;
      case ANALYTICS_TABS.LOGISTIC:
        analyticsData = [...logisticTripStatus];

        break;
      case ANALYTICS_TABS.COST:
        analyticsData = [...productionCost];

        break;
      case ANALYTICS_TABS.EXPENDITURE:
        analyticsData = [...productionExpenditure];
        break;

      default:
        break;
    }

    let selectedLocation = analyticsData.length
      ? analyticsData.find((d: { name: string; value: number }) => {
          return d['name'].toLowerCase() === item['name'].toLowerCase();
        })
      : null;

    // It is giving sideeffect to 2D to 3D toggle button
    // if (selectedLocation) {
    //   dispatch(changeIs3D(true));
    // }
    let polygonAutoHeight: number =
      selectedLocation && polygonFilter[selectedLocation?.severity] ? selectedLocation['value'] || 0 : 0;
    let polygonAutoColor: string =
      selectedLocation && polygonFilter[selectedLocation?.severity]
        ? getSeverityColor(selectedLocation['severity'])
        : getSeverityColor();
    setPolygonColor(polygonAutoColor);
    setPolygonHeight(polygonAutoHeight);
  };

  useEffect(() => {
    getLocationBasedData();
  }, [
    polygonFilter,
    tonnageDistribution,
    weighbridgeByProduct,
    logisticTripStatus,
    productionCost,
    productionExpenditure,
  ]);

  useEffect(() => {
    if (!isNewVersion) {
      setPolygonColor('#b1edfc');
    } else {
      getLocationBasedData();
    }
  }, [isNewVersion]);

  const key = useMemo(() => {
    return isLastLevel ? `${item._id}_isLast_` : item._id;
  }, [isLastLevel, item]);
  return (
    <Source id={`${key}_source_polygon`} key={`${key}_source_polygon`} type="geojson" data={item.location}>
      <Layer
        id={`${key}_border_polygon`}
        key={`${key}_border_polygon`}
        type="line"
        source={`${key}_source_polygon`}
        paint={{
          'line-color': colorBorder,
          'line-opacity': 0.8,
          'line-width': widthSetting,
        }}
      />
      {polygonHeight && isOpen && isNewVersion && (
        <Layer
          id={`${key}_fill_polygon`}
          key={`${key}_fill_polygon`}
          type="fill-extrusion"
          source={`${key}_source_polygon`}
          beforeId={`${key}_border_polygon`}
          paint={{
            // "fill-extrusion-color": colorBorder,
            'fill-extrusion-color': polygonColor,
            // "fill-extrusion-height": item.parent ? 0 : polygonHeight,
            'fill-extrusion-height': polygonHeight,
            'fill-extrusion-base': 0,
            'fill-extrusion-opacity': 0.8,
          }}
        />
      )}
      {!isLastLevel && (
        <Layer
          id={`${key}_layer_polygon`}
          key={`${key}_layer_polygon`}
          type="fill"
          source={`${key}_source_polygon`}
          beforeId={`${key}_border_polygon`}
          paint={{
            'fill-color': polygonColor,
            'fill-opacity': 0.1,
          }}
        />
      )}
    </Source>
  );
};

export default PolygonLayer;
