import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Button,
  debounce,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  List,
  Pagination,
  Skeleton,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import adminIcon from 'assets/images/dashboardAdmin/admin.png';
import { ICreateClientsFormData } from 'common/defines/clients';
import { QUERY_KEY, URI } from 'constants/constants';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isValidToken } from 'services/auth';
import { getClientById, getClientListByPagination, getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { IconNavBar } from './IconNavBar';

const useMapViewPageStyle = makeStyles((theme: any) => ({
  navBarButton: {
    width: '24px !important',
    minWidth: '24px !important',
    height: '24px !important',
    borderRadius: '20px !important',
    marginLeft: 'auto !important',
  },
  arrowIcon: {
    color: theme.palette.color.grey7,
    fontSize: '16px',
  },
  columnNavbar: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.color.white2} `,
  },
  dialog: {
    '& .MuiBackdrop-root': {
      marginLeft: '256px',
      width: '100%',
    },
    '& .MuiDialog-container ': {
      marginLeft: '0',
      justifyContent: 'initial',
      '& .MuiPaper-root': {
        height: '100%',
        maxHeight: '100%',
        width: '300px',
        margin: '0',
        boxShadow: 'none',
        borderRadius: '0',
      },
    },
  },
  textField: {
    width: '100%',
    height: '40px',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.MuiOutlinedInput-root': {
      height: '40px',
    },
    border: `0`,
    borderRadius: '5px',
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: '160px',
  height: '40px',
  border: `1px solid ${theme.palette.divider}`,
  borderColor: theme.palette.color.green4,
  backgroundColor: theme.palette.color.green4,
  color: theme.palette.mode === 'dark' ? theme.palette.color.black : theme.palette.color.white,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.color.green4,
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.color.green4,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.color.green,
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.color.green4,
  },
  '&:focus': {
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.color.green4,
  },
}));

const NavbarData = [
  {
    label: 'Dashboard',
    path: '/dashboard',
  },
  {
    label: 'Map View',
    path: '/map-view',
  },
  {
    label: 'New Dashboard',
    path: '/new-dashboard',
  },
  {
    label: 'New Map View',
    path: '/new-map-view',
  },
  // {
  //   label: 'Documents',
  //   path: '/',
  // },
  // {
  //   label: 'Clients',
  //   path: '/',
  // },
  // {
  //   label: 'Flight Planner',
  //   path: '/',
  // },
  // {
  //   label: 'Schedule',
  //   path: '/',
  // },
  // {
  //   label: 'Resources',
  //   path: '/',
  // },
  // {
  //   label: 'Inventory',
  //   path: '/',
  // },
];

const tilingMapNavBarData = [
  {
    label: 'Tiling Map',
    path: 'tiling-map',
  },
];

export const NavBar = ({ open }: any) => {
  const classes = useMapViewPageStyle(open);
  const { clientId } = useParams();
  const isAuth = isValidToken();
  const location = useLocation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const theme = useTheme();

  const { pathname } = location;

  const { data: checkSuperAdmin } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: false,
    enabled: isAuth,
  });

  const isSuperAdmin = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperAdmin;
  }, [checkSuperAdmin]);

  // superUser is same as superAdmin except for the permission to delete client
  const isSuperUser = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperUser;
  }, [checkSuperAdmin]);

  const userId = useMemo(() => {
    if (!checkSuperAdmin) return '';
    return checkSuperAdmin.data._id;
  }, [checkSuperAdmin]);

  const { data: userInfoByClient } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    }
  );

  const [searchClient, setSearchClient] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const position = useMemo(() => {
    const conditionArray = [URI.NOT_AUTH, URI.NOT_FOUND, URI.NOT_PERMIT];
    return conditionArray.some((item) => location.pathname.includes(item));
  }, [location]);

  const flagTabData = React.useMemo(() => {
    const NewNavBarData: any[] = [];
    if (isSuperAdmin || isSuperUser) {
      return NavbarData;
    }
    if (!checkSuperAdmin?.data?.role) return [];
    const dataRoleValue = userInfoByClient?.data?.flagTab;
    dataRoleValue?.forEach((item: string) => {
      if (item.includes('DASHBOARD')) {
        NewNavBarData.push({
          label: 'Dashboard',
          path: '/dashboard',
        });
        NewNavBarData.push({
          label: 'New Dashboard',
          path: '/new-dashboard',
        });
      } else if (item === 'MAPVIEW') {
        NewNavBarData.push({
          label: 'Map View',
          path: '/map-view',
        });
        NewNavBarData.push({
          label: 'New Map View',
          path: '/new-map-view',
        });
      }
    });
    const unique = Array.from(new Set(NewNavBarData.map((item: any) => JSON.stringify(item)))).map((item: any) =>
      JSON.parse(item)
    );
    return unique;
  }, [checkSuperAdmin, isSuperAdmin, isSuperUser, userInfoByClient]);

  const { data: clientDataFix, isLoading } = useQuery(
    [QUERY_KEY.CLIENT_DETAILS, page, rowsPerPage, searchClient],
    () => getClientListByPagination(page, rowsPerPage, searchClient),
    { keepPreviousData: false }
  );

  const skeletonProperties = useMemo(() => {
    return (
      <Typography component="ul" sx={{ pl: 0, pt: 2, padding: 3 }}>
        {Array.from(Array(10).keys()).map((item) => (
          <Box sx={{ marginBottom: '5px', borderRadius: '12px' }} key={item}>
            <Skeleton variant="rectangular" width="100%" height="60px" sx={{ borderRadius: '12px' }} />
          </Box>
        ))}
      </Typography>
    );
  }, []);

  const { data: clientData } = useQuery([QUERY_KEY.CLIENT_DATA, clientId], () => getClientById(clientId ?? ''), {
    enabled: !!clientId,
  });

  const debouncedSearchClient = debounce((value: string) => {
    setSearchClient(value);
  }, 500);

  const myFirstClientData = useMemo(() => {
    return clientDataFix?.data?.data;
  }, [clientDataFix]);

  const indexStartPage = (clientDataFix?.data.metadata.currentPage - 1) * clientDataFix?.data.metadata.pageSize + 1;
  const indexEndPage = Math.min(
    clientDataFix?.data.metadata.currentPage * clientDataFix?.data.metadata.pageSize,
    clientDataFix?.data.metadata.totalCount
  );

  return (
    <>
      {!position && (
        <Grid
          sx={{
            width: '100%',
            height: '65px',
            padding: '10px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-start',
            direction: 'row',
            alignItems: 'center',
          }}
          // direction="row"
          onClick={() => setOpenPopup((v) => !v)}>
          <>
            {clientData ? (
              <>
                <Avatar
                  sx={{
                    mr: '10px',
                  }}
                  src={clientData.avatar}
                />
                {open && (
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-wrap',
                    }}>
                    {clientData.name}
                  </Typography>
                )}
                <Button className={classes.navBarButton}>
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </Button>
              </>
            ) : (
              <>
                <Avatar
                  sx={{
                    mr: '10px',
                  }}
                  src={adminIcon}
                />
                {open && (
                  <>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre-wrap',
                      }}>
                      Agrimor Admin
                    </Typography>
                    <Button className={classes.navBarButton}>
                      <ArrowForwardIosIcon className={classes.arrowIcon} />
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        </Grid>
      )}
      <List disablePadding={true}>
        {clientData &&
          flagTabData.map((item: any, index: number) => (
            <IconNavBar
              key={item.label}
              open={open}
              name={item.label}
              path={clientId ? `${item.path}/${clientId}` : '/landing'}
            />
          ))}
        {pathname.includes('tiling-map') &&
          tilingMapNavBarData.map((item: any, index: number) => (
            <IconNavBar key={item.label} open={open} name={item.label} path={pathname} />
          ))}
      </List>
      {/* {clientData && ( */}
      <Dialog
        onClose={() => setOpenPopup(false)}
        open={openPopup}
        className={classes.dialog}
        sx={{ marginLeft: open ? '256px' : '65px' }}>
        <DialogContent>
          <TextField
            type="text"
            placeholder="Search Clients..."
            className={classes.textField}
            onChange={(e) => {
              debouncedSearchClient(e.target.value);
            }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ height: '24px', width: '24px' }} />
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                },
              },
            }}
          />
          {(isSuperAdmin || isSuperUser) && (
            <>
              <Typography variant="h2" my={1}>
                Agrimor
              </Typography>
              <ButtonStyled
                sx={{
                  mb: 2,
                  minWidth: '200px',
                  backgroundColor: theme.palette.primary.main,
                }}
                onClick={() => navigate(`/dashboard-admin`)}>
                Agrimor Administrator
              </ButtonStyled>
            </>
          )}
          <Typography variant="h2" my={1}>
            Clients
          </Typography>
          {isLoading && skeletonProperties}
          {!isLoading && (
            <Box
              sx={{
                minHeight: '650px',
              }}>
              {myFirstClientData?.map((clientDetail: ICreateClientsFormData) => {
                return (
                  <Grid
                    sx={{
                      width: '100%',
                      height: '65px',
                      padding: '10px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      direction: 'row',
                      alignItems: 'center',
                      borderRadius: '5px',
                      opacity: 0.8,
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        opacity: 1,
                        backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#f5f5f5',
                      },
                    }}
                    key={clientDetail._id}
                    onClick={() => {
                      navigate(`/dashboard/${clientDetail._id}`);
                      setOpenPopup(false);
                      window.location.reload();
                    }}>
                    <Avatar
                      sx={{
                        mr: '10px',
                        border: `1px solid ${theme.palette.divider}`,
                      }}
                      src={clientDetail.avatar}
                    />
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre-wrap',
                      }}>
                      {clientDetail.name}
                    </Typography>
                  </Grid>
                );
              })}
            </Box>
          )}
          {!isLoading && (
            <>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  p: '10px',
                }}>
                Showing {indexStartPage} to {indexEndPage} of {clientDataFix?.data.metadata.totalCount} clients
              </Typography>
              <Stack
                spacing={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Pagination
                  sx={{
                    '& .MuiPagination-ul': {
                      '& .Mui-selected': {
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: 'white',
                      },
                      '& .Mui-selected:hover': {
                        backgroundColor: '#23BE6AB0',
                      },
                      '& .MuiPaginationItem-icon': {
                        color: (theme) => theme.palette.primary.main,
                      },
                    },
                  }}
                  count={clientDataFix?.data.metadata.pageCount}
                  defaultPage={1}
                  page={page}
                  onChange={(_, value) => {
                    setPage(value);
                  }}
                />
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>
      {/* )} */}
    </>
  );
};
