import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, CircularProgress, Collapse, FormControlLabel, Stack, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSizeVector } from 'services/analytics/apiAnalyticsConfig.services';
import { getClientSettingsById, updateClientSettingsById } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { resetDataVectorContour, rightBarSelector, updateDataVectorContour } from 'store/slices/rightBarSlice';

const initialColor = 'linear-gradient(90deg, rgba(0, 0, 255, 1) 0%, rgba(255, 255, 0, 1) 50%, rgba(255, 0, 0, 1) 100%)';

interface IContour {
  id: number;
  key: string;
  color: string;
  label: string;
  value: boolean;
}

interface VectorContourCollapseProps {
  isShowVector: boolean;
  isShowListVectorContour: boolean;
  sensorId: string;
}

const VectorContourCollapse: FC<VectorContourCollapseProps> = ({ isShowVector, isShowListVectorContour, sensorId }) => {
  const [isShowContour, setIsShowContour] = useState<boolean>(false);
  const [color, setColor] = useState<string>(initialColor);
  const [isShowPickColor, setIsShowPickColor] = useState<boolean>(false);
  const [listContour, setListContour] = useState<IContour[]>([]);
  const [selectedId, setSelectedId] = useState<number>(1);

  const { dataVectorContour } = useAppSelector(rightBarSelector);
  const { levelId } = useAppSelector(mapViewSelector);
  const { clientId } = useParams();
  const classes = useLayerSelectCardStyle();

  const dispatch = useAppDispatch();

  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const settingId = useMemo(() => {
    return clientData?._id;
  }, [clientData]);

  useQuery([QUERY_KEY.GET_LAYER_VECTOR, levelId, sensorId], () => getSizeVector(sensorId), {
    onSuccess: (res) => {
      const vectorList = res.data as string[];
      const newListContour = vectorList.map((item, index) => {
        const foundItem = clientData?.vectorContourColorSetting.find((itemVector: any) => itemVector?.size === item);
        let newItem: IContour = {
          id: index + 1,
          key: item,
          color: foundItem?.color || initialColor,
          label: item,
          value: false,
        };
        return newItem;
      });
      setListContour(newListContour);
    },
    enabled: !!sensorId,
  });

  const handleUpdateSizeConfig = useMutation((data: any) => updateClientSettingsById(settingId || '', data), {
    onSuccess: () => {
      toast.success('Change color successfully');
    },
  });

  const onChangeContour = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    const checkedValue = event.target.checked;
    const newListContour = listContour.map((item: IContour) =>
      item?.id === id ? { ...item, value: checkedValue } : { ...item, value: false }
    );

    if (!checkedValue) {
      dispatch(resetDataVectorContour());
    } else {
      const getItemChecked = newListContour.find((i: IContour) => i.value);
      if (getItemChecked) {
        dispatch(
          updateDataVectorContour({
            sensorId,
            size: getItemChecked.key,
            isLoading: true,
            color: getItemChecked.color,
            page: 1,
          })
        );
      }
    }

    setListContour(newListContour);
  };

  const onChangeColor = (id: number, colorValue: string) => {
    const newListContour = listContour.map((item: IContour) =>
      item.id === id ? { ...item, color: colorValue } : item
    );
    const vectorContourSettingList = newListContour.map((item: IContour) => ({
      color: item.color,
      size: item.key,
    }));
    if (vectorContourSettingList.length > 0) {
      handleUpdateSizeConfig.mutate({
        clientId,
        vectorContourColorSetting: vectorContourSettingList,
      });
    }
    setListContour(newListContour);
    dispatch(updateDataVectorContour({ ...dataVectorContour, color: colorValue }));
  };

  const handleSelectDone = () => {
    setIsShowPickColor(false);
    onChangeColor(selectedId, color);
  };

  const renderLabelComp = (item: IContour) => {
    return (
      <>
        <Box
          sx={{
            height: '14px',
            width: '14px',
            display: 'inline-block',
            borderRadius: '50%',
            background: item.color,
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!dataVectorContour.size || item.key === dataVectorContour.size) {
              setIsShowPickColor(true);
              setSelectedId(item.id);
              setColor(item.color);
            }
          }}></Box>
        <Typography sx={{ display: 'inline-block', ml: '6px' }}>{item.label}</Typography>
      </>
    );
  };

  return (
    <Collapse in={isShowVector} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
      {isShowListVectorContour && (
        <>
          <Button
            startIcon={
              isShowContour ? (
                <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
              ) : (
                <ChevronRightIcon style={{ fontWeight: 'bold' }} />
              )
            }
            onClick={() => setIsShowContour(!isShowContour)}
            classes={{ root: classes.buttonTextStyle }}
            style={{ width: '100%' }}>
            <Typography
              className={classes.buttonTextStyle}
              style={{ marginRight: 'auto', fontWeight: '500', fontSize: '13px !important' }}>
              Contour
            </Typography>
          </Button>
          <Collapse in={isShowContour} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Stack>
              {listContour &&
                listContour.length > 0 &&
                listContour.map((item, index: number) => (
                  <Box display={'flex'} alignItems={'center'} key={index}>
                    <FormControlLabel
                      key={index}
                      control={
                        <SwitchCustom
                          onChange={(e: ChangeEvent<any>) => {
                            onChangeContour(e, item?.id);
                          }}
                          checked={item?.value}
                        />
                      }
                      label={renderLabelComp(item)}
                    />
                    <CommonModal
                      isShow={isShowPickColor}
                      handleClose={() => {
                        setIsShowPickColor(false);
                      }}>
                      <Box sx={{ pt: '40px', px: '16px', pb: '12px' }}>
                        <ColorPicker
                          value={color}
                          onChange={(value: string) => {
                            setColor(value);
                          }}
                          hideEyeDrop
                          hideAdvancedSliders
                          hideColorGuide
                          hideInputType
                        />
                        <Box sx={{ mt: '28px', textAlign: 'right' }}>
                          <Button variant="contained" color="primary" onClick={handleSelectDone}>
                            Select
                          </Button>
                        </Box>
                      </Box>
                    </CommonModal>
                    {dataVectorContour?.isLoading && dataVectorContour?.size === item.key && (
                      <CircularProgress size={'15px'} />
                    )}
                  </Box>
                ))}
            </Stack>
          </Collapse>
        </>
      )}
    </Collapse>
  );
};

export default VectorContourCollapse;
