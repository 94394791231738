import { Box, Button, Grid, InputLabel, Tooltip, Typography, useTheme } from '@mui/material';
import { SettingSectionName } from 'common/defines/clients';
import {
  defaultAnalysisUISetting,
  defaultClientUISetting,
  defaultClientUnitSetting,
  defaultIconFavoriteSetting,
  defaultSizePointSetting,
} from 'common/dummy/dummyClients';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY } from 'constants/constants';
import { useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById, queryMe } from 'services/clients/apiClient.services';
import { ClientSettingModal } from './ClientSettingModal';
import { useClientSettingsStyle } from './ClientSettingsStyle';
import DialogFavoriteIcon from './DialogFavoriteIcon';
import ConfigSizeModal from './DialogSizePoint';
import { SizePointCard } from './SizePointSelect';
import { UnitCardSelect } from './UnitCardSelect';
import ViewAnalysisSlopeModal from './ViewAnalysisSlopeModal';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

export const ClientSettings = () => {
  const classes = useClientSettingsStyle();
  const { clientId } = useParams();
  const {
    data: clientData,
    isLoading,
    refetch,
  } = useQuery([QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId], () => getClientSettingsById(clientId || ''), {
    enabled: !!clientId,
  });
  const settingId = useMemo(() => {
    return clientData?._id;
  }, [clientData]);

  const [openSetting, setOpenSetting] = useState(false);
  const [typeSetting, setTypeSetting] = useState<SettingSectionName | null>();
  const [openFavorite, setOpenFavorite] = useState(false);
  const [openViewAnalysis, setOpenViewAnalysis] = useState(false);
  const [openSizePoint, setOpenSizePoint] = useState(false);
  // const [isShowCollapse, setIsShowCollapse] = useState(true);
  // const [isShowTransparent, setIsShowTransparent] = useState(true);
  // const [isShowCollapseTop3D, setIsShowCollapseTop3D] = useState(true);
  // const [isShowCollapseTop2D, setIsShowCollapseTop2D] = useState(true);
  const [ableToEdit, setAbleToEdit] = useState(false);
  const [ableToView, setAbleToView] = useState(false);

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  useEffect(() => {
    if (isSuperAdmin || isSuperUser) {
      setAbleToEdit(true);
      setAbleToView(true);
      return;
    }
    if (!userInfo?.data?.role) {
      setAbleToEdit(false);
      setAbleToView(false);
      return;
    }

    const permissionsData: string[] = [];
    userInfo?.data?.role?.map((item: any) => {
      if (item.clientId === clientId) {
        permissionsData.push(...item.permissions);
      }
    });
    if (permissionsData.length === 0) return;
    permissionsData.forEach((item: any) => {
      switch (item) {
        case 'MANAGE_CLIENT_SETTING_EDIT':
          setAbleToEdit(true);
          break;
        case 'MANAGE_CLIENT_SETTING_VIEW':
          setAbleToView(true);
          break;
        default:
          break;
      }
    });
  }, [clientId, isSuperAdmin, isSuperUser, userInfo]);

  const handleOpenSetting = (value: SettingSectionName) => {
    setOpenSetting(true);
    setTypeSetting(value);
  };

  const openSettingFavorite = () => {
    setTypeSetting(SettingSectionName.FAVORITE_ICON_SETTING);
    setOpenFavorite(true);
  };

  const openViewAnalysisSettings = () => {
    setTypeSetting(SettingSectionName.VIEW_ANALYSIS_SETTING);
    setOpenViewAnalysis(true);
  };

  const openSizePointConfig = () => {
    setTypeSetting(SettingSectionName.SIZE_POINT_SETTING);
    setOpenSizePoint(true);
  }

  const handleCloseModal = () => {
    setTypeSetting(null);
    setOpenSetting(false);
    setOpenFavorite(false);
    setOpenViewAnalysis(false);
    setOpenSizePoint(false)
  };

  const theme = useTheme();

  return (
    <Grid className={classes.clientSettingsCard}>
      <Scrollbars autoHide>
        <Box
          sx={{ px: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '74px' }}>
          <Typography
            sx={{
              fontFamily: 'Barlow',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '20px',
              color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
              lineHeight: '20px',
            }}>
            Client Settings
          </Typography>
        </Box>
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
          }}
        />
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              height: 'calc(100% - 76px)',
            }}>
            <WaitingPageAPI />
          </Box>
        ) : (
          <>
            {ableToView ? (
              <>
                <>
                  <Box sx={{ px: 2.5 }}>
                    <Grid container sx={{ minWidth: '300px', pt: 2 }}>
                      <Grid item xs={6} sx={{ display: 'flex' }}>
                        <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>Measurement <Tooltip title="Define the unit for measurement">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip></InputLabel>
                      </Grid>
                      {ableToEdit && (
                        <Grid item xs={6}>
                          <Button
                            onClick={() => handleOpenSetting(SettingSectionName.UNIT_SETTING)}
                            variant="outlined"
                            size="small">
                            Edit
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    {clientData?.unitSetting?.length && !isLoading
                      ? clientData?.unitSetting.map((val: any, index: number) => {
                        return <UnitCardSelect data={val} key={index} type={SettingSectionName.UNIT_SETTING} />;
                      })
                      : defaultClientUnitSetting.map((val: any, index: number) => {
                        return <UnitCardSelect data={val} key={index} type={SettingSectionName.UNIT_SETTING} />;
                      })}
                  </Box>
                </>
                <>
                  <Box sx={{ pt: 2 }}>
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                      }}
                    />
                    <Box sx={{ px: 2.5 }}>
                      <Grid container sx={{ minWidth: '300px', pt: 2 }}>
                        <Grid item xs={6} sx={{ display: 'flex' }}>
                          <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>UI Settings <Tooltip title="Define the style of the boundary">
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip></InputLabel>
                        </Grid>
                        {ableToEdit && (
                          <Grid item xs={6}>
                            <Button
                              onClick={() => handleOpenSetting(SettingSectionName.UI_SETTING)}
                              variant="outlined"
                              size="small">
                              Edit
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                      {!isLoading &&
                        defaultClientUISetting.map((val: any, index: number) => {
                          const matchedSettings = clientData?.uiSetting?.find(
                            (setting: any) => setting.name === val.name
                          );
                          return (
                            <UnitCardSelect
                              data={matchedSettings ? matchedSettings : val}
                              key={index}
                              type={SettingSectionName.UI_SETTING}
                            />
                          );
                        })}
                    </Box>
                  </Box>
                </>
                <>
                  <Box
                    sx={{
                      mt: '16px',
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                  />
                  <Box sx={{ px: 2.5 }}>
                    <Grid container sx={{ minWidth: '300px', pt: 2 }}>
                      <Grid item xs={6} sx={{ display: 'flex' }}>
                        <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>Favorite Icon <Tooltip title="Customize favorite icon and color ">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip></InputLabel>
                      </Grid>
                      {ableToEdit && (
                        <Grid item xs={6}>
                          <Button onClick={() => openSettingFavorite()} variant="outlined" size="small">
                            Edit
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    {clientData?.favouriteSetting?.icon ? (
                      <UnitCardSelect
                        data={clientData.favouriteSetting}
                        type={SettingSectionName.FAVORITE_ICON_SETTING}
                      />
                    ) : (
                      <UnitCardSelect
                        data={defaultIconFavoriteSetting}
                        type={SettingSectionName.FAVORITE_ICON_SETTING}
                      />
                    )}
                  </Box>
                </>
                <>
                  {isSuperAdmin || isSuperUser ? (
                    <>
                      <Box
                        sx={{
                          mt: '16px',
                          border: `1px solid ${theme.palette.divider}`,
                        }}
                      />
                      <Box sx={{ px: 2.5, pb: 2.5 }}>
                        <Grid container sx={{ minWidth: '300px', pt: 2 }}>
                          <Grid item xs={6} sx={{ display: 'flex' }}>
                            <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>
                              View Analysis UI Settings <Tooltip title="View or hide Analysis">
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>
                          </Grid>
                          {ableToEdit && (
                            <Grid item xs={6}>
                              <Button onClick={() => openViewAnalysisSettings()} variant="outlined" size="small">
                                Edit
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                        {clientData?.viewAnalysisPropperties && clientData?.viewAnalysisPropperties?.length ? (
                          <UnitCardSelect
                            data={clientData.viewAnalysisPropperties}
                            type={SettingSectionName.VIEW_ANALYSIS_SETTING}
                          />
                        ) : (
                          <UnitCardSelect
                            data={defaultAnalysisUISetting}
                            type={SettingSectionName.VIEW_ANALYSIS_SETTING}
                          />
                        )}
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </>
            ) : (
              <Box sx={{ px: 2.5 }}>
                <Typography
                  sx={{
                    display: 'flex',
                    color: '#9e9e9e',
                    minHeight: '54px !important',
                  }}>
                  You don't have permission to view this client settings. Please contact your administrator.
                </Typography>
              </Box>
            )}

            <>
              <Box sx={{ pt: 2 }}>
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                />
                <Box sx={{ px: 2.5 }}>
                  <Grid container sx={{ minWidth: '300px', pt: 2 }}>
                    <Grid item xs={6} sx={{ display: 'flex' }}>
                      <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>Size Point Config <Tooltip title="Adjust Tree Point size to default or user-defined size">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip></InputLabel>
                    </Grid>
                    {ableToEdit && (
                      <Grid item xs={6}>
                        <Button
                          onClick={() => openSizePointConfig()}
                          variant="outlined"
                          size="small">
                          Edit
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Box sx={{ paddingBottom: '15px' }}>
                    {!clientData?.sizeOfPointSetting ? <SizePointCard
                      data={defaultSizePointSetting}
                      type={SettingSectionName.SIZE_POINT_SETTING}
                    /> : <SizePointCard
                      data={clientData?.sizeOfPointSetting || null}
                      type={SettingSectionName.SIZE_POINT_SETTING}
                    />}
                  </Box>
                </Box>
              </Box>
            </>
          </>




        )}
        <DialogFavoriteIcon
          settingId={settingId}
          clientId={clientId}
          refetch={refetch}
          clientData={clientData}
          open={openFavorite}
          onClose={handleCloseModal}
        />
        <ClientSettingModal
          settingId={settingId}
          clientId={clientId}
          refetch={refetch}
          clientData={clientData}
          open={openSetting}
          onClose={handleCloseModal}
          typeSetting={typeSetting}
        />
        <ViewAnalysisSlopeModal
          settingId={settingId}
          clientId={clientId}
          refetch={refetch}
          clientData={clientData}
          open={openViewAnalysis}
          onClose={handleCloseModal}
        />

        <ConfigSizeModal
          settingId={settingId}
          clientId={clientId}
          refetch={refetch}
          clientData={clientData}
          open={openSizePoint}
          onClose={handleCloseModal}
          sizeOfPointSetting={clientData?.sizeOfPointSetting}
        />
        {/* <Box sx={{ pt: 2 }}>
          <Box
                    sx={{
                      mt: '16px',
                                            border: `1px solid ${theme.palette.divider}`,
                    }}
                  />
          <Box sx={{ px: 2.5 }}>
            <Grid container sx={{ minWidth: '300px', pt: 2 }}>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>Side Surface</InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleOpenSetting(SettingSectionName.SIDE_SURFACE_SETTING)}
                  variant="outlined"
                  size="small">
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Grid container alignItems="center" sx={{ pt: 1 }}>
              <Grid item xs={6}>
                <Button
                  startIcon={isShowCollapse ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  onClick={() => setIsShowCollapse(!isShowCollapse)}>
                  {'CATEGORY'}
                </Button>
                <Collapse in={isShowCollapse} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                  <Grid container className={classes.textSetting}>
                    {clientData?.sideSurfaceSetting?.category ? 'Show' : 'Hide'}
                  </Grid>
                </Collapse>
              </Grid>
              <Grid item xs={6}>
                {!clientData?.sideSurfaceSetting?.opacity ? (
                  <>
                    <Button
                      startIcon={isShowTransparent ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      onClick={() => setIsShowTransparent(!isShowTransparent)}>
                      {'TRANSPARENT'}
                    </Button>
                    <Collapse in={isShowTransparent} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                      <Grid container className={classes.textSetting}>
                        Show
                      </Grid>
                    </Collapse>
                  </>
                ) : (
                  <>
                    <Button
                      startIcon={isShowTransparent ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      onClick={() => setIsShowTransparent(!isShowTransparent)}>
                      {'OPACITY'}
                    </Button>
                    <Collapse in={isShowTransparent} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                      <Grid container className={classes.textSetting}>
                        {clientData?.sideSurfaceSetting?.opacity}
                      </Grid>
                    </Collapse>
                  </>
                )}
              </Grid>
              {clientData?.sideSurfaceSetting?.opacity ? (
                <Grid item xs={12} sx={{ paddingLeft: '8px' }}>
                  <Box
                    className={classes.formBlockItem}
                    sx={{
                      flex: 1,
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '-8px',
                    }}>
                    <Box className={classes.pickupColorBox}>
                      <Button startIcon={<ExpandMoreIcon />}>{'CUSTOM COLOR'}</Button>
                      <Box
                        className={classes.pickupColorBtn}
                        sx={{
                          backgroundColor: clientData?.sideSurfaceSetting?.color || '#ffffff',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Box> */}
        {/* <Box sx={{ pt: 2 }}>
          <Box
                    sx={{
                      mt: '16px',
                                                                  border: `1px solid ${theme.palette.divider}`,
                  />
          <Box sx={{ px: 2.5 }}>
            <Grid container sx={{ minWidth: '300px', pt: 2 }}>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <InputLabel sx={{ alignSelf: 'center', fontWeight: '600', mb: 0 }}>Top Surface</InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleOpenSetting(SettingSectionName.TOP_SURFACE_SETTING)}
                  variant="outlined"
                  size="small">
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Grid container alignItems="flex-start" sx={{ pt: 1, minHeight: '150px' }}>
              <Grid item xs={6}>
                {!clientData?.sideSurfaceSetting?.opacity ? (
                  <>
                    <Button
                      startIcon={isShowCollapseTop3D ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      onClick={() => setIsShowCollapseTop3D(!isShowCollapseTop3D)}>
                      {'TRANSPARENT'}
                    </Button>
                    <Collapse in={isShowCollapseTop3D} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                      <Grid container className={classes.textSetting}>
                        Show
                      </Grid>
                    </Collapse>
                  </>
                ) : (
                  <>
                    <Button
                      startIcon={isShowCollapseTop3D ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      onClick={() => setIsShowCollapseTop3D(!isShowCollapseTop3D)}>
                      {'TOP SURFACE 3D'}
                    </Button>
                    <Collapse in={isShowCollapseTop3D} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                      <Grid container className={classes.textSetting}>
                        {clientData?.topSurfaceSetting?.isSameSideSurfaceOpacity ? 'Same as Side Surface' : 'Default'}
                      </Grid>
                    </Collapse>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                <Button
                  startIcon={isShowCollapseTop2D ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  onClick={() => setIsShowCollapseTop2D(!isShowCollapseTop2D)}>
                  {'TOP SURFACE 2D'}
                </Button>
                <Collapse in={isShowCollapseTop2D} timeout="auto" unmountOnExit sx={{ px: 4 }}>
                  <Grid container className={classes.textSetting}>
                    {clientData?.topSurfaceSetting?.opacity2D
                      ? `Opacity : ${clientData?.topSurfaceSetting?.opacity2D}`
                      : 'Transparent'}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Box>
        </Box> */}
      </Scrollbars>
    </Grid>
  );
};
