import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { IOption, Map2dOptionEnum } from 'interfaces/workspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeRightBar2dOptions } from 'store/slices/tilingMapSlice';

interface I2dOption extends IOption {
  name: Map2dOptionEnum;
}

const initial2dOptionList: I2dOption[] = [
  { key: 1, name: Map2dOptionEnum.orthophoto, label: 'RGB Orthoimage', disabled: false, value: true },
  { key: 2, name: Map2dOptionEnum.dsm, label: 'Digital Surface Model', disabled: false, value: false },
  { key: 3, name: Map2dOptionEnum.dtm, label: 'Digital Terrain Model', disabled: false, value: false },
];

const RasterOptions = () => {
  const [list2dOption, setList2dOption] = useState<I2dOption[]>(initial2dOptionList);
  const dispatch = useAppDispatch();

  // update option 2d value to redux
  useEffect(() => {
    const newOption2dList = list2dOption.map((item) => ({ name: item.name, value: item.value }));
    dispatch(changeRightBar2dOptions(newOption2dList));
  }, [dispatch, list2dOption]);

  const onToggle2dButton = async (event: ChangeEvent<HTMLInputElement>, key: number) => {
    const { checked } = event.target;
    setList2dOption((prev) => prev.map((item) => ({ ...item, value: item.key === key ? checked : false })));
  };
  return (
    <>
      <Typography sx={{ fontSize: '16px' }}>Rasters: </Typography>
      <Stack sx={{ ml: '12px', mb: '24px' }}>
        {list2dOption.map((item, i) => (
          <FormControlLabel
            key={i}
            control={
              <Switch disabled={item.disabled} checked={!!item.value} onChange={(e) => onToggle2dButton(e, item.key)} />
            }
            label={item.label}
          />
        ))}
      </Stack>
    </>
  );
};

export default RasterOptions;
