import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { uploadGeoTaggedPhoto } from 'services/MapView/apiMapViewConfig.services';
import { DrawIssueShapeEnum, IGeoTaggedPhoto, MediaTypeEnum } from '../../../interfaces';

interface UploadImageProps {
  formik: any;
  geoTaggedPhotoList: IGeoTaggedPhoto[];
  setGeoTaggedPhotoList: (arg: IGeoTaggedPhoto[]) => void;
  setIsShowDropMarkerModal: (arg: boolean) => void;
  handleClose: () => void;
  categoryUploadMedia: (arg1: FormData, arg2: MediaTypeEnum) => void;
}

const UploadImage: FC<UploadImageProps> = ({
  formik,
  geoTaggedPhotoList,
  setGeoTaggedPhotoList,
  setIsShowDropMarkerModal,
  handleClose,
  categoryUploadMedia,
}) => {
  const theme = useTheme();

  const uploadGeoTaggedImageMutation = useMutation(uploadGeoTaggedPhoto, {
    onSuccess: (res) => {
      const newGeoTaggedPhotoList = [...geoTaggedPhotoList, res.data];
      setGeoTaggedPhotoList(newGeoTaggedPhotoList);
    },
    onError: (error) => {
      if (geoTaggedPhotoList.length === 0) {
        setIsShowDropMarkerModal(true);
        handleClose();
      }
    },
  });

  const handleSelectImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : '';
    if (selectedFile && !selectedFile.type.includes('image')) {
      toast.error('Only upload images', { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    uploadGeoTaggedImageMutation.mutate(formData, {
      onError: () => {
        categoryUploadMedia(formData, MediaTypeEnum.IMAGE);
      },
    });
  };

  const handleRemovePreviewImage = (data: IGeoTaggedPhoto) => {
    const newGeoTaggedPhotoList = geoTaggedPhotoList.filter((item) => !isEqual(item, data));
    setGeoTaggedPhotoList(newGeoTaggedPhotoList);
  };

  return (
    <>
      {formik.values.type === DrawIssueShapeEnum.IMAGE && (
        <>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: '500' }}>Upload image: </Typography>
          </Grid>
          <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '4px' }}>
            {geoTaggedPhotoList.map((item, i) => (
              <Box sx={{ position: 'relative' }} key={i}>
                <img
                  src={item.path}
                  style={{
                    height: '80px',
                    width: '80px',
                    borderRadius: '4px',
                  }}
                  alt="geo tagged"
                />
                <CloseOutlinedIcon
                  color="error"
                  onClick={() => handleRemovePreviewImage(item)}
                  sx={{ fontSize: '16px', position: 'absolute', top: '2px', right: '2px', cursor: 'pointer' }}
                />
              </Box>
            ))}
            <label
              htmlFor="uploadImage"
              style={{
                height: '80px',
                width: '80px',
                borderRadius: '4px',
                border: `1px solid ${theme.palette.color.grey3}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              {uploadGeoTaggedImageMutation.isLoading ? (
                <CircularProgress size={16} />
              ) : (
                <AddOutlinedIcon sx={{ color: theme.palette.primary.main }} />
              )}
            </label>
            <input
              type="file"
              id="uploadImage"
              accept="image/png, image/jpeg"
              style={{ display: 'none' }}
              onChange={handleSelectImage}
              disabled={uploadGeoTaggedImageMutation.isLoading}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default UploadImage;
