import { AddCircleOutline } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  IOptionalProperty,
  IOptionalPropertyType,
  IPersonInChargeInfo,
  IUpdateHierarchyStructureDto,
  IUpdateProperties,
  RoleType,
} from 'common/defines/clients';
import PersonInfoForm from 'components/ClientsInfo/ClientProperties/PersonInfoForm';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import saveAs from 'file-saver';
import { useUpdateLevelProperties } from 'hooks/useClientProperties';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  exportKMLFile,
  queryMe,
  sendGetLocationDetails,
  sendGetLocationDetailsInformation,
} from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';
import { InfoFileItem } from './InfoFileItem';

const LevelInfo = ({ cropType, levelId, clientId }: any) => {
  const classes = useMapViewStyle();
  const [isCollapse, setIsCollapse] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const theme = useTheme();

  const {
    data: dataInformation,
    refetch: refetchDataMapViewLastLevel,
    remove: removeDataMapViewLastLevel,
  } = useQuery(
    [`${QUERY_KEY.DATA_MAP_KEY}_CLIENT`, clientId, cropType, levelId],
    () => sendGetLocationDetails(levelId || 'null'),
    {
      enabled: !!levelId,
    }
  );

  const { data: newDataDetail } = useQuery(
    [`${QUERY_KEY.DATA_DETAIL_INFOR_LEVEL}`, levelId],
    () => sendGetLocationDetailsInformation(levelId || 'null'),
    {
      enabled: !!levelId,
    }
  );

  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const hasPermission = useMemo(() => {
    const currentRole = userInfo?.data?.role?.find((role: RoleType) => role.clientId === clientId);
    return currentRole?.permissions?.some((permission: string) => permission === 'MANAGE_HIERARCHY_STRUCTURE_EDIT');
  }, [clientId, userInfo]);
  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const queryClient = useQueryClient();
  const updateLevelProperties = useUpdateLevelProperties();
  const { personInCharge } = useAppSelector(clientSelector);
  const dataRef = useRef<{ isSubmitAll: boolean }>({ isSubmitAll: false });

  const { control, setValue, handleSubmit, reset } = useForm<IUpdateProperties>({
    defaultValues: {
      personInCharge: [
        {
          name: '',
          jobTitle: '',
          contactNumber: '',
          email: '',
          avatar: '',
        },
      ],
    },
    mode: 'onBlur',
  });

  const { fields, append, update, remove } = useFieldArray({
    name: 'personInCharge',
    control,
  });

  useEffect(() => {
    dataInformation?.data?.personInCharge?.forEach((person: IPersonInChargeInfo, index: number) => {
      setValue(`personInCharge.${index}`, person);
      update(index, person);
    });
  }, [dataInformation, setValue, update]);

  useEffect(() => {
    reset({
      personInCharge:
        personInCharge && personInCharge.length > 0
          ? personInCharge
          : [
              {
                name: '',
                jobTitle: '',
                contactNumber: '',
                email: '',
                avatar: '',
              },
            ],
    });
  }, [levelId, setValue, reset, personInCharge]);

  const downloadFile = useMutation(
    (data: { id: string | undefined; type: string }) => exportKMLFile(data.id, data.type),
    {
      onSuccess: (response: any) => {
        saveAs(new Blob([response.data], { type: response.headers['content-type'] }), response.headers['filename']);
        alert('your file has downloaded!');
      },
    }
  );

  useEffect(() => {
    if (!clientId) {
      removeDataMapViewLastLevel();
      return;
    }
    if (levelId) {
      refetchDataMapViewLastLevel();
    }
  }, [refetchDataMapViewLastLevel, levelId, clientId, removeDataMapViewLastLevel]);

  const getItemProperty = useCallback(
    (propertyItem: IOptionalProperty, propertyIndex: number, properties: IOptionalProperty[]) => {
      switch (propertyItem.type) {
        case IOptionalPropertyType.TEXT:
          if (propertyItem.name !== 'Land Size') {
            return (
              <>
                <Typography className={classes.titleProperties}>{propertyItem?.name}</Typography>
                <Typography sx={{ py: 1 }}>{propertyItem?.value}</Typography>
              </>
            );
          }
          break;
        case IOptionalPropertyType.DATE:
          return (
            <>
              <Typography className={classes.titleProperties}>{propertyItem?.name}</Typography>
              {propertyItem?.value?.length ? (
                <Typography> {moment(propertyItem?.value, 'DD/MM/YYYY').format('DD MMM YYYY')}</Typography>
              ) : null}
            </>
          );
        case IOptionalPropertyType.SELECT:
          return (
            <>
              <Typography className={classes.titleProperties}>{propertyItem?.name}</Typography>
              {Array.isArray(propertyItem?.value) &&
                propertyItem?.value.map((item, index) => <Typography key={index}>{item}</Typography>)}
            </>
          );
        case IOptionalPropertyType.FILE:
          return properties ? (
            <InfoFileItem
              propertyItem={propertyItem}
              propertyIndex={propertyIndex}
              control={control}
              setValue={setValue}
              properties={properties}
              handleSubmitForm={handleSubmitForm}
              hasPermission={hasPermission || isSuperAdmin || isSuperUser}
            />
          ) : null;
        default:
          break;
      }
    },
    [classes]
  );

  const renderProperty = useCallback(
    (propertyItem: IOptionalProperty, propertyIndex: number) => {
      const item = getItemProperty(propertyItem, propertyIndex, newDataDetail?.data?.properties);
      return (
        <Typography component="ul" key={propertyItem.key} sx={{ pl: 0, pb: 1, pt: 1 }}>
          {item}
        </Typography>
      );
    },
    [getItemProperty, newDataDetail?.data?.properties]
  );

  const handleSubmitForm: SubmitHandler<IUpdateProperties> = (data) => {
    const dataStructure: IUpdateHierarchyStructureDto = {
      id: levelId,
      location: newDataDetail?.data?.location,
      properties: data.properties ? data.properties : newDataDetail?.data?.properties,
      personInCharge: data.personInCharge ? data.personInCharge : newDataDetail?.data?.personInCharge,
    };
    updateLevelProperties.mutate(dataStructure, {
      onSuccess: () => {
        queryClient.invalidateQueries(`${QUERY_KEY.DATA_MAP_KEY}_CLIENT`);
        queryClient.invalidateQueries(`${QUERY_KEY.DATA_DETAIL_INFOR_LEVEL}`);
        toast.success('Add properties success', { toastId: 1 });
      },
      onError: (err: any) => {
        toast.error(err || 'Save failed', { toastId: 1 });
      },
    });
  };

  const handleAddPersonInCharge = () => {
    append({
      name: '',
      jobTitle: '',
      contactNumber: '',
      email: '',
      avatar: '',
    });
  };

  return (
    <Grid container sx={{ pb: 2 }}>
      <Grid item xs={12}>
        <Typography className={classes.titleInput}>General</Typography>

        {newDataDetail?.data?.properties?.map(renderProperty)}
        <Button
          sx={{ p: 1, pl: 0 }}
          onClick={() => {
            downloadFile.mutate({ id: levelId, type: 'KML' });
          }}>
          <InsertDriveFileIcon fontSize="medium" color="info" sx={{ pr: 1 }} />
          Download KML
        </Button>
      </Grid>

      <Grid>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            sx={{ padding: 0, color: '#616161' }}
            className={classes.titleInput}
            startIcon={isCollapse ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsCollapse(!isCollapse)}>
            <Typography sx={{ color: '#616161', fontWeight: 600 }}>Person In Charge</Typography>
          </Button>
          {showEditForm ? (
            <CloseIcon sx={{ marginLeft: '5px', fontSize: '18px' }} onClick={() => setShowEditForm(!showEditForm)} />
          ) : (
            <EditOutlinedIcon
              sx={{ marginLeft: '5px', fontSize: '18px' }}
              onClick={() => setShowEditForm(!showEditForm)}
            />
          )}
        </Box>
        <Typography component="form" onSubmit={handleSubmit(handleSubmitForm)}>
          <Collapse
            in={showEditForm}
            sx={{ border: `1px solid ${theme.palette.divider}`, borderRadius: '10px', margin: '10px' }}>
            <Scrollbars style={{ height: 'calc(100vh - 500px)' }} autoHide>
              <Stack sx={{ padding: 2 }}>
                <Stack sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}>
                    <InputLabel sx={{ mb: 0 }}>Person In Charge</InputLabel>
                    <IconButton onClick={handleAddPersonInCharge}>
                      <AddCircleOutline fontSize="medium" />
                    </IconButton>
                  </Box>
                  {fields.map((personInfo: IPersonInChargeInfo, personIndex: number) => {
                    return (
                      <PersonInfoForm
                        control={control}
                        setValue={setValue}
                        remove={remove}
                        personIndex={personIndex}
                        personInfo={personInfo}
                        key={personInfo.key}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </Scrollbars>
            <Typography
              sx={{
                padding: 3,
                mt: 2,
              }}>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '200px',
                }}>
                <Button
                  type="submit"
                  variant="contained"
                  data-testid="next-btn"
                  sx={{
                    p: '6px',
                    width: '90px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    dataRef.current.isSubmitAll = true;
                  }}>
                  Save
                </Button>
              </Typography>
            </Typography>
          </Collapse>
        </Typography>
        <Collapse in={isCollapse} timeout="auto" unmountOnExit>
          {dataInformation?.data?.personInCharge?.map((personInCharge: any, index: number) => (
            <Grid item xs={12} key={index}>
              <div className={classes.avatarInfo}>
                <Avatar className={classes.avatarImage} src={personInCharge?.avatar} />
                <div className="info">
                  <Typography>{personInCharge?.name} </Typography>
                  <Typography className={classes.titleProperties}>{personInCharge?.jobTitle}</Typography>
                </div>
              </div>
              {personInCharge?.email ? (
                <Typography className={classes.titleInput} sx={{ display: 'flex' }}>
                  Email:
                  <Typography className={classes.titleProperties}>{personInCharge?.email}</Typography>
                </Typography>
              ) : null}
              {personInCharge?.email ? (
                <Typography className={classes.titleInput} sx={{ display: 'flex' }}>
                  Contact Number:
                  <Typography className={classes.titleProperties}>{personInCharge?.contactNumber}</Typography>
                </Typography>
              ) : null}
            </Grid>
          ))}
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default LevelInfo;
