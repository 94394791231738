import {
  Box,
  InputLabel,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { AscIcon, DescIcon } from 'assets/icons';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { clientSelector } from 'store/slices/clientSlice';

const PaddyTable = ({ nameTable }: any) => {
  const { allLocationCropType } = useAppSelector(clientSelector);
  const { clientId } = useParams();
  const [typeSortArea, setTypeSortArea] = useState(true);
  const [typeSortDistance, setTypeSortDistance] = useState(true);
  const theme = useTheme();

  const styles = {
    userListTitle: {
      py: '10px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#3C4043',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
      textAlign: 'center',
    },
    clientUpdateTime: {
      color: theme.palette.mode === 'dark' ? '#fff' : '#6E6B7B',
      textAlign: 'center',
      px: '16px',
    },
  };

  const formatData = (data: any[]) => {
    const helper = {} as any;
    const result = data.reduce((pre, current) => {
      const key = current.type;

      if (!helper[key]) {
        helper[key] = Object.assign({}, current);
        pre.push(helper[key]);
      } else {
        helper[key].lduseArea += current.lduseArea;
        helper[key].percentage += current.ldusePercentage;
      }

      return pre;
    }, []);
    return result;
  };

  const generatePercent = (data: any[]) => {
    const sumArea = data
      .map((_item: any) => _item.lduseArea)
      .reduce((previousValue: number, currentValue: number) => new BigNumber(previousValue).plus(currentValue), 0);
    let sumPercent = new BigNumber(0);

    const dataPercent = data.map((_item: any, index: number) => {
      if (index === data.length - 1) {
        return {
          ..._item,
          percent: new BigNumber(100).minus(sumPercent).toNumber(),
        };
      }
      const percent = new BigNumber(_item.lduseArea).div(sumArea).multipliedBy(100).toFixed(2);
      sumPercent = new BigNumber(percent).plus(sumPercent);
      return {
        ..._item,
        percent: +percent,
      };
    });
    return dataPercent;
  };

  const summaryDataAnalysis = (data: any[]) => {
    if (data.length > 0) {
      const result = formatData(data);
      const dataPercent = generatePercent(result);
      return dataPercent;
    }
    return [];
  };

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const lengthSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.LENGTH) ||
      defaultClientUnitSetting[1]
    );
  }, [clientSetting]);

  const clientLengthUnitMeasurement = useCallback(
    (length: any) => {
      switch (lengthSetting.unit) {
        case MeasurementUnit.KM:
          return new BigNumber(length * 0.001).toFixed(lengthSetting.decimalPlace);
        case MeasurementUnit.MILES:
          return new BigNumber(length * 0.000621371).toFixed(lengthSetting.decimalPlace);
        default:
          return length ? new BigNumber(length).toFixed(lengthSetting.decimalPlace) : null;
      }
    },
    [lengthSetting]
  );

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const tableArea = useMemo(() => {
    if (allLocationCropType && allLocationCropType.length > 0) {
      const mergeArray = allLocationCropType.reduce((pre: any, curent: any) => {
        switch (nameTable) {
          case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD:
            return pre.concat(curent.plantHealthAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION:
            return pre.concat(curent.weedInvasionAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA:
            return pre.concat(curent.vacantAreaAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY:
            return pre.concat(curent.tillerDensityAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD:
            return pre.concat(curent.chlorophyllPaddyAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD:
            return pre.concat(curent.vigorPaddyAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD:
            return pre.concat(curent.stressFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD:
            return pre.concat(curent.waterUptakeFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_SATURATION_FIELD:
            return pre.concat(curent.soilSaturationFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD:
            return pre.concat(curent.soilErosionFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD:
            return pre.concat(curent.leafPhenologyFieldAnalysisSummary);
          case TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD:
            return pre.concat(curent.slopeFieldAnalysisSummary);
          default:
            return pre;
          // code block
        }
      }, []);
      const formatArray = summaryDataAnalysis(mergeArray);

      const totalArea = formatArray.reduce((pre: any, current: any) => {
        return pre + current.lduseArea;
      }, 0);

      const totalAreaPercent = formatArray.reduce((pre: any, current: any) => {
        return new BigNumber(pre).plus(current.percent);
      }, 0);

      const sortedArray = formatArray.sort((a: any, b: any) => {
        return typeSortArea ? a.lduseArea - b.lduseArea : b.lduseArea - a.lduseArea;
      });

      if (sortedArray && sortedArray.length > 0) {
        return (
          <>
            {sortedArray.map((item: any, index: number) => {
              return (
                item.lduseArea > 0 && (
                  <TableRow key={index}>
                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        textAlign: 'left',
                        minWidth: '30px',
                      }}>
                      {item.type}
                    </TableCell>

                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        textAlign: 'left',
                        minWidth: '60px',
                      }}>
                      {clientAreaUnitMeasurement(item.lduseArea)} {`${areaUnit}`}
                    </TableCell>

                    <TableCell
                      component="td"
                      sx={{
                        ...styles.clientUpdateTime,
                        textAlign: 'left',
                        minWidth: '60px',
                      }}>
                      {item.percent} %
                    </TableCell>
                  </TableRow>
                )
              );
            })}
            <TableRow>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                Total:
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                {clientAreaUnitMeasurement(totalArea)} {`${areaUnit}`}
              </TableCell>
              <TableCell
                component="th"
                sx={{
                  ...styles.clientUpdateTime,
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}>
                {totalAreaPercent.toNumber()}%
              </TableCell>
            </TableRow>
          </>
        );
      }
    }
  }, [allLocationCropType, areaUnit, clientAreaUnitMeasurement, nameTable, summaryDataAnalysis, typeSortArea]);

  const handleSortTable = useCallback(
    (typeSort: string) => {
      if (typeSort === 'area') {
        setTypeSortArea(!typeSortArea);
      } else {
        setTypeSortDistance(!typeSortDistance);
      }
    },
    [typeSortArea, typeSortDistance]
  );

  return (
    <>
      <>
        <InputLabel sx={{ color: (theme) => theme.palette.primary.main }}>{nameTable} (Area)</InputLabel>
        <TableContainer
          sx={{
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '5px',
            mt: '-1px',
            mb: '12px',
          }}>
          <Table>
            <TableHead>
              {
                <TableRow
                  component="tr"
                  sx={{
                    // backgroundColor: '#EDF1f1',
                    backgroundColor: theme.palette.background.paper,
                  }}>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    Main Category
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Area
                      <SvgIcon
                        component={typeSortArea ? DescIcon : AscIcon}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable('area')}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    component="th"
                    sx={{
                      ...styles.userListTitle,
                      textAlign: 'left',
                      minWidth: '60px',
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Percentage
                      <SvgIcon
                        component={typeSortArea ? DescIcon : AscIcon}
                        inheritViewBox
                        sx={{ fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => handleSortTable('area')}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableHead>
            <TableBody
              sx={{
                pt: 0,
                flexDirection: 'column',
                minHeight: '510px',
                maxHeight: '510px',
                overflowY: 'scroll',
                '-ms-overflow-style': 'none' /* IE and Edge */,
                scrollbarWidth: 'none' /* Firefox */,
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
              {tableArea}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </>
  );
};

export default PaddyTable;
