import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { FC } from 'react';

interface CardWidgetProps {
  title: string;
  desc: string | number;
  unit?: string;
}

const CardWidget: FC<CardWidgetProps> = ({ title, desc, unit }) => {
  const formatDesc = (value: string | number) => {
    if (typeof value === 'string') {
      // Convert string value to number
      value = parseFloat(value);
    }
    return value.toLocaleString();
  };

  return (
    <Card elevation={0}>
      <CardActionArea>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
          <Typography gutterBottom variant="h2" component="div">
            <Typography component="span" variant="h1" sx={{ fontWeight: '800' }}>
              {formatDesc(desc)} {/* Call formatDesc function to format the desc */}
            </Typography>
            {unit ? (
              <Typography component="span" variant="h4">
                {' '}
                {unit}
              </Typography>
            ) : (
              ''
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardWidget;
