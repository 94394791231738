import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Tooltip, useTheme } from '@mui/material';
import { MapStateKey } from 'common/defines/constants';
import AppLayout from 'components/AppLayout';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import ErrorsPage from 'components/ErrorsPage';
import { MapView } from 'components/MapView';
import { RightBar } from 'components/MapView/RightBar';
import { RightBarOld } from 'components/MapView/RightBarOld';
import { NavbarMapView } from 'components/MapView/SideBar/NavbarMapView';
import { QUERY_KEY } from 'constants/constants';
import { NEW_VERSION_PATHS } from 'constants/enums/version-enums';
import usePathForVersion from 'hooks/common/usePathForVersion';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { changeIs3D } from 'store/slices/dashboardWidgetSlice';
import { changeDrawerShow } from 'store/slices/mapViewSlice';
import { changeIsOpen } from 'store/slices/rightBarSlice';
import { useMapViewPageStyle } from './MapViewPageStyle';

export const MapViewPage = () => {
  const classes = useMapViewPageStyle();
  const theme = useTheme();
  const isNewVersion = usePathForVersion(NEW_VERSION_PATHS.MAP_VIEW);
  const dispatch = useAppDispatch();
  const [isMapViewPermission, setIsMapViewPermission] = useState(false);
  const { clientId } = useParams();
  const [isShowNavbar, setIsShowNavbar] = useState<boolean>(true);
  const [isShowAnalytics, setIsShowAnalytics] = useState<boolean>(true);
  const { data: checkSuperAdmin, isLoading } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: true,
  });

  const isSuperAdmin = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperAdmin;
  }, [checkSuperAdmin]);
  const isSuperUser = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperUser;
  }, [checkSuperAdmin]);

  const userId = useMemo(() => {
    if (!checkSuperAdmin) return '';
    return checkSuperAdmin.data._id;
  }, [checkSuperAdmin]);

  const permissions = useMemo(() => {
    return checkSuperAdmin?.data?.role?.length && checkSuperAdmin?.data?.role[0]?.permissions;
  }, [checkSuperAdmin]);

  const { data: userInfoByClient } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    }
  );

  const mapViewPermissions = useMemo(() => {
    if (isSuperAdmin || isSuperUser) {
      return true;
    }
    if (!checkSuperAdmin?.data?.role) return false;
    const dataRoleValue = userInfoByClient?.data?.flagTab;

    if (!dataRoleValue || dataRoleValue.length === 0) return false;
    dataRoleValue.forEach((item: any) => {
      if (item === 'MAPVIEW') {
        setIsMapViewPermission(true);
      }
    });
    return isMapViewPermission;
  }, [checkSuperAdmin, isMapViewPermission, isSuperAdmin, isSuperUser, userInfoByClient]);

  const navbarMapWidth = useMemo(() => {
    if (isShowNavbar) {
      return '300px';
    }
    return '0px';
  }, [isShowNavbar]);

  const analyticPanelWidth = useMemo(() => {
    if (isShowAnalytics) {
      return '600px';
    }
    return '0px';
  }, [isShowAnalytics]);

  const mapWidth = useMemo(() => {
    return `calc(100% - ${navbarMapWidth} - ${analyticPanelWidth} - 4px)`;
  }, [analyticPanelWidth, navbarMapWidth]);

  useEffect(() => {
    dispatch(changeDrawerShow({ leftBar: isShowNavbar, rightBar: isShowAnalytics }));
  }, [dispatch, isShowAnalytics, isShowNavbar]);

  useEffect(() => {
    setIsShowAnalytics(false);
  }, []);
  useEffect(() => {
    dispatch(changeIsOpen(isShowAnalytics));
    dispatch(changeIs3D(isNewVersion ? isShowAnalytics : false));
  }, [isShowAnalytics, isNewVersion]);

  const mapView = useMemo(() => {
    if (isLoading) {
      return <WaitingPageAPI />;
    } else if (isSuperAdmin || isSuperUser) {
      return (
        <Grid
          container
          direction="column"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          sx={{ height: 'calc(100vh - 76px)', position: 'relative', overflow: 'hidden' }}>
          <Grid
            direction="column"
            className={classes.columnNavbar}
            sx={{
              width: '300px',
              height: '100%',
              // borderRight: `1px solid ${theme.palette.divider}`,
              display: isShowNavbar ? '' : 'none',
              position: 'relative',
              zIndex: 8,
            }}>
            <NavbarMapView />
          </Grid>
          <Button
            sx={{
              left: isShowNavbar ? '288px' : ' 12px',
              position: 'absolute',
              top: '100px',
            }}
            className={classes.navBarButton}
            onClick={() => setIsShowNavbar((value) => !value)}>
            {isShowNavbar ? (
              <ArrowBackIosNewIcon className={classes.arrowIcon} fontSize="small" />
            ) : (
              <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />
            )}
          </Button>
          <Grid
            direction="column"
            position="relative"
            height={'100%'}
            minHeight={'calc(100vh - 76px)'}
            sx={{ width: mapWidth, flex: 1 }}>
            <MapView
              navbarMapWidth={navbarMapWidth}
              analyticPanelWidth={analyticPanelWidth}
              showAnalytics={isShowAnalytics}
              isSuperAdmin={isSuperAdmin}
              isSuperUser={isSuperUser}
              openAnalytic={setIsShowAnalytics}
              mapStateKey={MapStateKey.MAP_VIEW}
            />
          </Grid>
          <Tooltip title="Hide Analytical Information">
            <Button
              sx={{
                position: 'absolute',
                visibility: isShowAnalytics ? 'visible' : 'hidden',
              }}
              classes={{ root: isShowAnalytics ? classes.showPanelBtn : undefined }}
              onClick={() => setIsShowAnalytics((value) => !value)}>
              {isShowAnalytics && <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />}
            </Button>
          </Tooltip>
          <Grid
            direction="column"
            sx={{
              width: '600px',
              height: '100%',
              borderLeft: `1px solid ${theme.palette.divider}`,
              display: isShowAnalytics ? '' : 'none',
              position: 'relative',
              // overflow: 'hidden',
            }}>
            {isNewVersion ? <RightBar /> : <RightBarOld />}
          </Grid>
        </Grid>
      );
    } else if (mapViewPermissions) {
      return (
        <Grid
          container
          direction="column"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          sx={{ height: 'calc(100vh - 76px)', position: 'relative', overflow: 'hidden' }}>
          <Grid
            direction="column"
            className={classes.columnNavbar}
            sx={{
              width: '300px',
              height: '100%',
              // borderRight: `1px solid ${theme.palette.divider}`,
              display: isShowNavbar ? '' : 'none',
              position: 'relative',
              zIndex: 8,
            }}>
            <NavbarMapView />
          </Grid>
          <Button
            sx={{
              left: isShowNavbar ? '288px' : ' 12px',
              position: 'absolute',
            }}
            className={classes.navBarButton}
            onClick={() => setIsShowNavbar((value) => !value)}>
            {isShowNavbar ? (
              <ArrowBackIosNewIcon className={classes.arrowIcon} fontSize="small" />
            ) : (
              <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />
            )}
          </Button>
          <Grid
            direction="column"
            position="relative"
            height={'100%'}
            minHeight={'calc(100vh - 76px)'}
            sx={{ width: mapWidth, flex: 1 }}>
            <MapView
              navbarMapWidth={navbarMapWidth}
              analyticPanelWidth={analyticPanelWidth}
              showAnalytics={isShowAnalytics}
              permissions={permissions}
              isSuperAdmin={isSuperAdmin}
              isSuperUser={isSuperUser}
              openAnalytic={setIsShowAnalytics}
              mapStateKey={MapStateKey.MAP_VIEW}
            />
          </Grid>
          <Button
            sx={{
              position: 'absolute',
              visibility: isShowAnalytics ? 'visible' : 'hidden',
            }}
            classes={{ root: isShowAnalytics ? classes.showPanelBtn : undefined }}
            onClick={() => setIsShowAnalytics((value) => !value)}>
            {isShowAnalytics && <ArrowForwardIosIcon className={classes.arrowIcon} fontSize="small" />}
          </Button>
          <Grid
            direction="column"
            sx={{
              width: '600px',
              height: '100%',
              // borderLeft: `1px solid ${theme.palette.divider}`,
              borderLeft: `1px solid ${theme.palette.divider}`,
              display: isShowAnalytics ? '' : 'none',
              position: 'relative',
              // overflow: 'hidden',
            }}>
            {isNewVersion ? <RightBar /> : <RightBarOld />}
          </Grid>
        </Grid>
      );
    } else {
      return <ErrorsPage status="403" />;
    }
  }, [
    analyticPanelWidth,
    classes.arrowIcon,
    classes.columnNavbar,
    classes.navBarButton,
    classes.showPanelBtn,
    isLoading,
    isShowAnalytics,
    isShowNavbar,
    isSuperAdmin,
    isSuperUser,
    mapViewPermissions,
    mapWidth,
    navbarMapWidth,
    theme.palette.divider,
    permissions,
    isNewVersion,
  ]);

  return <AppLayout>{mapView}</AppLayout>;
};
