import { Grid, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { MousePositionEnum, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import { MANAGE_CLIENT_SETTING_VIEW, QUERY_KEY } from 'constants/constants';
import { filter } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { superAdminSelector } from 'store/slices/superAdminSlice';

const fixedSize = {
  height: 250,
  width: 350,
};

const ToolTipMaker = ({ hoverInfo, dataLabel, analyticName, categoryByColor, mouseRef }: any) => {
  const { isShowAllInfoTooltip, dataIntervalAllLimit } = useAppSelector(mapViewSelector);
  const { drawerShow } = useAppSelector(mapViewSelector);
  const { clientId } = useParams();

  const dataLabelByHoverd = useMemo(() => {
    return dataLabel?.data?.find((item: any) => {
      return item?._id === hoverInfo?.properties?._id;
    });
  }, [dataLabel?.data, hoverInfo?.properties?._id]);
  //find point from getAlldata

  const pointInfo = categoryByColor.find((category: any) => {
    return category?.color === hoverInfo?.properties?.color;
  });

  const labelInfo = useMemo(() => {
    if (!pointInfo) return null;
    return pointInfo?.label ? pointInfo?.label : pointInfo?.lable;
  }, [pointInfo?.label, pointInfo?.lable]);

  const { permissions } = useAppSelector(superAdminSelector)

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => permissions[MANAGE_CLIENT_SETTING_VIEW] ? getClientSettingsById(clientId || '') : undefined,
    {
      enabled: !!clientId,
    }
  );

  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const lengthSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.LENGTH) ||
      defaultClientUnitSetting[1]
    );
  }, [clientSetting]);

  const clientLengthUnitMeasurement = useCallback(
    (length: any) => {
      switch (lengthSetting.unit) {
        case MeasurementUnit.KM:
          return new BigNumber(length * 0.001).toFixed(lengthSetting.decimalPlace);
        case MeasurementUnit.MILES:
          return new BigNumber(length * 0.000621371).toFixed(lengthSetting.decimalPlace);
        default:
          return length ? new BigNumber(length).toFixed(lengthSetting.decimalPlace) : null;
      }
    },
    [lengthSetting]
  );

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber((area / 10000) * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area / 1000000).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.HA:
        default:
          return new BigNumber(area / 10000).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const dataListLabel = useMemo(() => {
    if (!dataLabelByHoverd) return [];
    const stressIn = dataLabelByHoverd.stressIn;
    const waterUptakeIn = dataLabelByHoverd.uptakeIn;
    const vigorIn = dataLabelByHoverd.vigorIn;
    const chloroIn = dataLabelByHoverd.chloroIn;
    const phIn = dataLabelByHoverd.phIn;
    const cirLength = dataLabelByHoverd.cirLength;
    const area = dataLabelByHoverd.area;
    let waterLabel = null as any;
    let vigorLabel = null as any;
    let stressLabel = null as any;
    let chlorophyllLabel = null as any;
    let cownAreaLabel = null as any;
    let circumferenceLabel = null as any;
    let pointInfoByLabel = [] as any;
    let plantHealthOilPalmLabel = [] as any;

    dataIntervalAllLimit.forEach((i: any) => {
      switch (i.name) {
        case TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE:
          waterLabel = i.intervalLimit[0]?.range.find(
            (range: any) => waterUptakeIn >= range.from && waterUptakeIn <= range.to
          );
          break;
        case TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE:
          vigorLabel = i.intervalLimit[0]?.range.find((range: any) => vigorIn >= range.from && vigorIn <= range.to);
          break;
        case TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE:
          stressLabel = i.intervalLimit[0]?.range.find((range: any) => stressIn >= range.from && stressIn <= range.to);
          break;
        case TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE:
          chlorophyllLabel = i.intervalLimit[0]?.range.find(
            (range: any) => chloroIn >= range.from && chloroIn <= range.to
          );
          break;
        case TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE:
          plantHealthOilPalmLabel = i.intervalLimit[0]?.range.find(
            (range: any) => phIn >= range.from && phIn <= range.to
          );
          break;
        case TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS:
          circumferenceLabel = i.intervalLimit[0]?.range.find(
            (range: any) => cirLength >= range.from && cirLength <= range.to
          );
          break;
        case TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA:
          cownAreaLabel = i.intervalLimit[0]?.range.find((range: any) => area >= range.from && area <= range.to);
          break;
        default:
          break;
        // code block
      }
    });

    pointInfoByLabel.push(
      {
        isTop: analyticName === TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
        label: analyticName === TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE ? labelInfo : waterLabel?.lable,
        title: 'Water uptake',
        value: dataLabelByHoverd.uptakeIn.toFixed(lengthSetting.decimalPlace),
      },
      {
        isTop: analyticName === TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
        label: analyticName === TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE ? labelInfo : vigorLabel?.lable,
        title: 'Vigor',
        value: dataLabelByHoverd.vigorIn.toFixed(lengthSetting.decimalPlace),
      },
      {
        isTop: analyticName === TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
        label: analyticName === TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE ? labelInfo : stressLabel?.lable,
        title: 'Stress',
        value: dataLabelByHoverd.stressIn.toFixed(lengthSetting.decimalPlace),
      },
      {
        isTop: analyticName === TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
        label: analyticName === TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE ? labelInfo : chlorophyllLabel?.lable,
        title: 'Chlorophyll',
        value: dataLabelByHoverd.chloroIn.toFixed(lengthSetting.decimalPlace),
      },
      {
        isTop: analyticName === TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
        label: analyticName === TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS ? labelInfo : circumferenceLabel?.lable,
        title: 'Circumference',
        value: `${clientLengthUnitMeasurement(dataLabelByHoverd.cirLength)} ${lengthSetting?.unit}`,
      },
      {
        isTop: analyticName === TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
        label: analyticName === TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA ? labelInfo : cownAreaLabel?.lable,
        title: 'Crown area',
        value: `${clientAreaUnitMeasurement(dataLabelByHoverd.area)} ${areaUnit}`,
      },
      {
        isTop: analyticName === TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE,
        label: analyticName === TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_TREE ? labelInfo : plantHealthOilPalmLabel?.lable,
        title: 'Plant Health Tree',
      }
    );

    const filterPointInfoByLabel = filter(pointInfoByLabel, (item) => {
      return item?.label;
    }).sort((a: any, b: any) => {
      if (a.isTop) {
        return -1;
      }
      return 0;
    });
    return filterPointInfoByLabel;
  }, [analyticName, dataIntervalAllLimit, dataLabelByHoverd, labelInfo]);

  if (!hoverInfo) return null;

  const detectMousePosition = () => {
    // 64px is width of menu bar
    let clientX = mouseRef.current.clientX - 64;
    let clientY = mouseRef.current.clientY;

    let innerWidth = window.innerWidth - 64;
    const innerHeight = window.innerHeight;

    // 300px is width of left sideBar
    if (drawerShow.leftBar) {
      clientX -= 300;
      innerWidth -= 300;
    }

    // 454px is width of right sideBar
    if (drawerShow.rightBar) {
      innerWidth -= 454;
    }

    if (clientY < innerHeight / 2) {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.TOP_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.TOP_RIGHT;
      else return MousePositionEnum.TOP_CENTER;
    } else {
      if (clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_LEFT;
      else if (innerWidth - clientX < fixedSize.width / 2) return MousePositionEnum.BOTTOM_RIGHT;
      else return MousePositionEnum.BOTTOM_CENTER;
    }
  };

  const calculateOffsetYWithPosition = () => {
    if (detectMousePosition().includes('top')) return 8;
    else return -10;
  };

  return !isShowAllInfoTooltip ? (
    <Marker
      longitude={hoverInfo.properties.longX}
      latitude={hoverInfo.properties.latY}
      anchor={detectMousePosition()}
      style={{ zIndex: 10 }}
      offset={[0, calculateOffsetYWithPosition()]}>
      <Grid
        maxWidth={350}
        minWidth={350}
        height={'fit-content'}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          border: 0,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '5px',
          display: 'flex',
          paddingX: '4px',
          alignItems: 'center',
          pointerEvents: 'none',
          transformOrigin: '0 0 0',
          position: 'relative',
          zIndex: 10,
        }}>
        <Grid container padding={1} gap={0.25}>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              }}>
              Tree Id
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                padding: '2px',
                color: '#1C9855',
                width: '80%',
                wordWrap: 'break-word',
              }}>
              {hoverInfo.properties.treeId}
            </Typography>
          </Grid>
          {analyticName === TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA && (
            <Grid
              item
              xs={12}
              direction="row"
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                component="span"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                }}>
                Crown Area
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                  padding: '2px',
                  color: '#1C9855',
                  width: 'fit-content',
                }}>
                {`${clientAreaUnitMeasurement(hoverInfo.properties.area)} ${areaUnit}`}
              </Typography>
            </Grid>
          )}
          {analyticName === TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS && (
            <>
              <Grid
                item
                xs={12}
                direction="row"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                  }}>
                  Circumference
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'normal',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                    padding: '2px',
                    color: '#1C9855',
                    width: 'fit-content',
                  }}>
                  {`${clientLengthUnitMeasurement(hoverInfo.properties.cirLength)} ${lengthSetting?.unit}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                direction="row"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
                  }}>
                  Diameter
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'normal',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                    padding: '2px',
                    color: '#1C9855',
                    width: 'fit-content',
                  }}>
                  {`${clientLengthUnitMeasurement(hoverInfo.properties.cirLength / Math.PI)} ${lengthSetting?.unit}`}
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              }}>
              Categories
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                padding: '2px',
                color: '#1C9855',
                width: 'fit-content',
              }}>
              {labelInfo}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Marker>
  ) : (
    <Marker
      longitude={hoverInfo.properties.longX}
      latitude={hoverInfo.properties.latY}
      anchor={detectMousePosition()}
      style={{ zIndex: 10 }}
      offset={[0, calculateOffsetYWithPosition()]}>
      <Grid
        width={350}
        minWidth={250}
        height={'fit-content'}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          border: 0,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
          borderRadius: '5px',
          display: 'flex',
          paddingX: '4px',
          alignItems: 'center',
          pointerEvents: 'none',
          transformOrigin: '0 0 0',
          position: 'relative',
          zIndex: 10,
        }}>
        <Grid container padding={1} gap={0.25}>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#616161'),
              }}>
              Tree Id
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                padding: '2px',
                color: '#1C9855',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {hoverInfo.properties.treeId}
            </Typography>
          </Grid>
          {dataListLabel?.map((item: any, i) => {
            return (
              <Grid container key={i}>
                <Grid item xs={5}>
                  <Typography component="span" sx={{ fontSize: '14px', fontWeight: 'bold', color: '#616161' }}>
                    {item?.title}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                      margin: '2px',
                      color: '#1C9855',
                      width: 'fit-content',
                    }}>
                    {item.value}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? theme.palette.background.default : '#ECFDF5',
                      padding: '2px',
                      color: '#1C9855',
                      width: 'fit-content',
                    }}>
                    {item?.label}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Marker>
  );
};

export default ToolTipMaker;
