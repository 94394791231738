import { useTheme } from '@mui/material';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import { Layer, Source } from 'react-map-gl';
import { useAppSelector } from 'store/hooks';
import { tilingMapSelector } from 'store/slices/tilingMapSlice';

const ShowBoundaryLayer = () => {
  const theme = useTheme();
  const {
    selectedFieldId,
    drawingBoundary: { isShow: isShowDrawing, geometry },
  } = useAppSelector(tilingMapSelector);
  const { fieldDetail } = useQueryFieldDetail();

  const boundary = fieldDetail?.boundary;

  const defaultBorder = theme.palette.primary.main;

  const geojson: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [{ type: 'Feature', geometry: geometry, properties: {} }],
  };

  return isShowDrawing ? null : (
    <Source key={`${selectedFieldId}_source_polygon`} type="geojson" data={boundary || geojson}>
      {/* 2d contour layer */}
      <Layer
        id={`${selectedFieldId}_border_polygon`}
        key={`${selectedFieldId}_border_polygon`}
        type="line"
        source={`${selectedFieldId}_source_polygon`}
        paint={{
          'line-color': ['get', 'color'] || defaultBorder,
          'line-opacity': 0.8,
          'line-width': 2,
          'line-dasharray': [1, 0],
        }}
        layout={{ visibility: 'visible' }}
      />
    </Source>
  );
};

export default ShowBoundaryLayer;
