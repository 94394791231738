import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Skeleton, Stack, Typography } from '@mui/material';
import { IStreamlineOrderData } from 'common/defines/clients';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { SortDateEnum } from 'components/MapView/RightBarOld/IssuesTab/interfaces';
import { QUERY_KEY } from 'constants/constants';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getStreamlineOrderList } from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeAllStreamlineOrderDisplay, rightBarSelector } from 'store/slices/rightBarSlice';
import StreamlineOrderPieChart from './StreamlineOrderPieChart';
import SwitchStreamlineOrderList from './SwitchStreamlineOrderList';

interface StreamLineOrderCollapseProps {
  isShowVector: boolean;
}

const StreamLineOrderCollapse: FC<StreamLineOrderCollapseProps> = ({ isShowVector }) => {
  const [isShowStreamlineList, setIsShowStreamlineList] = useState<boolean>(false);
  const { streamlineOrderDisplay, streamlineOrderSensorId: sensorId } = useAppSelector(rightBarSelector);

  const dispatch = useAppDispatch();
  const classes = useLayerSelectCardStyle();

  const { isLoading, isRefetching } = useQuery(
    [QUERY_KEY.GET_STREAMLINE_ORDER_LIST, sensorId],
    () => getStreamlineOrderList({ page: 1, perPage: 20, sortDate: SortDateEnum.DESC, sensorId }),
    {
      enabled: !!sensorId,
      onSuccess(res) {
        const resData = res.data?.docs as IStreamlineOrderData[];
        const filteredStreamlineOrderList = resData.map(({ order, colorCode, length, lengthPercentage, sensorId }) => ({
          order,
          colorCode,
          length,
          lengthPercentage,
          visible: false,
          sensorId,
        }));
        dispatch(changeAllStreamlineOrderDisplay(filteredStreamlineOrderList));
      },
    }
  );

  return (
    <Collapse in={isShowVector} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
      <Button
        startIcon={
          isShowStreamlineList ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowStreamlineList(!isShowStreamlineList)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{ marginRight: 'auto', fontWeight: '500', fontSize: '13px !important' }}>
          Streamline Order
        </Typography>
      </Button>
      <Collapse in={isShowStreamlineList} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
        {sensorId && (
          <>
            <Stack>
              {isLoading || isRefetching ? (
                <Box>
                  {Array(6)
                    .fill(null)
                    .map((_, i) => (
                      <Skeleton key={i} variant="rectangular" width="100%" sx={{ mt: '8px' }} />
                    ))}
                </Box>
              ) : (
                <SwitchStreamlineOrderList />
              )}
            </Stack>
            <StreamlineOrderPieChart isLoading={isLoading || isRefetching} data={streamlineOrderDisplay} />
          </>
        )}
      </Collapse>
    </Collapse>
  );
};

export default StreamLineOrderCollapse;
