import { Box } from '@mui/material';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import BoundaryInteraction from './BoundaryInteraction';
import RasterOptions from './RasterOptions';

const Layer2dMap = () => {
  const { fieldDetail } = useQueryFieldDetail();

  return (
    <>
      {fieldDetail ? (
        <Box sx={{ m: '24px' }}>
          <RasterOptions />
          <BoundaryInteraction fieldDetail={fieldDetail} />
        </Box>
      ) : null}
    </>
  );
};

export default Layer2dMap;
