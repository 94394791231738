import { Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from '@mui/material';
interface IInfoTable {
  standCountData: any;
}

const InfoTable = ({ standCountData }: IInfoTable) => {
  const theme = useTheme();

  const styles = {
    userListTitle: {
      py: '10px',
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '18.2px',
      textAlign: 'center',
    },
    clientUpdateTime: {
      color: theme.palette.mode === 'dark' ? '#fff !important' : '#6E6B7B !important',
      textAlign: 'center',
      px: '16px',
    },
  };
  return (
    <TableContainer sx={{ overflow: 'hidden' }}>
      <Table>
        <TableBody
          sx={{
            pt: 0,
            flexDirection: 'column',
            minHeight: '510px',
            maxHeight: '510px',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          <TableRow>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                textAlign: 'left',
                fontWeight: 'bold',
              }}>
              Tree count:
            </TableCell>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                fontWeight: 'bold',
              }}>
              {standCountData.totalTree}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                textAlign: 'left',
                fontWeight: 'bold',
              }}>
              Stands per hectare:
            </TableCell>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                fontWeight: 'bold',
              }}>
              {standCountData.standPerHa ? standCountData.standPerHa.toFixed(2) : 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                textAlign: 'left',
                fontWeight: 'bold',
              }}>
              Blank spots:
            </TableCell>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                fontWeight: 'bold',
              }}>
              {standCountData.totalBlankSpot}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                textAlign: 'left',
                fontWeight: 'bold',
              }}>
              Potential stands per hectare:
            </TableCell>
            <TableCell
              component="td"
              sx={{
                ...styles.clientUpdateTime,
                fontWeight: 'bold',
              }}>
              {standCountData.potentialStandPerHa ? Math.round(standCountData.potentialStandPerHa.toFixed(2)) : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InfoTable;
