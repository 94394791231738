import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { analyticsConfigSlice } from './slices/analyticsConfigSlice';
import { authSlice } from './slices/authSlice';
import { breadCrumbsSlice } from './slices/breadcrumbsSlice';
import { clientDataUserSlice } from './slices/clientDataUserSlice';
import { clientSlice } from './slices/clientSlice';
import { chlorophyllAnalyticsSlice } from './slices/map-view/chlorophyllAnalytics';
import { circumferenceAnalyticsSlice } from './slices/map-view/circumferenceAnalytics';
import { crownAreaAnalyticSlice } from './slices/map-view/crownAreaAnalytics';
import { plantHealthOilPailmAnalyticsSlice } from './slices/map-view/plantHealthOilPailmAnalytics';
import { plantStressAnalyticsSlice } from './slices/map-view/plantStressAnalytics';
import { standCountAnalyticSlice } from './slices/map-view/standCountAnalytics';
import { vigorAnalyticsSlice } from './slices/map-view/vigorAnalytics';
import { waterUptakeAnalyticsSlice } from './slices/map-view/waterUptakeAnalytics';
import { mapPopupSlice } from './slices/mapPopupSlice';
import { mapViewSlice } from './slices/mapViewSlice';
import { rightBarSlice } from './slices/rightBarSlice';
import { superAdminSlice } from './slices/superAdminSlice';
import { tilingMapSlice } from './slices/tilingMapSlice';
import { userSlice } from './slices/userSlice';
import { dashboardWidget } from './slices/dashboardWidgetSlice';
import { bottomBarSlice } from './slices/map-view/bottomBarSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    client: clientSlice.reducer,
    user: userSlice.reducer,
    clientDataUser: clientDataUserSlice.reducer,
    analyticsConfig: analyticsConfigSlice.reducer,
    breadCrumbs: breadCrumbsSlice.reducer,
    superAdmin: superAdminSlice.reducer,
    // map view
    mapView: mapViewSlice.reducer,
    rightBar: rightBarSlice.reducer,
    vigorAnalytics: vigorAnalyticsSlice.reducer,
    plantStressAnalytic: plantStressAnalyticsSlice.reducer,
    crownAreaAnalyticSlice: crownAreaAnalyticSlice.reducer,
    waterUptakeAnalytic: waterUptakeAnalyticsSlice.reducer,
    chlorophyllAnalytic: chlorophyllAnalyticsSlice.reducer,
    circumferenceAnalytic: circumferenceAnalyticsSlice.reducer,
    standCountAnalytic: standCountAnalyticSlice.reducer,
    plantHealthOilPailmAnalytic: plantHealthOilPailmAnalyticsSlice.reducer,
    bottomBar: bottomBarSlice.reducer,
    // end map view
    // map popup
    mapPopup: mapPopupSlice.reducer,
    // end map popup
    tilingMap: tilingMapSlice.reducer,
    dashboardWidget: dashboardWidget.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
