import { Box } from '@mui/material';
import { ITensionCrackAnalysis } from 'common/defines/analytic';
import { DEFAULT_BORDER_COLOR, ISettingsClientUI, SettingUIName } from 'common/defines/clients';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { useEffect, useMemo, useState } from 'react';
import { Layer, Marker, Source } from 'react-map-gl';
import { useQuery } from 'react-query';
import { getTensionCrackPagination } from 'services/analytics/apiAnalyticsData.services';
import { rightBarSelector } from 'store/slices/rightBarSlice';
import { useAppSelector } from '../../../store/hooks';
import { mapViewSelector } from '../../../store/slices/mapViewSlice';

interface IMetadata {
  nextPage: number | null;
  pageSize: number;
  pageCount: number;
  totalCount: number;
}

export const useTensionCrackAnalytics = () => {
  const [page, setPage] = useState<number>(1);
  const [tensionCrackData, setTensionCrackData] = useState<ITensionCrackAnalysis[]>([]);

  const { analyticId } = useAppSelector(mapViewSelector);
  const { analyticName } = useAppSelector(rightBarSelector);
  const { clientSetting } = useQueryClientSetting();

  const uiSetting = clientSetting?.uiSetting as ISettingsClientUI[];
  const strokeWidthValue = uiSetting && uiSetting.find((item) => item.name === SettingUIName.STROKE_WIDTH)?.value;

  useQuery(
    [QUERY_KEY.GET_TENSION_CRACK_PAGINATION, page],
    () => getTensionCrackPagination({ analysisId: analyticId!, page }),
    {
      enabled: !!analyticId && analyticName === TYPE_ANALYTICS_MAP_VIEW.TENSION_CRACK,
      keepPreviousData: true,
      onSuccess(res) {
        const resData = res.data?.data as ITensionCrackAnalysis[];
        const metaData = res.data?.metadata as IMetadata;
        if (metaData?.nextPage) {
          setPage((prev) => prev + 1);
        }
        setTensionCrackData((prev) => [...prev, ...resData]);
      },
    }
  );

  // reset data when analyticId = null
  useEffect(() => {
    if (!analyticId || analyticName !== TYPE_ANALYTICS_MAP_VIEW.TENSION_CRACK) {
      setTensionCrackData([]);
      setPage(1);
    }
  }, [analyticId, analyticName]);

  const geojsonData: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: tensionCrackData.map((item) => ({
      type: 'Feature',
      properties: {},
      geometry: item.geometry,
    })) as GeoJSON.Feature[],
  };

  const calculateRotation = (coordinates: any[]) => {
    const [startX, startY] = coordinates[0];
    const [endX, endY] = coordinates[1];

    // Calculate the angle of rotation based on the line segment direction
    const angleRadians = Math.atan2(endY - startY, endX - startX);

    // Convert radians to degrees and adjust for icon orientation (if needed)
    const angleDegrees = (angleRadians * 180) / Math.PI;
    return angleDegrees;
  };

  const renderTensionCrackData = useMemo(() => {
    const renderTransformLogic = (coordinates: any[], j: number) => {
      if (j === coordinates[0].length - 1) {
        return `rotate(${-calculateRotation([coordinates[0][j - 1], coordinates[0][j]])}deg)`;
      } else if (j < coordinates[0].length - 1) {
        return `rotate(${-calculateRotation([coordinates[0][j], coordinates[0][j + 1]])}deg)`;
      }
    };

    return (
      tensionCrackData.length &&
      tensionCrackData.map((item, i) =>
        item.geometry.coordinates[0].map((element: any[], j: number) => {
          if (j > item.geometry.coordinates[0].length - 1) return null;
          return (
            <Marker key={`marker-${j}`} longitude={element[0]} latitude={element[1]} anchor="center">
              <Box
                sx={{
                  color: DEFAULT_BORDER_COLOR,
                  fontSize: '30px',
                  fontWeight: 500,
                  transform: renderTransformLogic(item.geometry.coordinates, j),
                }}>
                T
              </Box>
            </Marker>
          );
        })
      )
    );
  }, [tensionCrackData]);

  const layerTensionCrack = tensionCrackData.length && (
    <Source id="TensionCrack" key="TensionCrack" type="geojson" data={geojsonData}>
      <Layer
        id={`line_TensionCrack`}
        type="line"
        paint={{
          'line-color': DEFAULT_BORDER_COLOR,
          'line-opacity': 1,
          'line-width': strokeWidthValue || 2,
        }}
        layout={{ visibility: 'visible' }}
      />
      {renderTensionCrackData}
    </Source>
  );

  return { layerTensionCrack };
};
