import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY, STATUS_API } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getCheckExistPlantationCropCoverage,
  getDataCropCoverAnalytic,
  getPlantationCropCoverage
} from 'services/analytics/apiAnalyticsConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeColorAnalyticLayers,
  changeColorBorderPlantation,
  changeIsDisplayBoundary,
  changeLayer3D,
  changePlantationDataCropCoverage,
  changeSizeCropCoverageSelected,
  changeVisibleCropCoverAllLayers,
  changeVisibleCropCoverLayer,
  mapViewSelector,
  setDataCropCoverage
} from 'store/slices/mapViewSlice';
import { getClientSettingsById } from '../../../../../../services/clients/apiClient.services';
import {
  getColorsAnalyticLayer,
  saveColorsAnalyticLayer
} from '../../../../../../services/MapView/apiMapViewConfig.services';
import AbilityDownload from '../AbilityDownload';
import { PolygonLayer } from '../PolygonLayer';
import SideSurface from '../SideSurface';
import TopSurface from '../TopSurface';

export const CropCoverCardLayer = ({ labelCard }: any) => {
  const classes = useLayerSelectCardStyle();

  const {
    analyticId,
    isLayer3D,
    isShowAllLayer,
    isDisplayBoundaryLine,
    dataCropCoverage: data,
    sizeCropCoverageSelected,
    plantationCropCoverage,
    colorBorderPlantation,
  } = useAppSelector(mapViewSelector);

  const { clientId } = useParams();

  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isShow, setIsShow] = useState(true);
  const [isShowPlantation, setIsShowPlantation] = useState(false);
  const [plantationData, setPlantationData] = useState({} as any | undefined);

  useEffect(() => {
    if (!isShowAllLayer) {
      dispatch(changeVisibleCropCoverAllLayers(true));
    }
  }, []);

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const { data: colorsData, status } = useQuery(
    [QUERY_KEY.COLOR_ANALYTIC_LAYER, clientSetting?._id, analyticId],
    () => getColorsAnalyticLayer(clientSetting?._id || '', analyticId || ''),
    { enabled: !!clientSetting?._id && !!analyticId }
  );

  const { mutate } = useMutation((data: { analysisId: string; settingId: string; color: any }) =>
    saveColorsAnalyticLayer(data)
  );

  const { data: dataCropType, status: statusDataCropType } = useQuery(
    [QUERY_KEY.SIZE_CROP_COVER_ANALYTIC, analyticId, sizeCropCoverageSelected],
    () => getDataCropCoverAnalytic(analyticId || '', sizeCropCoverageSelected + ''),
    { enabled: !!analyticId && !!sizeCropCoverageSelected }
  );

  const { data: checkExistPlantation } = useQuery(
    ['CHECK_EXIST_PLANTATION'],
    () => getCheckExistPlantationCropCoverage(analyticId || ''),
    {
      enabled: !!analyticId,
    }
  );
  const isExistPlantation = useMemo(() => {
    if (!checkExistPlantation) return false;
    return checkExistPlantation.data.isExist;
  }, [checkExistPlantation]);

  const { mutate: getPlantation } = useMutation((_analyticId: string) => getPlantationCropCoverage(_analyticId), {
    onSuccess: (response: any) => {
      setPlantationData(response.data);
    },
  });

  useEffect(() => {
    if (isShowPlantation && isExistPlantation) {
      dispatch(changePlantationDataCropCoverage(plantationData));
    } else {
      dispatch(changePlantationDataCropCoverage(undefined));
    }
  }, [dispatch, isShowPlantation, plantationData]);

  const colorsDataAnalytics = useMemo(() => {
    if (status !== STATUS_API.SUCCESS) {
      return null;
    }
    return get(colorsData, 'data.data[0].color', DIFFERENT_COLOR);
  }, [colorsData, status]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const colorNews = data.map((_item: any) => _item.color);
    if (clientSetting?._id && analyticId && !isEmpty(colorNews)) {
      mutate({ analysisId: analyticId, settingId: clientSetting._id, color: colorNews });
    }
  }, [data, analyticId, clientSetting]);

  useEffect(() => {
    if (dataCropType?.data && status === STATUS_API.SUCCESS && statusDataCropType === STATUS_API.SUCCESS) {
      dispatch(
        setDataCropCoverage(
          dataCropType.data.map((_item: any, index: number) => ({
            ..._item,
            visible: true,
            color: get(colorsDataAnalytics, `[${index}]`) || get(DIFFERENT_COLOR, `[${index}]`, '#fff'),
          }))
        )
      );
    }
  }, [dataCropType, dispatch, status, colorsDataAnalytics]);

  const gridSizeList = useMemo((): any => {
    if (!queryClient.getQueryData([QUERY_KEY.CROP_COVER_ANALYTIC, analyticId])) {
      return [];
    }
    return (queryClient.getQueryData([QUERY_KEY.CROP_COVER_ANALYTIC, analyticId]) as any).data;
  }, [queryClient, analyticId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const filterDownloadData = useMemo(() => {
    const downloadData: any[] = [];
    data?.forEach((newData: any) => {
      if (newData.visible) {
        downloadData.push(newData);
      }
    });
    return downloadData;
  }, [data]);

  const callbackRefetchData = () => {
    // queryClient.invalidateQueries([QUERY_KEY.SIZE_CROP_COVER_ANALYTIC, analyticId, sizeCropCoverageSelected])
  }

  return (
    <>
      {data?.length > 0 && (
        <>
          <Button
            startIcon={isShow ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            onClick={() => setIsShow(!isShow)}
            classes={{ root: classes.buttonTextStyle }}>
            {labelCard}
            <Typography variant="caption" color="inherit" classes={{ root: classes.numberDataStyle }}>
              {data.length}
            </Typography>
          </Button>
          <Collapse in={isShow} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography className={classes.selectText} sx={{ ml: '-11px' }}>
                  Grid size
                </Typography>
              </Grid>
              <Select
                sx={{
                  border: 'none',
                  height: '37px',
                  margin: '8px 0 8px -11px',
                }}
                id="grid-size-select"
                value={sizeCropCoverageSelected}
                onChange={(event: any) => dispatch(changeSizeCropCoverageSelected(event.target.value))}>
                {gridSizeList.map((item: number) => (
                  <MenuItem key={item} value={item}>
                    {`${item}cm x ${item}cm`}
                  </MenuItem>
                ))}
              </Select>
              <Grid item xs={12} md={12}>
                {isExistPlantation && (
                  <FormControlLabel
                    control={
                      <SwitchCustom
                        // checked={!!plantationCropCoverage}
                        defaultValue={isShowPlantation}
                        onClick={(event: any) => {
                          if (event.target.checked) {
                            setIsShowPlantation(true);
                          } else {
                            setIsShowPlantation(false);
                          }
                          if (analyticId && event.target.checked) {
                            getPlantation(analyticId);
                          } else {
                            dispatch(changePlantationDataCropCoverage(undefined));
                          }
                        }}
                      />
                    }
                    label={
                      <Stack direction="row" spacing={1}>
                        {plantationCropCoverage && (
                          <Box
                            sx={{
                              width: '18px',
                              height: '14px',
                              border: `1px solid ${colorBorderPlantation}`,
                              borderRadius: '4px',
                              backgroundColor: colorBorderPlantation,
                            }}
                            onClick={(e: any) => handleClick(e)}
                          />
                        )}
                        <Typography className={classes.selectText}>Plantation field boundary</Typography>
                      </Stack>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isDisplayBoundaryLine}
                      onClick={() => dispatch(changeIsDisplayBoundary(!isDisplayBoundaryLine))}
                    />
                  }
                  label={<Typography className={classes.selectText}>2D Contour</Typography>}
                />
              </Grid>
              {!isDisplayBoundaryLine && (
                <Grid item xs={6} md={6}>
                  <FormControlLabel
                    control={<SwitchCustom checked={isLayer3D} onClick={() => dispatch(changeLayer3D(!isLayer3D))} />}
                    label={<Typography className={classes.selectText}>{isLayer3D ? '3D Fill' : '2D Fill'}</Typography>}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.color.grey3}`,
                  margin: '10px 0',
                }}></Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                  }}>
                  {filterDownloadData.length > 0 && (
                    <AbilityDownload dataPoints={filterDownloadData} type={TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE} />
                  )}
                </Box>
                <FormControlLabel
                  control={
                    <SwitchCustom
                      checked={isShowAllLayer}
                      onClick={() => dispatch(changeVisibleCropCoverAllLayers(!isShowAllLayer))}
                    />
                  }
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Typography className={classes.selectText}>
                        {isShowAllLayer ? 'Unselect all' : 'Select all'}
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
              <>
                {data.map((value: any, index: number) => {
                  return (
                    <PolygonLayer
                      key={index}
                      label={value.type}
                      color={value.color}
                      id={value._id}
                      visible={value.visible}
                      dataPoints={[value]}
                      changeColorAnalyticsLayer={changeColorAnalyticLayers}
                      changeVisibleAnalyticsLayer={changeVisibleCropCoverLayer}
                      typeOfAnalytic={TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE}
                      callback={callbackRefetchData}
                    />
                  );
                })}
              </>
            </Grid>
          </Collapse>
        </>
      )}
      {isLayer3D && <SideSurface />}
      <TopSurface />
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 130,
          horizontal: 60,
        }}>
        <Box>
          <HexColorPickerDebounce
            style={{
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}
            color={colorBorderPlantation}
            onChange={(_color: any) => dispatch(changeColorBorderPlantation(_color))}
          />
        </Box>
      </Popover>
    </>
  );
};
