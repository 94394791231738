import { ICropTypes } from 'common/defines/clients';
import { SortDateEnum } from 'components/MapView/RightBarOld/IssuesTab/interfaces';

export interface IWorkspace {
  _id: string;
  name: string;
  description: string;
  projectId: string;
}

export type IEditWorkspace = Pick<IWorkspace, 'name' | 'description' | '_id'>;

export interface IWorkspacePagination {
  keyword?: string;
  page: number;
  perPage: number;
  sortDate: SortDateEnum;
  paginationVersion: number;
  workspaceId: string;
}

export interface IRawFileUpload {
  id: string;
  project: string;
  options: { name: string; value: string }[];
}

export interface ITilingMapField {
  fieldName: string;
  'auto-boundary': boolean;
  dsm: boolean;
  dtm: boolean;
  tiles: boolean;
  '3d-tiles': true;
  cropType: ICropTypes;
  age: AgeEnum;
  type: CropIntelligenceEnum;
  sensorType: SensorTypeEnum;
}

export enum FieldOptionEnum {
  dsm = 'dsm',
  dtm = 'dtm',
  'auto-boundary' = 'auto-boundary',
  tiles = 'tiles',
  '3d-tiles' = '3d-tiles',
}

export enum OdmStatusCode {
  QUEUED = 10,
  RUNNING = 20,
  FAILED = 30,
  COMPLETED = 40,
  CANCELED = 50,
}

export interface IEventSourceData {
  processingTime: number;
  status: OdmStatusCode;
  runningProgress: number;
}

export interface IField {
  createdAt: Date;
  name: string;
  projectId: string;
  status: boolean;
  taskId: string;
  updatedAt: Date;
  _id: string;
  boundary: GeoJSON.FeatureCollection;
  maxX: string;
  maxY: string;
  zoom: number;
}

export interface ITilingMapOptions {
  dsm: boolean;
  dtm: boolean;
  orthophoto: boolean;
}

export enum Map2dOptionEnum {
  dsm = 'dsm',
  dtm = 'dtm',
  orthophoto = 'orthophoto',
}

export interface IMap3dOptions {
  model3d: boolean;
  pointCloud: boolean;
}

export enum Map3dOptionEnum {
  MODEL_3D = 'model',
  POINT_CLOUD = 'pointcloud',
}

export interface IOption {
  key: number;
  label: string;
  disabled: boolean;
  value: boolean;
}

export enum AgeEnum {
  IMMATURE = 'IMMATURE',
  MATURE = 'MATURE',
}

export enum CropIntelligenceEnum {
  RAW_IMAGE = 'RAW_IMAGE',
  RECONSTRUCTED_IMAGE = 'RECONSTRUCTED_IMAGE',
}

export enum SensorTypeEnum {
  RGB = 'RGB',
  RGB_MS = 'RGB_MS',
}
