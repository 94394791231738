import { Box, FormControlLabel, Grid, Popover, Stack, Typography } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useAppDispatch } from 'store/hooks';

interface IPolygonLayer {
  id: string;
  index: number;
  label: string;
  visible: boolean;
  color: string;
  typeOfAnalytic: string;
  changeColorAnalyticsLayer: (id: string, color: string, typeOfAnalytic: string) => void;
  changeVisibleAnalyticsLayer: ActionCreatorWithPayload<string, string>;
  dataPoints?: any[] | null;
}

export const PolygonLayerPaddy = ({
  changeColorAnalyticsLayer,
  changeVisibleAnalyticsLayer,
  typeOfAnalytic,
  id,
  label,
  visible,
  color,
  index,
}: IPolygonLayer) => {
  const classes = useLayerSelectCardStyle();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12} md={12}>
      <FormControlLabel
        control={<SwitchCustom checked={visible} onClick={() => dispatch(changeVisibleAnalyticsLayer(id))} />}
        label={
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {color && visible && (
              <Box
                sx={{
                  width: '14px',
                  height: '14px',
                  border: `1px solid ${color}`,
                  borderRadius: '100%',
                  backgroundColor: color,
                }}
                onClick={(e: any) => handleClick(e)}
              />
            )}
            <Typography className={classes.selectText}>{label}</Typography>
          </Stack>
        }
      />
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 160,
          horizontal: 30,
        }}>
        <Box>
          <HexColorPickerDebounce
            color={color}
            onChange={(_color: any) => changeColorAnalyticsLayer(id, _color, typeOfAnalytic)}
          />
        </Box>
      </Popover>
    </Grid>
  );
};
