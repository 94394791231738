import { Box, FormControlLabel, Typography } from '@mui/material';
import { BigNumber } from 'bignumber.js';
import { MeasurementUnit, SettingUnitName } from 'common/defines/clients';
import { defaultClientUnitSetting } from 'common/dummy/dummyClients';
import PieChartCommon from 'components/Common/PieChartCommon';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useCallback, useMemo, useState } from 'react';
import { LandUseSubChart } from './LandUseSubChart';

export const LandUsageChart = ({ mainData, mainColor, clientSetting, enableSlopeArea }: any) => {
  const [isLdUse, setIsLdUse] = useState<boolean>(true);
  const areaSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.AREA) || defaultClientUnitSetting[0]
    );
  }, [clientSetting]);

  const lengthSetting = useMemo(() => {
    return (
      clientSetting?.unitSetting?.find((data: any) => data.name === SettingUnitName.LENGTH) ||
      defaultClientUnitSetting[1]
    );
  }, [clientSetting]);

  const areaUnit = useMemo(() => {
    const unitArea = areaSetting.unit;
    if (unitArea === MeasurementUnit.M2) {
      return 'm²';
    }
    if (unitArea === MeasurementUnit.KM2) {
      return 'km²';
    }
    return unitArea;
  }, [areaSetting]);

  const clientAreaUnitMeasurement = useCallback(
    (area: any) => {
      switch (areaSetting.unit) {
        case MeasurementUnit.ACRE:
          return new BigNumber(area * 2.47105).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.KM2:
          return new BigNumber(area * 0.01).toFixed(areaSetting.decimalPlace);
        case MeasurementUnit.M2:
          return new BigNumber(area * 10000).toFixed(areaSetting.decimalPlace);
        default:
          return new BigNumber(area).toFixed(areaSetting.decimalPlace);
      }
    },
    [areaSetting]
  );

  const clientLengthUnitMeasurement = useCallback(
    (length: any) => {
      switch (lengthSetting.unit) {
        case MeasurementUnit.KM:
          return new BigNumber(length * 0.001).toFixed(lengthSetting.decimalPlace);
        case MeasurementUnit.MILES:
          return new BigNumber(length * 0.000621371).toFixed(lengthSetting.decimalPlace);
        default:
          return length ? new BigNumber(length).toFixed(lengthSetting.decimalPlace) : null;
      }
    },
    [lengthSetting]
  );

  const dataPie = useMemo(() => {
    if (!mainData) return [];
    const dataMapping: Array<{ name: string; value: any; subData: any }> = [];
    mainData.forEach((val: any) => {
      const _name = val.category ? `${val.landUse} : ${val.category}` : val.landUse;
      const index = dataMapping.findIndex((_i: any) => _i.name === _name);
      // If have museArea, use it, else show landArea
      const nonSlopeData = val.museArea || val.area;
      // Switch data between nonSlopeData and slopeArea
      const data = isLdUse ? nonSlopeData : val.slopeArea;
      if (index > -1) {
        dataMapping[index] = {
          name: _name,
          value: new BigNumber(Number(dataMapping[index].value) + Number(clientAreaUnitMeasurement(data))).toFixed(
            areaSetting.decimalPlace
          ),
          subData: new BigNumber(Number(dataMapping[index].subData) + Number(val.length)),
        };
        return;
      }
      dataMapping.push({
        name: _name,
        value: clientAreaUnitMeasurement(data),
        subData: val.length,
      });
    });
    return dataMapping;
  }, [areaSetting, clientAreaUnitMeasurement, mainData, isLdUse]);

  const formatText = useCallback(
    (name: any) => {
      const dataValue = dataPie.find((val: any) => val.name === name);
      if (!dataValue) {
        return '';
      }
      let subInfo = '';
      if (+dataValue.subData === 0) {
        subInfo = '';
      } else {
        subInfo = ` (${clientLengthUnitMeasurement(dataValue.subData)} ${lengthSetting.unit})`;
      }
      return `${name}  ${dataValue?.value} ${areaUnit}${subInfo}`;
    },

    [areaUnit, clientLengthUnitMeasurement, dataPie, lengthSetting.unit]
  );

  return (
    <>
      {dataPie.length > 0 && (
        <>
          {enableSlopeArea && (
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '15px 25px 0 25px' }}>
              <FormControlLabel
                control={<SwitchCustom checked={isLdUse} onClick={() => setIsLdUse(!isLdUse)} />}
                label={
                  <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>
                    {isLdUse ? 'Landuse Categories' : 'Slope Area'}
                  </Typography>
                }
              />
            </Box>
          )}
          <PieChartCommon
            title={enableSlopeArea ? '' : 'Landuse Categories'}
            dataChart={dataPie}
            formatText={formatText}
            colorCharts={mainColor}
          />
        </>
      )}
      {areaSetting && (
        <LandUseSubChart treeData={mainData} areaSetting={clientAreaUnitMeasurement} areaUnit={areaSetting.unit} />
      )}
    </>
  );
};
