import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getDataCircumfernenceAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsCircumference } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import {
  changeChartDataCircumferencePreview,
  changeDataCircumferenceAnalytics,
  changeDataCircumferenceInterval,
  changeDataPolygonCircumferenceAnalytics,
  circumferenceAnalyticsSelector,
} from '../../../../../../store/slices/map-view/circumferenceAnalytics';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { CircumferenceCardLayer } from '../../Layer/Circumference';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const CircumferenceAnalytics = ({ uploadDate }: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataCircumferenceInterval, dataCircumferencePolygon, chartDataCircumferencePreview } =
    useAppSelector(circumferenceAnalyticsSelector);

  const { data: dataCircumferenceBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.CIRCUMFERENCES_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'circumference'),
    { enabled: !!analyticId }
  );

  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.CIRCUMFERENCE_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'circumference', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        if (data.data?.docs) {
          dispatch(changeDataPolygonCircumferenceAnalytics([...dataCircumferencePolygon, ...data.data?.docs]));
        }
      },
    }
  );

  useQuery(
    [QUERY_KEY.CIRCUMFERENCE_ANALYSIS, analyticId],
    () => {
      return getDataCircumfernenceAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData) => {
        dispatch(changeDataCircumferenceAnalytics(mainData?.data || []));
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_CIRCUMFERENCE_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_CIRCUMFERENCE_ANALYTIC, isDefaultInterval],
    () => getChartsCircumference(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataCircumferenceInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataCircumferenceInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataCircumferenceBoxPlot?.data) return [];
    return dataCircumferenceBoxPlot?.data;
  }, [dataCircumferenceBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataCircumferencePreview
                      ? chartDataCircumferencePreview?.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS}
                  getCharts={getChartsCircumference}
                  intervalRange={
                    chartDataCircumferencePreview
                      ? chartDataCircumferencePreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_CIRCUMFERENCE_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataCircumferencePreview}
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<CircumferenceCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
