import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { ANALYTICS_TABS, POLYGON_FILTER_CHECK_BOX_STATE, MAP_ANGLES } from 'constants/constants';
import { IPolygonFilterCheckBoxState } from 'common/defines/clients';

interface IdashboardWidgetSlice {
  is_3d: boolean;
  analyticActiveTab: string;
  tonnageDistribution: any[];
  weighbridgeByProduct: any[];
  logisticTripStatus: any[];
  productionCost: any[];
  productionExpenditure: any[];
  mapViewAngle: number
  polygonFilter: IPolygonFilterCheckBoxState
}

const initialState: IdashboardWidgetSlice = {
  is_3d: false,
  analyticActiveTab: ANALYTICS_TABS.PRODUCTION,
  tonnageDistribution: [],
  weighbridgeByProduct: [],
  logisticTripStatus: [],
  productionCost: [],
  productionExpenditure: [],
  mapViewAngle: MAP_ANGLES.DEGREE_0,
  polygonFilter: POLYGON_FILTER_CHECK_BOX_STATE
};

export const dashboardWidget = createSlice({
  name: 'dashboardWidget',
  initialState,
  reducers: {
    setTonnageDistribution: (state, action: PayloadAction<any>) => {
      state.tonnageDistribution = action.payload;
    },
    setWeighbridgeByProduct: (state, action: PayloadAction<any>) => {
      state.weighbridgeByProduct = action.payload;
    },
    setLogisticTripStatus: (state, action: PayloadAction<any>) => {
      state.logisticTripStatus = action.payload;
    },
    setProductionCost: (state, action: PayloadAction<any>) => {
      state.productionCost = action.payload;
    },
    setProductionExpenditure: (state, action: PayloadAction<any>) => {
      state.productionExpenditure = action.payload;
    },
    changeIs3D: (state, action: PayloadAction<any>) => {
      state.is_3d = action.payload;
      state.mapViewAngle = action.payload ? MAP_ANGLES.DEGREE_90 : MAP_ANGLES.DEGREE_0
    },
    setAnalyticActiveTab: (state, action: PayloadAction<string>) => {
      state.analyticActiveTab = action.payload;
    },
    changeMapViewAngle: (state, action: PayloadAction<number>) => {
      state.mapViewAngle = action.payload
    },
    setPolygonFilter: (state, action: PayloadAction<IPolygonFilterCheckBoxState>) => {
      state.polygonFilter = action.payload;
    }
  },
});

export const {
  setTonnageDistribution,
  changeIs3D,
  setWeighbridgeByProduct,
  setLogisticTripStatus,
  setProductionCost,
  setProductionExpenditure,
  setAnalyticActiveTab,
  changeMapViewAngle,
  setPolygonFilter
} = dashboardWidget.actions;

export const dashboardWidgetSelector = (state: RootState) => state.dashboardWidget;

export default dashboardWidget.reducer;
