import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Popover, Typography } from '@mui/material';
import { DEFAULT_BORDER_COLOR } from 'common/defines/clients';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import useQueryClientSetting from 'hooks/common/useQueryClientSetting';
import { ChangeEvent, FC, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { queryMe } from 'services/clients/apiClient.services';
import { saveColorsAnalyticLayer } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeTreeTiltFallenVisible, rightBarSelector } from 'store/slices/rightBarSlice';

interface TreeTiltFallenCollapseProps {
  isShowVector: boolean;
}

const TreeTiltFallenCollapse: FC<TreeTiltFallenCollapseProps> = ({ isShowVector }) => {
  const [isShowTreeTiltFallen, setIsShowTreeTiltFallen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedColor, setSelectedColor] = useState<string>('');

  const dispatch = useAppDispatch();
  const classes = useLayerSelectCardStyle();
  const queryClient = useQueryClient();
  const { clientSetting, colorAnalysisLayer } = useQueryClientSetting();
  const {
    treeTiltFallen: { visible, sensorId },
  } = useAppSelector(rightBarSelector);
  const { data: userInfo } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());
  const colorFromClientSetting = colorAnalysisLayer?.find(
    (item) => item.analysisId === sensorId && item.userId === userInfo?.data?._id
  )?.color;

  const updateColorMutation = useMutation(saveColorsAnalyticLayer, {
    onSuccess() {
      toast.success('Update color successful');
      handlePopoverClose();
      queryClient.invalidateQueries([QUERY_KEY.CLIENT_SETTINGS_BY_ID]);
    },
  });

  const handleChangeColor = () => {
    // with vector related, replace analytic by sensorId
    updateColorMutation.mutate({ analysisId: sensorId, settingId: clientSetting?._id!, color: selectedColor });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const renderLabelComp = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box
          sx={{
            height: '14px',
            width: '14px',
            display: 'inline-block',
            borderRadius: '50%',
            background: colorFromClientSetting || DEFAULT_BORDER_COLOR,
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }}></Box>
        <Typography sx={{ display: 'inline-block', ml: '6px' }}>Colour</Typography>
      </Box>
    );
  };

  return (
    <>
      <Collapse in={isShowVector} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
        <Button
          startIcon={
            isShowTreeTiltFallen ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : (
              <ChevronRightIcon style={{ fontWeight: 'bold' }} />
            )
          }
          onClick={() => setIsShowTreeTiltFallen(!isShowTreeTiltFallen)}
          classes={{ root: classes.buttonTextStyle }}
          style={{ width: '100%' }}>
          <Typography
            className={classes.buttonTextStyle}
            style={{ marginRight: 'auto', fontWeight: '500', fontSize: '13px !important' }}>
            Tree Tilt/Fallen
          </Typography>
        </Button>
        <Collapse in={isShowTreeTiltFallen} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
          {sensorId && (
            <FormControlLabel
              control={
                <SwitchCustom
                  onChange={(e: ChangeEvent<any>) => {
                    dispatch(changeTreeTiltFallenVisible(e.target.checked));
                  }}
                  checked={visible}
                />
              }
              label={renderLabelComp()}
            />
          )}
        </Collapse>
      </Collapse>
      <Popover
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          '.MuiPopover-paper': {
            overflowX: 'initial',
            overflowY: 'initial',
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 160,
          horizontal: 30,
        }}>
        <Box>
          <HexColorPickerDebounce
            style={{
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}
            color={selectedColor}
            onChange={(color: string) => {
              setSelectedColor(color);
            }}
          />
          <Box sx={{ my: '8px', textAlign: 'center' }}>
            <Button color="inherit" variant="outlined" size="small" onClick={handlePopoverClose}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" size="small" sx={{ ml: '12px' }} onClick={handleChangeColor}>
              Save
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default TreeTiltFallenCollapse;
