import { Box, Typography } from '@mui/material';

export const TabPanel = (props: any) => {
  const { children, value, index, isAnalyticsTab, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: isAnalyticsTab ? 0 : 3, pt: isAnalyticsTab ? 0 : 1 }}>{children}</Box>}
    </Typography>
  );
};
