import { Box, Button, Grid, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createNewIssue, updateIssue } from 'services/MapView/apiMapViewConfig.services';
import { uploadMedia } from 'services/media';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import {
  changeDrawIssueShape,
  changeDropMarkerInfo,
  resetEditIssueData,
  rightBarSelector,
} from 'store/slices/rightBarSlice';
import { DrawIssueShapeEnum, IGeoTaggedPhoto, IShapeInfo, LineStyleEnum, MediaTypeEnum } from '../../../interfaces';
import EditShapeAndLocationButton from './EditShapeAndLocationButton';
import SelectShapeStyle from './SelectShapeStyle';
import SettingDisplayPolygon from './SettingDisplayPolygon';
import ShowMeasurementInfo from './ShowMeasurementInfo';
import UploadFile from './UploadFile';
import UploadImage from './UploadImage';

interface CreateAndEditIssueModalProps {
  isShow: boolean;
  handleClose: () => void;
  setIsShowDropMarkerModal: (arg: boolean) => void;
}

const optionList = [
  { key: 1, value: DrawIssueShapeEnum.IMAGE },
  { key: 2, value: DrawIssueShapeEnum.LINE },
  // { key: 3, value: DrawIssueShapeEnum.SQUARE },
  { key: 4, value: DrawIssueShapeEnum.POLYGON },
  // { key: 5, value: DrawIssueShapeEnum.CIRCLE },
  { key: 6, value: DrawIssueShapeEnum.POINT },
];

const initialPolygon3d = {
  colorSideSurface: 'rgb(255, 255, 255)',
  isSameColorTopSurface: false,
  sideSurfaceOpacity: 1,
  topSurfaceOpacity: 1,
  isSameSideSurface: false,
};

const CreateAndEditIssueModal: FC<CreateAndEditIssueModalProps> = ({
  isShow,
  handleClose,
  setIsShowDropMarkerModal,
}) => {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;
  const [pickedColor, setPickedColor] = useState<string>(mainColor);
  const [geoTaggedPhotoList, setGeoTaggedPhotoList] = useState<IGeoTaggedPhoto[]>([]);
  const [documentLink, setDocumentLink] = useState<string>('');
  const [nonGeoPhotoLink, setNonGeoPhotoLink] = useState<string>('');
  const [shapeInfo, setShapeInfo] = useState<IShapeInfo>({
    area: 0,
    length: 0,
  });

  const mapViewStylesClasses = useMapViewStyle();
  const { levelId } = useAppSelector(mapViewSelector);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { issuesTab } = useAppSelector(rightBarSelector);
  const { polygon3dList } = issuesTab;

  const initialData = issuesTab.editIssueData;

  const foundPolygon3d = polygon3dList?.find((item) => item.id === initialData._id);

  const { sideSurfaceOpacity, colorSideSurface, isSameColorTopSurface, isSameSideSurface, topSurfaceOpacity } =
    foundPolygon3d || initialPolygon3d;

  const formik = useFormik({
    initialValues: {
      type: DrawIssueShapeEnum.IMAGE,
      description: '',
      width: 2,
      style: LineStyleEnum.SOLID,
    },
    onSubmit: (values) => {
      const date = moment().utc().format();
      if (values.type === DrawIssueShapeEnum.IMAGE) {
        const imageList = geoTaggedPhotoList.map((item) => item.path);
        if (checkAllImageHaveTheSameGeometry()) {
          const request = {
            ...values,
            date,
            levelId: levelId!,
            images: imageList,
            geometry: geoTaggedPhotoList[0].geometry,
            document: documentLink,
            status: true,
            border: {
              color: pickedColor,
              width: 2,
              style: '',
            },
            isNonGeotag: !!nonGeoPhotoLink,
            colorSideSurface,
            isSameColorTopSurface,
            sideSurfaceOpacity,
            topSurfaceOpacity,
            isSameSideSurface,
          };
          if (initialData._id) {
            updateIssueMutation.mutate({
              ...request,
              _id: initialData._id,
              status: initialData.status,
              isNonGeotag: initialData.isNonGeotag,
              isDisplayBoundaryLine: false,
              isLayer3D: false,
            });
          } else {
            createNewIssueMutation.mutate(request);
          }
        } else {
          toast.error("All the images don't have the same location", { position: 'top-center' });
        }
      } else {
        const initialIdFromDrawShape = issuesTab.drawIssueShape.initialData?.id;
        const geometry =
          initialIdFromDrawShape || !initialData._id ? issuesTab.drawIssueShape.geometry : initialData.geometry;
        if (!geometry?.coordinates.length) {
          dispatch(changeDrawIssueShape({ isShow: true, type: formik.values.type }));
        } else {
          const request = {
            ...values,
            date,
            levelId: levelId!,
            images: [],
            geometry: geometry,
            document: documentLink,
            status: true,
            area: shapeInfo.area,
            length: shapeInfo.length,
            border: {
              width: values.width,
              style: values.style,
              color: pickedColor,
            },
            isNonGeotag: false,
            sideSurfaceOpacity,
            colorSideSurface,
            isSameColorTopSurface,
            isSameSideSurface,
            topSurfaceOpacity,
          };
          if (initialData._id) {
            const editedRequest = {
              ...request,
              _id: initialData._id,
              status: initialData.status,
            };
            const selectedPolygon = polygon3dList.find((item) => item.id === initialData._id);
            // if edited shape is a polygon
            if (selectedPolygon) {
              updateIssueMutation.mutate({
                ...editedRequest,
                isDisplayBoundaryLine: selectedPolygon.isDisplayBoundaryLine,
                isLayer3D: selectedPolygon.isLayer3D,
              });
            } else {
              updateIssueMutation.mutate({
                ...editedRequest,
                isDisplayBoundaryLine: true,
                isLayer3D: false,
                colorSideSurface: 'rgb(255, 255, 255)',
                isSameColorTopSurface: false,
                sideSurfaceOpacity: 1,
                topSurfaceOpacity: 1,
                isSameSideSurface: false,
              });
            }
          } else {
            createNewIssueMutation.mutate(request);
          }
          handleResetData();
        }
        handleClose();
      }
    },
  });

  // initial values for form in edit mode
  useEffect(() => {
    if (isShow && initialData._id) {
      const initialGeoTaggedPhotoList = initialData.images.map((item: string) => ({
        geometry: initialData.geometry,
        path: item,
      }));
      setGeoTaggedPhotoList(initialGeoTaggedPhotoList);
      setDocumentLink(initialData.document);
      setShapeInfo({
        area: Number(initialData.area),
        length: Number(initialData.length),
      });
      formik.setValues({
        type: initialData.type,
        description: initialData.description,
        width: initialData.border?.width || 2,
        style: (initialData.border?.style as LineStyleEnum) || LineStyleEnum.SOLID,
      });
      setPickedColor(initialData.border?.color || mainColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, isShow]);

  // reset values when change to shape different from image
  useEffect(() => {
    if (formik.values.type !== DrawIssueShapeEnum.IMAGE) {
      setGeoTaggedPhotoList([]);
    }
  }, [formik.values.type]);

  // add photo after select drop marker
  useEffect(() => {
    const {
      dropMarker: { coordinates, initialData },
    } = issuesTab;
    if (nonGeoPhotoLink && coordinates?.length) {
      const addedPhoto = {
        geometry: {
          type: 'Point',
          coordinates: coordinates,
        },
        path: nonGeoPhotoLink,
      };
      const newGeoTaggedPhotoList = [...geoTaggedPhotoList, addedPhoto];
      setGeoTaggedPhotoList(newGeoTaggedPhotoList);
      dispatch(changeDropMarkerInfo({ coordinates: [] }));
    }
    if (initialData?.id && coordinates?.length) {
      const newGeoTaggedPhotoList = geoTaggedPhotoList.map((item) => ({
        ...item,
        geometry: { ...item.geometry, coordinates },
      }));
      setGeoTaggedPhotoList(newGeoTaggedPhotoList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, issuesTab, nonGeoPhotoLink]);

  const categoryUploadMedia = (formData: FormData, mediaType: MediaTypeEnum) => {
    uploadMediaMutation.mutate(formData, {
      onSuccess: (res) => {
        if (mediaType === MediaTypeEnum.DOCUMENT) {
          setDocumentLink(res.data.path);
        } else {
          setNonGeoPhotoLink(res.data.path);
          if (geoTaggedPhotoList.length > 0) {
            const addedPhoto = {
              geometry: {
                type: 'Point',
                coordinates: geoTaggedPhotoList[0].geometry.coordinates,
              },
              path: res.data.path,
            };
            const newGeoTaggedPhotoList = [...geoTaggedPhotoList, addedPhoto];
            setGeoTaggedPhotoList(newGeoTaggedPhotoList);
          }
        }
      },
    });
  };

  const uploadMediaMutation = useMutation(uploadMedia);

  const createNewIssueMutation = useMutation(createNewIssue, {
    onSuccess: () => {
      toast.success('Create new issue successful', { position: 'top-center' });
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_ISSUE_PAGINATION]);
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_DISPLAYED_ISSUES]);
      handleClose();
      handleResetData();
    },
  });

  const updateIssueMutation = useMutation(updateIssue, {
    onSuccess: () => {
      toast.success('Update issue successful', { position: 'top-center' });
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_ISSUE_PAGINATION]);
      queryClient.invalidateQueries([QUERY_KEY.GET_LIST_DISPLAYED_ISSUES]);
      handleClose();
      handleResetData();
    },
  });

  const handleResetData = () => {
    setGeoTaggedPhotoList([]);
    setDocumentLink('');
    setShapeInfo({ area: 0, length: 0 });
    formik.resetForm();
    dispatch(resetEditIssueData());
    dispatch(
      changeDropMarkerInfo({
        isShow: false,
        coordinates: [],
        initialData: {
          id: '',
          coordinates: [],
        },
      })
    );
    dispatch(
      changeDrawIssueShape({
        isShow: false,
        type: null,
        geometry: { coordinates: [], type: '' },
        initialData: { id: '' },
        isLockMap: false,
      })
    );
    setNonGeoPhotoLink('');
    setPickedColor(mainColor);
  };

  const checkAllImageHaveTheSameGeometry = () => {
    const geoList = geoTaggedPhotoList.map((item) => item.geometry.coordinates);
    const isAllTheSameGeometry = geoList.every((item) => isEqual(item, geoList[0]));
    return isAllTheSameGeometry;
  };

  const isDisabledSubmit = () => {
    if (formik.values.type === DrawIssueShapeEnum.IMAGE && !geoTaggedPhotoList.length) return true;
    return false;
  };

  return (
    <CommonModal
      isShow={isShow}
      handleClose={() => {
        handleClose();
        handleResetData();
      }}
      style={{ p: 4, width: '600px' }}>
      <Box>
        <Box sx={{ mb: '16px' }}>
          <Typography className={mapViewStylesClasses.titleInput}>
            {initialData._id ? 'Edit Issue' : 'New Issue'}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: '500' }}>Date: </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography sx={{ display: 'inline-block' }}>
                {initialData._id ? moment(initialData.updatedAt).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              <EditShapeAndLocationButton formik={formik} initialData={initialData} handleClose={handleClose} />
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: '500' }}>Type: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Select
                fullWidth
                size="small"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}>
                {optionList.map((item, i) => (
                  <MenuItem key={i} value={item.value} disabled={!!initialData._id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <UploadImage
              formik={formik}
              geoTaggedPhotoList={geoTaggedPhotoList}
              setGeoTaggedPhotoList={setGeoTaggedPhotoList}
              setIsShowDropMarkerModal={setIsShowDropMarkerModal}
              handleClose={handleClose}
              categoryUploadMedia={categoryUploadMedia}
            />
            <SelectShapeStyle formik={formik} pickedColor={pickedColor} setPickedColor={setPickedColor} />
            <SettingDisplayPolygon formik={formik} initialData={initialData} />
            <UploadFile
              categoryUploadMedia={categoryUploadMedia}
              documentLink={documentLink}
              setDocumentLink={setDocumentLink}
            />
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: '500' }}>Description: </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                size="small"
                id="description"
                name="description"
                rows={4}
                multiline
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </Grid>
            {![DrawIssueShapeEnum.IMAGE, DrawIssueShapeEnum.POINT].includes(formik.values.type) && (
              <>
                <Grid item xs={3}>
                  <Typography sx={{ fontWeight: '500' }}>Measurement: </Typography>
                </Grid>
                <Grid item xs={9}>
                  <ShowMeasurementInfo setShapeInfo={setShapeInfo} shapeInfo={shapeInfo} />
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ textAlign: 'right', mt: '12px' }}>
            <Button size="small" color="primary" variant="contained" type="submit" disabled={isDisabledSubmit()}>
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </CommonModal>
  );
};

export default CreateAndEditIssueModal;
