import { Box, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import useQueryFieldDetail from 'hooks/workspace/useQueryFieldDetail';
import { IRawFileUpload, ITilingMapField } from 'interfaces/workspace';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import FillFieldData, { initialValues } from './FillFieldData';
import ProgressingData from './ProgressingData';

interface AddFieldModalProps {
  isShow: boolean;
  handleClose: () => void;
}

const AddFieldModal: FC<AddFieldModalProps> = ({ isShow, handleClose }) => {
  const [rawFileUpload, setRawFileUpload] = useState<IRawFileUpload>();

  const queryClient = useQueryClient();
  const form = useForm<ITilingMapField>();
  const mapViewStylesClasses = useMapViewStyle();

  const { fieldDetail, setFieldDetail } = useQueryFieldDetail();

  const onCloseModal = () => {
    form.reset(initialValues);
    setRawFileUpload(undefined);
    setFieldDetail(undefined);
    queryClient.invalidateQueries([QUERY_KEY.GET_LIST_FIELD]);
    handleClose();
  };

  return (
    <CommonModal isShow={isShow} handleClose={onCloseModal}>
      <Box sx={{ width: '600px', p: '32px' }} className="add_field_modal">
        <Typography className={mapViewStylesClasses.titleInput} sx={{ mb: '24px', fontSize: '18px' }}>
          Create New Field
        </Typography>
        {rawFileUpload || fieldDetail ? (
          <ProgressingData rawFileUpload={rawFileUpload} fieldDetail={fieldDetail} handleClose={handleClose} />
        ) : (
          <FillFieldData form={form} setRawFileUpload={setRawFileUpload} />
        )}
      </Box>
    </CommonModal>
  );
};

export default AddFieldModal;
