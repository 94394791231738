import { Grid, InputLabel, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FILE_TYPE, LABEL_DATA_RASTERS, LayerTypeEnum, mapFileLabel } from 'common/defines/constants';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import SelectFile from 'components/Common/SelectFile';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { FC, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createSurfaceExposedWaterAnalytic,
  deleteRasterAnalysisData,
  getRasterDetails,
} from 'services/analytics/apiAnalyticsConfig.services';
import { ErrorsDialog } from './ErrorDialog';

interface SurfaceExposedWaterFormProps {
  sensorId: string;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const SurfaceExposedWaterForm: FC<SurfaceExposedWaterFormProps> = ({ sensorId, deleteFileGeojson }) => {
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery(
    [QUERY_KEY.RASTER_DETAILS, sensorId],
    () => getRasterDetails(sensorId),
    {
      enabled: !!sensorId,
    }
  );

  const createAnalyticMutation = useMutation(createSurfaceExposedWaterAnalytic, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      toast.success(`Update ${LABEL_DATA_RASTERS[LayerTypeEnum.SURFACE_EXPOSED_WATER_FIELD]} Analytic Successful`, {
        toastId: 1,
      });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      setDataErrors(e?.data?.message);
    },
  });

  const initialFields = useMemo(() => {
    let initParam: any = { geoJson: undefined };
    if (analyticDetails?.data?.uploadHistory) {
      analyticDetails.data.uploadHistory.forEach((_item: any) => {
        if (_item?.layerType) {
          initParam[_item.layerType] = _item;
        } else {
          initParam.geoJson = _item;
        }
      });
    }
    return initParam;
  }, [analyticDetails]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          formData.append('dataFile', values.geoJson || '');
          formData.append('geoJsonFile', values.GEOJSON || '');
          createAnalyticMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
            },
          });
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm }) => (
          <form onSubmit={handleSubmit}>
            <InputLabel sx={{ fontWeight: '600', mt: '24px' }}>Geojson</InputLabel>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {mapFileLabel[FILE_TYPE.SURFACE_EXPOSED_WATER_GEOJSON]}
                  </InputLabel>
                  <SelectFile
                    acceptFile="(.geojson|.json)"
                    fileSelected={values.geoJson}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file: any) => {
                      setFieldValue('geoJson', file);
                    }}
                    analysisId={sensorId}
                    fileType={FILE_TYPE.SURFACE_EXPOSED_WATER_GEOJSON}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft="auto">
                <ButtonCustom
                  title="Save"
                  border="0px"
                  colorLabel="#fff"
                  sx={{
                    width: '160px',
                    height: '1.4375em',
                  }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
            {/* Raster area  */}
            <InputLabel sx={{ fontWeight: '600', mt: '64px' }}>Raster</InputLabel>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {LABEL_DATA_RASTERS[LayerTypeEnum.SURFACE_EXPOSED_WATER_FIELD]}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.zip$/)}
                    fileSelected={values[LayerTypeEnum.SURFACE_EXPOSED_WATER_FIELD]}
                    onSelectFile={(file) => setFieldValue(LayerTypeEnum.SURFACE_EXPOSED_WATER_FIELD, file)}
                    analysisId={sensorId}
                    layerType={LayerTypeEnum.SURFACE_EXPOSED_WATER_FIELD}
                    deleteFileGeojson={deleteRasterAnalysisData}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'} sx={{ minWidth: '160px' }}></Grid>
            </Grid>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {LABEL_DATA_RASTERS[LayerTypeEnum.SURFACE_EXPOSED_WATER_POLYGON]}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.zip$/)}
                    fileSelected={values[LayerTypeEnum.SURFACE_EXPOSED_WATER_POLYGON]}
                    onSelectFile={(file) => setFieldValue(LayerTypeEnum.SURFACE_EXPOSED_WATER_POLYGON, file)}
                    analysisId={sensorId}
                    layerType={LayerTypeEnum.SURFACE_EXPOSED_WATER_POLYGON}
                    deleteFileGeojson={deleteRasterAnalysisData}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'} sx={{ minWidth: '160px' }}></Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

export default SurfaceExposedWaterForm;
