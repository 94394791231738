import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import { IClientData } from 'common/defines/clients';
import { useMapViewStyle } from 'components/MapView/MapViewStyle';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientByIdNew } from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { setClientData } from 'store/slices/clientSlice';

const ClientInfo = () => {
  const classes = useMapViewStyle();
  const { clientId } = useParams();
  const dispatch = useAppDispatch();

  const { data: _newClientData, isLoading: isNewClientDataLoading } = useQuery<IClientData>(
    [QUERY_KEY.CLIENT_DATA_NEW, clientId],
    () => getClientByIdNew(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  useEffect(() => {
    if (_newClientData) {
      dispatch(setClientData(_newClientData));
    }
  }, [_newClientData, dispatch]);

  const newAdditionalInformation = useMemo(() => {
    if (!_newClientData?.additionalInformation)
      return (
        <Typography
          variant="body2"
          sx={{
            color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
            fontSize: '14px',
            lineHeight: '21px',
          }}>
          No additional information
        </Typography>
      );
    return _newClientData?.additionalInformation?.map((item: any) => {
      switch (item.type) {
        case 'SELECT':
          return isNewClientDataLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                display: 'flex',
                minHeight: '80px',
                width: '100%',
              }}
            />
          ) : (
            <div
              key={item.key}
              style={{
                display: 'flex',
                gap: '12px',
                alignItems: 'start',
                minHeight: '24px',
                width: '100%',
                margin: '4px 0',
              }}>
              <p
                style={{
                  margin: '0',
                  flex: '1',
                }}>
                {item.name}:
              </p>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  flex: '2',
                  gap: '12px',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}>
                {item.value.map((value: any) => {
                  return (
                    <p
                      style={{
                        margin: '0',
                        flex: '2',
                      }}
                      key={value}>
                      {!value ? 'None' : value}
                    </p>
                  );
                })}
              </Box>
            </div>
          );
        case 'TEXT':
          return isNewClientDataLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                display: 'flex',
                minHeight: '80px',
                width: '100%',
              }}
            />
          ) : (
            <div
              key={item.key}
              style={{
                display: 'flex',
                gap: '12px',
                alignItems: 'start',
                minHeight: '24px',
                width: '100%',
                margin: '4px 0',
              }}>
              <p
                style={{
                  margin: '0',
                  flex: '1',
                }}>
                {item.name}:
              </p>
              {item.value.map((value: any) => (
                <p
                  style={{
                    margin: '0',
                    flex: '2',
                  }}
                  key={value}>
                  {value === '' ? 'None' : value}
                </p>
              ))}
            </div>
          );
        case 'DATE':
          return isNewClientDataLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                display: 'flex',
                minHeight: '80px',
                width: '100%',
              }}
            />
          ) : (
            <div
              key={item.key}
              style={{
                display: 'flex',
                gap: '12px',
                alignItems: 'start',
                minHeight: '24px',
                width: '100%',
                margin: '4px 0',
              }}>
              <p
                style={{
                  margin: '0',
                  flex: '1',
                }}>
                {item.name}:
              </p>
              {item.value.map((value: any) => (
                <p
                  style={{
                    margin: '0',
                    flex: '2',
                  }}
                  key={value}>
                  {value === '' ? 'None' : value}
                </p>
              ))}
            </div>
          );
        default:
          return isNewClientDataLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                display: 'flex',
                minHeight: '80px',
                width: '100%',
              }}
            />
          ) : (
            <div
              key={item.key}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                minHeight: '24px',
                width: '100%',
                margin: '4px 0',
              }}>
              <div style={{ display: 'flex', flexDirection: 'row', maxWidth: 'calc(100% - 100px)' }}>
                {item?.value?.map((value: any) => {
                  if (value === '' || !value) {
                    return (
                      <p
                        style={{
                          margin: '0',
                          flex: '2',
                        }}
                        key={value}>
                        None
                      </p>
                    );
                  }
                  return (
                    <Box
                      sx={{ pr: 2, display: 'flex', alignItems: 'start', width: '100%', flexDirection: 'column' }}
                      key={value}>
                      <Box>
                        <InsertDriveFileIcon fontSize="large" color="info" />
                        <a
                          href={value}
                          target="_blank"
                          rel="noreferrer"
                          // onClick={() => downloadResource(value, value.split('/').at(-1))}
                          style={{
                            alignItems: 'center',
                            fontWeight: 500,
                            cursor: 'pointer',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textDecorationLine: 'none',
                            color: '#10B981',
                          }}>
                          Master map ({item.name})
                        </a>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: '12px', color: '#9E9E9E' }}>
                          {moment(item?.media?.createdAt).format('MMM Do, YYYY')},{' '}
                          {item?.media?.fileSize / 1000 > 1000
                            ? `${(item?.media?.fileSize / 1000 / 1000).toFixed(2)} MB`
                            : `${(!item.media?.fileSize ? 0 : item.media?.fileSize / 1000).toFixed(2)} KB`}
                        </Typography>
                        {/* <Typography
                          sx={{
                            color: 'black',
                          }}>
                          Uploaded by:{' '}
                          {item.media?.userUpload.length === 0 ? (
                            <span>Unknown</span>
                          ) : (
                            <span>{item.media?.userUpload[0].name}</span>
                          )}
                        </Typography> */}
                      </Box>
                    </Box>
                  );
                })}
              </div>
            </div>
          );
      }
    });
  }, [_newClientData, isNewClientDataLoading]);

  return (
    <Box>
      <Box sx={{ py: 2 }}>
        <Typography className={classes.titleProperties}>Company Name</Typography>
        <Typography>{_newClientData?.name}</Typography>
      </Box>
      <Box sx={{ py: 2 }}>
        <Avatar src={_newClientData?.avatar} sx={{ width: 72, height: 72 }} />
      </Box>
      <Box sx={{ py: 2 }}>
        <Typography className={classes.titleProperties}>Business Registration</Typography>
        <Typography>{_newClientData?.ssm}</Typography>
      </Box>
      <Box sx={{ py: 2 }}>
        <Typography className={classes.titleProperties}>Address</Typography>
        <Typography>{_newClientData?.contactAddress}</Typography>
      </Box>

      <Box sx={{ py: 2 }}>
        <Typography className={classes.titleProperties}>Email</Typography>
        <Typography>{_newClientData?.email}</Typography>
      </Box>

      <Box sx={{ py: 2 }}>
        <Typography className={classes.titleProperties}>Contact Number</Typography>
        <Typography>{_newClientData?.contactNumber}</Typography>
      </Box>

      <Box sx={{ py: 2 }}>
        <Typography className={classes.titleProperties}>Additional Information</Typography>
        {newAdditionalInformation}
      </Box>
    </Box>
  );
};

export default ClientInfo;

// function forceDownload(blob: any, filename: any) {
//   var a = document.createElement('a');
//   a.download = filename;
//   a.href = blob;
//   document.body.appendChild(a);
//   a.click();
//   a.remove();
// }

// Current blob size limit is around 500MB for browsers
// function downloadResource(url: any, filename: any) {
//   if (!filename) filename = url.split('\\').pop().split('/').pop();
//   fetch(url, {
//     headers: new Headers({
//       Origin: 'location.origin',
//     }),
//     mode: 'cors',
//   })
//     .then((response) => response.blob())
//     .then((blob) => {
//       let blobUrl = window.URL.createObjectURL(blob);
//       forceDownload(blobUrl, filename);
//     })
//     .catch((e) => console.error(e));
// }
