import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Map2dOptionEnum, Map3dOptionEnum } from 'interfaces/workspace';
import { RootState } from 'store/store';

export interface IDrawingBoundary {
  geometry: {
    type: any;
    coordinates: any[];
  };
  isShow: boolean;
  _id: string;
  isLoading: boolean;
}

interface ITilingMap {
  selectedFieldId: string;
  rightBar: {
    options2d: { name: Map2dOptionEnum; value: boolean }[];
    options3d: { name: Map3dOptionEnum; value: boolean }[];
    selectedTabHistory: number[];
  };
  drawingBoundary: IDrawingBoundary;
}

const initialState: ITilingMap = {
  selectedFieldId: '',
  rightBar: {
    options2d: [
      { name: Map2dOptionEnum.dsm, value: false },
      { name: Map2dOptionEnum.dtm, value: false },
      { name: Map2dOptionEnum.orthophoto, value: true },
    ],
    options3d: [
      { name: Map3dOptionEnum.MODEL_3D, value: false },
      { name: Map3dOptionEnum.POINT_CLOUD, value: false },
    ],
    selectedTabHistory: [0],
  },
  drawingBoundary: {
    geometry: {
      type: 'Polygon',
      coordinates: [],
    },
    isShow: false,
    _id: '',
    isLoading: false,
  },
};

export const tilingMapSlice = createSlice({
  name: 'tilingMap',
  initialState,
  reducers: {
    changeSelectedFieldId: (state, action: PayloadAction<string>) => {
      state.selectedFieldId = action.payload;
    },
    changeRightBar2dOptions: (state, action: PayloadAction<{ name: Map2dOptionEnum; value: boolean }[]>) => {
      state.rightBar.options2d = action.payload;
    },
    changeRightBar3dOptions: (state, action: PayloadAction<{ name: Map3dOptionEnum; value: boolean }[]>) => {
      state.rightBar.options3d = action.payload;
    },
    changeSelectedTabHistory: (state, action: PayloadAction<{ id: number }>) => {
      const lastTabIndex = state.rightBar.selectedTabHistory.slice(-1)[0];
      if (lastTabIndex !== action.payload.id) {
        state.rightBar.selectedTabHistory = [...state.rightBar.selectedTabHistory, action.payload.id];
      }
    },
    changeDrawingBoundary: (state, action: PayloadAction<Partial<IDrawingBoundary>>) => {
      state.drawingBoundary = { ...state.drawingBoundary, ...action.payload };
    },
    clearAllTilingMapData: () => initialState,
  },
});

export const {
  changeSelectedFieldId,
  changeRightBar2dOptions,
  changeRightBar3dOptions,
  changeSelectedTabHistory,
  changeDrawingBoundary,
  clearAllTilingMapData,
} = tilingMapSlice.actions;

export const tilingMapSelector = (state: RootState) => state.tilingMap;

export default tilingMapSlice.reducer;
