import { Visibility, VisibilityOff } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { IChangeUserPassword } from 'common/defines/clients';
import { ChangeEvent, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changeUserPassword, logoutUser } from 'services/clients/apiClient.services';
import { clearUserPermissionsAndUserType } from 'store/slices/superAdminSlice';

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const sessionId = localStorage.getItem('sessionLogin');
  const dispatch = useDispatch();

  const theme = useTheme();

  const styles = {
    changePasswordContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    titleContainer: {
      p: '18px 0',
      borderBottom: `1px solid ${theme.palette.divider}`,
      mb: '20px',
    },
    titleItem: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
      // color: '#333',
    },
    subTitleItem: {
      mt: '0.1875rem',
      fontSize: '.875rem',
      lineHeight: '1.0625rem',
      // color: '#555',
    },
    changePasswordFormBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 0',
    },
    formInputBox: {
      mb: '20px',
      width: '500px',
      mr: '20px',
    },
    formIconInputStart: {
      height: '24px',
      width: '24px',
    },
    formIconInputStop: {
      marginRight: '-10px',
    },
    forgotPasswordBox: {
      fontSize: '0.875rem',
      mt: '11px',
      textDecoration: 'none',
      color: '#C4C4C4',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        textDecoration: 'underline',
      },
    },

    validationBox: {
      padding: '12px 0',
    },
    iconValidation: {
      width: '20px',
      height: '20px',
      marginRight: '8px',
    },
    validationError: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px',
      color: theme.palette.mode === 'dark' ? '#ddd' : '#555',
    },

    // Common
    displayFlex: {
      display: 'flex',
    },
  };

  // Check validation new password
  const [checkLengthPassword, setCheckLengthPassword] = useState(false);
  const [checkUpperCase, setCheckUpperCase] = useState(false);
  const [checkNumber, setCheckNumber] = useState(false);
  const [checkSpecialChar, setCheckSpecialChar] = useState(false);

  const { control, handleSubmit, reset, setValue } = useForm<IChangeUserPassword>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  // Call API PATCH
  const useChangePassword = () => {
    return useMutation(
      (param: IChangeUserPassword) =>
        changeUserPassword({
          ...param,
        }),
      {
        onSuccess: () => {
          toast.success('Change password successfully', { toastId: 1 });
          reset();
          setCheckLengthPassword(false);
          setCheckUpperCase(false);
          setCheckNumber(false);
          setCheckSpecialChar(false);
          const theme = localStorage.getItem('theme');
          localStorage.clear();
          localStorage.setItem('theme', theme || 'light');
          navigate('/login');
          mutationLogoutUser.mutate();
        },
        onError: (err: any) => {
          toast.error(err.data.message, { toastId: 1 });
        },
      }
    );
  };

  const mutationChangePassword = useChangePassword();

  const handleSetNewPassword = (e: ChangeEvent<HTMLInputElement>, fieldName: keyof IChangeUserPassword) => {
    const value = e.target.value;
    setValue(fieldName, value);
    if (value.length >= 8) {
      setCheckLengthPassword(true);
    } else {
      setCheckLengthPassword(false);
    }
    if (value.match(/[A-Z]/)) {
      setCheckUpperCase(true);
    } else {
      setCheckUpperCase(false);
    }
    if (value.match(/[0-9]/)) {
      setCheckNumber(true);
    } else {
      setCheckNumber(false);
    }
    if (value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
      setCheckSpecialChar(true);
    } else {
      setCheckSpecialChar(false);
    }
  };

  const handleShowOldPassword = (): void => {
    setShowOldPassword(!showOldPassword);
  };

  const handleShowNewPassword = (): void => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = (): void => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmitForm: SubmitHandler<IChangeUserPassword> = (data) => {
    mutationChangePassword.mutate({ ...data });
  };

  const handleChangeField = (event: any, fieldName: keyof IChangeUserPassword) => {
    setValue(fieldName, event.target.value);
  };

  // Call Logout API to reset password
  const useLogoutUser = () => {
    return useMutation(() => logoutUser(sessionId || ''), {
      onSuccess: () => {
        toast.success('Please enter your email!', { toastId: 1 });
        dispatch(clearUserPermissionsAndUserType())
      },
      onError: (err) => {
        console.log('err', err);
      },
    });
  };

  const mutationLogoutUser = useLogoutUser();

  const handleForgotPass = () => {
    if (!sessionId) {
      navigate('/login');
      return;
    }
    const theme = localStorage.getItem('theme');
    localStorage.clear();
    localStorage.setItem('theme', theme || 'light');
    navigate('/forgot-password');
    mutationLogoutUser.mutate();
  };

  return (
    <Box
      sx={{
        ...styles.changePasswordContainer,
      }}>
      {/* Title */}
      <Box
        sx={{
          ...styles.titleContainer,
        }}>
        <Typography
          sx={{
            ...styles.titleItem,
          }}>
          Change Password
        </Typography>
        <Typography
          sx={{
            ...styles.subTitleItem,
          }}>
          For your account's security, do not share your password with anyone else.
        </Typography>
      </Box>
      {/* Form change password */}
      <Box
        component="form"
        onSubmit={handleSubmit(handleSubmitForm)}
        sx={{
          ...styles.changePasswordFormBox,
        }}>
        {/* Current Password */}
        <Box
          sx={{
            ...styles.displayFlex,
          }}>
          <Controller
            name="oldPassword"
            control={control}
            render={({ field }) => (
              <Box
                sx={{
                  ...styles.formInputBox,
                }}>
                <TextField
                  required
                  type={showOldPassword ? 'text' : 'password'}
                  placeholder="Current Password"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleShowOldPassword}
                          sx={{
                            ...styles.formIconInputStop,
                          }}>
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  onChange={(event) => handleChangeField(event, 'oldPassword')}
                />
              </Box>
            )}
          />
          <Typography
            component="a"
            rel="noreferrer"
            sx={{
              ...styles.forgotPasswordBox,
            }}
            onClick={handleForgotPass}>
            Forgot your password?
          </Typography>
        </Box>
        {/* New Password */}
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <Box
              sx={{
                ...styles.formInputBox,
              }}
              key="newPassword">
              <TextField
                required
                type={showNewPassword ? 'text' : 'password'}
                placeholder="New Password"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleShowNewPassword}
                        sx={{
                          ...styles.formIconInputStop,
                        }}>
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetNewPassword(e, 'newPassword')}
              />
            </Box>
          )}
        />
        {/* Confirm New Password */}
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <Box
              sx={{
                ...styles.formInputBox,
              }}>
              <TextField
                required
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm New Password"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        sx={{
                          ...styles.formIconInputStop,
                        }}>
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                onChange={(event) => handleChangeField(event, 'confirmPassword')}
              />
            </Box>
          )}
        />
        <Box
          sx={{
            ...styles.validationBox,
          }}>
          {checkLengthPassword ? (
            <Typography
              sx={{
                ...styles.validationError,
                color: (theme) => theme.palette.primary.main,
              }}>
              <FiberManualRecordIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>
                Password must be{' '}
                <strong
                  style={{
                    color: '#1c9d57',
                  }}>
                  at least 8 characters
                </strong>{' '}
                long.
              </Typography>
            </Typography>
          ) : (
            <Typography
              sx={{
                ...styles.validationError,
              }}>
              <FiberManualRecordOutlinedIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>
                Password must be{' '}
                <strong
                  style={{
                    color: theme.palette.mode === 'dark' ? '#F1F1F2' : '#000',
                  }}>
                  at least 8 characters
                </strong>{' '}
                long.
              </Typography>
            </Typography>
          )}
          {checkUpperCase ? (
            <Typography
              sx={{
                ...styles.validationError,
                color: (theme) => theme.palette.primary.main,
              }}>
              <FiberManualRecordIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>Password must contain at least one uppercase character (A-Z).</Typography>
            </Typography>
          ) : (
            <Typography
              sx={{
                ...styles.validationError,
              }}>
              <FiberManualRecordOutlinedIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>Password must contain at least one uppercase character (A-Z).</Typography>
            </Typography>
          )}
          {checkNumber ? (
            <Typography
              sx={{
                ...styles.validationError,
                color: (theme) => theme.palette.primary.main,
              }}>
              <FiberManualRecordIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>Password must contain at least one digit (0-9).</Typography>
            </Typography>
          ) : (
            <Typography
              sx={{
                ...styles.validationError,
              }}>
              <FiberManualRecordOutlinedIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>Password must contain at least one digit (0-9).</Typography>
            </Typography>
          )}
          {checkSpecialChar ? (
            <Typography
              sx={{
                ...styles.validationError,
                color: (theme) => theme.palette.primary.main,
              }}>
              <FiberManualRecordIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>Password must contain at least one special character (e.g. !@#$%^&*).</Typography>
            </Typography>
          ) : (
            <Typography
              sx={{
                ...styles.validationError,
              }}>
              <FiberManualRecordOutlinedIcon
                sx={{
                  ...styles.iconValidation,
                }}
              />
              <Typography>Password must contain at least one special character (e.g. !@#$%^&*).</Typography>
            </Typography>
          )}
        </Box>
        <Button
          type="submit"
          sx={{
            width: '152px',
            height: '40px',
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: (theme) => theme.palette.primary.main,
            color: 'white',
            textTransform: 'none',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              backgroundColor: '#1C9D57',
              border: `1px solid ${theme.palette.primary.main}`,
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
              backgroundColor: '#1C9D57',
              border: `1px solid ${theme.palette.primary.main}`,
            },
            '&:focus': {
              boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
              border: `1px solid ${theme.palette.primary.main}`,
            },
            '&:disabled': {
              backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1C1C1C' : '#E0E0E0'),
              border: `1px solid ${theme.palette.divider}`,
            },
          }}
          disabled={!checkLengthPassword || !checkUpperCase || !checkNumber || !checkSpecialChar}>
          <Typography
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '26px',
            }}>
            Save
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePassword;
