import { SettingUIAnalysis, SettingUIIconFavorite, SettingUIName, SettingUISizePointAnalysis } from './clients';

export const VALIDATION_REQUIRED = {
  required: 'Please fill in this field!',
  companyName: 'Please provide the company name',
  email: 'Please provide an email',
  inValidEmail: 'Email is invalid',
  ssm: 'Please provide a valid number',
  address: 'Please provide the current address',
  contactNumber: 'Please provide a contact number',
  avatar: 'Please provide an avatar',
  cropType: 'Please select a type',
  fullName: 'Please provide full name',
  propertyName: 'Please provide property name ',
  propertyType: 'Please select a property type',
  valueOptions: 'Please provide at least one value option',
  inputLength: 'Please provide input length',
};

export const DIFFERENT_COLOR = [
  '#ff0505',
  '#05FF12',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#e055c4',
  '#F3E5AB',
];

export enum TypeRaster {
  RGB = 'RGB',
  RGB_MULTI_SPECTRAL = 'RGB Multispectral',
}

export enum TYPE_ANALYTICS_MAP_VIEW {
  LAND_USE_ANALYSIS = 'Land Use',
  CROP_COVERAGE = 'Crop Coverage',
  STAND_COUNT = 'Stand Count',
  WATER_LOGGING = 'Water Logging',
  CROWN_AREA = 'Crown Area',
  CIRCUMFERENCE_ANALYSIS = 'Circumference',
  BOUNDING_BOX = 'Bounding Box',
  WEED_INVASION = 'Weed Invasion',
  VACANT_AREA = 'Vacant Area',
  TILLER_DENSITY = 'Tiller Density',
  SOIL_SATURATION_FIELD = 'Soil Saturation Field',
  SOIL_EROSION_FIELD = 'Soil Erosion Field',
  LEAF_PHENOLOGY_FIELD = 'Leaf Phenology Field',
  SLOPE_FIELD = 'Slope Field',
  SURFACE_EXPOSED_WATER = 'Surface Exposed Water',
  TENSION_CRACK = 'Tension Crack',
  STRESS_TREE = 'Stress Tree',
  STRESS_FIELD = 'Stress Field',
  WATER_UPTAKE_TREE = 'Water Uptake Tree',
  WATER_UPTAKE_FIELD = 'Water Uptake Field',
  PLANT_HEALTH_TREE = 'Plant Health Tree',
  PLANT_HEALTH_FIELD = 'Plant Health Field',
  VIGOR_TREE = 'Vigor Tree',
  VIGOR_FIELD = 'Vigor Field',
  CHLOROPHYLL_TREE = 'Chlorophyll Tree',
  CHLOROPHYLL_FIELD = 'Chlorophyll Field',
}

export enum FILE_TYPE {
  CHLOROPHYLL_FILE = 'CHLOROPHYLL_FILE',
  CHLOROPHYLL_PADDY_GEOJSON = 'CHLOROPHYLL_PADDY_GEOJSON',
  CIRCUMFERENCE_DATA_FILE = 'CIRCUMFERENCE_DATA_FILE',
  CROP_NONCROP_GEOJSON = 'CROP_NONCROP_GEOJSON',
  CROWN_AREA_FILE = 'CROWN_AREA_FILE',
  INTERVALS_FILE = 'INTERVALS_FILE',
  LINE_STRING_FILE = 'LINE_STRING_FILE',
  POLYGON_FILE = 'POLYGON_FILE',
  POINT_FILE = 'POINT_FILE',
  PLANTATION_BOUNDARY_GEOJSON = 'PLANTATION_BOUNDARY_GEOJSON',
  PLANT_HEALTH_GEOJSON = 'PLANT_HEALTH_GEOJSON',
  STAND_COUNT_FILE = 'STAND_COUNT_FILE',
  STRESS_FILE = 'STRESS_FILE',
  TILLER_DENSITY_GEOJSON = 'TILLER_DENSITY_GEOJSON',
  VACANT_AREA_GEOJSON = 'VACANT_AREA_GEOJSON',
  VIGOR_FILE = 'VIGOR_FILE',
  VIGOR_PADDY_GEOJSON = 'VIGOR_PADDY_GEOJSON',
  WATER_UPTAKE_FILE = 'WATER_UPTAKE_FILE',
  WEED_INVASION_GEOJSON = 'WEED_INVASION_GEOJSON',
  PLAN_HEALTH_OILPALM_FILE = 'PLAN_HEALTH_OILPALM_FILE',
  STRESS_FIELD_GEOJSON = 'STRESS_FIELD_GEOJSON',
  WATER_UPTAKE_FIELD_GEOJSON = 'WATER_UPTAKE_FIELD_GEOJSON',
  SOIL_SATURATION_FIELD_GEOJSON = 'SOIL_SATURATION_FIELD_GEOJSON',
  SOIL_EROSION_FIELD_GEOJSON = 'SOIL_EROSION_FIELD_GEOJSON',
  LEAF_PHENOLOGY_FIELD_GEOJSON = 'LEAF_PHENOLOGY_FIELD_GEOJSON',
  SLOPE_FIELD_GEOJSON = 'SLOPE_FIELD_GEOJSON',
  SURFACE_EXPOSED_WATER_GEOJSON = 'SURFACE_EXPOSED_WATER_GEOJSON',
  TENSION_CRACK_GEOJSON = 'TENSION_CRACK_GEOJSON',
  CONTOUR = 'CONTOUR',
}

export const mapFileLabel = {
  CHLOROPHYLL_FILE: 'Chlorophyll file', //chlorophyll-analysis
  CHLOROPHYLL_PADDY_GEOJSON: 'Chlorophyll field geojson', //chlorophyll-paddy-analysis
  CIRCUMFERENCE_DATA_FILE: 'Circumference data file', //circumference-analysis
  CROP_NONCROP_GEOJSON: 'Crop/ Noncrop geojson', //crop-cover
  CROWN_AREA_FILE: 'Crown Area file', //crown-area-analysis
  INTERVALS_FILE: 'Intervals file', //interval-limit-analysis
  LINE_STRING_FILE: 'Line String file', //land-use-analysis
  POLYGON_FILE: 'Polygon file', //land-use-analysis
  POINT_FILE: 'Point file', //land-use-analysis
  PLANTATION_BOUNDARY_GEOJSON: 'Plantation boundary geojson', //crop-cover/downloadPlantationData
  PLANT_HEALTH_GEOJSON: 'Plant Health geojson', //plant-health-analysis
  STAND_COUNT_FILE: 'Point file', //stand-count-analysis
  STRESS_FILE: 'Stress file', //stress-analysis
  TILLER_DENSITY_GEOJSON: 'Tiller Density geojson', //tiller-density-analysis
  VACANT_AREA_GEOJSON: 'Vacant Area geojson', //vacant-area-analysis
  VIGOR_FILE: 'Vigor file', //vigor-analysis
  VIGOR_PADDY_GEOJSON: 'Vigor Field geojson', //vigor-paddy-analysis
  WATER_UPTAKE_FILE: 'Water Uptake file', //water-uptake-analysis
  WEED_INVASION_GEOJSON: 'Weed Invasion geojson', //weed-invasion-analysis
  PLAN_HEALTH_OILPALM_FILE: 'Plant Health file', //plant-health-oilpalm-analysis
  STRESS_FIELD_GEOJSON: 'Stress Field geojson',
  WATER_UPTAKE_FIELD_GEOJSON: 'Water Uptake Field',
  SOIL_SATURATION_FIELD_GEOJSON: 'Soil Saturation Field',
  SOIL_EROSION_FIELD_GEOJSON: 'Soil Erosion Field',
  LEAF_PHENOLOGY_FIELD_GEOJSON: 'Leaf Phenology Field',
  SLOPE_FIELD_GEOJSON: 'Slope Field',
  SURFACE_EXPOSED_WATER_GEOJSON: 'Surface Exposed Water',
  TENSION_CRACK_GEOJSON: 'Tension Crack',
  CONTOUR: 'Contour',
};

export const mapFileToApi = {
  CHLOROPHYLL_FILE: 'chlorophyll-analysis',
  CHLOROPHYLL_PADDY_GEOJSON: 'chlorophyll-paddy-analysis',
  CIRCUMFERENCE_DATA_FILE: 'circumference-analysis',
  CROP_NONCROP_GEOJSON: 'crop-cover',
  CROWN_AREA_FILE: 'crown-area-analysis',
  INTERVALS_FILE: 'interval-limit-analysis',
  LINE_STRING_FILE: 'land-use-analysis',
  POLYGON_FILE: 'land-use-analysis',
  POINT_FILE: 'land-use-analysis',
  PLANTATION_BOUNDARY_GEOJSON: 'crop-cover',
  PLANT_HEALTH_GEOJSON: 'plant-health-analysis',
  STAND_COUNT_FILE: 'stand-count-analysis',
  STRESS_FILE: 'stress-analysis',
  TILLER_DENSITY_GEOJSON: 'tiller-density-analysis',
  VACANT_AREA_GEOJSON: 'vacant-area-analysis',
  VIGOR_FILE: 'vigor-analysis',
  VIGOR_PADDY_GEOJSON: 'vigor-paddy-analysis',
  WATER_UPTAKE_FILE: 'water-uptake-analysis',
  WEED_INVASION_GEOJSON: 'weed-invasion-analysis',
  PLAN_HEALTH_OILPALM_FILE: 'plant-health-oilpalm-analysis',
  STRESS_FIELD_GEOJSON: 'stress-field-analysis',
  WATER_UPTAKE_FIELD_GEOJSON: 'water-uptake-field-analysis',
  SOIL_SATURATION_FIELD_GEOJSON: 'soil-saturation-field-analysis',
  SOIL_EROSION_FIELD_GEOJSON: 'soil-erosion',
  SLOPE_FIELD_GEOJSON: 'slope-field-analysis',
  LEAF_PHENOLOGY_FIELD_GEOJSON: 'leaf-phenology-field-analysis',
  SURFACE_EXPOSED_WATER_GEOJSON: 'surface-exposed-water',
  TENSION_CRACK_GEOJSON: 'tension-crack',
  CONTOUR: 'contour',
};

export const dataTypeRaster = [
  { value: 0, label: TypeRaster.RGB },
  { value: 1, label: TypeRaster.RGB_MULTI_SPECTRAL },
];

export enum LayerTypeEnum {
  RGB_ORTHOIMAGE = 'RGB_ORTHOIMAGE',
  VIGOR_FIELD = 'VIGOR_FIELD',
  VIGOR_TREE = 'VIGOR_TREE',
  STRESS_FIELD = 'STRESS_FIELD',
  STRESS_TREE = 'STRESS_TREE',
  CHLOROPHYLL_FIELD = 'CHLOROPHYLL_FIELD',
  CHLOROPHYLL_TREE = 'CHLOROPHYLL_TREE',
  WATER_UPTAKE_FIELD = 'WATER_UPTAKE_FIELD',
  WATER_UPTAKE_TREE = 'WATER_UPTAKE_TREE',
  PLANT_HEALTH_FIELD = 'PLANT_HEALTH_FIELD',
  PLANT_HEALTH_TREE = 'PLANT_HEALTH_TREE',
  SURFACE_EXPOSED_WATER_FIELD = 'SURFACE_EXPOSED_WATER_FIELD',
  SURFACE_EXPOSED_WATER_POLYGON = 'SURFACE_EXPOSED_WATER_POLYGON',
  VECTOR_CONTOUR = 'VECTOR_CONTOUR',
  STREAM_LINE_ORDER = 'STREAM_LINE_ORDER',
  TREE_TILT_FALLEN = 'TREE_TILT_FALLEN',
  SOIL_SATURATION_FIELD = 'SOIL_SATURATION_FIELD',
  SOIL_EROSION_FIELD = 'SOIL_EROSION_FIELD',
  SLOPE_FIELD = 'SLOPE_FIELD',
  LEAF_PHENOLOGY_FIELD = 'LEAF_PHENOLOGY_FIELD',
  TENSION_CRACK = 'TENSION_CRACK',
}

export const DATA_RASTERS = {
  [TypeRaster.RGB]: [LayerTypeEnum.RGB_ORTHOIMAGE],
  [TypeRaster.RGB_MULTI_SPECTRAL]: [LayerTypeEnum.RGB_ORTHOIMAGE],
};

export const LABEL_DATA_RASTERS = {
  [LayerTypeEnum.RGB_ORTHOIMAGE]: 'Orthoimage',
  [LayerTypeEnum.VIGOR_FIELD]: 'Vigor field',
  [LayerTypeEnum.VIGOR_TREE]: 'Vigor tree',
  [LayerTypeEnum.STRESS_FIELD]: 'Stress field',
  [LayerTypeEnum.STRESS_TREE]: 'Stress tree',
  [LayerTypeEnum.CHLOROPHYLL_FIELD]: 'Chlorophyll field',
  [LayerTypeEnum.CHLOROPHYLL_TREE]: 'Chlorophyll tree',
  [LayerTypeEnum.WATER_UPTAKE_FIELD]: 'Water uptake field',
  [LayerTypeEnum.WATER_UPTAKE_TREE]: 'Water uptake tree',
  [LayerTypeEnum.PLANT_HEALTH_FIELD]: 'Plant health field',
  [LayerTypeEnum.PLANT_HEALTH_TREE]: 'Plant health tree',
  [LayerTypeEnum.SOIL_SATURATION_FIELD]: 'Soil saturation field',
  [LayerTypeEnum.SOIL_EROSION_FIELD]: 'Soil erosion field',
  [LayerTypeEnum.LEAF_PHENOLOGY_FIELD]: 'Leaf phenology field',
  [LayerTypeEnum.SLOPE_FIELD]: 'Slope field',
  [LayerTypeEnum.SURFACE_EXPOSED_WATER_FIELD]: 'Surface exposed water field',
  [LayerTypeEnum.SURFACE_EXPOSED_WATER_POLYGON]: 'Surface exposed water polygon',
  [LayerTypeEnum.TENSION_CRACK]: 'Tension crack',
  [LayerTypeEnum.VECTOR_CONTOUR]: 'Contour',
  [LayerTypeEnum.STREAM_LINE_ORDER]: 'Streamline order',
  [LayerTypeEnum.TREE_TILT_FALLEN]: 'Tree tilt/fallen',
};

export const DEFAULT_SURFACE_SETTINGS = {
  COLOR: '#fff',
  OPACITY: 1,
  IS_SAME_SIDE_SURFACE_OPACITY: false,
  OPACITY_2D: 1,
};

export const uiSettingDescriptionMapping: Record<string, string> = {
  [SettingUIName.STROKE_WIDTH]: 'Width',
  [SettingUIName.CONTOUR_3D_HEIGHT]: 'Height',
  [SettingUIName.CYLINDAR_3D_RADIUS]: 'Radius',
};

export const uiIconSettingFavorite: Record<string, string> = {
  [SettingUIIconFavorite.FAVORITE_ICON]: 'Icon',
  [SettingUIIconFavorite.FAVORITE_COLOR]: 'Color',
};

export const uiViewAnalysisProperties: Record<string, string> = {
  [SettingUIAnalysis.SHOW_HIDE_SLOPE_ANALYSIS]: 'Show/Hide Slope Analysis',
};

export const uiSizePointConfig: Record<string, string> = {
  [SettingUISizePointAnalysis.SIZE_CONFIG]: 'Fixed Size',
};

export enum MousePositionEnum {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom',
  BOTTOM_RIGHT = 'bottom-right',
}

export const analyticsDisplayedList = [
  TYPE_ANALYTICS_MAP_VIEW.CROWN_AREA,
  TYPE_ANALYTICS_MAP_VIEW.CIRCUMFERENCE_ANALYSIS,
  TYPE_ANALYTICS_MAP_VIEW.STRESS_TREE,
  TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_TREE,
  TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE,
  TYPE_ANALYTICS_MAP_VIEW.VIGOR_TREE,
];

export enum ComparedIndex {
  FIRST = 1,
  SECOND = 2,
}

export enum MapStateKey {
  MAP_VIEW = 'MAP_VIEW',
  MAP_POPUP = 'MAP_POPUP',
}

export const FILE_TYPE_GEOJSON_INTERVAL_FILE = [
  FILE_TYPE.CHLOROPHYLL_FILE,
  FILE_TYPE.STRESS_FILE,
  FILE_TYPE.VIGOR_FILE,
  FILE_TYPE.WATER_UPTAKE_FILE,
  FILE_TYPE.PLAN_HEALTH_OILPALM_FILE,
  FILE_TYPE.CIRCUMFERENCE_DATA_FILE,
  FILE_TYPE.CROWN_AREA_FILE,
];

export const LIST_FILE_RASTER = [
  LayerTypeEnum.RGB_ORTHOIMAGE,
  LayerTypeEnum.VIGOR_FIELD,
  LayerTypeEnum.VIGOR_TREE,
  LayerTypeEnum.STRESS_FIELD,
  LayerTypeEnum.STRESS_TREE,
  LayerTypeEnum.CHLOROPHYLL_FIELD,
  LayerTypeEnum.CHLOROPHYLL_TREE,
  LayerTypeEnum.WATER_UPTAKE_FIELD,
  LayerTypeEnum.WATER_UPTAKE_TREE,
  LayerTypeEnum.PLANT_HEALTH_FIELD,
  LayerTypeEnum.PLANT_HEALTH_TREE,
  LayerTypeEnum.SOIL_SATURATION_FIELD,
  LayerTypeEnum.SOIL_EROSION_FIELD,
  LayerTypeEnum.LEAF_PHENOLOGY_FIELD,
  LayerTypeEnum.SLOPE_FIELD,
  LayerTypeEnum.VECTOR_CONTOUR,
  LayerTypeEnum.STREAM_LINE_ORDER,
  LayerTypeEnum.TREE_TILT_FALLEN,
];
