import { Box, Button, ButtonProps } from '@mui/material';
import ExcelIcon from 'assets/icons/IconComponent/ExcelIcon';
import { FC } from 'react';
import LoopIconAnimation from './LoopIconAnimation';

interface ExcelButtonProps extends ButtonProps {
  isLoading?: boolean;
}

const ExcelButton: FC<ExcelButtonProps> = (props) => {
  const { isLoading } = props;
  return (
    <Button variant="contained" {...props} sx={{ height: '36px' }}>
      {isLoading ? <LoopIconAnimation /> : <ExcelIcon height="20px" width="20px" />}
      <Box component="span" sx={{ ml: '8px' }}>
        Download
      </Box>
    </Button>
  );
};

export default ExcelButton;
