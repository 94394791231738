import { DIFFERENT_COLOR, TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getStandCountAnalytic, getStandCountChartAnalytic } from 'services/analytics/apiAnalyticsConfig.services';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { getAnalyticLayer } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeStatusLoading, standCountAnalyticSelector } from 'store/slices/map-view/standCountAnalytics';
import { changeVisibleStandCountAllLayers, mapViewSelector, setDataStandCount } from 'store/slices/mapViewSlice';
import { useStandCountAnalytics } from '../../../../MapViewStyle';
import { StandCountCardLayer } from '../../Layer/StandCount';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import InfoTable from './InfoTable';

const StandCountAnalytics = () => {
  const classes = useStandCountAnalytics();
  const dispatch = useAppDispatch();
  const { analyticId, dataStandCount } = useAppSelector(mapViewSelector);
  const { isShowPopupAdd } = useAppSelector(standCountAnalyticSelector);

  const { clientId } = useParams();

  const { data: clientSetting } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const { data: dataStandCountAnalysis, isLoading } = useQuery(
    [QUERY_KEY.STAND_COUNT_ANALYSIS, analyticId],
    () => getStandCountAnalytic(analyticId || ''),
    { enabled: !!analyticId }
  );

  const { data: dataStandCountChart } = useQuery(
    [QUERY_KEY.STAND_COUNT_CHART_ANALYSIS, analyticId],
    () => getStandCountChartAnalytic(analyticId || ''),
    { enabled: !!analyticId }
  );

  const {
    data: colorsData,
    isLoading: isLoadingColors,
    status,
  } = useQuery(
    [QUERY_KEY.COLOR_ANALYTIC_LAYER, clientId, analyticId],
    () => getAnalyticLayer(clientId || '', analyticId || ''),
    { enabled: !!clientId && !!analyticId }
  );

  useEffect(() => {
    dispatch(changeStatusLoading(isLoading || isLoadingColors));
  }, [isLoading, isLoadingColors]);

  useEffect(() => {
    dispatch(changeVisibleStandCountAllLayers(true));
  }, [dispatch]);

  useEffect(() => {
    const colorsDataAnalytics = get(colorsData, 'data');
    if (dataStandCountAnalysis?.data) {
      const standCountData: any = [];
      let indexStandCountStatus = 0;
      let colors: string[] = [];
      // count status of stand count tree or blank spot
      const statusList = [...new Set(dataStandCountAnalysis.data.map((item: any) => item.status))];
      if (statusList.length === 1) {
        if (isEmpty(colorsDataAnalytics)) {
          colors = (statusList[0] + '').toLowerCase() === 'tree' ? [DIFFERENT_COLOR[1]] : [DIFFERENT_COLOR[0]];
        }
      } else if (isEmpty(colorsDataAnalytics)) {
        colors = [...DIFFERENT_COLOR];
      } else {
        colors = colorsDataAnalytics;
      }

      dataStandCountAnalysis.data.forEach((_item: any) => {
        const standCountCategory = standCountData.find((category: any) => category.status === _item.status);
        if (!standCountCategory) {
          standCountData.push({
            status: _item.status,
            visible: true,
            color: get(colors, `[${indexStandCountStatus}]`),
            data: [_item],
          });
          indexStandCountStatus++;
          return;
        }
        standCountCategory.data.push(_item);
      });
      if (!isLoadingColors && !isLoading) {
        dispatch(setDataStandCount(standCountData));
      }
    }
  }, [dataStandCountAnalysis, isLoadingColors, isLoading, colorsData]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT}
      analyticTab={<>{dataStandCountChart && <InfoTable standCountData={dataStandCountChart?.data} />}</>}
      layerTab={<StandCountCardLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.STAND_COUNT} />}
      isShowDownloadButton={!!dataStandCountChart}
    />
  );
};

export default StandCountAnalytics;
