import SatelliteOutlinedIcon from '@mui/icons-material/SatelliteOutlined';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip, useTheme } from '@mui/material';
import {
  ClientsIcon,
  DocumentsIcon,
  FlightPlannerIcon,
  GroupIcon,
  HomeIcon,
  InventoryIcon,
  MapViewIcon,
  ScheduleIcon,
} from 'assets/icons/sidebarIcon';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { changeBreadScrumbsSelected } from 'store/slices/breadcrumbsSlice';
import { NewDashboardIcon } from '../../assets/icons/sidebarIcon/NewDashboardIcon';
import { NewMapIcon } from '../../assets/icons/sidebarIcon/NewMapIcon';

interface IIconNavBar {
  open: boolean;
  name: string;
  path: string;
}

export const IconNavBar = ({ open, name, path }: IIconNavBar) => {
  const dispatch: any = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(path !== '/' && location.pathname.includes(path));
  }, [location, setIsSelected, path]);

  const styles = useMemo(() => {
    const bgForStyles = () => {
      if (isSelected) {
        if (theme.palette.mode === 'dark') {
          return theme.palette.background.paper + '99';
        } else {
          return theme.palette.background.paper;
        }
      } else {
        return 'transparent';
      }
    };
    return {
      listItemBtn: {
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
        mx: open ? 0 : -1,
        cursor: 'pointer',
        backgroundColor: bgForStyles(),
      },
      listItemIcon: {
        minWidth: 42,
        mr: open ? 3 : 'auto',
        justifyContent: 'center',
      },
      listItemText: {
        opacity: open ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
      },
    };
  }, [isSelected, open, theme.palette.background.paper, theme.palette.mode]);

  return (
    <ListItemButton
      sx={{
        ...styles.listItemBtn,
      }}
      onClick={() => {
        navigate(path);
        if (name === 'Dashboard') {
          dispatch(changeBreadScrumbsSelected('Overview'));
        } else {
          dispatch(changeBreadScrumbsSelected(''));
        }
      }}>
      <Tooltip title={name}>
        <ListItemIcon
          sx={{
            ...styles.listItemIcon,
          }}>
          {name === 'Dashboard' && <HomeIcon isSelected={isSelected} />}
          {name === 'Map View' && <MapViewIcon isSelected={isSelected} />}
          {name === 'Documents' && <DocumentsIcon isSelected={isSelected} />}
          {name === 'Clients' && <ClientsIcon isSelected={isSelected} />}
          {name === 'Flight Planner' && <FlightPlannerIcon isSelected={isSelected} />}
          {name === 'Schedule' && <ScheduleIcon isSelected={isSelected} />}
          {name === 'Resources' && <GroupIcon isSelected={isSelected} />}
          {name === 'Inventory' && <InventoryIcon isSelected={isSelected} />}
          {name === 'Tiling Map' && (
            <SatelliteOutlinedIcon sx={{ color: isSelected ? theme.palette.primary.main : '' }} />
          )}
          {/*{name === 'New Dashboard' && <CheckBoxOutlineBlankIcon color={isSelected ? 'success' : 'secondary'} />}*/}
          {/*{name === 'New Map View' && <CheckBoxOutlineBlankIcon color={isSelected ? 'success' : 'info'} />}*/}
          {name === 'New Dashboard' && <NewDashboardIcon isSelected={isSelected} />}
          {name === 'New Map View' && <NewMapIcon isSelected={isSelected} />}
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        primary={name}
        sx={{
          ...styles.listItemText,
          color: isSelected ? (theme) => theme.palette.primary.main : '#737373',
        }}
      />
    </ListItemButton>
  );
};
