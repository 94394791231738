import { DASHBOARD_NEW_NAV_BAR, DASHBOARD_OLD_NAV_BAR } from 'constants/dashboard-constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useNavBarBasedPath = () => {
  const [navBars, setNavBars] = useState(DASHBOARD_OLD_NAV_BAR); // Initially set to OldNavBar
  const location = useLocation();

  useEffect(() => {
    const isNewDashboard = location.pathname.includes('/new-dashboard');
    setNavBars(isNewDashboard ? DASHBOARD_NEW_NAV_BAR : DASHBOARD_OLD_NAV_BAR);
  }, [location]); // Run once on mount

  return navBars;
};

export default useNavBarBasedPath;
