import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Grid, styled, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { inside, point as pointTurf } from '@turf/turf';
import { TYPE_ANALYTICS_MAP_VIEW } from 'common/defines/constants';
import ExcelButton from 'components/Common/ExcelButton';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { TabPanel } from 'components/MapView/TabPanel';
import { QUERY_KEY } from 'constants/constants';
import { saveAs } from 'file-saver';
import { isArray, isEmpty } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import { getAllDataIntervalLimit } from 'services/analytics/apiAnalyticsData.services';
import { exportExcelLastLevelAnalytics, getUIDisplay, queryMe } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import {
  changeDataPointClicked,
  changeIsPointerMode,
  changeIsPolygonMode,
  changeVisiblePopupAdd,
  clearDataPointPolygon,
  standCountAnalyticSelector,
} from 'store/slices/map-view/standCountAnalytics';
import { changeDataIntervalAllLimit, mapViewSelector } from 'store/slices/mapViewSlice';
import { useLandUsageLayerStyle, useLandUsageTabStyle } from '../../../MapViewStyle';
import DialogDeleteStandPoint from '../Category/StandCount/DialogDeleteStandPoint';
import { DialogStandPoint } from '../Category/StandCount/DialogStandPoint';

const fieldList = [
  TYPE_ANALYTICS_MAP_VIEW.CHLOROPHYLL_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.CROP_COVERAGE,
  TYPE_ANALYTICS_MAP_VIEW.PLANT_HEALTH_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.TILLER_DENSITY,
  TYPE_ANALYTICS_MAP_VIEW.VACANT_AREA,
  TYPE_ANALYTICS_MAP_VIEW.VIGOR_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.WEED_INVASION,
  TYPE_ANALYTICS_MAP_VIEW.STRESS_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.SOIL_SATURATION_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.SOIL_EROSION_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.LEAF_PHENOLOGY_FIELD,
  TYPE_ANALYTICS_MAP_VIEW.SLOPE_FIELD,
];
interface ISwipeableViewsCustomProps {
  analyticName?: string;
  analyticTab: ReactNode;
  layerTab: ReactNode;
  isDisableLabel?: boolean;
  isShowDownloadButton?: boolean;
}

export const SwipeableViewsCustom = ({
  analyticName,
  analyticTab,
  layerTab,
  isShowDownloadButton = false,
  isDisableLabel = false,
}: ISwipeableViewsCustomProps) => {
  const classesLandUse = useLandUsageTabStyle();
  const classes = useLandUsageLayerStyle();
  const bottomBock = useRef() as React.MutableRefObject<HTMLDivElement>;
  const scrollBlockRef = useRef() as any;
  const dispatch = useDispatch();
  const { clientId } = useParams();
  // const [isDisplayExpand, setIsDisplayExpand] = useState(true);
  // const [isDisplayExpandLabel, setIsDisplayExpandLabel] = useState(true);
  const [isDisplayExpandEditMode, setIsDisplayExpandEditMode] = useState(true);
  const [tabChildNumber, setTabChildNumber] = useState<number>(0);
  const [isShowMoreBtn, setIsShowMoreBtn] = useState(false);
  const [idPointsDelete, setIdPointDelete] = useState<string | string[]>('');
  // const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [ableToView, setAbleToView] = useState(false);
  const [isAbleShowEditTabUI, setIsAbleShowEditTabUI] = useState(false);
  const [isDisplayDeleteButton, setIsDisplayDeleteButton] = useState(false);
  const theme = useTheme();

  const { data: userInfo } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: false,
  });

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);
  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  const { data: userInfoByClient } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && userId !== '' && !isSuperAdmin && !isSuperUser,
    }
  );

  const isShowEditTabUI = useMemo(() => {
    if (isSuperAdmin || isSuperUser) return true;
    if (!userInfo?.data?.role) return false;
    const dataRoleValue = userInfoByClient?.data?.flagTab;

    if (!dataRoleValue || dataRoleValue.length === 0) return false;
    dataRoleValue.includes('MAPVIEW-ANALYSIS-TAB-EDIT') && setIsAbleShowEditTabUI(true);
    return isAbleShowEditTabUI;
  }, [isSuperAdmin, isSuperUser, userInfo, userInfoByClient, isAbleShowEditTabUI]);

  useEffect(() => {
    if (isSuperAdmin || isSuperUser) {
      setAbleToView(true);
      return;
    }
    if (!userInfo?.data?.role) {
      setAbleToView(false);
      return;
    }

    const permissionsData: string[] = [];
    userInfo?.data?.role?.map((item: any) => {
      if (item.clientId === clientId) {
        permissionsData.push(...item.permissions);
      }
    });
    if (permissionsData.length === 0) return;
    permissionsData.includes('MAPVIEW_ANALYSIS_STAND_COUNT_TAB_EDIT_VIEW') && setAbleToView(isShowEditTabUI);
  }, [clientId, isShowEditTabUI, isSuperAdmin, isSuperUser, userInfo]);

  const {
    analyticId,
    isDefaultInterval,
    parentAnalyticsId,
    dataStandCount,
    sizeCropCoverageSelected,
    sizePlantHealthSelected,
    sizeTilerDensitySelected,
    sizeVacantAreaSelected,
    sizeChlorophyllPaddySelected,
    sizeVigorPaddySelected,
    sizeStressFieldSelected,
    sizeWaterUptakeFieldSelected,
    sizeSoilSaturationFieldSelected,
    sizeSoilErosionFieldSelected,
    sizeLeafPhenologyFieldSelected,
    sizeSlopeFieldSelected,
  } = useAppSelector(mapViewSelector);
  const { isShowPopupAdd, dataPointClicked, dataPointPolygon, point, isLoading, isPointerMode, isPolygonMode } =
    useAppSelector(standCountAnalyticSelector);

  useQuery(
    [QUERY_KEY.GET_ALL_LIMIT_INTERVAL, isDefaultInterval, parentAnalyticsId, analyticId],
    () => getAllDataIntervalLimit(parentAnalyticsId || '', analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId && !!parentAnalyticsId,
      onSuccess: (data) => {
        dispatch(changeDataIntervalAllLimit(data.data));
      },
    }
  );

  const standCountGeoJSON = useMemo(() => {
    if (isEmpty(dataStandCount) || isLoading) {
      return null;
    }

    let standCountGeoPoint: any = [];

    dataStandCount?.forEach((category: any) => {
      category?.data?.forEach((standCount: any) => {
        if (standCount.latY && standCount.longX) {
          standCountGeoPoint.push(pointTurf([standCount.longX, standCount.latY], standCount));
        } else if (standCount.geometry?.coordinates && !isArray(standCount.geometry?.coordinates?.[0])) {
          standCountGeoPoint.push(pointTurf(standCount.geometry.coordinates));
        } else if (standCount.geometry?.coordinates?.[0]) {
          standCountGeoPoint.push(pointTurf(standCount.geometry.coordinates[0], standCount));
        }
      });
    });

    return standCountGeoPoint;
  }, [dataStandCount, isLoading]);

  const arrayIdInside = useMemo(() => {
    let tempArrayId = [] as string[];

    if (!dataPointPolygon.length) return [];

    dataPointPolygon.forEach((item: any) => {
      standCountGeoJSON?.forEach((point: any) => {
        let pointInsidePolygon = inside(point, item);
        if (pointInsidePolygon) tempArrayId.push(point.properties?._id);
      });
    });

    return tempArrayId;
  }, [dataPointPolygon, standCountGeoJSON]);

  useEffect(() => {
    dispatch(clearDataPointPolygon());
  }, [dispatch, isPolygonMode]);

  useEffect(() => {
    dispatch(changeIsPolygonMode(false));
    dispatch(changeIsPointerMode(false));
  }, [dispatch]);

  const handleDeletePoint = useCallback(() => {
    if (isPolygonMode && arrayIdInside.length > 0) {
      setIdPointDelete(arrayIdInside);
      setOpen(true);
    } else if (isPointerMode) {
      setIdPointDelete(dataPointClicked?._id);
      setOpen(true);
    }
  }, [arrayIdInside, dataPointClicked?._id, isPointerMode, isPolygonMode]);

  useEffect(() => {
    if ((isPolygonMode && arrayIdInside.length > 0) || dataPointClicked?._id) {
      setIsDisplayDeleteButton(true);
    } else {
      setIsDisplayDeleteButton(false);
    }
  }, [arrayIdInside.length, dataPointClicked?._id, isPolygonMode]);

  const onClickAddPoint = () => {
    dispatch(changeVisiblePopupAdd(true));
    dispatch(changeDataPointClicked(undefined));
  };

  const handleChangePointerMode = useCallback(() => {
    dispatch(changeIsPointerMode(!isPointerMode));
    dispatch(changeDataPointClicked(undefined));
    if (isPolygonMode) {
      dispatch(changeIsPolygonMode(false));
    }
    dispatch(changeDataPointClicked(undefined));
  }, [dispatch, isPointerMode, isPolygonMode]);

  const handleChangePolygonMode = useCallback(() => {
    dispatch(changeIsPolygonMode(!isPolygonMode));
    if (isPointerMode) {
      dispatch(changeIsPointerMode(false));
    }
    dispatch(changeDataPointClicked(undefined));
  }, [dispatch, isPointerMode, isPolygonMode]);

  const handleParamCondition = () => {
    let size = null;
    if (fieldList.includes(analyticName as TYPE_ANALYTICS_MAP_VIEW)) {
      size =
        sizeCropCoverageSelected ||
        sizePlantHealthSelected ||
        sizeVigorPaddySelected ||
        sizeChlorophyllPaddySelected ||
        sizeVacantAreaSelected ||
        sizeTilerDensitySelected ||
        sizeStressFieldSelected ||
        sizeWaterUptakeFieldSelected ||
        sizeSoilSaturationFieldSelected ||
        sizeSoilErosionFieldSelected ||
        sizeLeafPhenologyFieldSelected ||
        sizeSlopeFieldSelected;
    }
    const defaultInterval = size ? undefined : isDefaultInterval;
    return exportExcelLastLevelAnalytics(analyticId!, defaultInterval, size!);
  };

  const exportExcelMutation = useMutation(() => handleParamCondition(), {
    onSuccess(res) {
      saveAs(res.request.responseURL, `${analyticName}-Analytics.xlsx`);
      toast.success('Download file successful');
    },
    onError() {
      toast.error('Download failed');
    },
  });

  return (
    <Grid
      direction="column"
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        height: 'fit-content',
        width: '100%',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
      }}>
      <Grid direction="row">
        <Tabs
          value={tabChildNumber}
          onChange={(_event, value) => setTabChildNumber(value)}
          variant="fullWidth"
          className={classesLandUse.tabsContainer}>
          {tabChildMenu.map((item) => {
            return (
              <TabStyled
                key={item.id}
                label={item.label}
                {...a11yProps(item.id)}
                sx={{
                  fontSize: '16px',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              />
            );
          })}
          {analyticName === 'Stand Count' && ableToView ? (
            <TabStyled
              label={'Edit'}
              {...a11yProps(2)}
              sx={{
                fontSize: '16px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                '&.Mui-selected': {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
            />
          ) : null}
        </Tabs>
      </Grid>
      <Grid direction="row">
        <SwipeableViews axis={'x-reverse'} index={tabChildNumber}>
          <TabPanel value={tabChildNumber} index={0} isAnalyticsTab={true}>
            <Scrollbars
              ref={scrollBlockRef}
              style={{
                height: 'calc(100vh - 348px)',
                backgroundColor: theme.palette.background.paper,
              }}
              autoHide
              onScrollFrame={(values: any) => {
                if (values.top > 0.16) {
                  setIsShowMoreBtn(false);
                } else {
                  setIsShowMoreBtn(true);
                }
              }}>
              {analyticTab}
              {isShowDownloadButton && (
                <Box textAlign="center" mt="16px" mb="12px">
                  {/* Inside analytics -> categories tab */}
                  <ExcelButton
                    onClick={() => exportExcelMutation.mutate()}
                    disabled={exportExcelMutation.isLoading}
                    isLoading={exportExcelMutation.isLoading}
                  />
                </Box>
              )}
              <div ref={bottomBock}></div>
              <Box
                sx={{
                  display: isShowMoreBtn ? 'block' : 'none',
                  width: '100%',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  backgroundColor: 'transparent',
                  position: 'fixed',
                  bottom: '60px',
                  transition: 'all 0.3s ease',
                }}>
                <Button
                  sx={{
                    maxWidth: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.background.default,
                    borderRadius: '5px',
                    padding: '5px',
                    border: `1px solid ${theme.palette.primary.main}`,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.5)',
                    margin: 'auto',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: '#fff',
                    },
                  }}
                  onClick={() => {
                    scrollBlockRef.current.view.scroll({
                      top: 10000,
                      behavior: 'smooth',
                    });
                  }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      width: '200px',
                    }}>
                    More
                  </Typography>
                </Button>
              </Box>
            </Scrollbars>
          </TabPanel>
          <TabPanel value={tabChildNumber} index={1} isAnalyticsTab={true}>
            <Scrollbars style={{ height: 'calc(100vh - 350px)' }} autoHide>
              <Grid
                container
                classes={{ root: classes.containerStyle }}
                direction="column"
                sx={{ display: 'flex', flex: 1 }}>
                <Box className={classes.boxStyle}>
                  <Box className={classes.boxDataStyle}>{layerTab}</Box>
                </Box>
              </Grid>
            </Scrollbars>
          </TabPanel>
          <TabPanel value={tabChildNumber} index={2} isAnalyticsTab={true}>
            <Scrollbars style={{ height: 'calc(100vh - 350px)' }} autoHide>
              <Grid
                container
                classes={{ root: classes.containerStyle }}
                direction="column"
                sx={{ display: 'flex', flex: 1 }}>
                <Box className={classes.boxStyle}>
                  <Box>{layerTab}</Box>
                  <Box className="mode" sx={{}}>
                    <Grid sx={{}}>
                      <Button
                        startIcon={isDisplayExpandEditMode ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        onClick={() => setIsDisplayExpandEditMode(!isDisplayExpandEditMode)}
                        classes={{ root: classes.buttonTextStyle }}>
                        {'Edit Mode'}
                      </Button>
                      <Collapse in={isDisplayExpandEditMode} timeout="auto" unmountOnExit sx={{ px: 2.5 }}>
                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <FormControlLabel
                              control={<SwitchCustom checked={isPointerMode} onClick={handleChangePointerMode} />}
                              label={<Typography classes={{ root: classes.selectText }}>{'Pointer'}</Typography>}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <FormControlLabel
                              control={<SwitchCustom checked={isPolygonMode} onClick={handleChangePolygonMode} />}
                              label={<Typography classes={{ root: classes.selectText }}>{'Polygon'}</Typography>}
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Box>
                  {dataPointClicked && isPointerMode ? (
                    <Box className="info" sx={{ padding: '16px 8px 16px 8px', color: '#616161' }}>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          display: 'flex',
                        }}>
                        Tree Id: <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.treeId}</Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          display: 'flex',
                        }}>
                        Longitude: <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.longX}</Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          display: 'flex',
                        }}>
                        Latitude: <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.latY} </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          display: 'flex',
                        }}>
                        Status:
                        <Typography sx={{ color: '#23BE63', px: 1 }}>{dataPointClicked?.status} </Typography>
                      </Typography>
                    </Box>
                  ) : null}
                  <Box sx={{ display: 'flex', pt: 2 }}>
                    <Typography component="div">
                      <Button variant="contained" sx={{ m: 1 }} onClick={onClickAddPoint}>
                        Add point
                      </Button>
                    </Typography>
                    {isDisplayDeleteButton ? (
                      <Typography component="div">
                        <Button color="error" variant="contained" sx={{ m: 1 }} onClick={handleDeletePoint}>
                          Delete point
                        </Button>
                      </Typography>
                    ) : null}
                  </Box>
                  {isShowPopupAdd && <DialogStandPoint />}
                  <DialogDeleteStandPoint
                    open={open}
                    setOpen={setOpen}
                    idPoints={idPointsDelete}
                    isPointerMode={isPointerMode}
                  />
                </Box>
              </Grid>
            </Scrollbars>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

const tabChildMenu = [
  {
    id: 0,
    label: 'Categories',
  },
  {
    id: 1,
    label: 'Layer',
  },
];

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

const TabStyled = styled(Tab)((theme) => ({
  textTransform: 'unset',
  fontWeight: 500,
  fontSize: '12px',
}));
