import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Slider,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ICreateClientSettings, MeasurementUnit, SettingSectionName, SettingUnitName } from 'common/defines/clients';
import { uiSettingDescriptionMapping } from 'common/defines/constants';
import {
  defaultClientSettings,
  defaultClientUISetting,
  defaultClientUnitSetting,
  defaultSideSurfaceSetting,
  defaultTopSurfaceSetting,
} from 'common/dummy/dummyClients';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateClientSettingsById } from 'services/clients/apiClient.services';
import { useClientSettingsStyle } from './ClientSettingsStyle';

export const AreaTypeValue = [
  {
    key: MeasurementUnit.HA,
    value: MeasurementUnit.HA,
  },
  {
    key: MeasurementUnit.KM2,
    value: 'km²',
  },
  {
    key: MeasurementUnit.ACRE,
    value: MeasurementUnit.ACRE,
  },
  {
    key: MeasurementUnit.M2,
    value: 'm²',
  },
];

export const LengthUnitValue = [
  {
    key: MeasurementUnit.KM,
    value: MeasurementUnit.KM,
  },
  {
    key: MeasurementUnit.MILES,
    value: MeasurementUnit.MILES,
  },
  {
    key: MeasurementUnit.M,
    value: MeasurementUnit.M,
  },
];

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 24,
    height: 24,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      position: 'absolute',
      top: '3px',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export const ClientSettingModal = (props: any) => {
  const { open, onClose, refetch, clientData, clientId, typeSetting, settingId } = props;
  const classes = useClientSettingsStyle();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [colorTransparent, setColorTransparent] = useState(clientData?.sideSurfaceSetting?.color);
  const [opacityValue, setOpacityValue] = useState(clientData?.sideSurfaceSetting?.opacity);
  const [isSameSideSurface, setIsSameAsSurFace] = useState(clientData?.topSurfaceSetting?.isSameSideSurfaceOpacity);
  const [opacityTopSurface, setOpacityTopSurface] = useState(clientData?.topSurfaceSetting?.opacity2D);

  const handleChangeField = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const trimInputValue = event.target.value.replace(/\s+/g, ' ');
    setColorTransparent(trimInputValue);
  }, []);

  const { control, handleSubmit, reset, setValue } = useForm<ICreateClientSettings>({
    defaultValues: defaultClientSettings,
  });

  const { fields: unitSettingFields } = useFieldArray({
    name: 'unitSetting',
    control,
  });

  const { fields: uiSettingFields } = useFieldArray({
    name: 'uiSetting',
    control,
  });

  useEffect(() => {
    reset({
      unitSetting: clientData?.unitSetting?.length > 0 ? clientData?.unitSetting : defaultClientUnitSetting,
      uiSetting: defaultClientUISetting.map((val: any) => {
        const matchedSettings = clientData?.uiSetting?.find((setting: any) => setting.name === val.name);
        return matchedSettings ? matchedSettings : val;
      }),
      sideSurfaceSetting: clientData?.sideSurfaceSetting ? clientData?.sideSurfaceSetting : defaultSideSurfaceSetting,
      topSurfaceSetting: clientData?.topSurfaceSetting ? clientData?.topSurfaceSetting : defaultTopSurfaceSetting,
    });
  }, [clientData, reset]);

  const handleClose = () => {
    onClose();
  };

  const handleUpdateMeasure = useMutation(
    (data: ICreateClientSettings) => updateClientSettingsById(settingId || '', data),
    {
      onSuccess: () => {
        onClose();
        refetch();
        toast.success('Change settings successfully');
      },
    }
  );

  const handleSubmitForm: SubmitHandler<ICreateClientSettings> = (data) => {
    handleUpdateMeasure.mutate({ ...data, clientId });
  };

  const handleChangeDecimalPlace = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const valueToNum = parseInt(event.target.value);
      if (typeSetting === SettingSectionName.UNIT_SETTING) {
        setValue(`unitSetting.${index}.decimalPlace`, valueToNum);
      }
      setValue(`uiSetting.${index}.value`, valueToNum);
    },
    [setValue, typeSetting]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = useCallback(
    (color: string) => {
      setColorTransparent(color);
      setValue('sideSurfaceSetting.color', color);
    },
    [setValue]
  );

  const handleChangeOpacity = useCallback(
    (e: any) => {
      setOpacityValue(e.target.value);
      setValue('sideSurfaceSetting.opacity', e.target.value);
    },
    [setValue]
  );

  const handleChangeOpacityTopSurface = useCallback(
    (e: any) => {
      setOpacityTopSurface(e.target.value);
      setValue('topSurfaceSetting.opacity2D', e.target.value);
    },
    [setValue]
  );

  const handleChecked3D = useCallback(
    (e: any) => {
      setIsSameAsSurFace(!isSameSideSurface);
      setValue('topSurfaceSetting.isSameSideSurfaceOpacity', e.target.checked);
    },
    [setValue, isSameSideSurface]
  );

  const dialogFormContent = useMemo(() => {
    switch (typeSetting) {
      case SettingSectionName.UNIT_SETTING:
        return (
          <>
            {unitSettingFields.map((item, index) => {
              return (
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
                      marginBottom: '1rem',
                    }}>
                    {item.name}
                  </Typography>
                  <Box
                    key={`${item.id}-${index}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '1rem',
                    }}>
                    <Box sx={{ width: '100%' }}>
                      <Controller
                        name={`unitSetting.${index}.unit`}
                        control={control}
                        render={({ field }) => (
                          <Box
                            sx={{
                              display: 'flex',
                            }}>
                            <Box
                              sx={{
                                flex: 1,
                              }}>
                              <InputLabel>Unit</InputLabel>
                              <Select
                                {...field}
                                sx={{
                                  width: '100%',
                                  mb: '1rem',
                                  height: '37px',
                                }}
                                variant="outlined"
                                value={field.value}>
                                {item.name === SettingUnitName.AREA
                                  ? AreaTypeValue.map((item) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.value}
                                      </MenuItem>
                                    ))
                                  : LengthUnitValue.map((item) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.value}
                                      </MenuItem>
                                    ))}
                              </Select>
                            </Box>
                          </Box>
                        )}
                      />
                      <Controller
                        name={`unitSetting.${index}.decimalPlace`}
                        control={control}
                        render={({ field }) => (
                          <Box
                            sx={{
                              flex: 1,
                            }}>
                            <InputLabel>Decimal Places</InputLabel>
                            <TextField
                              type="number"
                              defaultValue={2}
                              size="small"
                              fullWidth
                              value={field.value}
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              onChange={(e: any) => handleChangeDecimalPlace(e, index)}
                            />
                          </Box>
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </>
        );
      case SettingSectionName.UI_SETTING:
        return (
          <>
            {uiSettingFields.map((item, index) => {
              return (
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
                      marginBottom: '1rem',
                    }}>
                    {item.name}
                  </Typography>
                  <Box
                    key={`${item.id}-${index}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '1rem',
                    }}>
                    <Controller
                      name={`uiSetting.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <Box
                          sx={{
                            flex: 1,
                          }}>
                          <InputLabel>{uiSettingDescriptionMapping[item.name]}</InputLabel>
                          <TextField
                            type="number"
                            defaultValue={2}
                            size="small"
                            fullWidth
                            value={field.value}
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            onChange={(e: any) => handleChangeDecimalPlace(e, index)}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
              );
            })}
          </>
        );
      case SettingSectionName.SIDE_SURFACE_SETTING:
        return (
          <Grid container alignItems="center" sx={{ p: 3, overflow: 'hidden' }}>
            <Grid item xs={6}>
              <Controller
                name="sideSurfaceSetting.category"
                control={control}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      control={
                        <SwitchCustom
                          checked={false}
                          onClick={() => {
                            // dispatch(changeIsTransparent(!isShowTransparent));
                          }}
                        />
                      }
                      label={<Typography className={classes.textSetting}>Category</Typography>}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="sideSurfaceSetting.opacity"
                control={control}
                render={({ field }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PrettoSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      value={opacityValue}
                      max={1}
                      min={0}
                      step={0.1}
                      onChange={handleChangeOpacity}
                    />
                    <Typography className={classes.textSetting} sx={{ pl: 2, minWidth: '100px' }}>
                      {!opacityValue ? 'Transparent' : `Opacity: ${opacityValue}`}
                    </Typography>
                  </Box>
                )}
              />
            </Grid>
            {opacityValue ? (
              <Grid item xs={12} sx={{ paddingLeft: '24px' }}>
                <Controller
                  name="sideSurfaceSetting.color"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        label=""
                        control={
                          <Box className={classes.formBlockItem}>
                            <Box className={classes.pickupColorBox}>
                              <Typography className={classes.textSetting}>Custom Color</Typography>
                              <Tooltip
                                title={openPopover ? 'Close pick color' : 'Open pick color'}
                                placement="right"
                                arrow>
                                <Box
                                  onClick={(e: any) => handleClick(e)}
                                  className={classes.pickupColorBtn}
                                  sx={{
                                    backgroundColor: colorTransparent,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Box sx={{ paddingLeft: '10px' }}>
                              <TextField
                                id="cropTypeColor"
                                type="text"
                                size="small"
                                sx={{
                                  color: colorTransparent,
                                  border: `1px solid ${colorTransparent}`,
                                  borderRadius: '5px',
                                  width: '130px',
                                }}
                                fullWidth
                                value={colorTransparent}
                                onChange={handleChangeField}
                              />
                            </Box>
                            <Popover
                              id={id}
                              open={openPopover}
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              sx={{
                                '.MuiPopover-paper': {
                                  overflowX: 'initial',
                                  overflowY: 'initial',
                                },
                              }}
                              transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              anchorOrigin={{
                                vertical: 130,
                                horizontal: 210,
                              }}>
                              <Box className={classes.pickupColorValueContainer}>
                                <HexColorPickerDebounce
                                  style={{
                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '10px',
                                  }}
                                  color={colorTransparent}
                                  onChange={handleChangeColor}
                                />
                              </Box>
                            </Popover>
                          </Box>
                        }
                      />
                    </>
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      case SettingSectionName.TOP_SURFACE_SETTING:
        return (
          <Grid container alignItems="center" sx={{ overflow: 'hidden', py: 2 }}>
            <Grid item xs={6}>
              <Controller
                name="topSurfaceSetting.isSameSideSurfaceOpacity"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
                        marginBottom: '1rem',
                      }}>
                      {'3D'}
                    </Typography>
                    <Box sx={{ pl: 1 }}>
                      <FormControlLabel
                        control={<SwitchCustom checked={isSameSideSurface} onClick={handleChecked3D} />}
                        label={<Typography className={classes.textSetting}>Same as side surface</Typography>}
                      />
                    </Box>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="topSurfaceSetting.opacity2D"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
                        marginBottom: '1rem',
                      }}>
                      {'2D'}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PrettoSlider
                        valueLabelDisplay="auto"
                        aria-label="pretto slider"
                        value={opacityTopSurface}
                        max={1}
                        min={0}
                        step={0.1}
                        onChange={handleChangeOpacityTopSurface}
                      />
                      <Typography className={classes.textSetting} sx={{ pl: 3, minWidth: '100px' }}>
                        {!opacityTopSurface ? 'Transparent' : `Opacity: ${opacityTopSurface}`}
                      </Typography>
                    </Box>
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        );
      default:
        return <></>;
    }
  }, [
    control,
    typeSetting,
    uiSettingFields,
    unitSettingFields,
    handleChangeDecimalPlace,
    id,
    openPopover,
    handleChangeField,
    anchorEl,
    colorTransparent,
    handleChangeColor,
    classes.formBlockItem,
    classes.pickupColorBox,
    classes.pickupColorBtn,
    classes.pickupColorValueContainer,
    classes.textSetting,
    opacityValue,
    handleChangeOpacity,
    isSameSideSurface,
    opacityTopSurface,
    handleChecked3D,
    handleChangeOpacityTopSurface,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            backgroundColor: (theme) => theme.palette.background.paper,
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        },
      }}>
      <DialogTitle className={classes.dialogTitle}>Client Settings Edit</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          p: 0,
          position: 'absolute',
          right: 8,
          top: 8,
          '.MuiSvgIcon-root': {
            width: '16px',
            height: '16px',
          },
          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.color.grey5),
        }}>
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          padding: '1rem 2rem',
        }}>
        <Typography
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}
          onSubmit={handleSubmit(handleSubmitForm)}>
          <DialogContent
            sx={{
              padding: 0,
            }}>
            {dialogFormContent}
          </DialogContent>
          <DialogActions>
            <Button type="submit" fullWidth className={classes.saveButton}>
              Save
            </Button>
          </DialogActions>
        </Typography>
      </Box>
    </Dialog>
  );
};
