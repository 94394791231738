import { yupResolver } from '@hookform/resolvers/yup';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import BusinessIcon from '@mui/icons-material/Business';
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import SaveIcon from '@mui/icons-material/Save';
import { DatePicker, LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Switch,
  Tab,
  Tabs,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { ICreateClientAdminFormData, ICreateUserFormData } from 'common/defines/clients';
import AvatarUser from 'components/Common/AvatarUser';
import ErrorMessage from 'components/Common/ErrorMessage';
import configs from 'constants/config';
import { QUERY_KEY } from 'constants/constants';
import { find, isArray } from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidToken } from 'services/auth';
import {
  createClientUserAdminAxios,
  getClientList,
  getRoleData,
  getUserDetails,
  queryMe,
  updateClientUserAdminByIdAxios,
} from 'services/clients/apiClient.services';
import * as yup from 'yup';

// time out
const timeOutOptions = [
  {
    value: '',
    label: 'None',
  },
  {
    value: '5m',
    label: '5 minutes',
  },
  {
    value: '15m',
    label: '15 minutes',
  },
  {
    value: '30m',
    label: '30 minutes',
  },
  {
    value: '1h',
    label: '1 hour',
  },
  {
    value: '4h',
    label: '4 hours',
  },
  {
    value: '12h',
    label: '12 hours',
  },
];

const userProfileValidationSchema = yup
  .object({
    name: yup.string().required('Full name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    address: yup.string().required('Address is required'),
    clientId: yup.array().min(1, 'Please select a client'),
  })
  .required();

const userProfileValidationSchemaAdmin = yup
  .object({
    name: yup.string().required('Full name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    address: yup.string().required('Address is required'),
  })
  .required();

interface IUserProfile {
  handleBack: () => void;
  userId?: string;
  canBecomeSuperUser?: boolean;
}
interface IAccessControlProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: IAccessControlProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        width: '100%',
      }}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const UserProfile = ({ handleBack, userId, canBecomeSuperUser }: IUserProfile) => {
  const [valueTab, setValueTab] = useState(0);
  const { clientId } = useParams();
  const isAuth = isValidToken();
  const [avaShow, setAvaShow] = useState('');
  const [rolesData, setRolesData] = useState([]);
  const [tempRoles, setTempRoles] = useState([] as any);
  const [clientList, setClientList] = useState<any[]>([]);
  const [idRoleByClientId, setIdRoleByClientId] = useState('');
  const [clientIdList, setClientIdList] = useState<any[]>(
    clientId !== undefined && [clientId].length === 1 ? [clientId] : []
  );
  const theme = useTheme();
  const token = localStorage.getItem('token');

  const styles = {
    UserContainer: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px',
      overflow: 'hidden',
    },
    btnBackEditClient: {
      fontWeight: '600',
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '26px',
      color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#3C4043',
      textTransform: 'none',
      my: '16px',
      ml: '20px',
      p: '6px 16px',
    },
    btnUploadAva: {
      height: '30px',
      width: '100px',
    },
    navbarTabUser: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      p: '16px 30px',
      cursor: 'pointer',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      color: '#3C4043',
      bgcolor: '#F5F7F9',
    },
    navBarTab: {
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
    },
    submitBtn: {
      width: '200px',
      height: '40px',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '22px',
    },
    tabsList: {
      borderColor: 'divider',
      width: '220px',
    },
    iconTab: {
      width: '28px',
      height: '28px',
      margin: '0 12px !important',
    },
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<ICreateUserFormData>({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      zipCode: '',
      state: '',
      country: '',
      jobTitle: '',
      role: '',
      company: '',
      department: '',
      workLocation: '',
      manager: '',
      clientId: clientIdList,
      status: true,
      joinDate: null,
      avatar: '',
      isSuperAdmin: false,
      isSuperUser: false,
      tokenExpiredIn: null,
    },
    resolver: async (data, context, options) => {
      if (dataUserDetails?.data?.isSuperAdmin || dataUserDetails?.data?.isSuperUser) {
        return yupResolver(userProfileValidationSchemaAdmin)(data, context, options);
      }
      return yupResolver(userProfileValidationSchema)(data, context, options);
    },
  });

  const { data: checkSuperAdmin } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe(), {
    keepPreviousData: false,
    enabled: isAuth,
  });

  const isSuperAdmin = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperAdmin;
  }, [checkSuperAdmin]);
  const isSuperUser = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperUser;
  }, [checkSuperAdmin]);

  const { data: dataUserDetails } = useQuery([QUERY_KEY.USER_DETAILS, userId], () => getUserDetails(userId), {
    keepPreviousData: false,
    enabled: !!userId,
    onSuccess: (res: any) => {
      const listRoleId = isArray(res?.data?.role) && res?.data?.role.map((item: any) => item._id);
      setTempRoles(listRoleId);

      const userDetailByClientId = find(res.data.role, (item: any) => item.clientId === clientId);
      setIdRoleByClientId(userDetailByClientId._id);
    },
  });
  const userIsSuperAdmin = useMemo(() => {
    if (!dataUserDetails) return false;
    return dataUserDetails.data.isSuperAdmin;
  }, [dataUserDetails]);

  //  Call API to get list of client
  useQuery(QUERY_KEY.CLIENT_LIST, () => getClientList(), {
    enabled: (isSuperAdmin || isSuperUser) && !clientId,
    onSuccess: (data) => {
      const clientNameData = data.data.map((item: any) => {
        return {
          value: item._id,
          name: item.name,
          avatar: item.avatar,
          email: item.email,
        };
      });
      setClientList(clientNameData);
    },
    onError: (err) => {
      console.log(err);
    },
    keepPreviousData: false,
  });

  // call API to get role data
  useQuery(QUERY_KEY.ROLES_DATA, () => getRoleData(clientId!), {
    enabled: (isSuperAdmin || isSuperUser) && !!clientId,
    onSuccess: (data: any) => {
      setRolesData(data.data);
    },
    onError: (err: any) => {
      console.log(err);
    },
    keepPreviousData: false,
  });
  if (!isSuperAdmin && dataUserDetails && !isSuperUser) {
    setValue('clientId', dataUserDetails.data?.clientId);
  }

  const handleChangeRole = useCallback(
    (event: any) => {
      setValue('role', event.target.value);
    },
    [setValue]
  );

  const handleSelectClient = useCallback(
    (event: any) => {
      setClientIdList(event.target.value);
      setValue('clientId', event.target.value);
    },
    [setValue]
  );

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>, fieldName: keyof ICreateUserFormData) => {
    const trimInputValue = event.target.value.replace(/\s+/g, ' ');
    setValue(fieldName, trimInputValue);
  };

  const handleCheckError = () => {
    if (getValues('name') === '') {
      setError('name', {
        type: 'required',
        message: 'Full Name is required',
      });
    } else {
      setError('name', {
        message: '',
      });
    }
    if (getValues('email') === '') {
      setError('email', {
        type: 'required',
        message: 'Email is required',
      });
    } else {
      setError('email', {
        message: '',
      });
    }
    if (getValues('phoneNumber') === '') {
      setError('phoneNumber', {
        type: 'required',
        message: 'Phone Number is required',
      });
    } else {
      setError('phoneNumber', {
        message: '',
      });
    }
    if (getValues('address') === '') {
      setError('address', {
        type: 'required',
        message: 'Address is required',
      });
    } else {
      setError('address', {
        message: '',
      });
    }
    if (
      getValues('name') !== '' &&
      getValues('email') !== '' &&
      getValues('phoneNumber') !== '' &&
      getValues('address') !== ''
    ) {
      setValueTab(1);
    }
  };

  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const file = event.target.files;
    if (!file) return;
    const newFile = file[0];
    (newFile as any).preview = URL.createObjectURL(newFile);
    const formData = new FormData();
    formData.append('file', newFile);

    setAvaShow((newFile as any).preview);
    fetch(`${configs.API_DOMAIN}/media/uploadGeneral`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        value = result.path;
        setValue('avatar', value);
        return value;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const useCreateUser = () => {
    return useMutation(
      (param: ICreateUserFormData) =>
        createClientUserAdminAxios({
          ...param,
        }),
      {
        onSuccess: () => {
          // handleBack();
          toast.success('Create user successfully');
        },
        onError: (err: any) => {
          const errorMessage = err?.data?.message[0];
          toast.error(errorMessage);
        },
      }
    );
  };
  const mutationCreateUser = useCreateUser();

  useEffect(() => {
    return () => {
      avaShow && URL.revokeObjectURL((avaShow as any).preview);
    };
  }, [avaShow]);

  useEffect(() => {
    const userDetail = dataUserDetails?.data;
    if (!userDetail) return;
    reset({
      name: userDetail.name || '',
      email: userDetail.email || '',
      phoneNumber: userDetail.phoneNumber || '',
      address: userDetail.address || '',
      zipCode: userDetail.zipCode || '',
      state: userDetail.state || '',
      country: userDetail.country || '',
      jobTitle: userDetail.jobTitle || '',
      role: idRoleByClientId ? idRoleByClientId : '',
      company: userDetail.company || '',
      department: userDetail.department || '',
      workLocation: userDetail.workLocation || '',
      manager: userDetail.manager || '',
      clientId: userDetail.clientId || [],
      status: userDetail.status || true,
      joinDate: userDetail.joinDate || '',
      avatar: userDetail.avatar || '',
      isSuperAdmin: userDetail.isSuperAdmin || false,
      isSuperUser: userDetail.isSuperUser || false,
      tokenExpiredIn: userDetail.tokenExpiredIn || '',
    });
    setAvaShow(userDetail.avatar);
  }, [dataUserDetails, idRoleByClientId, reset, setValue]);

  const handleUpdateUser = useMutation((data: ICreateClientAdminFormData) => updateClientUserAdminByIdAxios(data), {
    onSuccess: () => {
      // handleBack();
      toast.success('User updated successfully');
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const handleSubmitForm: SubmitHandler<ICreateUserFormData> = (body) => {
    const selectedRoleId = body.role;

    let newArr = [...tempRoles] as any;
    if (selectedRoleId !== idRoleByClientId) {
      newArr = [...newArr, selectedRoleId].filter((item: any) => item !== idRoleByClientId);
    }

    //Body for Create
    const bodyUpdate = newArr.filter((item: any) => item !== '');
    let bodyCreate = [] as any;
    if (!body.role) {
      bodyCreate = [];
    } else {
      bodyCreate = [body.role].filter((item: any) => item !== '');
    }

    // Update
    if (userId) {
      handleUpdateUser.mutate({
        ...body,
        clientId: body.clientId || [],
        avatar: getValues('avatar') || '',
        id: userId,
        isFirstLogin: false,
        role: bodyUpdate,
      });
    }
    // Create
    else {
      mutationCreateUser.mutate({
        ...body,
        clientId: body.clientId || [],
        avatar: getValues('avatar') || '',
        isFirstLogin: true,
        role: bodyCreate,
      });
    }
  };

  const handleRenderClientList = (listItem: any) => {
    if (listItem.length === 0 || clientList.length === 0)
      return <Typography variant="body2">No client found</Typography>;
    return (
      <Box
        sx={{
          display: 'flex',
        }}>
        {listItem.map((item: any) => {
          const client = clientList.find((clientItem: any) => clientItem.value === item);
          return (
            <Box
              key={item}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '50%',
                width: '36px',
                height: '36px',
              }}>
              <Avatar
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                src={client?.avatar}
                alt="client"
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...styles.UserContainer,
      }}>
      <Box
        sx={{
          ...styles.navBarTab,
        }}>
        <Button
          startIcon={<SvgIcon sx={{ width: '20px', height: '20px' }} component={ArrowBackRoundedIcon} inheritViewBox />}
          onClick={handleBack}
          sx={{
            ...styles.btnBackEditClient,
          }}>
          {userId ? 'Edit Profile' : 'Add Profile'}
        </Button>
      </Box>
      <Box
        //Content User
        component="form"
        sx={{
          display: 'flex',
        }}
        onSubmit={handleSubmit(handleSubmitForm)}>
        <Box
          // Navbar User
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '220px',
            '.MuiButtonBase-root': {
              justifyContent: 'start',
            },
            backgroundColor: theme.palette.background.paper,
          }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={valueTab}
            onChange={handleChangeTab}
            sx={{
              ...styles.tabsList,
            }}>
            <Tab
              icon={
                <AccountBoxIcon
                  sx={{
                    ...styles.iconTab,
                  }}
                />
              }
              iconPosition="start"
              label="Basic Information"
            />
            <Tab
              icon={
                <BusinessIcon
                  sx={{
                    ...styles.iconTab,
                  }}
                />
              }
              iconPosition="start"
              label="Work Information"
            />
            <Tab
              icon={
                <ManageHistoryRoundedIcon
                  sx={{
                    ...styles.iconTab,
                  }}
                />
              }
              iconPosition="start"
              label="Advanced Settings"
            />
          </Tabs>
        </Box>
        <TabPanel value={valueTab} index={0}>
          <Box
            // User Profile Content
            sx={{
              flex: 4,
              borderLeft: `1px solid ${theme.palette.divider}`,
              p: '20px 24px',
            }}>
            <Typography
              component="h2"
              sx={{
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                mb: '24px',
              }}>
              Basic Information
            </Typography>
            <Box
              sx={{
                display: 'flex',
              }}>
              <Box
                sx={{
                  flex: 1,
                }}>
                <InputLabel>Profile Photo</InputLabel>
                {/* Avatar */}
                <AvatarUser avatar={avaShow} handleChangeFile={handleChangeFile} />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  marginLeft: '21px',
                  overflow: 'hidden',
                }}>
                {isSuperAdmin || isSuperUser ? (
                  <>
                    {clientId && (
                      <Controller
                        name="role"
                        control={control}
                        render={({ field }) => (
                          <Box
                            sx={{
                              mb: '10px',
                            }}
                            key="role">
                            <InputLabel>Access</InputLabel>
                            <Select
                              sx={{
                                height: '40px',
                                padding: '0px 14px',
                                width: '100%',
                                maxWidth: '680.61px',
                              }}
                              fullWidth
                              {...field}
                              defaultValue={getValues('role') || ''}
                              onChange={(event) => handleChangeRole(event)}>
                              {rolesData?.length > 0
                                ? rolesData.map((role: any) => {
                                  const { _id, name } = role;
                                  return (
                                    <MenuItem key={_id} value={_id}>
                                      {name}
                                    </MenuItem>
                                  );
                                })
                                : dataUserDetails?.data?.role?.map((role: any) => {
                                  const { _id, name } = role;
                                  return (
                                    <MenuItem key={_id} value={_id}>
                                      {name}
                                    </MenuItem>
                                  );
                                })}
                              <MenuItem
                                sx={{
                                  height: '35.98px',
                                }}
                                value="">
                                {/* Clear */}
                              </MenuItem>
                            </Select>
                            {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>}
                          </Box>
                        )}
                      />
                    )}
                    {!clientId && !userIsSuperAdmin && (
                      <Box>
                        <Controller
                          name="clientId"
                          control={control}
                          render={({ field }) => (
                            <Box
                              sx={{
                                mb: '10px',
                              }}
                              key="clientId">
                              <InputLabel>
                                Select Client{' '}
                                <span
                                  style={{
                                    color: 'red',
                                  }}>
                                  *
                                </span>{' '}
                              </InputLabel>
                              <Select
                                sx={{
                                  height: '46px',
                                  padding: '0px 14px',
                                  width: '100%',
                                }}
                                fullWidth
                                multiple
                                {...field}
                                onChange={(event) => handleSelectClient(event)}
                                renderValue={(selected) => handleRenderClientList(selected)}>
                                {clientList ? (
                                  clientList.map((data: any) => (
                                    <MenuItem key={data} value={data.value}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                        }}>
                                        <Avatar
                                          sx={{
                                            width: '36px',
                                            height: '36px',
                                            marginRight: '10px',
                                          }}
                                          src={data.avatar}
                                        />
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}>
                                          <Typography>{data.name}</Typography>
                                          <Typography>{data.email}</Typography>
                                        </Box>
                                      </Box>
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem>No Client Found</MenuItem>
                                )}
                              </Select>
                              {errors.clientId && <ErrorMessage>{errors.clientId.message}</ErrorMessage>}
                            </Box>
                          )}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {/* Form content */}
            <Stack
              sx={{
                mt: '20px',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <Box
                component="form"
                sx={{
                  flex: 1,
                  mr: '30px',
                }}
                onSubmit={handleSubmit(handleSubmitForm)}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="name">
                      <InputLabel>
                        Full Name{' '}
                        <span
                          style={{
                            color: 'red',
                          }}>
                          *
                        </span>{' '}
                      </InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'name')}
                      />
                      {errors.name && <ErrorMessage>{errors?.name?.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="email">
                      <InputLabel>
                        Email{' '}
                        <span
                          style={{
                            color: 'red',
                          }}>
                          *
                        </span>{' '}
                      </InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'email')}
                      />
                      {errors.email && <ErrorMessage>{errors?.email?.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="address">
                      <InputLabel>
                        Address{' '}
                        <span
                          style={{
                            color: 'red',
                          }}>
                          *
                        </span>{' '}
                      </InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'address')}
                      />
                      {errors.address && <ErrorMessage>{errors?.address?.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="phoneNumber">
                      <InputLabel>
                        Phone Number{' '}
                        <span
                          style={{
                            color: 'red',
                          }}>
                          *
                        </span>{' '}
                      </InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'phoneNumber')}
                      />
                      {errors.phoneNumber && <ErrorMessage>{errors?.phoneNumber?.message}</ErrorMessage>}
                    </Box>
                  )}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                }}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="state">
                      <InputLabel>State</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'state')}
                      />
                      {errors.state && <ErrorMessage>{errors.state.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="zipCode">
                      <InputLabel>Zip Code</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'zipCode')}
                      />
                      {errors.zipCode && <ErrorMessage>{errors.zipCode.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="country">
                      <InputLabel>Country</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'country')}
                      />
                      {errors.country && <ErrorMessage>{errors.country.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="joinDate"
                  control={control}
                  render={({ field }) => (
                    <Box>
                      <InputLabel>Joined Date</InputLabel>
                      <Box sx={{ display: 'flex' }}>
                        <DatePicker
                          {...field}
                          disableFuture
                          renderInput={(params: TextFieldProps) => (
                            <TextField type="text" fullWidth {...params} size="small" />
                          )}
                        />
                      </Box>
                      {errors.joinDate && <ErrorMessage>{errors.joinDate.message}</ErrorMessage>}
                    </Box>
                  )}
                />
              </Box>
            </Stack>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mt: '20px',
                mb: '10px',
              }}>
              {mutationCreateUser.isLoading || handleUpdateUser.isLoading ? (
                <LoadingButton
                  sx={{
                    ...styles.submitBtn,
                  }}
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined">
                  Save
                </LoadingButton>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    ...styles.submitBtn,
                  }}>
                  Save
                </Button>
              )}
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mt: '20px',
                mb: '10px',
              }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  ...styles.submitBtn,
                }}
                onClick={() => handleCheckError()}>
                Next
              </Button>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <Box
            sx={{
              flex: 4,
              borderLeft: `1px solid ${theme.palette.divider}`,
              p: '20px 24px',
            }}>
            <Typography
              component="h2"
              sx={{
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                my: '20px',
              }}>
              Work Information
            </Typography>
            <Stack
              sx={{
                mt: '20px',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <Box
                sx={{
                  flex: 1,
                  mr: '30px',
                }}>
                <Controller
                  name="jobTitle"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="jobTitle">
                      <InputLabel>Job Title</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'jobTitle')}
                      />
                      {errors.jobTitle && <ErrorMessage>{errors.jobTitle.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="company">
                      <InputLabel>Company</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'company')}
                      />
                      {errors.company && <ErrorMessage>{errors.company.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="workLocation"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="workLocation">
                      <InputLabel>Work Location</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'workLocation')}
                      />
                      {errors.workLocation && <ErrorMessage>{errors.workLocation.message}</ErrorMessage>}
                    </Box>
                  )}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                }}>
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="department">
                      <InputLabel>Department</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'department')}
                      />
                      {errors.department && <ErrorMessage>{errors.department.message}</ErrorMessage>}
                    </Box>
                  )}
                />
                <Controller
                  name="manager"
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="manager">
                      <InputLabel>Reporting To</InputLabel>
                      <TextField
                        type="text"
                        size="small"
                        fullWidth
                        placeholder='Default: "None"'
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeField(e, 'manager')}
                      />
                      {errors.manager && <ErrorMessage>{errors.manager.message}</ErrorMessage>}
                    </Box>
                  )}
                />
              </Box>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mt: '20px',
                mb: '10px',
              }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  ...styles.submitBtn,
                }}
                onClick={() => {
                  setValueTab(2);
                }}>
                Next
              </Button>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <Box
            sx={{
              flex: 4,
              borderLeft: `1px solid ${theme.palette.divider}`,
              p: '20px 24px',
            }}>
            {isSuperAdmin && canBecomeSuperUser && (
              <>
                <Typography
                  component="h2"
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    my: '20px',
                  }}>
                  Super User (Optional)
                </Typography>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pb: '12px',
                  }}>
                  <Controller
                    name="isSuperUser"
                    control={control}
                    render={({ field }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '37.13px',
                        }}>
                        <InputLabel
                          sx={{
                            margin: '0px 10px 0px 0px',
                            lineHeight: '37.13px',
                            minWidth: '140px',
                          }}>
                          Set User as Super User
                        </InputLabel>
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={() => {
                            field.onChange(!field.value);
                          }}
                        />
                      </Box>
                    )}
                  />
                </Stack>
              </>
            )}
            <Typography
              component="h2"
              sx={{
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                my: '20px',
              }}>
              Idle Timeout Settings (Optional)
            </Typography>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                pb: '12px',
              }}>
              <Controller
                name="tokenExpiredIn"
                control={control}
                render={({ field }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '37.13px',
                    }}>
                    <InputLabel
                      sx={{
                        margin: '0px 10px 0px 0px',
                        lineHeight: '37.13px',
                        minWidth: '140px',
                      }}>
                      Type of Timeout
                    </InputLabel>
                    <Button
                      sx={{
                        height: '37.13px',
                        border: 'none',
                      }}>
                      <Select
                        sx={{
                          border: 'none',
                          minWidth: '120px',
                          height: '37.13px',
                        }}
                        fullWidth
                        {...field}
                        onChange={(e: any) => {
                          setValue('tokenExpiredIn', e.target.value);
                        }}>
                        {timeOutOptions.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Button>
                  </Box>
                )}
              />
            </Stack>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mt: '20px',
                mb: '10px',
              }}>
              {mutationCreateUser.isLoading || handleUpdateUser.isLoading ? (
                <LoadingButton
                  sx={{
                    ...styles.submitBtn,
                  }}
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined">
                  Save
                </LoadingButton>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    ...styles.submitBtn,
                  }}
                  onClick={() => {
                    if (
                      getValues('name') === '' ||
                      getValues('email') === '' ||
                      getValues('phoneNumber') === '' ||
                      getValues('address') === ''
                    ) {
                      toast.error('Please fill all required fields', { toastId: 1 });
                      handleCheckError();
                      setValueTab(0);
                    }
                  }}>
                  Save
                </Button>
              )}
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default UserProfile;