import { QUERY_KEY } from 'constants/constants';
import { get, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAnalyticBoxPlot,
  getAnalyticPolygon,
  getDataWaterUptakeAnalytic,
  updateInterval,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getChartsWaterUptake } from 'services/analytics/apiAnalyticsData.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeChartDataWaterUptakePreview,
  changeDataPolygonWaterUptakeAnalytics,
  changeDataWaterUptakeAnalytics,
  changeDataWaterUptakeInterval,
  waterUptakeAnalyticsSelector,
} from 'store/slices/map-view/waterUptakeAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import { TYPE_ANALYTICS_MAP_VIEW } from '../../../../../../common/defines/constants';
import SkeletonCharts from '../../../../../Common/SkeletonCharts';
import { WaterUptakeLayer } from '../../Layer/WaterUptake';
import { SwipeableViewsCustom } from '../../SwipeableViewsCustom';
import ChartsAnalytics from '../ChartsAnalytics/ChartsAnalytics';

export const WaterUptakeAnalytics = ({ uploadDate }: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { analyticId, isDefaultInterval } = useAppSelector(mapViewSelector);
  const { dataWaterUptakeInterval, dataWaterUptakePolygon, chartDataWaterUptakePreview } =
    useAppSelector(waterUptakeAnalyticsSelector);

  const [currentPage, setCurrentPage] = useState(1);

  useQuery(
    [QUERY_KEY.WATER_POLYGON, analyticId, currentPage],
    () => getAnalyticPolygon(analyticId || '', 'water-uptake', currentPage),
    {
      enabled: !!analyticId,
      onSuccess: (data: any) => {
        if (data.data?.nextPage && data.data.nextPage !== currentPage) {
          setCurrentPage(data.data?.nextPage);
        }
        if (data.data?.docs) {
          dispatch(changeDataPolygonWaterUptakeAnalytics([...dataWaterUptakePolygon, ...data.data?.docs]));
        }
      },
    }
  );

  const { data: dataWaterUptakeBoxPlot, isLoading } = useQuery(
    [QUERY_KEY.WATER_UPTAKE_BOX_PLOT, analyticId],
    () => getAnalyticBoxPlot(analyticId || '', 'water-uptake'),
    { enabled: !!analyticId }
  );

  useQuery(
    [QUERY_KEY.WATER_ANALYTIC, analyticId],
    () => {
      return getDataWaterUptakeAnalytic(analyticId || '');
    },
    {
      enabled: !!analyticId,
      onSuccess: (mainData: any) => {
        dispatch(changeDataWaterUptakeAnalytics(mainData?.data || []));
      },
    }
  );

  const { data: chartsData } = useQuery(
    [QUERY_KEY.CHARTS_WATER_ANALYTIC, isDefaultInterval],
    () => getChartsWaterUptake(analyticId || '', isDefaultInterval),
    {
      enabled: !!analyticId,
      onSuccess: (data) => {
        dispatch(
          changeDataWaterUptakeInterval({
            ...data.data?.intervalLimit,
            range: data.data?.intervalLimit.range.map((range: any, index: number) => ({
              ...range,
              visible: get(dataWaterUptakeInterval, `range[${index}].visible`, true),
            })),
          })
        );
      },
    }
  );

  const handleUpdateIntervals = useMutation(
    (data: any) => updateInterval(chartsData?.data?.intervalLimit?._id || '', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY.CHARTS_WATER_ANALYTIC, isDefaultInterval]).then(() => {});
      },
    }
  );

  const dataBoxPlot = useMemo(() => {
    if (!dataWaterUptakeBoxPlot?.data) return [];
    return dataWaterUptakeBoxPlot.data;
  }, [dataWaterUptakeBoxPlot]);

  return (
    <SwipeableViewsCustom
      analyticName={TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE}
      analyticTab={
        <>
          {!isLoading ? (
            <>
              {!isEmpty(chartsData?.data?.chartData) && (
                <ChartsAnalytics
                  dataChart={
                    chartDataWaterUptakePreview
                      ? chartDataWaterUptakePreview?.chartData
                      : chartsData?.data?.chartData || []
                  }
                  title={TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE}
                  getCharts={getChartsWaterUptake}
                  intervalRange={
                    chartDataWaterUptakePreview
                      ? chartDataWaterUptakePreview?.intervalLimit?.range
                      : chartsData?.data?.intervalLimit?.range || []
                  }
                  handleUpdateIntervals={handleUpdateIntervals}
                  queryKey={QUERY_KEY.CHARTS_WATER_ANALYTIC}
                  dataBoxPlot={dataBoxPlot}
                  changeChartDataPreview={changeChartDataWaterUptakePreview}
                />
              )}
            </>
          ) : (
            <SkeletonCharts count={1} />
          )}
        </>
      }
      layerTab={<WaterUptakeLayer labelCard={TYPE_ANALYTICS_MAP_VIEW.WATER_UPTAKE_TREE} />}
      isShowDownloadButton={!isLoading && !isEmpty(chartsData?.data?.chartData)}
    />
  );
};
