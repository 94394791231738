import { Grid, styled, Tab, Tabs, useTheme } from '@mui/material';
import { TabPanel } from 'components/MapView/TabPanel';
import { FC, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useAppDispatch } from 'store/hooks';
import { changeSelectedTabHistory } from 'store/slices/tilingMapSlice';
import Layer2dMap from './layer-2d-map';
import Layer3dMap from './Layer3dMap';

interface RightBarTilingMapProps {}

const RightBarTilingMap: FC<RightBarTilingMapProps> = () => {
  const [tabNumber, setTabNumber] = useState<number>(0);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const a11yProps = (index: number) => {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  };

  const TabStyled = styled(Tab)(() => ({
    textTransform: 'unset',
    color: '#CACED3',
    fontWeight: 500,
    fontSize: '12px',
    '&.Mui-selected': {
      color: '#3C4043',
    },
  }));

  const tabMenu = [
    {
      id: 0,
      label: 'Map 2D',
      disabled: false,
    },
    {
      id: 1,
      label: 'Map 3D',
      disabled: false,
    },
    // {
    //   id: 2,
    //   label: 'Download',
    //   disabled: false,
    // },
  ];

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default),
        height: 'fit-content',
        width: '100%',
      }}>
      <Grid direction="row" width="100%" height="100%">
        <Grid direction="row">
          <Tabs
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#22263199' : theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`,
              padding: '0px',
              margin: '0px',
            }}
            value={tabNumber}
            children={tabMenu.map((item: any, index: number) => {
              return (
                <TabStyled
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: '90px',
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                  key={item.id}
                  label={item.label}
                  {...a11yProps(item.id)}
                  onClick={() => {
                    setTabNumber(item.id);
                    dispatch(changeSelectedTabHistory({ id: item.id }));
                  }}
                  value={item.id}
                />
              );
            })}
          />
        </Grid>
        <Grid direction="row">
          <SwipeableViews index={tabNumber}>
            <TabPanel value={tabNumber} index={0}>
              <Layer2dMap />
            </TabPanel>
            <TabPanel value={tabNumber} index={1}>
              <Layer3dMap />
            </TabPanel>
            {/* <TabPanel value={tabNumber} index={2}>
              <DownloadTab />
            </TabPanel> */}
          </SwipeableViews>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightBarTilingMap;
