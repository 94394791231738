import { ExportFileEnum, IIssue, IIssuePagination } from 'components/MapView/RightBarOld/IssuesTab/interfaces';
import { sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest } from 'services';

export const getAllDateLandUseAnalytic = (levelId: string) =>
  sendGetRequest(`/data-analytics/getDateAnalysis/${levelId}`);

export const getColorsAnalyticLayer = (settingId: string, analyticId: string) =>
  sendGetRequest(`/client-setting/${settingId}/${analyticId}`);

export const saveColorsAnalyticLayer = (data: { analysisId: string; settingId: string; color: any }) =>
  sendPatchRequest(`/client-setting/analysis-layer/${data.settingId}`, {
    analysisId: data.analysisId,
    color: data.color,
  });

export const getAnalyticLayer = (clientId: string, analyticId: string) =>
  sendGetRequest(`/client-setting/analysis-color/${clientId}/${analyticId}`);

export const getListDisplayedIssues = (id: string) => sendGetRequest(`/issue/find-by-levelId/${id}`);

export const getListIssuesWithPagination = (params: IIssuePagination) => sendGetRequest('/issue/pagination', params);

export const uploadGeoTaggedPhoto = (data: FormData) => sendPostRequest(`/media/uploadGeoTaggedPhoto`, data);

export const getMediaInfo = (id: string) => sendGetRequest(`/media/${id}`);

export const createNewIssue = (data: Omit<IIssue, '_id' | 'isDisplayBoundaryLine' | 'isLayer3D'>) =>
  sendPostRequest('/issue', data);

export const changeIssueStatus = (params: { id: string; status: boolean }) =>
  sendPatchRequest(`/issue/update-status/${params.id}`, { status: params.status });

export const changeAllIssuesStatus = (params: { status: boolean }) => sendPatchRequest('/issue/check-all', params);

export const downloadIssueFile = (data: { id: string; fileType: ExportFileEnum }) =>
  sendGetRequest(`/issue/exportFile/${data.id}`, { fileType: data.fileType });

export const deleteIssue = (data: { id: string }) => sendDeleteRequest(`/issue/${data.id}`);

export const updateIssue = (data: IIssue) => sendPatchRequest(`/issue/${data._id}`, data);
