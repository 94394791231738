import { Box, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ComparedIndex, MapStateKey, TypeRaster } from 'common/defines/constants';
import { MapView } from 'components/MapView';
import { QUERY_KEY } from 'constants/constants';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getAllDateLandUseAnalytic } from 'services/MapView/apiMapViewConfig.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeListDateInfo, IDateInfo, IRaster, mapPopupSelector } from 'store/slices/mapPopupSlice';
import { mapViewSelector } from 'store/slices/mapViewSlice';

interface ComparedAnalyticProps {
  comparedIndex: ComparedIndex;
}

const initialDateInfo = {
  date: '',
  raster: {
    description: '',
    name: TypeRaster.RGB_MULTI_SPECTRAL,
    _id: '',
  },
};

const ComparedAnalytic: FC<ComparedAnalyticProps> = ({ comparedIndex }) => {
  const [dateOptionList, setDateOptionList] = useState<IDateInfo[]>([]);
  const [selectedDateInfo, setSelectedDateInfo] = useState<IDateInfo>(initialDateInfo);

  const dispatch = useAppDispatch();
  const { levelId, dateAnalyticSelected } = useAppSelector(mapViewSelector);
  const { listDateInfo } = useAppSelector(mapPopupSelector);

  useQuery([QUERY_KEY.DATE_HISTORY_LEVEL_M, levelId], () => getAllDateLandUseAnalytic(levelId!), {
    enabled: !!levelId,
    onSuccess(res) {
      const optionList: IDateInfo[] = res.data
        .map((item: { date: Date; rasters: IRaster[] }) => ({
          date: moment(item.date).format('YYYY-MM-DD'),
          raster: item.rasters.find((item) => item.name === TypeRaster.RGB_MULTI_SPECTRAL),
        }))
        .sort((a: { date: Date }, b: { date: Date }) => {
          return moment(a.date) < moment(b.date) ? 1 : -1;
        });
      setDateOptionList(optionList);
      if (optionList.length) {
        const initialDate = optionList.find((item) => item.date === dateAnalyticSelected);
        dispatch(changeListDateInfo([initialDate || optionList[0], optionList[0]]));
        if (comparedIndex === ComparedIndex.FIRST) setSelectedDateInfo(initialDate || optionList[0]);
        else if (comparedIndex === ComparedIndex.SECOND) setSelectedDateInfo(optionList[0]);
      }
    },
  });

  const handleChangeDate = (event: SelectChangeEvent) => {
    const selectedDate = dateOptionList.find((item) => item.date === event.target.value);
    if (selectedDate) {
      setSelectedDateInfo(selectedDate);
      const newListDateInfo = listDateInfo.map((item, index) => (index + 1 === comparedIndex ? selectedDate : item));
      dispatch(changeListDateInfo(newListDateInfo));
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Grid sx={{ mb: '20px' }} container>
        <Grid item xs={4}>
          <Typography sx={{ mr: '8px', display: 'inline', fontWeight: 500 }}>Date: </Typography>
          <Select
            value={selectedDateInfo.date}
            onChange={handleChangeDate}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size="small">
            {dateOptionList.map((item, index) => (
              <MenuItem key={index} value={item.date}>
                {item.date}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={8} sx={{ lineHeight: '32px' }}>
          <Typography sx={{ display: 'inline', fontWeight: 500 }}>Description: </Typography>
          <Typography sx={{ display: 'inline' }}>{selectedDateInfo.raster.description || 'No description'}</Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          '& .tool_map_icon': {
            display: 'none',
          },
          '& #minimap': {
            display: comparedIndex === ComparedIndex.SECOND ? 'none' : 'initial',
          },
          '& .minimap-box': {
            transform: 'scale(0.7)',
            left: '14px',
          },
          height: '760px',
          width: '100%',
        }}>
        <MapView
          navbarMapWidth={0}
          analyticPanelWidth={0}
          showAnalytics={false}
          openAnalytic={() => null}
          mapStateKey={MapStateKey.MAP_POPUP}
          comparedIndex={comparedIndex}
        />
      </Box>
    </Box>
  );
};

export default ComparedAnalytic;
