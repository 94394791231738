import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  Popover,
  Slider,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { HexColorPickerDebounce } from 'common/utils/HexColorPickerDebounce';
import { useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeColorSideSurface,
  changeSameColorTopSurface,
  changeSideSurfaceOpacity,
  mapViewSelector,
} from 'store/slices/mapViewSlice';
import { change3dPolygonValue, rightBarSelector } from 'store/slices/rightBarSlice';
import { SwitchCustom } from '../../../../../Common/SwitchCustom';

export const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 24,
    height: 24,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      position: 'absolute',
      top: '3px',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

interface SideSurfaceProps {
  id?: string;
}

export const SideSurface: FC<SideSurfaceProps> = ({ id = '' }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isDisplayExpand, setIsDisplayExpand] = useState(false);
  const dispatch = useAppDispatch();
  const classes = useLayerSelectCardStyle();
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'simple-popover' : undefined;

  const mapViewState = useAppSelector(mapViewSelector);
  const {
    issuesTab: { polygon3dList },
  } = useAppSelector(rightBarSelector);
  const foundPolygon3d = polygon3dList.find((item) => item.id === id);

  const { sideSurfaceOpacity, colorSideSurface, isSameColorTopSurface } = !id ? mapViewState : foundPolygon3d || {};

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = useCallback(
    (color: string) => {
      id ? dispatch(change3dPolygonValue({ id, colorSideSurface: color })) : dispatch(changeColorSideSurface(color));
    },
    [dispatch, id]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeOpacity = useCallback(
    (e: any) => {
      id
        ? dispatch(change3dPolygonValue({ id, sideSurfaceOpacity: e.target.value }))
        : dispatch(changeSideSurfaceOpacity(e.target.value));
    },
    [dispatch, id]
  );

  const handleChangeField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const trimInputValue = event.target.value.replace(/\s+/g, ' ');
      id
        ? dispatch(change3dPolygonValue({ id, colorSideSurface: trimInputValue }))
        : dispatch(changeColorSideSurface(trimInputValue));
    },
    [dispatch, id]
  );

  return (
    <>
      <Button
        startIcon={isDisplayExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        onClick={() => setIsDisplayExpand(!isDisplayExpand)}
        classes={{ root: classes.buttonTextStyle }}>
        {'Side Surface'}
      </Button>
      <Collapse in={isDisplayExpand} timeout="auto" unmountOnExit sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
              <PrettoSlider
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                value={sideSurfaceOpacity}
                max={1}
                min={0}
                step={0.1}
                onChange={handleChangeOpacity}
              />
              <Typography sx={{ pl: 2, minWidth: '100px' }}>
                {!sideSurfaceOpacity ? 'Transparent' : `Opacity: ${sideSurfaceOpacity}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ px: 1.5 }}>
              <FormControlLabel
                control={
                  <SwitchCustom
                    checked={isSameColorTopSurface}
                    onClick={() => {
                      id
                        ? dispatch(change3dPolygonValue({ id, isSameColorTopSurface: !isSameColorTopSurface }))
                        : dispatch(changeSameColorTopSurface(!isSameColorTopSurface));
                    }}
                  />
                }
                label={<Typography className={classes.selectText}>Same color as top surface</Typography>}
              />
            </Box>
          </Grid>
          {!isSameColorTopSurface && (
            <Grid item xs={12}>
              <Box
                className={classes.formBlockItem}
                sx={{
                  flex: 1,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  // padding: '24px 0 !important',
                  marginLeft: '-8px',
                }}>
                <Box className={classes.pickupColorBox}>
                  <Typography sx={{ pl: 1.5 }}>Custom Color</Typography>
                  <Tooltip title={openPopover ? 'Close pick color' : 'Open pick color'} placement="right" arrow>
                    <Box
                      onClick={(e: any) => handleClick(e)}
                      className={classes.pickupColorBtn}
                      sx={{
                        backgroundColor: colorSideSurface,
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box sx={{ paddingLeft: '10px' }}>
                  <TextField
                    id="cropTypeColor"
                    type="text"
                    size="small"
                    sx={{
                      color: colorSideSurface,
                      border: `1px solid ${colorSideSurface}`,
                      borderRadius: '5px',
                      width: '130px',
                    }}
                    fullWidth
                    value={colorSideSurface}
                    onChange={handleChangeField}
                  />
                </Box>

                <Popover
                  id={idPopover}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  sx={{
                    '.MuiPopover-paper': {
                      overflowX: 'initial',
                      overflowY: 'initial',
                    },
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  anchorOrigin={{
                    vertical: 130,
                    horizontal: 60,
                  }}>
                  <Box>
                    <HexColorPickerDebounce
                      style={{
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                        borderRadius: '10px',
                      }}
                      color={colorSideSurface}
                      onChange={handleChangeColor}
                    />
                  </Box>
                </Popover>
              </Box>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </>
  );
};
