import { Box, Card, Grid, Typography, useTheme } from "@mui/material"
import { useRightBarStyle } from "../../RightBarStyle";
// import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { CostPerMetricTonneBlocks } from "./CostPerMetricTonneBlocks";
import { CostPerBlock } from "./CostPerBlock";
import { useAppSelector } from "store/hooks";
import { bottomBarSelector } from "store/slices/map-view/bottomBarSlice";
import { dashboardWidgetSelector } from 'store/slices/dashboardWidgetSlice';
import { useEffect, useState } from "react";

export const CostTab = () => {
    const theme = useTheme();
    const classes = useRightBarStyle();
    const { dateRange } = useAppSelector(bottomBarSelector);
    const { productionCost } = useAppSelector(dashboardWidgetSelector);
    const [totalProduction, setTotalProduction] = useState<number>(0);
    const [averageProduction, setAverageProduction] = useState<number>(0);

    useEffect(() => {
        let totatlProduction: number = productionCost.reduce((sum, entry) => sum + entry.value, 0);
        let averageProduction: number = Number((totatlProduction / productionCost.length).toFixed(3)) || 0;
        setTotalProduction(Number(totatlProduction.toFixed(3)) || 0);
        setAverageProduction(averageProduction);
    }, [productionCost]);

    return (
        <Grid>
            <Card className={classes.totalProductionCardStyle}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box className={classes.borderRight}>
                            <Typography variant="h2" component="h2">Total Production</Typography>
                            <Typography variant="h1" component="h1" mt={1}>{totalProduction}</Typography>
                            <Typography variant="subtitle2" component="p" mt={1} sx={{ color: theme.palette.color.grey7 }}>Compared to last month</Typography>
                            {/* <span className={classes.Percentage}> <ArrowDownward fontSize="small" /> 12%</span> */}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant="h2" component="h2">Avg Production</Typography>
                            <Typography variant="h1" component="h1" mt={1}>{averageProduction} <small style={{ color: theme.palette.color.grey7 }}>/day</small> </Typography>
                            <Typography variant="subtitle2" component="p" mt={1} sx={{ color: theme.palette.color.grey7 }}>Compared to last month</Typography>
                            {/* <span className={classes.Percentage}><ArrowUpward fontSize="small" /> 12%</span> */}
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            <Card className={classes.shadowUnsetBorderRadius}>
                <CostPerMetricTonneBlocks dateRange={dateRange} />
            </Card>
            <Card className={classes.shadowUnsetBorderRadius}>
                <CostPerBlock dateRange={dateRange} />
            </Card>
        </Grid>)
}