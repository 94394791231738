import { RenderTree } from "common/defines/clients";
import { ICropTreeLevelObj } from "store/slices/mapViewSlice";

const cropTreeLevelSearch = (data: ICropTreeLevelObj[] | RenderTree[] | [] = [], selectedId: string = ''): ICropTreeLevelObj[] | [] => {

    function searchNode(node: ICropTreeLevelObj | any | {}): ICropTreeLevelObj[] | [] {
        if (node._id === selectedId) {
            return [node];
        }

        for (const child of node.children || []) {
            const result = searchNode(child);
            if (result.length > 0) {
                return [node].concat(result);
            }
        }

        return [];
    }

    for (const node of data) {
        const result = searchNode(node);
        if (result.length > 0) {
            return result;
        }
    }

    return [];
}

export default cropTreeLevelSearch