import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ICreateClientAdminFormData } from 'common/defines/clients';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createClientUserAdminAxios } from 'services/clients/apiClient.services';
import * as yup from 'yup';
import { useSignupPageStyle } from './SignupPageStyle';

const styles = {
  formStyles: {
    width: '310px',
    m: 'auto',
    fontFamily: 'Barlow',
  },
  crossLineStyles: {
    borderTop: 1,
    width: '94px',
    mt: 1.25,
  },
  labelStyles: {
    textAlign: 'left',
    fontSize: '18px',
    height: '24px',
  },
  textStyles: {
    fontSize: '14px',
    height: '17px',
  },
  fontStyles: {
    fontFamily: 'Barlow',
    '& .MuiInputBase-input': {
      height: '14px',
    },
  },
};

const signupValidationSchema = yup
  .object({
    name: yup.string().required('Full name is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup.string().required('Password is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    address: yup.string().required('Address is required'),
    state: yup.string().required('State is required'),
    zipCode: yup.string().required('Zipcode is required'),
    country: yup.string().required('Country is required'),
    jobTitle: yup.string().required('Job Title is required'),
    department: yup.string().required('Department is required'),
    company: yup.string().required('Company is required'),
    manager: yup.string().required('Manager is required'),
    workLocation: yup.string().required('WorkLocation is required'),
  })
  .required();

const SignupPage = () => {
  const navigate = useNavigate();
  const toastId = useRef('');
  const classes = useSignupPageStyle();
  const [showPassword, setShowPassword] = useState(false);
  const [termAccept, setTermAccept] = useState(false);
  const theme = useTheme();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICreateClientAdminFormData>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      phoneNumber: '',
      address: '',
      avatar: '',
      clientId: [],
      role: [],
      status: true,
      isSuperAdmin: false,
      isSuperUser: false,
      isFirstLogin: true,
      state: '',
      zipCode: '',
      country: '',
      jobTitle: '',
      department: '',
      company: '',
      manager: '',
      workLocation: '',
    },
    resolver: yupResolver(signupValidationSchema),
  });

  const handleChangeField = (e: any, fieldName: any) => {
    if (fieldName === 'phoneNumber') {
      setValue(fieldName, e.trim());
    } else if (fieldName === 'password') {
      setValue(fieldName, e.target.value.trim());
    } else {
      setValue(fieldName, e.target.value);
    }
  };
  const useCreateAccount = () => {
    return useMutation((param: ICreateClientAdminFormData) => createClientUserAdminAxios({ ...param }), {
      onSuccess: () => {
        toast.success('Create account success', { toastId: 1 });
        navigate('/login');
      },
      onError: (err: any) => {
        toast.error(err.data.message[0], { toastId: 1 });
      },
    });
  };
  const mutationCreateTreeLevel = useCreateAccount();

  const handleSubmitForm: SubmitHandler<ICreateClientAdminFormData> = (data) => {
    mutationCreateTreeLevel.mutate(data);
    toast.dismiss(toastId.current);
  };

  const handleAcceptTerm = (e: any) => {
    setTermAccept(e.target.checked);
  };

  return (
    <>
      <Typography
        component="div"
        sx={{
          height: '100vh',
          display: ' flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1F1F1F' : '#f9f9f9'),
        }}>
        <Stack
          direction="row"
          sx={{
            maxWidth: '1130px',
            m: 'auto',
            borderRadius: '10px',
            py: 5,
          }}>
          <Box
            className={classes.baseStyles}
            sx={{
              borderBottomRightRadius: '0px',
              borderTopRightRadius: '0px',
              width: '630px',
              backgroundColor: (theme) => theme.palette.background.default,
            }}>
            <Typography
              sx={{
                ...styles.fontStyles,
                mt: 6,
                ml: 10,
                fontSize: '36px',
                height: '48px',
                color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#3C4043'),
              }}
              gutterBottom
              component="div">
              Welcome back
            </Typography>
          </Box>
          <Box
            alignItems="center"
            className={classes.baseStyles}
            sx={{
              borderBottomLeftRadius: '0px',
              borderTopLeftRadius: '0px',
              // backgroundColor: 'white',
              width: '500px',
              border: `1px solid ${theme.palette.divider}`,
            }}>
            <Typography
              component="div"
              sx={{
                width: '100%',
                textAlign: 'right',
              }}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  navigate('/');
                  const theme = localStorage.getItem('theme');
                  localStorage.clear();
                  localStorage.setItem('theme', theme || 'light');
                }}
                sx={{
                  color: '#616161',
                }}>
                <CloseIcon />
              </IconButton>
            </Typography>
            <Typography
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '310px',
                ...styles.formStyles,
                textAlign: 'center',
              }}
              onSubmit={handleSubmit(handleSubmitForm)}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Full Name
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'name')}
                    />
                    {errors.name && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.name.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Email
                    </Typography>
                    <TextField
                      type="email"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'email')}
                    />
                    {errors.email && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.email.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Password
                    </Typography>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                        'input::-ms-reveal': {
                          display: 'none',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword}
                              sx={{
                                height: '24px',
                                width: '24px',
                              }}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'password')}
                    />
                    {errors.password && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.password.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        mb: '8px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Phone Number
                    </Typography>
                    <MuiPhoneNumber
                      sx={{
                        input: {
                          height: '14px',
                        },
                      }}
                      name="phoneNumber"
                      variant="outlined"
                      type="tel"
                      regions={'asia'}
                      dropdownClass="dropdown-container"
                      preferredCountries={['my', 'vn']}
                      disableAreaCodes={true}
                      autoFormat={true}
                      defaultCountry="my"
                      value="+60"
                      onChange={(e: any) => {
                        handleChangeField(e, 'phoneNumber');
                      }}
                    />
                    {errors.phoneNumber && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.phoneNumber.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Address
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'address')}
                    />
                    {errors.address && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.address.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Typography
                          component="div"
                          sx={{
                            mt: '20px',
                            ...styles.labelStyles,
                            ...styles.fontStyles,
                          }}>
                          State
                        </Typography>
                        <TextField
                          type="text"
                          sx={{
                            mt: '8px',
                            ...styles.fontStyles,
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'state')}
                        />
                        {errors.state && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              textAlign: 'left',
                              my: '10px',
                            }}>
                            {errors.state.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="zipCode"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Typography
                          component="div"
                          sx={{
                            mt: '20px',
                            ...styles.labelStyles,
                            ...styles.fontStyles,
                          }}>
                          Zip code
                        </Typography>
                        <TextField
                          type="text"
                          sx={{
                            mt: '8px',
                            ...styles.fontStyles,
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          onChange={(e) => handleChangeField(e, 'zipCode')}
                        />
                        {errors.zipCode && (
                          <Typography
                            component="div"
                            className="errorMsg"
                            sx={{
                              fontFamily: 'Barlow',
                              fontSize: '14px',
                              lineHeight: '12px',
                              color: '#FF4646',
                              textAlign: 'left',
                              my: '10px',
                            }}>
                            {errors.zipCode.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Country
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'country')}
                    />
                    {errors.country && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.country.message}
                      </Typography>
                    )}
                  </>
                )}
              />

              <Controller
                name="jobTitle"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Job Title
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'jobTitle')}
                    />
                    {errors.jobTitle && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.jobTitle.message}
                      </Typography>
                    )}
                  </>
                )}
              />

              <Controller
                name="department"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Department
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'department')}
                    />
                    {errors.department && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.department.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Company
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'company')}
                    />
                    {errors.company && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.company.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="manager"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Reporting to
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'manager')}
                    />
                    {errors.manager && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.manager.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <Controller
                name="workLocation"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography
                      component="div"
                      sx={{
                        mt: '20px',
                        ...styles.labelStyles,
                        ...styles.fontStyles,
                      }}>
                      Work Location
                    </Typography>
                    <TextField
                      type="text"
                      sx={{
                        mt: '8px',
                        ...styles.fontStyles,
                      }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(e) => handleChangeField(e, 'workLocation')}
                    />
                    {errors.workLocation && (
                      <Typography
                        component="div"
                        className="errorMsg"
                        sx={{
                          fontFamily: 'Barlow',
                          fontSize: '14px',
                          lineHeight: '12px',
                          color: '#FF4646',
                          textAlign: 'left',
                          my: '10px',
                        }}>
                        {errors.workLocation.message}
                      </Typography>
                    )}
                  </>
                )}
              />
              <FormControlLabel
                name="terms"
                sx={{
                  mt: '20px',
                }}
                label="I agree to the terms and conditions"
                control={
                  <Checkbox
                    icon={<CheckCircleOutlineIcon fontSize="small" />}
                    checkedIcon={<CheckCircleIcon fontSize="small" />}
                    onChange={(e) => handleAcceptTerm(e)}
                  />
                }
              />
              <Button
                type="submit"
                sx={{
                  ...styles.fontStyles,
                  textTransform: 'none',
                }}
                className={classes.signupBtn}
                data-testid="login-btn"
                disabled={termAccept ? false : true}>
                Sign Up
              </Button>
            </Typography>
            <Box sx={{ py: 2.5, mx: '94.555px' }}>
              <Typography component="div">
                <Typography
                  component="span"
                  sx={{
                    ...styles.fontStyles,
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fcfcfc' : '#3C4A53'),
                    textAlign: 'center',
                    mb: '20px',
                  }}>
                  Already have an account?
                </Typography>
                <Button
                  sx={{
                    ...styles.fontStyles,
                    textTransform: 'none',
                  }}
                  onClick={() => navigate('/login')}
                  data-testid="login-btn">
                  Sign In
                </Button>
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Typography>
    </>
  );
};

export default SignupPage;
