import ClientDashboard from 'components/ClientDashboard';
import ClientsForm from 'components/ClientsInfo/ClientsForm';
import MyProfilePage from 'components/Dashboard/UserInfo/MyProfilePage';
import ErrorsPage from 'components/ErrorsPage';
import SessionExpiredPage from 'components/ErrorsPage/SessionExpiredPage';
import PrivateRoute from 'components/PrivateRoute';
import TilingMap from 'components/TilingMap';
import { QUERY_KEY } from 'constants/constants';
import { NEW_VERSION_PATHS } from 'constants/enums/version-enums';
import AddEmailPage from 'pages/AddEmailPage';
import ChangePassword from 'pages/ChangePassword';
import DashboardPage from 'pages/DashboardPage';
import { DashboardAdminPage } from 'pages/DashboardPage/DashboardAdminPage';
import ForgotPassword from 'pages/ForgotPassword';
import { LandingAdminPage } from 'pages/LandingAdminPage';
import LoginPage from 'pages/LoginPage';
import { MapViewPage } from 'pages/MapViewPage';
import RequestInfomation from 'pages/RequestInfomation';
import SignupPage from 'pages/SignupPage';
import VerifyEmail from 'pages/VerifyEmailSuccess';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isValidToken } from 'services/auth';
import { queryMe } from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { savePermissions, saveSuperAdminOrSuperUser } from 'store/slices/superAdminSlice';

export const Root = () => {
  const dispatch = useAppDispatch();

  useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe(), {
    enabled: isValidToken(),
    onSuccess: (data) => {
      if (data) {
        const role = data.data.role;
        if (role?.length) {
          const permissions: { [key: string]: boolean } = {};
          role[0]?.permissions?.forEach((permission: string) => {
            permissions[permission] = true;
          });
          dispatch(savePermissions(permissions));
        }
        dispatch(saveSuperAdminOrSuperUser(data?.data));
      }
    },
  });

  return (
    <Routes>
      {/* <Route element={<LandingPage />} path="/" /> */}
      <Route
        element={
          <PrivateRoute>
            <LandingAdminPage />
          </PrivateRoute>
        }
        path="/landing"
      />
      <Route element={<LoginPage />} path="/" />
      <Route element={<LoginPage />} path="/login" />
      <Route element={<SignupPage />} path="/signup" />
      <Route element={<RequestInfomation />} path="/request-infomation" />
      <Route
        element={
          <PrivateRoute>
            <MyProfilePage />
          </PrivateRoute>
        }
        path="/profile"
      />
      <Route element={<AddEmailPage />} path="/add-email"></Route>
      <Route element={<ForgotPassword />} path="/forgot-password" />
      <Route element={<LinkedInCallback />} path="/linkedin" />
      <Route element={<ChangePassword />} path="/change-password/:tokenId" />
      <Route element={<VerifyEmail />} path="/verify-email/:tokenId" />
      <Route
        element={
          <PrivateRoute>
            <DashboardAdminPage />
          </PrivateRoute>
        }
        path="/dashboard-admin"
      />
      <Route
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
        path="/dashboard/:clientId"
      />
      {/* new dashboard */}
      <Route
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
        path={NEW_VERSION_PATHS.DASHBOARD + '/:clientId'}
      />
      <Route
        element={
          <PrivateRoute>
            <ClientsForm />
          </PrivateRoute>
        }
        path="/create-clients"
      />
      <Route
        element={
          <PrivateRoute>
            <ClientsForm />
          </PrivateRoute>
        }
        path="/clients/edit/:clientId"
      />
      <Route
        element={
          <PrivateRoute>
            <ClientDashboard />
          </PrivateRoute>
        }
        path="/clients/dashboard/:clientId"
      />
      <Route
        element={
          <PrivateRoute>
            <TilingMap />
          </PrivateRoute>
        }
        path="/tiling-map/:workspaceId"
      />
      <Route
        element={
          <PrivateRoute>
            <ClientDashboard />
          </PrivateRoute>
        }
        path="/clients/dashboard-admin"
      />
      <Route
        element={
          <PrivateRoute>
            <SessionExpiredPage />
          </PrivateRoute>
        }
        path="/session-expired"
      />
      <Route element={<ErrorsPage status="401" />} path="/401" />
      <Route element={<ErrorsPage status="404" />} path="/404" />
      <Route element={<ErrorsPage status="waiting" />} path="/waiting-approval" />
      <Route
        element={
          <PrivateRoute>
            <MapViewPage />
          </PrivateRoute>
        }
        path="/map-view/:clientId"
      />
      {/* New Map View */}
      <Route
        element={
          <PrivateRoute>
            <MapViewPage />
          </PrivateRoute>
        }
        path={NEW_VERSION_PATHS.MAP_VIEW + '/:clientId'}
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};
