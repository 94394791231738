import { Add } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Skeleton,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { makeStyles } from '@mui/styles';
import { QUERY_KEY } from 'constants/constants';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { isValidToken } from 'services/auth';
import { getClientListByPagination, logoutUser, queryMe } from 'services/clients/apiClient.services';
import {
  clearUserPermissionsAndUserType,
  savePermissions,
  saveSuperAdminOrSuperUser,
} from 'store/slices/superAdminSlice';
import { useAppDispatch } from '../../store/hooks';
import { setClientData } from '../../store/slices/clientSlice';
import { CardClient } from './CardClient';

const LandingAdminPageStyle = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    minWidth: '100vw',
    minHeight: '100vh',
    width: '100%',
    height: 'fit-content',
    backgroundColor: theme.palette.background.default,
  },
  body: {
    boxSizing: 'border-box',
    maxWidth: '80rem',
    width: '100%',
    height: '100%',
    margin: 'auto',
    padding: '80px 20px',
    '&.mobile': {
      width: 'auto',
      // margin: '20px',
    },
  },
  inputSearch: {
    marginRight: `${theme.spacing(1)} !important`,
    marginBottom: '10px !important',
    width: '350px',
    minWidth: '350px',
    height: '40px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '&.mobile': {
      width: '100%',
      minWidth: '100%',
    },
  },
  card: {
    width: '100%',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '& :hover': {
      backgroundColor: theme.palette.color.green5,
    },
  },
  skeleton: {
    height: '90px',
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: '160px',
  height: '40px',
  border: `1px solid ${theme.palette.divider}`,
  borderColor: theme.palette.color.green4,
  backgroundColor: theme.palette.color.green4,
  color: theme.palette.color.white,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.color.green4,
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.color.green4,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.color.green,
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.color.green4,
  },
  '&:focus': {
    border: `1px solid ${theme.palette.divider}`,
    borderColor: theme.palette.color.green4,
  },
}));

export const LandingAdminPage = () => {
  const classes = LandingAdminPageStyle();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuth = isValidToken();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchClient, setSearchClient] = useState('');
  const handleAddClient = () => {
    dispatch(setClientData(null));
    navigate('/create-clients');
  };
  const {
    data,
    remove: removeClient,
    isLoading,
  } = useQuery(
    [QUERY_KEY.CLIENT_DETAILS, currentPage, searchClient],
    () => getClientListByPagination(currentPage, 18, searchClient),
    { keepPreviousData: false, enabled: isAuth }
  );
  const theme = useTheme();

  const { data: checkSuperAdmin } = useQuery([QUERY_KEY.CHECK_SUPER_ADMIN], () => queryMe(), {
    keepPreviousData: false,
    onSuccess: (data) => {
      if (data) {
        const role = data.data.role;
        if (role?.length) {
          const permissions: { [key: string]: boolean } = {};
          role[0]?.permissions?.forEach((permission: string) => {
            permissions[permission] = true;
          });
          dispatch(savePermissions(permissions));
        }
        dispatch(saveSuperAdminOrSuperUser(data?.data));
      }
    },
  });

  const isSuperAdmin = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperAdmin;
  }, [checkSuperAdmin]);

  // superUser is same as superAdmin except for the permission to delete client
  const isSuperUser = useMemo(() => {
    return checkSuperAdmin?.data?.isSuperUser;
  }, [checkSuperAdmin]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchClient = useCallback(
    debounce((value: string) => {
      setSearchClient(value);
      setCurrentPage(1);
    }, 500),
    [debounce, setSearchClient]
  );

  const clientList = useMemo(() => {
    if (!data || !data.data?.data) {
      return [];
    }

    return data.data.data;
  }, [data]);

  useEffect(() => {
    return () => removeClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const counterLabel = useMemo(() => {
    if (!data?.data || isEmpty(data.data?.data)) {
      return null;
    }
    const indexStartPage = (data.data.metadata.currentPage - 1) * data.data.metadata.pageSize + 1;
    const indexEndPage = Math.min(
      data.data.metadata.currentPage * data.data.metadata.pageSize,
      data.data.metadata.totalCount
    );

    return (
      <Grid display="flex" justifyContent="flex-end" style={{ margin: '32px 0px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography>
            Showing {indexStartPage} to {indexEndPage} of {data.data.metadata.totalCount} clients
          </Typography>
          <Stack spacing={2}>
            <Pagination
              sx={{
                '& .MuiPagination-ul': {
                  '& .Mui-selected': {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: 'white',
                  },
                  '& .Mui-selected:hover': {
                    backgroundColor: '#23BE6AB0',
                  },
                  '& .MuiPaginationItem-icon': {
                    color: (theme) => theme.palette.primary.main,
                  },
                },
              }}
              count={data.data.metadata.pageCount}
              defaultPage={1}
              page={currentPage}
              onChange={(_, value) => {
                setCurrentPage(value);
              }}
            />
          </Stack>
        </Box>
      </Grid>
    );
  }, [data, setCurrentPage, currentPage]);

  // Call Logout API
  const useLogoutUser = useMutation((sessionId: string) => logoutUser(sessionId || ''), {
    onSuccess: () => {
      navigate('/login');
      dispatch(clearUserPermissionsAndUserType());
    },
    onError: (err) => {
      console.log('err', err);
    },
  });

  const handleLogOutCurrentUser = () => {
    const sessionId = localStorage.getItem('sessionLogin');
    if (!sessionId) {
      navigate('/login');
      const theme = localStorage.getItem('theme');
      localStorage.clear();
      localStorage.setItem('theme', theme || 'light');
    } else {
      const theme = localStorage.getItem('theme');
      localStorage.clear();
      localStorage.setItem('theme', theme || 'light');
      useLogoutUser.mutate(sessionId);
    }
  };

  const renderLoadingGrid = useMemo(
    () => (
      <Grid
        container
        sx={{
          marginTop: '20px',
          display: 'flex',
          columnGap: '71px',
        }}
        columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
        spacing={3}>
        {/* default of 8 skeleton items */}
        {Array.from(new Array(8)).map((item, index: number) => (
          <Grid item className={classes.skeleton} key={index}>
            <Grid className={classes.skeleton} display="flex">
              <Grid sx={{ width: '40px' }} display="flex" justifyContent="center" alignItems="center">
                <Skeleton animation="wave" variant="circular" width={40} height={40} />
              </Grid>
              <Grid sx={{ width: 'calc(100% - 40px)', pl: 2 }} display="flex" justifyContent="space-around">
                <Skeleton
                  sx={{
                    borderRadius: '4px',
                  }}
                  animation="wave"
                  variant="rectangular"
                  width={294}
                  height={60}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClear = () => {
    setSearchClient(''); //clear actual data
    const target: any = document?.querySelector('input[type=text]'); //detect search field make sure only have one input field on this screen
    target!.value = ''; //clear UI data without passing value as props on TextField -> to improve UX
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid className={classes.container}>
      <Scrollbars style={{ width: '100%', height: '100vh' }} autoHide>
        <Grid className={isMobile ? `${classes.body} mobile` : classes.body}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Tooltip title="Logout" arrow>
              <IconButton
                aria-label="delete"
                onClick={handleLogOutCurrentUser}
                sx={{
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    color: '#DA3E3E',
                  },
                }}>
                <LogoutRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Hidden mdDown smDown xsDown>
            {(isSuperAdmin || isSuperUser) && (
              <>
                <Typography variant="h1" children={'Agrimor'} mb={2} />
                <ButtonStyled
                  sx={{
                    mb: 2,
                    minWidth: '200px',
                    marginRight: '20px',
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={() => navigate(`/dashboard-admin`)}>
                  Agrimor Administrator
                </ButtonStyled>
              </>
            )}
          </Hidden>
          {/* <AddWorkspaces /> */}
          <Typography variant="h1" children={'Select Client'} mb={2} />
          <TextField
            type="text"
            className={isMobile ? `${classes.inputSearch} mobile` : classes.inputSearch}
            placeholder="Search Clients..."
            onChange={(e) => {
              debouncedSearchClient(e.target.value);
            }}
            InputProps={{
              size: 'small',
              endAdornment: (
                <InputAdornment position="end">
                  {searchClient ? (
                    <ClearIcon onClick={handleClear} sx={{ cursor: 'pointer', height: '24px', width: '24px' }} />
                  ) : (
                    <SearchIcon sx={{ height: '24px', width: '24px' }} />
                  )}
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  fontFamily: 'Barlow',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                },
              },
            }}
          />
          <Hidden mdDown smDown xsDown>
            {(isSuperAdmin || isSuperUser) && (
              <Button
                startIcon={<Add />}
                onClick={handleAddClient}
                sx={{
                  width: '160px',
                  height: '40px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#1C9D57',
                    border: `1px solid ${theme.palette.primary.main}`,
                    boxShadow: 'none',
                  },
                  '&:active': {
                    boxShadow: 'none',
                    backgroundColor: '#1C9D57',
                    border: `1px solid ${theme.palette.primary.main}`,
                  },
                  '&:focus': {
                    boxShadow: '0 0 0 0.1rem rgba(70, 255, 70, 0.15)',
                    border: `1px solid ${theme.palette.primary.main}`,
                  },
                }}>
                Add Client
              </Button>
            )}
          </Hidden>
          {isLoading ? (
            renderLoadingGrid
          ) : (
            <Grid
              container
              sx={{
                marginTop: '20px',
                display: 'flex',
                columnGap: '71px',
              }}
              columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
              spacing={1.5}>
              {clientList.length > 0 ? (
                clientList.map((client: any) => (
                  <Grid item className={classes.card} key={client._id}>
                    <CardClient client={client} />
                  </Grid>
                ))
              ) : (
                <Typography variant="h1" mb={2}>
                  No Clients Found Or You Are Not Authorized To View Clients
                </Typography>
              )}
            </Grid>
          )}
          {!isEmpty(data?.data) && counterLabel}
        </Grid>
      </Scrollbars>
    </Grid>
  );
};
