import {
  IClientType,
  ICreateClientSettings,
  ICreateClientsFormData,
  ISettingsClientMeasure,
  ISettingsClientUI,
  ISettingsFavoriteIconUI,
  ISizeConfigSetting,
  MeasurementUnit,
  SettingUIAnalysis,
  SettingUIIconFavorite,
  SettingUIName,
  SettingUISizePointAnalysis,
  SettingUnitName,
} from '../defines/clients';

export const clientsDetails = [
  {
    name: 'Sime Darby Plantation',
    avatar: 'https://iconape.com/wp-content/files/ri/184428/png/sime-darby-logo.png',
  },
  {
    name: 'FELDA',
    avatar: 'https://iconape.com/wp-content/files/gx/58669/svg/felda-logo.svg',
  },
  {
    name: 'John’s Farm',
    avatar: 'https://irp-cdn.multiscreensite.com/6004308c/dms3rep/multi/Johns-57px.png',
  },
  {
    name: 'Genting Plantation',
    avatar: 'https://www.gentingplantations.com/wp-content/themes/genting/images/favicon.ico',
  },
];

export const defaultClientUnitSetting: ISettingsClientMeasure[] = [
  {
    name: SettingUnitName.AREA,
    unit: MeasurementUnit.HA,
    decimalPlace: 5,
  },
  {
    name: SettingUnitName.LENGTH,
    unit: MeasurementUnit.KM,
    decimalPlace: 4,
  },
];

export const defaultClientUISetting: ISettingsClientUI[] = [
  {
    name: SettingUIName.STROKE_WIDTH,
    value: 2,
  },
  {
    name: SettingUIName.CONTOUR_3D_HEIGHT,
    value: 35,
  },
  {
    name: SettingUIName.CYLINDAR_3D_RADIUS,
    value: 1,
  },
];

export const defaultIconFavoriteSetting: ISettingsFavoriteIconUI = {
  name: SettingUIIconFavorite.FAVORITE_ICON,
  icon: 'StarRoundedIcon',
  color: '#FD48F1',
};

export const defaultAnalysisUISetting = [
  {
    name: SettingUIAnalysis.SHOW_HIDE_SLOPE_ANALYSIS,
    value: false,
  },
];


export const defaultSizePointSetting: ISizeConfigSetting = {
  isFixedPoint: false,
  value: null
};


export const defaultSideSurfaceSetting = {
  category: false,
  color: '#ffffff',
  opacity: 0,
};

export const defaultTopSurfaceSetting = {
  isSameSideSurfaceOpacity: false,
  opacity2D: 0,
};

export const defaultClientFormData: ICreateClientsFormData = {
  showCropDashboard: false,
  name: '',
  email: '',
  ssm: '',
  contactNumber: '',
  contactAddress: '',
  cropType: [],
  clientType: IClientType.INDEPENDENT,
  avatar: '',
  avatarFile: '',
  optionalProperties: [],
  additionalInformation: [],
};

export const defaultClientSettings: ICreateClientSettings = {
  unitSetting: defaultClientUnitSetting,
  uiSetting: defaultClientUISetting,
  sideSurfaceSetting: defaultSideSurfaceSetting,
  topSurfaceSetting: defaultTopSurfaceSetting,
  favouriteSetting: defaultIconFavoriteSetting,
  viewAnalysisPropperties: defaultAnalysisUISetting,
  sizeOfPointSetting: defaultSizePointSetting,

};

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';
export const LOCATION_KEY = 'location';
export const GOOGLE_CLIENT_KEY = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '';
export const MICROSOFT_CLIENT_KEY = process.env.REACT_APP_MICROSOFT_CLIENT_ID || '';
export const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID || '';
export const TWITTER_CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID || '';
export const REACT_APP_REQ_LINKEDIN = process.env.REACT_APP_CONSUMER_SECRET || '';
