import ReactEcharts from 'echarts-for-react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import agrimorIcon from '../../assets/icons/dashboard-logo-v4.jpg';

interface Filter {
  dateRange: { startDate: string; endDate: string };
  estateList: { name: string;[key: string]: any }[];
}

interface TableData {
  head: string[];
  data: any[][];
}

const normalText = (doc: any) => doc.setFont('helvetica', 'normal');
const boldText = (doc: any) => doc.setFont('helvetica', 'bold');
const spaceBelowAgrimor: number = 10;

const addHeader = (doc: any, pageWidth: number, filter: Filter) => {
  const newY: number = 52 + spaceBelowAgrimor;
  doc.addImage(agrimorIcon, (pageWidth - 20) / 2, 20, 20, 20);
  doc.setTextColor('#409576');
  doc.text('Agrimor', (pageWidth - doc.getTextWidth('Agrimor')) / 2, 45);
  doc.setTextColor('black');

  // Add Header
  const fromDateText = 'From Date: ';
  const toDateText = 'To Date: ';

  const fromDateWidth = doc.getTextWidth(fromDateText);
  const toDateWidth = doc.getTextWidth(toDateText);
  const startDateWidth = doc.getTextWidth(filter?.dateRange?.startDate);
  const endDateWidth = doc.getTextWidth(filter?.dateRange?.endDate);

  const centerTextX = (pageWidth - fromDateWidth - startDateWidth - toDateWidth - endDateWidth) / 2;

  boldText(doc);
  doc.text(fromDateText, centerTextX, newY);

  normalText(doc);
  doc.text(filter?.dateRange?.startDate, centerTextX + fromDateWidth, newY);

  boldText(doc);
  doc.text(toDateText, centerTextX + fromDateWidth + startDateWidth + 5, newY);

  normalText(doc);
  doc.text(filter?.dateRange?.endDate, centerTextX + fromDateWidth + startDateWidth + toDateWidth + 5, newY);
};

const addEstates = (doc: any, pageWidth: number, filter: Filter) => {
  const newY: number = 60 + spaceBelowAgrimor;
  const estateText = 'Estates: ';
  const estates = filter?.estateList?.map((es: any) => es?.name).join(', ');

  const estateTextWidth = doc.getTextWidth(estateText);
  const estateWidth = doc?.getTextWidth(estates);

  const centerEstateX = (pageWidth - estateTextWidth - estateWidth) / 2;

  boldText(doc);
  doc.text(estateText, centerEstateX, newY);

  normalText(doc);
  doc.text(estates, centerEstateX + estateTextWidth, newY);
};

// const addChartImage = (doc: any, ref: React.RefObject<ReactEcharts> | ReactEcharts | undefined) => {
//   const newY: number = 65 + spaceBelowAgrimor;
//   const width = doc.internal.pageSize.getWidth();
//   const chartInstance = ref instanceof ReactEcharts ? ref?.getEchartsInstance() : ref?.current?.getEchartsInstance();
//   const base64 = chartInstance?.getDataURL();
//   const imgData = base64 || ''; // Replace with your image data
//   doc.addImage(imgData, 'JPEG', (width - (width - 20)) / 2, newY, width - 20, 100);
// };

const base64ToImage = (base64: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
};

const addChartImage = async (
  doc: any,
  ref: React.RefObject<ReactEcharts> | ReactEcharts | undefined,
  chartType: string | undefined
) => {
  const newY: number = 65 + spaceBelowAgrimor;
  const pdfWidthInPoints = doc.internal.pageSize.getWidth();
  const pdfWidthInPixels = doc.internal.scaleFactor * pdfWidthInPoints;
  const chartInstance = ref instanceof ReactEcharts ? ref?.getEchartsInstance() : ref?.current?.getEchartsInstance();
  const base64 = chartInstance?.getDataURL();
  const imgData = base64 || '';

  let canvasImageWidthPoints = pdfWidthInPoints;
  let canvasImageWidthInPixels = pdfWidthInPixels;
  if (imgData) {
    await base64ToImage(imgData.toString())
      .then((image) => {
        canvasImageWidthPoints = image.width / doc.internal.scaleFactor;
        canvasImageWidthInPixels = image.width;
      })
      .catch((error) => {
        console.error('Error loading image:', error);
      });
  }

  let imageWidth = pdfWidthInPoints - (chartType === 'pie' ? 60 : 20);

  if (canvasImageWidthPoints <= pdfWidthInPoints) {
    if (canvasImageWidthPoints <= pdfWidthInPoints / 2) {
      imageWidth = canvasImageWidthPoints + 50;
    } else {
      imageWidth = canvasImageWidthPoints;
    }
  }

  doc.addImage(imgData, 'JPEG', (pdfWidthInPoints - imageWidth) / 2, newY, imageWidth, 85);
};

const addTableData = (doc: any, tableData: TableData) => {
  autoTable(doc, {
    startY: 175,
    // startY: 185,
    head: [tableData?.head],
    body: tableData?.data,
  });
};

const addBarHeader = (doc: any, headerText: string, pageWidth: number) => {
  doc.setFontSize(20);
  doc.text(headerText, (pageWidth - doc.getTextWidth(headerText)) / 2, 85);
  doc.setFontSize(12);
};

const pdfDownload = async (
  docName: string,
  tableData: TableData,
  ref: React.RefObject<ReactEcharts> | ReactEcharts | undefined,
  filter: Filter,
  header?: string,
  chartType?: string,
  orientation?: 'portrait' | 'landscape',
) => {
  const doc = new jsPDF({ orientation });
  doc.setFontSize(12);
  const pageWidth = doc.internal.pageSize.width;

  addHeader(doc, pageWidth, filter);
  addEstates(doc, pageWidth, filter);

  if (header) {
    addBarHeader(doc, header, pageWidth);
  }

  // Add image to the PDF
  await addChartImage(doc, ref, chartType);

  // Or use javascript directly:
  // Adding Table
  addTableData(doc, tableData);

  // Save the PDF
  doc.save(`${docName}.pdf`);
};

export default pdfDownload;
